<template>
    <m-main-layout>
        <add-button @click.native="$router.push({path:'/member/add'})" slot="right-btn" v-if="dataList.length > 0" rule="/member/add"></add-button>
        <div class="page-content-main">
            <el-table :data="dataList" default-expand-all style="width: 100%" row-key="id" :tree-props="{children: 'child'}" :indent="30" v-if="dataList.length > 0">
                <el-table-column
                        prop="username"
                        label="用户名">
                </el-table-column>
                <el-table-column
                        prop="group_name"
                        label="角色">
                </el-table-column>
                <el-table-column
                        prop="state"
                        label="状态" style="font-size:12px;" width="100">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.state === 1 ? 'success' : 'danger'" disable-transitions>{{scope.row.state === 0 ? '禁用' : '可用'}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="create_time"
                        label="创建时间"></el-table-column>
                <el-table-column
                        prop="update_time"
                        label="修改时间"></el-table-column>
                <el-table-column
                        prop="last_time"
                        label="最后登录时间">
                    <template slot-scope="scope">
                        {{scope.row.last_time === 0 ? '—' : $moment.unix(scope.row.last_time).format('YYYY-M-D HH:mm:ss')}}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="last_ip"
                        label="最后登录IP">
                </el-table-column>
                <el-table-column>
                    <template slot-scope="scope">
                        <edit-button @click.native="$router.push({path:'/member/edit',query:{id:scope.row.id}})" rule="/member/edit"></edit-button>
                        <del-button @click.native="del(scope.row.id)" rule="/member/del"></del-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="text-center null-data f14 info" v-else>
                还没有路由规则，<el-button type="primary" size="small" @click="$router.push({path:'/rule/path/add'})">立即添加</el-button>
            </div>
            <div class="text-center padding-top-bottom-20" v-if="dataList.length > 0">
                <el-pagination
                        background
                        layout="total,prev, pager, next"
                        :page-size="pageSize"
                        :current-page.sync="page"
                        :total="totalCount"
                        @current-change="pageChange">
                </el-pagination>
            </div>
        </div>
    </m-main-layout>
</template>

<script>
    export default {
        name: "MemberList",
        data(){
            return{
                dataList: [],
                totalPage:0,
                totalCount:0,
                pageSize:20,
                page:1,
            }
        },
        methods:{
            getList(){
                let _this = this;
                this.dataList = [];
                this.$api.memberList({page:this.page,pageSize:this.pageSize},function(res){
	                _this.dataList = res.list;

	                _this.totalPage = res.totalPage;
	                _this.totalCount = res.totalCount;
	                // _this.pageSize = res.pageSize;
                })
            },
	        del(id) {
		        var _this = this;
		        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
			        confirmButtonText: '确定',
			        cancelButtonText: '取消',
			        type: 'warning'
		        }).then(() => {
			        _this.$api.ruleDel({id:id},function(res){
				        _this.getList();
			        })
		        }).catch(() => {
		        });
	        },
	        pageChange(page){
		        this.page = page;
		        this.getList();
	        },
        },
        mounted() {
            this.getList();
        }
    }
</script>
