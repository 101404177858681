import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/main.css'
import Moment from 'moment';

Vue.use(ElementUI);

import Config from './script/config';
import HTTPs from './script/http';
import Utils from './script/utils'
import User from './script/user';
import Rule from './script/rule';
import sideMenu from './script/sideMenu';
import RegExp from './script/RegExp';
import EchartsJs from './echartScript/echartsJs';
import BaseConfig from './BaseConfig';
let echarts = require('echarts');

import Print from 'vue-print-nb'
Vue.use(Print);
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

Vue.prototype.$config = Config;
Vue.prototype.$http = HTTPs;
Vue.prototype.$utils = Utils;
Vue.prototype.$user = User;
Vue.prototype.$rule = Rule;
Vue.prototype.$moment = Moment;
Vue.prototype.$sideMenu = sideMenu;
Vue.prototype.$regExp = RegExp;
Vue.prototype.$echarts = echarts;
Vue.prototype.$echartsJs = EchartsJs;
Vue.prototype.$baseConfig = BaseConfig;


import VueUeditorWrap from 'vue-ueditor-wrap'
import Editor from './components/Editor';
import UserNavMain from './components/common/UserNavMain';
import MainLayout from './components/common/MainLayout'
import SideMenu from './components/common/SideMenu';
import TypeSelect from "@/components/widget/TypeSelect";
import YearSelect from "@/components/widget/YearSelect";
import EditButton from "@/components/button/EditButton";
import DelButton from "@/components/button/DelButton";
import AddButton from "@/components/button/AddButton";
import BaseButton from "@/components/button/BaseButton";
import MainBreadcrumb from "@/components/common/MainBreadcrumb";
import HeadImgUpload from "@/components/widget/HeadImgUpload";
import PushTime from "@/components/widget/PushTime";
import CompanySelect from "@/components/widget/CompanySelect";
import FileUpload from "@/components/widget/FileUpload";
import ProvinceSelect from "@/components/widget/ProvinceSelect";
import RangeTime from "@/components/widget/RangeTime";
import PriceTimeSelect from "@/components/widget/PriceTimeSelect";
import PriceVip from "@/components/widget/PriceVip";
import BlockVip from "@/components/widget/BlockVip";
import PutInt from "@/components/widget/PutInt";
import UEditor from "@/components/UEditor";
import CleanUEditor from '@/components/CleanUEditor';
import BarEcharts from "@/components/widget/BarEcharts";
import ImagePreView from './components/widget/ImagePreView'
import FilePreView from './components/widget/FilePreView'
import QuarterPicker from './components/widget/QuarterPicker'
import HalfPicker from './components/widget/HalfPicker'
import MonthPicker from './components/widget/MonthPicker'
import YearPicker from './components/widget/YearPicker'
import TreeSelect from './components/widget/TreeSelect'
import QuarterSelect from './components/widget/QuarterSelect'
import ProvincePicker from './components/widget/ProvincePicker'
import AutoMakeYearData from './components/widget/AutoMakeYearData'
import OneFileUpload from './components/widget/OneFileUpload'

Vue.component("edit-button", EditButton);
Vue.component("del-button", DelButton);
Vue.component("add-button", AddButton);
Vue.component("base-button", BaseButton);
Vue.component("tree-select", TreeSelect);
// Vue.component("editor", Editor);

Vue.component('m-main-layout',MainLayout);
Vue.component('m-side-menu',SideMenu);
Vue.component('m-user-nav-main',UserNavMain);
Vue.component('editor',UEditor);
Vue.component('clean-editor',CleanUEditor);
Vue.component('type-select',TypeSelect);
Vue.component('company-select',CompanySelect);
Vue.component('year-select',YearSelect);
Vue.component('m-breadcrumb',MainBreadcrumb);
Vue.component('head-img-upload',HeadImgUpload);
Vue.component('push-time',PushTime);
Vue.component('file-upload',FileUpload);
Vue.component('province-select',ProvinceSelect);
Vue.component('range-time',RangeTime);
Vue.component('price-time-select',PriceTimeSelect);
Vue.component('price-vip',PriceVip);
Vue.component('block-vip',BlockVip);
Vue.component('put-in',PutInt);
Vue.component('vue-ueditor-wrap',VueUeditorWrap);
Vue.component('bar-echarts', BarEcharts);
Vue.component('image-pre-view', ImagePreView);
Vue.component('file-pre-view', FilePreView);
Vue.component('quarter-picker', QuarterPicker);
Vue.component('half-picker', HalfPicker);
Vue.component('month-picker', MonthPicker);
Vue.component('year-picker', YearPicker);
Vue.component('quarter-select', QuarterSelect);
Vue.component('province-picker', ProvincePicker);
Vue.component('auto-make-year-data', AutoMakeYearData);
Vue.component('one-file-upload', OneFileUpload);


router.beforeEach((to, from, next) => {
  let _crossList = ['/login'];
  if(_crossList.indexOf(to.path) === -1){
    if(!User.getToken()){
      next({ path: '/login' })
    }else{
      next();
    }
  }else{
    next();
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
