<template>
	<div>
		<el-button type="warning" @click="toShow">生产年度数据</el-button>
		<el-dialog title="提示" :visible.sync="show" width="30%" @close="toClose">
			<el-form ref="form" :model="form" label-width="auto">
				<el-form-item label="年份：" prop="year" :rules="[{required:true,message:'年份不能为空'}]">
					<el-select v-model="form.year" clearable>
						<el-option :label="t.timeStr" :value="t.value" v-for="(t,i) in yearList"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="toClose">关闭</el-button>
				<el-button type="primary" @click="toSubmit">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "AutoMakeYearData",
	data() {
		return {
			show:false,
			form:{
				year:''
			},
			yearList:[]
		}
	},
	props:{
		getYearUrl:{
			type:String,
			default:''
		},
		submitUrl:{
			type:String,
			default:''
		},
		getYearData:{
			type:[Array,Object],
			default(){
				return {}
			}
		},
		submitData:{
			type:[Array,Object],
			default(){
				return {}
			}
		}
	},
	created() {
	},
	mounted() {
	},
	methods: {
		getYear(){
			let _this = this;
			if(!this.getYearUrl){
				return;
			}
			_this.$http.httpPost({
				url: this.getYearUrl,
				datas: {...this.getYearData},
				success: res => {
					_this.yearList = res;

				}
			})
		},
		toShow(){
			this.getYear();
			this.show = true;
		},
		toClose(){
			this.form.year = '';
			this.show = false;
		},
		toSubmit(){
			let _this = this;
			this.$refs.form.validate(valid => {
				if (valid) {
					if(!_this.submitUrl){
						return;
					}
					_this.$http.httpPost({
						url: this.submitUrl,
						datas: {year:this.form.year,...this.submitData},
						success: res => {
							_this.$message.success('操作成功');
							_this.toClose();
						}
					})
				}
			})
		}
	}
}
</script>
