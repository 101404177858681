<template>
	<div class="bg-light padding-20">
		<div>
			<el-button @click="$router.back()" icon="el-icon-arrow-left" class="margin-right">返回</el-button>
			<company-select :value="formData.companyList" :datas="{tid:formData.tid}" can-choose @change="onSelectCompany" style="width: 500px;"></company-select>
		</div>
<!--				{{formData}}-->
<!--		<el-form :model="formData">-->
<!--			<el-form-item>-->
<!--				<el-form-item label-width="300px" style="margin-bottom: 22px;" :label="item.title+':'" v-for="(item,index) in formData.companyList" :prop="'companyList.'+index+'.arank'" :rules="[{required:true,message:'排名不能为空',trigger:'blur'},{pattern:$regExp.positiveInteger,message: '只能输入正整数'}]">-->
<!--					排名：-->
<!--					<el-input v-model="item.arank" style="width: 100px;"></el-input>-->
<!--					<el-button size="mini" style="margin-left:10px;" @click="delCompany(item,index)">删除</el-button>-->
<!--				</el-form-item>-->
<!--			</el-form-item>-->
<!--		</el-form>-->
		<el-form :model="formData" ref="form">
			<el-table :data="formData.companyList">
				<el-table-column label="企业名称" width="400px">
					<template slot-scope="scope">
						{{ scope.row.title }}
					</template>
				</el-table-column>
				<el-table-column label="排名" width="300px">
					<template slot-scope="scope">
						<el-form-item :prop="'companyList.'+scope.$index+'.arank'" :rules="[{required:true,message:'排名不能为空'}]">
							<el-input-number v-model="scope.row.arank" :min="0" :max="99999" :step="1"></el-input-number>
						</el-form-item>
					</template>
				</el-table-column>
				<el-table-column>
					<template slot-scope="scope">
						<el-button size="mini" style="margin-left:10px;" @click="delCompany(scope.row,scope.$index)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-form>
		<div class="margin-top-20">
			<el-button type="primary" @click="toSubmit">保存</el-button>
		</div>
	</div>
</template>

<script>
export default {
	name: "choose",
	data() {
		return {
			formData: {
				tid:'',
				companyList: []
			},
			searchForm: {
				prize_id: ''
			},
			// totalCount: 0,
			// totalPage: 1
		}
	},
	created() {
		this.searchForm.prize_id = this.$route.query.tid ? this.$route.query.tid : '';
		this.formData.tid = this.$route.query.tid ? this.$route.query.tid : '';
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList() {
			let _this = this;
			this.$http.httpPost({
				url: '/PrizeCompanyChoose/listAll',
				datas: {...this.searchForm, orderBy:'arank',sortBy:'asc'},
				success: (res) => {
					let _list = [];
					for(let i=0;i < res.length;i++){
						_list.push({id:res[i].company_id,title:res[i].company.title,arank:res[i].arank});
					}
					_this.formData.companyList = _list;
					// _this.totalPage = res.totalPage;
					// _this.totalCount = res.totalCount;
				}
			});
		},
		delCompany(item,index){
			this.formData.companyList.splice(index,1);
		},
		onSelectCompany(e){
			let _ids = [];
			for(let i=0;i < this.formData.companyList.length;i++){
				_ids.push(this.formData.companyList[i].id);
			}

			for(let i=0;i < e.length;i++){
				if(_ids.indexOf(e[i].id) < 0){
					this.formData.companyList.push(e[i]);
				}
			}
		},
		toSubmit() {
			//存在回调的方法，因为回调外和回调内的作用域不一致，所以务必为 _this 复制 this，否则在回调中无法访问this对象
			let _this = this;
			//通过传递form表单ref名称，来调用表单的validate方法
			this.$refs['form'].validate(valid => {
				//为true时验证通过
				if (valid) {
					_this.create();
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		create() {
			let _this = this;

			let _list = [];
			for(let i=0;i < this.formData.companyList.length;i++){
				let _data = {
					prize_id:this.formData.tid,
					company_id:this.formData.companyList[i].id,
					arank:this.formData.companyList[i].arank,
				}
				_list.push(_data);
			}

			this.$http.httpPost({
				url: '/PrizeCompanyChoose/add',
				datas: {prize_id:this.formData.tid,companyList:JSON.stringify(_list)},
				success(res) {
					_this.$utils.success('保存成功！');
					_this.$router.back();
				}
			});
		},
	}
}
</script>
