<template>
  <div class="bg-light padding-20">
        <el-row :gutter="10">
            <el-form :model="searchForm" label-width="100px">
                <el-col :span="5">
                    <el-form-item label="年份">
                        <year-select v-model="searchForm.year" @change="change"></year-select>
                    </el-form-item>
                </el-col>
                <el-col :span="5" v-if="list.length > 0">
                    <del-button size="normal" @click.native="del(searchForm.year)" :label="'删除'+searchForm.year+'年数据'"></del-button>
                </el-col>
<!--                <el-col :span="5">-->
<!--                    <el-form-item label="是否免费">-->
<!--                        <type-select v-model="searchForm.is_free" :options="[{name:'是',id:'1'},{name:'否',id:'0'}]" :canAdd="false" style="width: 100%;"></type-select>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
                <el-col :span="4">
                    <el-button type="primary" @click="toSearch">搜索</el-button>
                    <el-button type="info" @click="toReset">重置</el-button>
                </el-col>
                <el-col :span="4">
                    <el-button type="warning" @click="toExport">导出</el-button>
                </el-col>
            </el-form>
        </el-row>
<!--        <el-form :model="totalForm" ref="total" :rules="totalRules" label-width="150px" v-if="list.length > 0">-->
<!--            <el-row>-->
<!--                <el-col :span="6">-->
<!--                    <el-form-item label="全国总营收" prop="amount">-->
<!--                        <el-input v-model="totalForm.amount">-->
<!--                            <template slot="append">亿元</template>-->
<!--                        </el-input>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col :span="6">-->
<!--                    <el-form-item label="全国市场规模" prop="shichang">-->
<!--                        <el-input v-model="totalForm.shichang">-->
<!--                            <template slot="append">亿元</template>-->
<!--                        </el-input>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col :span="6">-->
<!--                    <el-form-item label="入围门槛" prop="menkan">-->
<!--                        <el-input v-model="totalForm.menkan">-->
<!--                            <template slot="append">万元</template>-->
<!--                        </el-input>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col :span="6">-->
<!--                    <el-form-item>-->
<!--                        <add-button @click.native="saveTotal('total')" label="保存"></add-button>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--            </el-row>-->
<!--        </el-form>-->
        <div class="border-top" v-if="list.length > 0">
            <el-table
                ref="listtable"
                :data="list"
                @selection-change="selectionChange"
                style="width: 100%">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column label="企业名称" prop="title" width="300"></el-table-column>
                <el-table-column label="排名" prop="arank"></el-table-column>
                <el-table-column label="排名变化" prop="arank_change"></el-table-column>
                <el-table-column label="是否新增">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.is_new === 0 ? 'info' : 'danger'">{{scope.row.is_new === 0 ? '否' : '是'}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="企业性质" prop="nature.name"></el-table-column>
                <el-table-column label="地区" prop="province_name"></el-table-column>
                <el-table-column label="营收（万元）" prop="amount"></el-table-column>
<!--                <el-table-column label="是否免费查看数据" prop="amount">-->
<!--                    <template slot-scope="scope">-->
<!--                        <el-tag :type="scope.row.is_free === 1 ? 'success' : 'danger'" disable-transitions>{{scope.row.is_free === 0 ? '否' : '是'}}</el-tag>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column label="操作" width="100" align="right">
                    <template slot-scope="scope">
                        <edit-button @click.native="$router.push({ path: '/company/edit',query:{id:scope.row.id }})"></edit-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="margin-top">
                <base-button @click.native="selectAll">全选</base-button>
                <base-button icon="el-icon-close-notification" type="danger" plain @click.native="listChangeState('is_free','免费',0)">批量取消免费</base-button>
                <base-button icon="el-icon-close-notification" type="success" plain @click.native="listChangeState('is_free','免费',1)">批量设置免费</base-button>
            </div>
            <div class="text-center padding-top-bottom-20 rel">
                <el-pagination
                    background
                    layout="total,prev, pager, next"
                    :page-size="searchForm.pageSize"
                    :current-page.sync="searchForm.page"
                    :total="totalCount"
                    @current-change="pageChange">
                </el-pagination>
            </div>
        </div>
        <div class="padding-top-bottom-40 border-top text-center dark-2" v-else>
            暂无数据，<add-button @click.native="initData" :label="'初始化'+searchForm.year+'年数据'"></add-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "list",
    data(){
        return{
            searchForm: {
                pageSize:10,
                page:1,
                year:'',
                is_free:''
            },
            totalForm:{
                amount:0,
                shichang:0,
                menkan:0
            },
            listSort:{orderBy:'arank',sortBy:'asc'},
            list:[],
            totalCount:0,
            totalRules: {
                amount: [{required: true, message: '全国总营收必填'}],
                shichang: [{required: true, message: '全国市场规模必填'}],
                menkan: [{required: true, message: '入围门槛必填'}],
            },
            ids:[]
        }
    },
    created() {
        let _now = new Date();
        this.searchForm.year = _now.getFullYear()-1;

        this.searchForm = this.$route.query ? {...this.searchForm,...this.$route.query} : {...this.searchForm};
        this.searchForm.page = parseInt(this.searchForm.page);
        this.searchForm.pageSize = parseInt(this.searchForm.pageSize);
        this.searchForm.is_free = this.$route.query.is_free ? this.$route.query.is_free.toString() : '';
    },
    mounted() {
        this.getList();
        // this.getTotal();
    },
    methods: {
        listChangeState(type,text,val){
            let _this = this;
            if(this.ids.length > 0) {
                this.$confirm('是否批量改变'+this.ids.length+'条数据的'+text+'状态?', "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        let _datas = {};

                        _datas[type] = val;

                        this.$http.httpPost({
                            url: '/Company/allEdit',
                            datas: {id: [this.ids, 'in'],..._datas},
                            success(res) {
                                _this.$utils.success("操作成功！");
                                _this.getList();
                            }
                        });
                    })
                    .catch(() => {
                        // _this.$utils.warning('取消了');
                    });
            }else{
                this.$utils.warning('请选择要操作的数据');
            }
        },
        selectionChange(val){
            let _check = [];
            if(val.length > 0){
                for(let i =0; i < val.length;i++){
                    _check.push(val[i].id);
                }
            }

            this.ids = _check;
        },
        selectAll(){
            this.$refs.listtable.toggleAllSelection();
        },
        change(e){
            this.searchForm.year = e;

            this.$router.replace({query: {...this.searchForm}});
        },
        toSearch() {
            this.$router.replace({query: {...this.searchForm}});
        },
        toReset() {
            this.searchForm.is_free = '';

            this.$router.replace({query: {...this.searchForm}});
        },
        initData(){
            let _this = this;
            this.$http.httpPost({
                url: '/Company/initData',
                datas: {year:this.searchForm.year},
                success: (res) => {
                    console.log(res);
                    _this.getList();
                    // _this.getTotal();
                }
            });
        },
        pageChange(page){
            console.log(page);
            this.searchForm.page = page;
            this.$router.replace({query:{...this.searchForm}});
        },
        getList(){
            let _this = this;
            this.$http.httpPost({
                url: '/Company/listPage',
                datas: {...this.searchForm,...this.listSort},
                success: (res) => {
                    _this.list = res.list ? res.list : [];
                    _this.totalCount = res.totalCount;

                    if(_this.list.length > 0){
                        // _this.getTotal();
                    }
                }
            });
        },
        getTotal(){
            let _this = this;
            this.$http.httpPost({
                url: '/CompanyTotal/detail',
                datas: {year:this.searchForm.year},
                success: (res) => {
                    _this.totalForm.menkan = res.menkan;
                    _this.totalForm.amount = res.amount;
                    _this.totalForm.shichang = res.shichang;
                }
            });
        },
        saveTotal(formName) {
            //存在回调的方法，因为回调外和回调内的作用域不一致，所以务必为 _this 复制 this，否则在回调中无法访问this对象
            let _this = this;
            //通过传递form表单ref名称，来调用表单的validate方法
            this.$refs[formName].validate(valid => {
                //为true时验证通过
                if (valid) {
                    _this.$http.httpPost({
                        url: '/CompanyTotal/save',
                        datas: {..._this.totalForm,year:_this.searchForm.year},
                        success: (res) => {
                            if(res === 'ok'){
                                _this.$utils.success('保存成功');
                            }
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        del(year){
            let _this = this;
            this.$confirm("是否删除"+year+'年数据', "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.$http.httpPost({
                        url: '/Company/del',
                        datas: {year:year},
                        success(res) {
                            _this.$utils.success("删除成功！");
                            _this.getList();
                        }
                    });
                })
                .catch(() => {
                    // _this.$utils.warning('取消了');
                });
        },
        toExport(){
            let _this = this;
            this.$http.httpPost({
                download:true,
                url: '/Company/output',
                datas: {year:this.searchForm.year},
                success(res) {
                    console.log(res);
                    const blob = new Blob([res]) // 构造一个blob对象来处理数据
                    let num = _this.$moment().format('YYYYMMDD');

                    const fileName = '奖项企业统计' + num + '.xlsx';
                    const link = document.createElement('a') // 创建a标签
                    link.download = fileName // a标签添加属性
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click();
                    URL.revokeObjectURL(link.href);
                    document.body.removeChild(link);
                }
            });
        }
    }
}
</script>
