export default {
  '/divisions': {
    isData: true,
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: false,
    name: '地区设置',
    apiUrl: '/divisions',
    listRouter: '/divisions',
    editRouter: '/divisions/edit',
    listData: {orderBy: 'year desc,times', sortBy: 'desc', time_type: 'sxspxfl'},
    list: {
      com: '/divisions/index',
    },
    edit: {
      com: '',
    }
  },
}
