import Vue from 'vue';
let vm = new Vue();
import axios from 'axios'
import qs from 'qs';

import Config from './config'
import Utils from './utils'
import User from './user'
import Router from '../router'

import md5 from 'js-md5';
import Moment from 'moment';

// export default {
const http = {
    httpPost:function(obj){

        let _datas = Utils.isUndefined(obj.datas) ? null : qs.stringify(obj.datas);
        let _showLoad = Utils.isUndefined(obj.showLoad) ? false : obj.showLoad;
        let _heads = {'Content-Type':'application/x-www-form-urlencoded','Accept': 'application/json'};

        let _setting = {}

        if(obj.download){
            _setting = {responseType: 'arraybuffer'}
            // _heads = {
            //     // "response-Type": "blob",
            //     'Content-Type':'application/x-www-form-urlencoded',
            //     'Accept': 'application/json'
            // };
        }

        let loadingInstance = null;
        if(_showLoad) {
            loadingInstance = vm.$loading({lock:true});
        }

        if(obj.url !== '/Member/login'){
            _heads.Authorization = User.getToken();
        }

        let _time = Moment().unix();

        // let _secret = md5(Config.secret + _time.toString());
        //
        // _heads.secret = _secret;
        // _heads.timestamp = _time;

        // console.log(_heads);
        axios({
            ..._setting,
            headers:_heads,
            method: 'post',
            url: obj.url.indexOf('http') >= 0 ? obj.url : Config.apiUrl+obj.url,
            data:_datas
        }).then(function(res){
            if(loadingInstance){
                vm.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    loadingInstance.close();
                });
            }

            if(obj.download){
                obj.success(res.data);
            }else {
                if (res.data.code === 0) {
                    if (obj.success) {
                        obj.success(res.data.result);
                    }
                } else {
                    if (res.data.code === -1004 || res.data.code === -1002) {
                        vm.$message.error({message: '登录信息已失效，请重新登录', duration: 2000});
                        Utils.sessionClear();
                        Router.replace({path: '/'});
                    } else {
                        if (obj.fail) {
                            obj.fail(res.data);
                        } else {
                            vm.$message.error({message: res.data.msg, duration: 2000});
                        }
                    }
                }
            }
        },function(error){
            vm.$message.error(error);
        }).catch(function (error) {
            console.log(error);
            vm.$message.error(error.toString());
            if(loadingInstance){
                vm.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    loadingInstance.close();
                });
            }
        })
    },
    upLoad:function(file){
        let config = {
            //添加请求头
            headers: { "Content-Type": "multipart/form-data" },
            //添加上传进度监听事件
            onUploadProgress: e => {
                var completeProgress = ((e.loaded / e.total * 100) | 0) + "%";
                this.progress = completeProgress;
            }
        };

        let param = new FormData();
        param.append("imageFile", file);
        console.log(param.get("imageFile"));

        axios.post('http://miiheapi/user/up', param, config).then(
            function (response)
            { console.log(response); })
            .catch(function (error) {
                console.log(error);
            });
    }
};

export default http;
