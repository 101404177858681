<template>
    <div class="flex" style="justify-content:space-between;flex:1;background: #2f4c8f;">
        <div style="align-items:center;" class="flex">
            <el-button type="primary" class="margin-left f16" :icon="iconClass" size="mini" @click="toggleSide"></el-button>
        </div>
        <div style="align-items:center;" class="flex">
            <ul class="cl user-info-main">
                <li class="y">
                    <a type="primary" :class="linkClass" @click="quit()">退出</a>
                </li>
                <li class="y hand line-height-0">
                    <a @click="$router.push({path:'/changePass'})">修改密码</a>
                </li>
                <li class="y hand line-height-0">
                    <a href="#">
                        <div class="head-img">
                            <img src="https://avatar.pgyer.com/5dcb046297100a5f6a24b1130363c68f?imageMogr2/thumbnail/!200x200r/gravity/center/crop/200x200"/>
                            <span>{{$user.username()}}</span>
                        </div>
                    </a>
                </li>
                <li class="y">
                    <a :class="linkClass" class="rel" @click="$router.push({path:'/service/list'})">
                        <el-badge is-dot style="top:0;right:0;line-height: 0;" :hidden="hideBadge">
                            <i class="el-icon-message-solid"></i>
                        </el-badge>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "UserNavMain",
        data () {
            return {
                sideOpen:false,
                timeId:null,
                hideBadge:true
            }
        },
        props:{
            linkClass:{
                type:String,
                default:'dark-2'
            }
        },
        methods:{
            quit(){
                this.$utils.sessionClear();
                this.$router.replace({path:'/login'});
            },
	        toggleSide(){
            	this.sideOpen = !this.sideOpen;
            	this.$emit('sideToggle',this.sideOpen);
            },
            getServiceCount(){
                let _this = this;
                this.$http.httpPost({
                    url:'Service/getNewCount',
                    datas:null,
                    success:(res)=>{
                        if(res > 0){
                            _this.hideBadge = false;
                        }else{
                            _this.hideBadge = true;
                        }
                    }
                })
            },
        },
        computed:{
        	iconClass(){
        		if(this.sideOpen){
                    return 'el-icon-s-unfold';
                }else{
        			return 'el-icon-s-fold';
                }
            }
        },
        mounted() {
            // this.getServiceCount();
            // this.timeId = setInterval(this.getServiceCount,10000);
        },
    }
</script>
