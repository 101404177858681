<template>
	<div class="bg-light padding-20">
		<div class="margin-top">
<!--			{{formData}}-->
			<el-form :model="formData" :rules="rules" ref="form" :label-width="params.edit.labelWidth ? params.edit.labelWidth : '150px'">
				<el-form-item label="ID" v-if="formData && formData.id">
					{{ formData.id }}
				</el-form-item>
				<el-form-item label="年份" prop="year" v-if="params.edit.chooseYear" :rules="[{required: true,message:'年份必填'}]">
					<year-select v-model="year" @change="changeYear" style="width: 500px;"></year-select>
				</el-form-item>
<!--				<el-form-item label="季度" prop="quarter" v-if="params.edit.showQuarter && year >= 2024" :rules="[{required: true,message:'季度必填'}]">-->
<!--					<quarter-select v-model="quarter" @change="changeQuarter" style="width: 500px;"></quarter-select>-->
<!--				</el-form-item>-->
				<el-row :gutter="10">
					<el-col :span="24 / (item.cols ? item.cols : (params.edit.cols ? params.edit.cols : 1))" v-for="(item,index) in editParams">
						<template v-if="item.colums && item.colums.length > 0">
<!--							<el-form-item :label="item.name" :width="item.labelWidth ? item.labelWidth : '100px'" :class="item.type ==='editor' ? 'no-line-height' : ''" v-if="!item.show ? true : (typeof item.show === 'function' ? item.show(formData) : item.show)">-->
<!--								<el-form-item :rules="" v-for="(tt,ii) in item.colums">-->
<!--									<edit-form :params="params" :item="tt" :form-data="formData" :province="province" :times-quarter="timesQuarter" @onChangeTime="onChangeTime" @onChangeRadio="onChangeRadio" @changeVipPrice="changeVipPrice" @changeProvince="changeProvince"></edit-form>-->
<!--								</el-form-item>-->
<!--							</el-form-item>-->
						</template>
						<template v-else>
							<el-form-item :label="item.name" :prop="item.value" :width="item.labelWidth ? item.labelWidth : '100px'" :class="item.type ==='editor' ? 'no-line-height' : ''" v-if="!item.show ? true : (typeof item.show === 'function' ? item.show(formData) : item.show)">
								<edit-form :params="params" :item="item" :form-data="formData" :province="province" :times-quarter="timesQuarter" @onChangeTime="onChangeTime" @onChangeRadio="onChangeRadio" @changeVipPrice="changeVipPrice" @changeProvince="changeProvince"></edit-form>
							</el-form-item>
						</template>
					</el-col>
				</el-row>
				<el-form-item>
					<el-button @click="$router.back()">返回</el-button>
					<el-button type="primary" @click="submitForm('form')">保存</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import EditForm from './editForm'
export default {
	name: "PolicyEdit",
	components: {EditForm},
	data() {
		return {
			url: '',
			params: null,
			editParams: null,
			fileList: [],
			formData: {
				id: '',
				quarter: '',
			},
			editData: null,
			rules: {},
			province: '',
			year: '',
			quarter: '',
			priceType: 1,
			timesQuarter: '',
			times: ''
		};
	},
	created() {
		let _config = this.$baseConfig;
		let _path = this.$route.path.replace('/edit', '');

		if (this.$route.params.type) {
			this.$set(this.formData, 'type', this.$route.params.type);
		}

		this.params = _config[_path];

		this.editParams = this.params['edit']['params'];

		this.editData = this.params['edit']['editData'] ? this.params['edit']['editData'] : {};


		this.formData.id = this.$route.query.id ? this.$route.query.id : '';

		if (this.$route.query.pid) {
			this.formData.pid = this.$route.query.pid;
		}

		if (this.$route.query.company_id) {
			this.formData.company_id = this.$route.query.company_id;
		}

		for (let i = 0; i < this.editParams.length; i++) {
			// if(this.params[i].value === 'permission' || this.params[i].value === 'attachment') {
			//     this.$set(this.formData,this.params[i].value,[]);
			// }else{
			//     this.$set(this.formData,this.params[i].value,'');
			// }

			if (this.editParams[i].edit) {
				// console.log(this.editParams[i].def);
				// console.log(this.editParams[i].def === 0 || this.editParams[i].def);
				this.$set(this.formData, this.editParams[i].value, this.editParams[i].def === 0 || this.editParams[i].def ? this.editParams[i].def : '');

				if (this.editParams[i].type === 'vipPrice') {
					this.$set(this.formData, 'price', '');
				}

				this.rules[this.editParams[i].value] = [];

				if (this.editParams[i].rq) {
					this.rules[this.editParams[i].value].push({required: true, message: this.editParams[i].name + '必填'});
				}

				if (this.editParams[i].type === 'price') {
					this.rules[this.editParams[i].value].push({validator: this.$utils.rulePriceCanZero});
				} else if (this.editParams[i].type === 'number') {
					this.rules[this.editParams[i].value].push({validator: this.$utils.must09});
				} else if (this.editParams[i].type === 'float') {
					this.rules[this.editParams[i].value].push({pattern: this.$regExp.float, message: '请输入正确的数字格式'});
				} else if (this.editParams[i].type === 'percent') {
					this.rules[this.editParams[i].value].push({pattern: this.$regExp.percent, message: '请输入正确的百分比'});
				} else if (this.editParams[i].type === 'textarea') {
					// this.rules[this.editParams[i].value].push({validator:this.$utils.mustTrim});
					// this.rules[this.editParams[i].value].push({validator: this.$utils.mustNormalWord});
				} else {
					// this.rules[this.editParams[i].value].push({validator: this.$utils.mustNormalWord});
					if (this.editParams[i].regexp) {
						this.rules[this.editParams[i].value].push({pattern: this.$regExp[this.editParams[i].regexp], message: '请填写正确数值'});
					}
				}
			}

			// if(this.editParams[i].type === 'province'){
			//     this.province = {code:}
			// }
		}
	},
	mounted() {
		// this.getTypeList();
		if (this.formData.id) {
			this.getDetail();
			this.url = this.params.apiUrl + '/edit';
		} else {
			this.url = this.params.apiUrl + '/add';
		}
		// this.getPermission();
	},
	methods: {
		onChangeRadio(item){
			if(item.resetColumn && item.resetColumn.length > 0){
				for (let i=0;i < item.resetColumn.length;i++){
					if(item.resetColumn[i] === 'timesQuarter'){
						this.timesQuarter = ''
					}else {
						this.$set(this.formData, item.resetColumn[i], '')
					}
				}
			}
		},
		changeProvince(e) {
			this.province = e;
			this.formData.province_code = e.code;
			this.formData.province_name = e.name;
		},
		changeYear(e) {
			this.year = e;
			this.formData.year = e;
			this.formData.id = '';

			if (!this.params.edit.showQuarter) {
				this.getDetail();
			}
		},
		changeQuarter(e) {
			this.quarter = e;
			this.formData.quarter = e;
			this.formData.id = '';

			this.getDetail();
		},
		onChangeTime(e) {
			console.log(e);
			this.year = e.year;
			this.times = e.times;
			this.formData.year = e.year;
			this.formData.times = e.times;
			this.formData.id = '';
			this.getDetail();
		},
		getDetail() {
			let _this = this;
			let _data = {};
			if (this.params.edit.chooseYearQuarter) {
				_data = {id: this.formData.id, year: this.year, times: this.times, ...this.editData};
			} else if (this.params.edit.chooseYear) {
				_data = {id: this.formData.id, year: this.year, ...this.editData};
			} else {
				_data = {id: this.formData.id, ...this.editData};
			}

			this.$http.httpPost({
				url: this.params.apiUrl + '/detail',
				datas: _data,
				success(res) {
					if (res) {
						_this.formData = res ? JSON.parse(JSON.stringify(res)) : null;
						if (_this.params.edit.chooseYear && res.year) {
							_this.year = res.year;
						} else if (_this.params.edit.chooseYearQuarter && res.year) {
							_this.year = res.year;
							_this.times = res.times;
							_this.timesQuarter = res.year + (res.times ? '-' + res.times : '');
						}

						for (let i = 0; i < _this.editParams.length; i++) {
							if (_this.editParams[i].type === 'vip') {
								if (parseFloat(res.price) === 9.9) {
									_this.priceType = 1;
								} else if (parseFloat(res.price) === 19.9) {
									_this.priceType = 2;
								} else if (parseFloat(res.price) === 3880) {
									_this.priceType = 3;
								} else {
									_this.priceType = 4;
								}
							}
						}
					} else {
						// _this.formData = {};
						let _formData = {};

						for (let i = 0; i < _this.editParams.length; i++) {
							if(!_this.editParams[i].notReset) {
								if (_this.editParams[i].type === 'price' || _this.editParams[i].type === 'number') {
									// _this.$set(_this.formData, _this.editParams[i].value, _this.editParams[i].defaultValue ? _this.editParams[i].defaultValue : '0');
									_formData[_this.editParams[i].value] = _this.editParams[i].def ? _this.editParams[i].def : '0';
								} else if (_this.editParams[i].type === 'percent') {
									// _this.$set(_this.formData, _this.editParams[i].value, _this.editParams[i].defaultValue ? _this.editParams[i].defaultValue : '0.00');
									_formData[_this.editParams[i].value] = _this.editParams[i].def ? _this.editParams[i].def : '0.00';
								} else if (_this.editParams[i].type === 'vip') {

									// console.log(parseFloat(res.price));
									if (parseFloat(res.price) === 9.9) {
										_this.priceType = 1;
									} else if (parseFloat(res.price) === 19.9) {
										_this.priceType = 2;
									} else if (parseFloat(res.price) === 3880) {
										_this.priceType = 3;
									} else {
										_this.priceType = 4;
									}
								} else {
									console.log(_this.editParams[i].def === 0 || _this.editParams[i].def)
									_formData[_this.editParams[i].value] = _this.editParams[i].def === 0 || _this.editParams[i].def ? _this.editParams[i].def : '';
									// _this.$set(_this.formData, _this.editParams[i].value, _this.editParams[i].defaultValue ? _this.editParams[i].defaultValue : '');
								}
							}else{
								_formData[_this.editParams[i].value] = _this.formData[_this.editParams[i].value];
							}
						}

						if (_this.params.edit.chooseYear) {
							// _this.formData.year = _this.year;
							_formData.year = _this.year;
						} else if (_this.params.edit.chooseYearQuarter) {
							// _this.formData.year = _this.year;
							_formData.year = _this.year;
							// _this.formData.times = _this.times;
							_formData.times = _this.times;
						}

						_this.formData = JSON.parse(JSON.stringify(_formData));
					}

					if (_this.formData.province_code && _this.formData.province_name) {
						_this.province = {code: _this.formData.province_code, name: _this.formData.province_name};
					}
				}
			});
		},
		create() {
			let _this = this;

			if (this.formData.id) {
				this.url = this.params.apiUrl + '/edit';
			} else {
				this.url = this.params.apiUrl + '/add';
			}

			this.$http.httpPost({
				url: this.url,
				datas: {...this.formData, ...this.editData},
				success(res) {
					_this.$utils.success('保存成功！');
					_this.$router.back();
				}
			});
		},
		submitForm(formName) {
			//存在回调的方法，因为回调外和回调内的作用域不一致，所以务必为 _this 复制 this，否则在回调中无法访问this对象
			let _this = this;
			//通过传递form表单ref名称，来调用表单的validate方法
			this.$refs[formName].validate(valid => {
				//为true时验证通过
				if (valid) {
					_this.create();
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		changeVipPrice(e) {
			this.formData.price = e.price;
			this.formData.user_vip_type = e.user_vip_type;
			// if(e === 'free'){
			//     this.formData.price = 0.00;
			// }else if(e === 'user'){
			//     this.formData.price = 0.00;
			// }else if(e === 'vip'){
			//     this.formData.price = '9.9';
			//
			//     this.priceType = 1;
			// }
		},
	}
};
</script>
