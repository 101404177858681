<template>
  <div class="bg-light padding-20">
    <el-form
        :model="formData"
        status-icon
        :rules="rules"
        ref="form"
        label-width="auto"
        class="margin-top"
        style="width:800px;"
    >
        <el-form-item label="开启政策标准" prop="show_policy">
            <el-switch
                v-model="formData.show_policy"
                active-color="#13ce66"
                active-value="1"
                inactive-value="0"
                active-text="开启"
                inactive-text="关闭"
            >
            </el-switch>
        </el-form-item>
        <el-form-item label="开启新闻资讯" prop="show_news">
            <el-switch
                v-model="formData.show_news"
                active-color="#13ce66"
                active-value="1"
                inactive-value="0"
                active-text="开启"
                inactive-text="关闭"
            >
            </el-switch>
        </el-form-item>
        <el-form-item label="开启研究报告" prop="show_report">
            <el-switch
                v-model="formData.show_report"
                active-color="#13ce66"
                active-value="1"
                inactive-value="0"
                active-text="开启"
                inactive-text="关闭"
            >
            </el-switch>
        </el-form-item>
        <el-form-item label="开启问答中心" prop="show_question">
            <el-switch
                v-model="formData.show_question"
                active-color="#13ce66"
                active-value="1"
                inactive-value="0"
                active-text="开启"
                inactive-text="关闭"
            >
            </el-switch>
        </el-form-item>
        <el-form-item label="开启行业数据" prop="show_hydata">
            <el-switch
                v-model="formData.show_hydata"
                active-color="#13ce66"
                active-value="1"
                inactive-value="0"
                active-text="开启"
                inactive-text="关闭"
            >
            </el-switch>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitForm('form')">确定</el-button>
            <el-button type="" @click="$router.back()">返回</el-button>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
    name: "pay",
    data() {
        return {
            formData: {
                show_policy:'0',
                show_news: '0',
                show_question: '0',
                show_hydata:'0',
                show_report:'0'
            },
            rules: {

                // weixin_service_qrcode: [{required: true,message:'服务号二维码必填'}],
            }
        };
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        submitForm(formName) {
            let _this = this;
            this.$refs[formName].validate(valid => {
                if (valid) {
                    _this.$http.httpPost({
                        url: "/SystemSetting/edit",
                        datas: {..._this.formData},
                        success: res => {
                            if (res === "ok") {
                                _this.$message.success("修改成功");
                            } else {
                                _this.$message.error("修改失败");
                            }
                            // _this.$message.info(res.msg);
                            // _this.$router.replace({path: '/cate/list'})
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        getDetail() {
            let _this = this;

            let _skeys = [];

            for (let k in this.formData) {
                _skeys.push(k);
            }

            _this.$http.httpPost({
                url: "/SystemSetting/getSystem",
                datas: {skey: _skeys},
                success(res){
                    console.log(res);
                    _this.formData = res;
                    console.log(_this.formData);
                }
            });
        }
    }
};
</script>
