export default {
  '/prize': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showSearch: true,
    showCheck: true,
    stateOkText:'发布',
    stateErrorText:'撤回',
    stateErrorTip:'是否撤回该奖项？',
    stateOkTip:'是否发布该奖项？',
    showCheck2: true,
    stateOkText2:'结束报名',
    stateErrorText2:'恢复报名',
    stateErrorTip2:'是否恢复奖项报名？',
    stateOkTip2:'是否结束奖项报名？',
    checkStateKey2:'is_done',
    name: '奖项管理',
    apiUrl: '/Prize',
    listRouter: '/prize',
    editRouter: '/prize/edit',
    editPath: '/prize/edit',
    listData: null,
    optionsWidth:540,
    rightBottons: [
      // {label:'导出医药',path:'/Prize/outputyiyao',data:{}},
      // {label:'导出医疗器械',path:'/Prize/outputqixie',data:{}},
    ],
    list: {
      com: '',
      params: [
        // {name: 'ID', value: 'id'},
        {name: '奖项名称', value: 'jiangxiang.name',root:true,width: 120},
        {name: '年份', value: 'year',width: 80},
        {name: '已选择企业数量', value: 'choose_count',width: 100},
        {name: '报名企业数量', value: 'baoming_count',width: 100},
        // {name: '申报链接', value: 'id',type:'link',linkUrl:'http://api.test.chaincloud.org.cn/prize/join?id=',width: 300},
        // {name: '申报链接', value: 'id',type:'link',linkUrl:'/prize/join?id=',width: 350},
        {name: '是否发布', value: 'state', type: 'custom-select',options:[{label:'已发布',val:1,color:'success'},{label:'未发布',val:0,color:'info'}],width: 80},
        {name: '是否结束报名', value: 'is_done', type: 'custom-select',options:[{label:'已结束',val:1,color:'success'},{label:'报名中',val:0,color:'info'}],width: 80},
        // {name: '数据分类', value: 'mm_type'},
        // {name: '创建时间', value: 'create_time'},
      ],
      extButtons: [
        // {label:'发布奖项',path:'/prize/choose',key:'tid'},
        {label:'选择企业',path:'/prize/choose',key:'tid',router:true},
        {label:'报名审核',path:'/prize/baoming',key:'tid'},
        // {label:'导出数据',path:'/prize/output',data:{},call:true,download:true},
        // {label:'器械数据',path:'/company/qixie',router:'/company/qixie'}
      ]
    },
    edit: {
      editData: null,
      com: '/prize/edit',
      // com: '',
      params: [
        // {name: '奖项名称', value: 'title', rq: true, sc: true, edit: true, max: 40},
        {name: '奖项', value: 'type_jiangxiang_id', rq: true, sc: true, edit: true, type:'select',url:'/Type/listAll',data:{akey:'jiangxiang',orderBy:'sort',sortBy:'asc',state:1}},
        {name:'奖项年份',value:'year',rq:true,edit:true},
        // {name:'数据分类',value:'mm_type',type:'select',options:[{name:'药品',id:'medicine'},{name:'医疗器材',id:'apparatus'}],rq:true,sc:true,edit:true},
        // {name: '封面图', value: 'head_img', rq: true, sc: false, edit: true},
        {name: '奖项简介', value: 'prize_info', type: 'textarea', rq: true, sc: false, edit: true, max: 100},
        {name: '奖项要求', value: 'prize_need', type: 'textarea', rq: true, sc: false, edit: true, max: 100},
        {name: '奖项设置', value: 'prize_setting', type: 'textarea', rq: true, sc: false, edit: true, max: 100},
        {name: '奖项说明', value: 'html_content', type: 'editor', rq: true, sc: false, edit: true},
        // {name:'获奖企业',value: 'company_ids',type:'company',rq:true,sc:false,edit:true},
        // {name:'权限',value: 'permission',rq:true,sc:false},
      ]
    },
    search: {
      params: [
        {name: '奖项', value: 'type_jiangxiang_id', rq: true, sc: true, edit: true, type:'select',url:'/Type/listAll',data:{akey:'jiangxiang',orderBy:'sort',sortBy:'asc',state:1}},
        // {name: '状态', value: 'state',type:'select',options:[{name:'禁用',id:'0'},{name:'可用',id:'1'}]},
      ]
    }
  },
  '/prize/baoming': {
    showAdd: false,
    showDel: false,
    showEdit: false,
    showDetail: true,
    showSearch:true,
    showBack:true,
    name: '报名审核',
    apiUrl: '/PrizeCompanyItem',
    listRouter: '/prize/baoming',
    editRouter: '/prize/baoming/edit',
    editPath: '/prize/baoming/edit',
    detailRouter: '/prize/baoming/detail',
    listData: null,
    rightBottons: [
      // {label:'导出医药',path:'/Prize/outputyiyao',data:{}},
      // {label:'导出医疗器械',path:'/Prize/outputqixie',data:{}},
    ],
    list: {
      com: '',
      params: [
        // {name: 'ID', value: 'id'},
        {name: '企业名称', value: 'company.title',root:true},
        {name: '提交用户', value: 'user.username',root:true},
        // {name: '年份', value: 'year'},
        // {name: '企业数量', value: 'avalue'},
        // {name: '申报链接', value: 'id',type:'link',linkUrl:'http://localhost:8081/prize/join?id=',width:200},
        {name: '审核状态', value: 'state', type: 'custom-select',options:[{label:'已通过',val:1,color:'success'},{label:'待审核',val:0,color:'info'},{label:'未通过',val:2,color:'danger'}]},
        // {name: '数据分类', value: 'mm_type'},
        {name: '创建时间', value: 'create_time'},
      ],
      extButtons: [
        {label:'审核',type:'shenpi',rules:{key:'state',val:0},button_type:'primary',url:'/PrizeCompanyItem/toShenpi',column:{stateKey:'state',reasonKey:'reason'}},
        {label:'反审核',type:'changeState',rules:{key:'state',val:1},button_type:'primary',apiUrl:'/PrizeCompanyItem/changeStatus',key:'state',val:'0',text:'是否重置审核状态？'},
        {label:'反审核',type:'changeState',rules:{key:'state',val:2},button_type:'primary',apiUrl:'/PrizeCompanyItem/changeStatus',key:'state',val:'0',text:'是否重置审核状态？'},
        // {label:'报名审核',path:'/prize/baoming',key:'tid'},
        // {label:'导出数据',path:'/prize/output',data:{},call:true,download:true},
        // {label:'器械数据',path:'/company/qixie',router:'/company/qixie'}
      ]
    },
    edit: {
      editData: null,
      com: '/prize/edit',
      // com: '',
      params: [
        // {name: '奖项名称', value: 'title', rq: true, sc: true, edit: true, max: 40},
        {name: '奖项', value: 'type_jiangxiang_id', rq: true, sc: true, edit: true, type:'select',url:'/Type/listAll',data:{akey:'jiangxiang',orderBy:'sort',sortBy:'asc',state:1}},
        {name:'奖项年份',value:'year',rq:true,edit:true},
        // {name:'数据分类',value:'mm_type',type:'select',options:[{name:'药品',id:'medicine'},{name:'医疗器材',id:'apparatus'}],rq:true,sc:true,edit:true},
        // {name: '封面图', value: 'head_img', rq: true, sc: false, edit: true},
        {name: '奖项简介', value: 'prize_info', type: 'textarea', rq: true, sc: false, edit: true, max: 100},
        {name: '奖项要求', value: 'prize_need', type: 'textarea', rq: true, sc: false, edit: true, max: 100},
        {name: '奖项设置', value: 'prize_setting', type: 'textarea', rq: true, sc: false, edit: true, max: 100},
        {name: '奖项说明', value: 'html_content', type: 'editor', rq: true, sc: false, edit: true},
        // {name:'获奖企业',value: 'company_ids',type:'company',rq:true,sc:false,edit:true},
        // {name:'权限',value: 'permission',rq:true,sc:false},
      ]
    },
    detail: {
      com: '/prize/baomingDetail',
    },
    search: {
      params: [
        {name: '状态', value: 'state',type:'select',options:[{name:'待审核',id:'0'},{name:'已通过',id:'1'},{name:'未通过',id:'2'}]},
      ]
    }
  },
  '/prize/choose': {
    showAdd: false,
    showDel: false,
    showEdit: false,
    showDetail: false,
    showSearch:false,
    // showBack:true,
    name: '选择企业',
    // apiUrl: '/PrizeCompanyItem',
    listRouter: '/prize/choose',
    rightBottons: [
      // {label:'导出医药',path:'/Prize/outputyiyao',data:{}},
      // {label:'导出医疗器械',path:'/Prize/outputqixie',data:{}},
    ],
    list: {
      com: '/prize/choose',
      params:[]
    },
    edit: {
      com: '/prize/edit',
      
    },
  },
  
  '/company': {
    showAdd: false,
    showDel: false,
    showEdit: false,
    showSearch: true,
    showDetail: true,
    name: '企业管理',
    apiUrl: '/Company',
    listRouter: '/company',
    editRouter: '/company/edit',
    detailRouter: '/company/detail',
    list: {
      com: '',
      params: [
        {name: '名称', value: 'title',type:'html'},
        {name: '法人', value: 'faren'},
        {name: '企业性质', value: 'nature.name',root:true},
        {name: '企业属性', value: 'property.name',root:true},
        {name: '所在地', value: 'province_name',tem:[
            {name:'',val:'province_name'},
            {name:'',val:'city_name'},
          ]},
        {name: '创建人', value: 'userInfo.username',root:true},
        {name: '创建时间', value: 'create_time'},
      ],
      extButtons: [
        {label:'企业资讯',button_type:'primary',path:'/company/news',key:'company_id'},
      ]
    },
    edit: {
      com: '',
      cols: 4,
      params: [
      
      ]
    },
    detail: {
      com: '',
      cols: 4,
      params: [
        {name: '企业名称', value: 'title'},
        {name: '法人', value: 'faren'},
        {name: '企业性质', value: 'nature.name', root:true},
        {name: '企业属性', value: 'property.name', root:true},
        {name: '所在地', value: ['province_name','city_name'],type:'array-column'},
        {name: '主要网点', value: 'main_location'},
        {name: '合作客户', value: 'customer'},
        {name: '荣誉', value: 'honor'},
        {name: '企业宣传PPT', value: 'files',type:'manyFile'},
      ]
    },
    search: {
      params: [
        {name: '企业名称', value: 'title'},
      ]
    }
  },
  '/company/news': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showSearch: true,
    showBack: true,
    name: '新闻资讯',
    apiUrl: '/News',
    listRouter: '/company/news',
    editRouter: '/company/news/edit',
    list: {
      com: '',
      params: [
        // {name: 'ID', value: 'id'},
        {name: '新闻标题', value: 'title',width:300},
        {name: '分类', value: 'news.name', root:true,width: 100},
        {name: '来源', value: 'source', width: 120},
        // {name: '是否开启支付', value: 'is_pay',type:'bool'},
        {name: '价格', value: 'price',width: 50},
        {name: '权限', value: 'vip_txt'},
        // {name: '创建时间', value: 'create_time'},
      ]
    },
    edit: {
      com: '',
      params: [
        {name: '新闻标题', value: 'title', rq: true, sc: true, edit: true, max: 40},
        {name:'是否同步到公共资讯',value:'is_sync',rq: true, sc: true, edit: true,type:'radioGroup',def:0,options:[{label:'不同步',value:0},{label:'同步',value:1}],resetColumn:['type_news_id']},
        {name: '分类', value: 'type_news_id', rq: true, sc: true, edit: true, type:'select',url:'/Type/listAll',data:{akey:'news',orderBy:'sort',sortBy:'asc',state:1},show:row=>{
          if(row.is_sync === 1){
            return true;
          }else{
            return false;
          }
        }},
        {name: '封面图', value: 'head_img', rq: true, sc: false, edit: true},
        {name: '来源', value: 'source', rq: true, sc: false, edit: true, max: 20},
        {name: '内容', value: 'html_content', type: 'editor', rq: true, sc: false, edit: true},
        // {name:'是否开启支付',value:'is_pay',type:'state',rq:true,edit:true},
        {name: '开启收藏', value: 'open_favorite', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '开启点赞', value: 'open_like', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '开启评论', value: 'open_comment', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '访问权限', value: 'user_vip_type', type: 'vipPrice', rq: true, edit: true},
      ]
    },
    search: {
      params: [
        {name: '标题', value: 'title'},
        {name: '来源', value: 'source'},
        // {name: '是否开启支付', value: 'is_pay',type:'select',options:[{name:'是',id:'1'},{name:'否',id:'0'}]},
        {name: '创建时间', value: 'create_time', type: 'timeRange'}
      ]
    }
  },
}
