<template>
	<div class="bg-light padding-20">
		<el-form
			:model="formData"
			status-icon
			:rules="rules"
			ref="form"
			label-width="auto"
			class="margin-top"
			style="width:800px;"
		>
			<el-form-item label="最低开票金额" prop="less_kaipiao_price">
				<el-input v-model="formData.less_kaipiao_price" style="width:500px;"></el-input>
			</el-form-item>
			<el-form-item label="政策数量" prop="policy_count">
				<el-input-number v-model="formData.policy_count" :min="0" :step="1" :precision="0"></el-input-number>
			</el-form-item>
			<el-form-item label="标准数量" prop="standard_count">
				<el-input-number v-model="formData.standard_count" :min="0" :step="1" :precision="0"></el-input-number>
			</el-form-item>
			<el-form-item label="公司地址" prop="company_address">
				<el-input v-model="formData.company_address"></el-input>
			</el-form-item>
			<el-form-item label="邮编" prop="company_zip_code">
				<el-input v-model="formData.company_zip_code"></el-input>
			</el-form-item>
			<el-form-item label="联系电话" prop="company_phone">
				<el-input v-model="formData.company_phone"></el-input>
			</el-form-item>
			<el-form-item label="客服联系人" prop="service_person">
				<el-input v-model="formData.service_person"></el-input>
			</el-form-item>
			<el-form-item label="商务合作联系人" prop="business_person">
				<el-input v-model="formData.business_person"></el-input>
			</el-form-item>
			<el-form-item label="冷链委公众号二维码" prop="lenglianwei_gzh_qrcode">
				<head-img-upload v-model="formData.lenglianwei_gzh_qrcode"></head-img-upload>
			</el-form-item>
			<el-form-item label="食材供应链分会二维码" prop="shicai_gzh_qrcode">
				<head-img-upload v-model="formData.shicai_gzh_qrcode"></head-img-upload>
			</el-form-item>
			<el-form-item label="默认封面图" prop="base_head_img">
				<head-img-upload v-model="formData.base_head_img"></head-img-upload>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('form')">确定</el-button>
				<el-button type="" @click="$router.back()">返回</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
export default {
	name: "add",
	data() {
		return {
			formData: {
				less_kaipiao_price: "",
				base_head_img:'',
				lenglianwei_gzh_qrcode:'',
				shicai_gzh_qrcode:'',
				company_phone:'',
				company_address:'',
				company_zip_code:'',
				service_person:'',
				business_person:'',
				standard_count:'',
				policy_count:'',
			},
			rules: {
				less_kaipiao_price: [{required: true, message: '最低开票金额必填'}, {pattern: this.$regExp.money, message: '请输入正确的金额'}],
			}
		};
	},
	mounted() {
		this.getDetail();
	},
	methods: {
		submitForm(formName) {
			let _this = this;
			console.log(_this.formData);
			this.$refs[formName].validate(valid => {
				if (valid) {
					_this.$http.httpPost({
						url: "/SystemSetting/edit",
						datas: {..._this.formData},
						success: res => {
							if (res === "ok") {
								_this.$message.success("修改成功");
							} else {
								_this.$message.error("修改失败");
							}
							// _this.$message.info(res.msg);
							// _this.$router.replace({path: '/cate/list'})
						}
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		getDetail() {
			let _this = this;

			let _skeys = [];

			for (let k in this.formData) {
				_skeys.push(k);
			}

			_this.$http.httpPost({
				url: "/SystemSetting/getSystem",
				datas: {skey: _skeys},
				success: res => {
					_this.formData = res;
				}
			});
		}
	}
};
</script>
