<template>
  <div class="bg-light padding-20">
    <div class="bg-light" v-if="list.length > 0">
      <div class="padding bg-gray-3 dark-2 f16">当前合计数量：<b class="brand">{{totalCount}}</b></div>
      <el-table :data="list" style="width: 100%" ref="listtable" :fit="true">
        <el-table-column label="ID" prop="id"></el-table-column>
        <el-table-column label="奖项名称" prop="title"></el-table-column>
        <el-table-column label="创建时间" prop="create_time"></el-table-column>
        <el-table-column label="操作" align="right">
          <template slot-scope="scope">
<!--            <base-button @click.native="toPutout(scope.row)">导出数据</base-button>-->
            <edit-button @click.native="$router.push({ path:'/prize/edit',query:{id:scope.row.id }})"></edit-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="padding-top-bottom-20 text-center">
        <el-pagination
            background
            layout="total,prev, pager, next"
            :page-size="searchForm.pageSize"
            :current-page.sync="searchForm.page"
            :total="totalCount"
            @current-change="pageChange">
        </el-pagination>
      </div>
    </div>
    <el-dialog
        title="请选择年份"
        :visible.sync="showYear"
        width="30%" @close="toCloseYear">
      <div>
        <el-select v-model="year" placeholder="请选择" style="width: 100%;">
          <el-option
              v-for="item in yearList"
              :key="item"
              :label="item+'年'"
              :value="item">
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
      <el-button @click="toCloseYear">取 消</el-button>
      <el-button type="primary" @click="submitOutput">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "list",
  data(){
    return{
      showYear:false,
      list:[],
      totalCount:0,
      searchForm: {
        pageSize:15,
        page:1,
        mm_type:''
      },
      yearList:[],
      year:'',
      tid:''
    }
  },
  created() {
    this.searchForm.mm_type = this.$route.params.mm_type ? this.$route.params.mm_type : 'nongcp'
  },
  mounted() {
    this.getList();
  },
  methods:{
    toCloseYear(){
      this.yearList = [];
      this.tid = '';
      this.year = '';
      this.showYear = false;
    },
    getYears(){
      let _this = this;
      this.$http.httpPost({
        url: '/Prize/getYears',
        datas: {tid:this.tid},
        success(res) {
          _this.yearList = res;
        }
      });
    },
    submitOutput(){
      let _this = this;
      console.log(this.year);

      if(!this.year){
        this.$message.error('请选择年份');
        return;
      }

      this.$http.httpPost({
        download:true,
        url: '/Prize/output',
        datas: {id:this.tid,year:this.year},
        success(res) {
          console.log(res);
          const blob = new Blob([res]) // 构造一个blob对象来处理数据
          let num = _this.$moment().format('YYYYMMDD');

          const fileName = '导出' + num + '.xlsx';
          const link = document.createElement('a') // 创建a标签
          link.download = fileName // a标签添加属性
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click();
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
          _this.$utils.success("操作成功！");

          _this.showYear = false;
        }
      });
    },
    getList(){
      let _this = this;
      this.$http.httpPost({
        url: '/Prize/listPage',
        datas: {..._this.searchForm},
        success(res) {
          _this.list = res.list ? res.list : [];
          _this.totalCount = res.totalCount;
        }
      });
    },
    toPutout(e){
      this.showYear = true;
      this.tid = e.id;
      this.getYears();
    },
    pageChange(page) {
      this.searchForm.page = page;
      this.$router.replace({query:{...this.searchForm}});
    },
  }
}
</script>