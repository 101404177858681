<template>
	<div class="bg-light padding-20">
		<el-form ref="form" label-width="200px" v-if="info">
			<el-form-item :label="item.name" v-for="(item,index) in detailParams">
				<template v-if="item.type === 'sex'">
					{{ item.root ? ($utils.evalInfoGet(info, 'info', item.value) === 1 ? '男' : '女') : info[item.value] }}
				</template>
				<template v-else-if="item.type === 'image'">
					<template v-if="item.root">
						<el-image
							style="height: 60px"
							:src="$utils.evalInfoGet(info,'info',item.value).indexOf('http')>= 0 ? $utils.evalInfoGet(info,'info',item.value) : $config.assetsUrl + $utils.evalInfoGet(info,'info',item.value)"
							:preview-src-list="$utils.evalInfoGet(info,'info',item.value).indexOf('http')>= 0 ? [$utils.evalInfoGet(info,'info',item.value)] : [$config.assetsUrl + $utils.evalInfoGet(info,'info',item.value)]" v-if="$utils.evalInfoGet(info,'info',item.value)">
						</el-image>
						<span v-else>-</span>
						<!--                    <el-image-->
						<!--                        style="width: 100px; height: 100px"-->
						<!--                        :src="$config.assetsUrl + $utils.evalInfoGet(info,'info',item.value)"-->
						<!--                        fit="fill" v-if="$utils.evalInfoGet(info,'info',item.value)"></el-image>-->
						<!--                    <span v-else>-</span>-->
					</template>
					<span v-else>{{ info[item.value] }}</span>
				</template>
				<template v-else-if="item.type === 'bool'">
					<template v-if="item.root">
						<el-tag type="danger" disable-transitions v-if="$utils.evalInfoGet(info,'info',item.value) === 0">否</el-tag>
						<el-tag type="success" disable-transitions v-if="$utils.evalInfoGet(info,'info',item.value) === 1">是
						</el-tag>
					</template>
					<template v-else>
						<el-tag type="danger" disable-transitions v-if="info[item.value] === 0">否</el-tag>
						<el-tag type="success" disable-transitions v-if="info[item.value] === 1">是</el-tag>
					</template>
				</template>
				<template v-else-if="item.type === 'timeDate'">
					<template>{{ $moment.unix(info[item.value]).format('YYYY-MM-DD HH:mm:ss') }}</template>
				</template>
				<template v-else-if="item.type === 'manyFile'">
					<a target="_blank" :href="$utils.formatImagePath(tt.url)" v-for="(tt,ii) in $utils.evalInfoGet(info,'info',item.value)">{{tt.name}}</a>
				</template>
				<template v-else-if="item.type === 'state'">
					<template v-if="item.root">
						<el-tag type="danger" disable-transitions v-if="$utils.evalInfoGet(info,'info',item.value) === 0">禁用
						</el-tag>
						<el-tag type="success" disable-transitions v-if="$utils.evalInfoGet(info,'info',item.value) === 1">可用
						</el-tag>
					</template>
					<template v-else>
						<el-tag type="danger" disable-transitions v-if="info[item.value] === 0">禁用</el-tag>
						<el-tag type="success" disable-transitions v-if="info[item.value] === 1">可用</el-tag>
					</template>
				</template>
				<template v-else-if="item.type === 'custom-select'">
					<template v-for="(st,si) in item.options">
						<el-tag :type="st.color ? st.color : 'primary'" v-if="$utils.evalGet(info,'info',item.value) === st.value">
							{{ st.label }}
						</el-tag>
					</template>
					<!--            <template v-if="item.root">-->
					<!--              <el-tag type="danger" disable-transitions v-if="$utils.evalInfoGet(info,'info',item.value) === 0">禁用</el-tag>-->
					<!--              <el-tag type="success" disable-transitions v-if="$utils.evalInfoGet(info,'info',item.value) === 1">可用</el-tag>-->
					<!--            </template>-->
				</template>
				<template v-else-if="item.type === 'array-column'">
					<template v-if="item.value && item.value.length > 0">
						<template v-for="(t,i) in item.value">
							{{$utils.evalInfoGet(info,'info',t)}}
							<template v-if="i + 1 < item.value.length">-</template>
						</template>
					</template>
				</template>
				<template v-else>
					{{ item.root ? $utils.evalInfoGet(info, 'info', item.value) : info[item.value] }}
				</template>
			</el-form-item>
			<el-form-item>
				<el-button @click="$router.back()">返回</el-button>
			</el-form-item>
		</el-form>
		<div class="padding-top-bottom-40 dark-2 text-center" v-else>无数据</div>
	</div>
</template>

<script>
export default {
	name: "PolicyEdit",
	data() {
		return {
			url: '',
			params: null,
			detailParams: null,
			info: null,
			id: ''
		};
	},
	created() {
		let _config = this.$baseConfig;
		let _path = this.$route.path.replace('/detail', '');

		if (this.$route.params.type) {
			this.$set(this.formData, 'type', this.$route.params.type);
		}

		this.params = _config[_path];

		this.detailParams = this.params['detail']['params'];

		this.id = this.$route.query.id ? this.$route.query.id : '';
	},
	mounted() {
		if (this.id) {
			this.getDetail();
		}
	},
	methods: {
		getDetail(x) {
			let _this = this;
			this.$http.httpPost({
				url: this.params.apiUrl + '/detail',
				datas: {id: this.id},
				success(res) {
					_this.info = res ? res : null;

					// console.log(eval('_this.info.'+'user.avatar'));
				}
			});
		},
	}
};
</script>
