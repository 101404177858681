export default {
  '/news': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showSearch: true,
    name: '新闻资讯',
    apiUrl: '/News',
    listRouter: '/news',
    editRouter: '/news/edit',
    listData:{draft:'0'},
    list: {
      com: '',
      params: [
        // {name: 'ID', value: 'id'},
        {name: '新闻标题', value: 'title',width:300,type:'html'},
        {name: '是否置顶', value: 'sort',type:'bool',width:300},
        {name: '所属企业', value: 'companyName',root:true,width:300},
        {name: '分类', value: 'news.name', root:true,width: 100},
        {name: '来源', value: 'source', width: 120},
        // {name: '是否开启支付', value: 'is_pay',type:'bool'},
        {name: '价格', value: 'price',width: 50},
        {name: '权限', value: 'vip_txt'},
        {name: '创建时间', value: 'create_time'},
      ]
    },
    edit: {
      com: '',
      params: [
        {name: '新闻标题', value: 'title', rq: true, sc: true, edit: true, max: 60},
        {name: '分类', value: 'type_news_id', rq: true, sc: true, edit: true, type:'select',url:'/Type/listAll',data:{akey:'news',orderBy:'sort',sortBy:'asc',state:1}},
        {name: '是否置顶', value: 'sort', type: 'bool', def: 0, rq: false, sc: false, edit: true},
        {name: '封面图', value: 'head_img', rq: false, sc: false, edit: true},
        {name: '来源', value: 'source', rq: false, sc: false, edit: true, max: 20},
        {name: '内容', value: 'html_content', type: 'editor', rq: true, sc: false, edit: true},
        // {name:'是否开启支付',value:'is_pay',type:'state',rq:true,edit:true},
        {name: '开启收藏', value: 'open_favorite', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '开启点赞', value: 'open_like', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '开启评论', value: 'open_comment', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '访问权限', value: 'user_vip_type', type: 'vipPrice', rq: false, edit: true},
      ]
    },
    search: {
      params: [
        {name: '标题', value: 'title'},
        {name: '来源', value: 'source'},
        {name: '是否置顶', value: 'sort',type:'select',options:[{name:'是',id:1},{name:'否',id:0}]},
        // {name: '是否开启支付', value: 'is_pay',type:'select',options:[{name:'是',id:'1'},{name:'否',id:'0'}]},
        // {name: '创建时间', value: 'create_time', type: 'timeRange'}
      ]
    }
  },
  
  '/news/ready': {
    showAdd: false,
    showDel: true,
    showEdit: true,
    showSearch: true,
    showCheck: true,
    checkStateKey:'draft',
    okStateVal:0,
    errorStateVal:1,
    stateOkText:'发布',
    // stateErrorText:'撤回',
    // stateErrorTip:'是否撤回该奖项？',
    stateOkTip:'是否发布该资讯？',
    name: '资讯草稿箱',
    apiUrl: '/News',
    listRouter: '/news/ready',
    editRouter: '/news/ready/edit',
    listData:{draft:'1',orderBy:'collect_time',sortBy:'desc'},
    optionsWidth:280,
    list: {
      com: '',
      params: [
        // {name: 'ID', value: 'id'},
        {name: '新闻标题', value: 'title',width:300,type:'html'},
        {name: '是否置顶', value: 'sort',type:'bool',width:300},
        {name: '所属企业', value: 'companyName',width:300},
        {name: '分类', value: 'news.name', root:true,width: 100},
        {name: '来源', value: 'source', width: 120},
        // {name: '是否开启支付', value: 'is_pay',type:'bool'},
        // {name: '价格', value: 'price',width: 50},
        // {name: '权限', value: 'vip_txt'},
        {name: '抓取网站', value: 'site',width: 100},
        // {name: '抓取网址', value: 'collect',type:'link',width: 400},
        {name: '创建时间', value: 'create_time',width: 160},
        {name: '抓取时间', value: 'collect_time',width: 160},
      ]
    },
    edit: {
      com: '',
      params: [
        {name: '新闻标题', value: 'title', rq: true, sc: true, edit: true, max: 60},
        {name: '分类', value: 'type_news_id', rq: true, sc: true, edit: true, type:'select',url:'/Type/listAll',data:{akey:'news',orderBy:'sort',sortBy:'asc',state:1}},
        {name: '是否置顶', value: 'sort', type: 'bool', def: 0, rq: false, sc: false, edit: true},
        {name: '封面图', value: 'head_img', rq: false, sc: false, edit: true},
        {name: '来源', value: 'source', rq: false, sc: false, edit: true, max: 20},
        {name: '内容', value: 'html_content', type: 'editor', rq: false, sc: false, edit: true},
        // {name:'是否开启支付',value:'is_pay',type:'state',rq:true,edit:true},
        {name: '开启收藏', value: 'open_favorite', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '开启点赞', value: 'open_like', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '开启评论', value: 'open_comment', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '访问权限', value: 'user_vip_type', type: 'vipPrice', rq: false, edit: true},
      ]
    },
    search: {
      params: [
        {name: '标题', value: 'title'},
        {name: '来源', value: 'source'},
        {name: '是否置顶', value: 'sort',type:'select',options:[{name:'是',id:1},{name:'否',id:0}]},
        // {name: '是否开启支付', value: 'is_pay',type:'select',options:[{name:'是',id:'1'},{name:'否',id:'0'}]},
        // {name: '创建时间', value: 'create_time', type: 'timeRange'}
      ]
    }
  },
}
