<template>
  <div class="bg-light padding-20">
    <el-form
        :model="formData"
        status-icon
        :rules="rules"
        ref="form"
        label-width="auto"
        class="margin-top"
        style="width:800px;"
    >
        <el-form-item label="smtp服务器" prop="mail_host">
            <el-input v-model="formData.mail_host" style="width:500px;">
            </el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="mail_username">
            <el-input v-model="formData.mail_username" style="width:500px;">
            </el-input>
        </el-form-item>
        <el-form-item label="密码" prop="mail_password">
            <el-input v-model="formData.mail_password" style="width:500px;">
            </el-input>
        </el-form-item>
        <el-form-item label="端口号" prop="mail_port">
            <el-input v-model="formData.mail_port" style="width:500px;">
            </el-input>
        </el-form-item>
        <el-form-item label="发件人" prop="mail_from">
          <el-input v-model="formData.mail_from" style="width:500px;"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitForm('form')">确定</el-button>
            <el-button type="" @click="$router.back()">返回</el-button>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
    name: "add",
    data() {
        return {
            formData: {
              mail_host: "",
              mail_username: "",
              mail_password: "",
              mail_port: "",
              mail_from:''
            },
            rules: {
              mail_host: [{required: true,message:'邮件服务器必填'}],
              mail_username: [{required: true,message:'用户名必填'}],
              mail_password: [{required: true,message:'密码必填'}],
              mail_port: [{required: true,message:'端口号必填'}],
              mail_from: [{required: true,message:'发件人必填'}],
            }
        };
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        submitForm(formName) {
            let _this = this;
            console.log(_this.formData);
            this.$refs[formName].validate(valid => {
                if (valid) {
                    _this.$http.httpPost({
                        url: "/SystemSetting/edit",
                        datas: {..._this.formData},
                        success: res => {
                            if (res === "ok") {
                                _this.$message.success("修改成功");
                            } else {
                                _this.$message.error("修改失败");
                            }
                            // _this.$message.info(res.msg);
                            // _this.$router.replace({path: '/cate/list'})
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        getDetail() {
            let _this = this;

            let _skeys = [];

            for (let k in this.formData) {
                _skeys.push(k);
            }

            _this.$http.httpPost({
                url: "/SystemSetting/getSystem",
                datas: {skey: _skeys},
                success: res => {
                    _this.formData = res;
                }
            });
        }
    }
};
</script>
