<template>
    <div :id="id"></div>
</template>

<script>
import wangEditor from 'wangeditor'
export default {
    name: "Editor",
    data(){
        return{
            editor: null,
            editorData: '',
            isInit:false,
            id:''
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        value:String
    },
    created() {
        this.id = this.$utils.randomWords(8);
    },
    mounted() {
        this.createEditor();
    },
    watch:{
        value(n,o){
            if(!this.isInit) {
                this.editor.txt.html(n);
                this.isInit = true;
            }
        }
    },
    methods:{
        createEditor(){
            let _this = this;
            const editor = new wangEditor('#'+this.id);
            // 配置 onchange 回调函数，将数据同步到 vue 中
            editor.config.onchange = (newHtml) => {
                this.editorData = newHtml;
                this.$emit('change',newHtml)
            }
            editor.config.uploadImgServer = this.$config.uploadUrl;
            editor.config.uploadFileName = 'file';
            editor.config.uploadImgMaxLength = 1;
            editor.config.zIndex = 1;
            editor.config.height = 200
            editor.config.uploadImgHeaders = {
                Authorization : this.$user.getToken()
            }
            editor.config.uploadImgParams = {
                key: 'article',
            }
            editor.config.uploadImgHooks = {
                customInsert: function(insertImgFn, result) {
                    if(result.code === 0) {
                        // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
                        insertImgFn(result.result.url.indexOf('http') >= 0 ? result.result.url : _this.$config.assetsUrl + result.result.url);
                    }else{
                        _this.$message.error({message: result.msg, duration: 2000});
                    }
                }
            }

            // 创建编辑器
            editor.create()

            // console.log(this.value);

            editor.txt.html(this.value);

            this.editor = editor
        },
    }
}
</script>
