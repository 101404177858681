<template>
  <div class="bg-light padding-20">
    <el-button @click="$router.back()">返回</el-button>
    <el-button type="primary" @click="toOutPut">导出</el-button>
    <el-table :data="list">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="nickname" label="用户昵称"></el-table-column>
      <el-table-column prop="name" label="用户姓名"></el-table-column>
      <el-table-column prop="mobile" label="用户手机号"></el-table-column>
      <el-table-column prop="create_time" label="提交时间"></el-table-column>
      <el-table-column prop="use_time" label="答题时间(秒)"></el-table-column>
      <el-table-column prop="ip" label="来自IP"></el-table-column>
      <el-table-column prop="address" label="IP归属地"></el-table-column>
      <el-table-column prop="platform" label="来源">
	      <template slot-scope="scope">
		      <span v-if="scope.row.platform === 'pc'">PC端</span>
		      <span v-if="scope.row.platform === 'weixin_mini'">微信小程序</span>
	      </template>
      </el-table-column>
      <el-table-column width="200px">
	      <template slot-scope="scope">
		      <base-button @click.native="toDetail(scope.row.id)">查看</base-button>
					<base-button @click.native="toExportWord(scope.row)" type="primary">导出Word</base-button>
	      </template>
      </el-table-column>
    </el-table>
    <div class="padding-top-bottom-20 text-center">
      <el-pagination
        background
        layout="total,prev, pager, next,jumper"
        :page-size="searchForm.pageSize"
        :current-page.sync="searchForm.page"
        :total="totalCount"
        @current-change="pageChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "list",
  data() {
    return {
      list:[],
      searchForm:{
        tid:'',
        page:1,
        pageSize:15,
      },
      totalCount:0,
			printContent:{
				url: 'https://www.baidu.com', // 打印的url
				preview: false, // 预览工具是否启用
				previewTitle: '这是预览标题',
				popTitle: '发送到发送到', // 打印页面的页眉
				extraCss: "https://cdn.bootcdn.net/ajax/libs/animate.css/4.1.1/animate.compat.css, https://cdn.bootcdn.net/ajax/libs/hover.css/2.3.1/css/hover-min.css",
				extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
			}
    }
  },
  created() {
    this.searchForm.tid = this.$route.query.id ? this.$route.query.id : '';
  },
  mounted() {
    this.getList();
  },
  methods: {
		toExportWord(row){
			let _this = this;
			_this.$http.httpPost({
				download:true,
				url: '/SurveyLog/exportWord2',
				datas: {id:row.id},
				success: (res) => {
					const blob = new Blob([res]) // 构造一个blob对象来处理数据
					let num = _this.$moment().format('YYYYMMDD');

					const fileName = row.title + '_' + row.name + '_'+ row.mobile + '.docx';
					const link = document.createElement('a') // 创建a标签
					link.download = fileName // a标签添加属性
					link.style.display = 'none'
					link.href = URL.createObjectURL(blob)
					document.body.appendChild(link)
					link.click();
					URL.revokeObjectURL(link.href);
					document.body.removeChild(link);
					_this.$utils.success("操作成功！");
				}
			})
		},
	  toDetail(e){
			this.$router.push({path:'/survey/log/detail',query:{id:e}});
	  },
    pageChange(e){
      this.searchForm.page = e;

      this.getList();
    },

    toOutPut(){
      let _this = this;

      this.$http.httpPost({
        download:true,
        url: '/SurveyLog/output',
        datas: {...this.searchForm},
        success(res) {
          const blob = new Blob([res]) // 构造一个blob对象来处理数据
          let num = _this.$moment().format('YYYYMMDD');

          const fileName = _this.list[0].title +'-'+num+'.xlsx';
          const link = document.createElement('a') // 创建a标签
          link.download = fileName // a标签添加属性
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click();
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
          _this.$utils.success("操作成功！");
        }
      });
    },

    getList(){
      let _this = this;

      _this.$http.httpPost({
        url: '/SurveyLog/listPage',
        datas:this.searchForm,
        success: (res) => {
          console.log(res);
          _this.list = res.list;
          _this.totalCount = res.totalCount;
        }
      })
    },
  }
}
</script>
