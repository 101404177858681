<template>
	<el-popover
		v-model="visible"
		placement="bottom"
		width="400"
		@show="onShow"
		trigger="click" popper-class="diy-popover">
		<div class="">
			<div class="el-picker-panel__body-wrapper">
				<div class="el-picker-panel__body">
					<div class="el-date-picker__header el-date-picker__header--bordered">
						<button type="button" aria-label="前一年" class="el-picker-panel__icon-btn el-date-picker__prev-btn el-icon-d-arrow-left" @click="lastYear"></button>
						<span role="button" class="el-date-picker__header-label">{{tmpYear}} 年</span>
						<button type="button" aria-label="后一年" class="el-picker-panel__icon-btn el-date-picker__next-btn el-icon-d-arrow-right" @click="nextYear"></button>
					</div>
					<div class="el-picker-panel__content">
						<table class="el-month-table" style="width: 100%;">
							<tbody>
							<tr>
								<td :class="times === '1' && year === tmpYear ? 'in-range start-date end-date' : ''">
									<div><a class="cell" style="margin:0 8px;width: unset !important;" @click="choose('1')">第一季度</a></div>
								</td>
								<td :class="times === '2' && year === tmpYear ? 'in-range start-date end-date' : ''">
									<div><a class="cell" style="margin:0 8px;width: unset !important;" @click="choose('2')">第二季度</a></div>
								</td>
							</tr>
							<tr>
								<td :class="times === '3' && year === tmpYear ? 'in-range start-date end-date' : ''">
									<div><a class="cell" style="margin:0 8px;width: unset !important;" @click="choose('3')">第三季度</a></div>
								</td>
								<td :class="times === '4' && year === tmpYear ? 'in-range start-date end-date' : ''">
									<div><a class="cell" style="margin:0 8px;width: unset !important;" @click="choose('4')">第四季度</a></div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<el-input prefix-icon="el-icon-date" slot="reference" placeholder="请选择日期" clearable readonly class="hand" style="max-width: 200px;" v-model="text">
		</el-input>
	</el-popover>
</template>

<script>
import emitter from "element-ui/src/mixins/emitter";
export default {
	name: "QuarterPicker",
	mixins: [emitter],
	data() {
		return {
			visible:false,
			year:'',
			tmpYear:'',
			times:'0',
			tmpTimes:'',
			text:''
		}
	},
	props:{
		value:{
			type:String,
			default:''
		},
		now:{
			type:Boolean,
			default:false
		},
		props:{
			type:Object,
			default(){
				return {}
			}
		}
	},
	model:{
		prop:'value',
		event:'input'
	},
	created() {
		this.tmpYear = this.$moment().year();

		if(this.value){
			let _tmp = this.value.split('-');
			this.year = _tmp[0];
			this.tmpYear = _tmp[0];
			this.times = _tmp[1];
			this.text = this.year + '年' + '第 ' + this.times + ' 季度';
		}else if(this.now){
			this.year = this.$moment().year();
			this.tmpYear = this.$moment().year();
			this.times = this.$moment().quarter();

			this.text = this.year + '年' + '第 ' + this.times + ' 季度';

			this.$emit('input',this.year+'-'+this.times);
			this.dispatch('ElFormItem', 'el.form.change', [this.year+'-'+this.times])
			this.dispatch('ElFormItem', 'el.form.blur', [this.year+'-'+this.times])
			// this.$emit('change',{year:this.year,times:this.times});
		}
	},
	mounted() {
	},
	methods: {
		lastYear(){
			this.tmpYear = (parseInt(this.tmpYear) - 1).toString()
		},
		nextYear(){
			this.tmpYear = (parseInt(this.tmpYear) + 1).toString()
		},
		choose(e){
			this.times = e;
			this.tmpTimes = e;
			this.year = this.tmpYear
			this.text = this.year + '年' + '第 ' + this.times + ' 季度';
			this.$emit('input',this.year+'-'+this.times);
			this.$emit('change',{year:this.year,times:this.times});
			this.dispatch('ElFormItem', 'el.form.change', [this.year+'-'+this.times])
			this.dispatch('ElFormItem', 'el.form.blur', [this.year+'-'+this.times])
			this.visible = false;
		},
		onShow(){
			if(this.year){
				this.tmpYear = this.year;
			}
		}
	},
	watch:{
		value(n,o){
			if(n){
				let _tmp = n.split('-');
				console.log(_tmp);
				this.year = _tmp[0];
				this.tmpYear = _tmp[0];
				this.times = _tmp[1];
				this.text = this.year + '年' + '第 ' + this.times + ' 季度';
				// this.$emit('change',{year:this.year,times:this.times});
			}
		}
	}
}
</script>
