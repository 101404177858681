<template>
  <span>
    <el-button @click="open" :type="btnType">{{btnText}}</el-button>
		<template v-if="isShow">
			<el-dialog title="选择企业" append-to-body :visible.sync="isShow" width="50%" @close="onClose" class="dialog-h-400">
				<div class="margin-bottom" slot="title">
					<div>{{title}}</div>
					<el-input v-model="searchForm.title" style="width: 400px;" placeholder="输入企业名称搜索" class="margin-right"></el-input>
					<el-button type="primary" @click="toSearch">查询</el-button>
					<el-button @click="toClean">重置</el-button>
				</div>
				<el-table ref="list" :data="list" row-key="id" @selection-change="toChoose">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column label="企业名称" prop="title"></el-table-column>
					<el-table-column label="所在地区">
						<template slot-scope="scope">
							{{scope.row.province_name}} - {{scope.row.city_name}}
						</template>
					</el-table-column>
					<el-table-column label="主要网点" prop="main_location"></el-table-column>
				</el-table>
				<span slot="footer" class="dialog-footer">
					<el-button @click="onClose">取 消</el-button>
					<el-button type="primary" @click="submit">确 定</el-button>
				</span>
			</el-dialog>
		</template>
  </span>
</template>

<script>
export default {
  name: "CompanySelect",
  data(){
    return{
      list:[],
      values:[],
      title:'',
      tmpList:[],
      tmpValues:[],
      isShow:false,

      searchForm:{
        title:''
      }
    }
  },
  model:{
    prop:'value',
    event:'input'
  },
  props:{
    value:{
      type:[Array,String],
      default(){
        return []
      }
    },
		btnType:{
      type:String,
      default:'primary'
    },
		btnText:{
      type:String,
      default:'选择企业'
    },
		title:{
      type:String,
      default:'选择企业'
    },
    datas:{
      type:[Array,Object],
      default(){
        return {}
      }
    },
  },
  created() {
    this.values = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
    // this.tmpValues = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
    // this.values = this.value ? this.value : [];
  },
  watch:{
    value(n,o){
      console.log(n);
      //   this.values = n ? n : [];
      this.values = n ? JSON.parse(JSON.stringify(n)) : [];
      // this.tmpValues = n ? JSON.parse(JSON.stringify(n)) : [];
    },
    datas:{
      handler(n,o){
        // this.getList();
      },
      deep:true
    }
  },
  mounted() {
    // this.getList();
  },
  methods:{
    toSearch(){
      this.getList();
    },
    toClean(){
      this.searchForm.title = '';
      this.getList();
    },
    open(){
      this.values = this.value;
      this.isShow = true;
			this.getList();
    },
    onClose(){
      this.values = [];
      this.isShow = false;
    },
    submit(){
			let _list = [];
			this.list.forEach(t=>{
				if(this.values.indexOf(t.id)>=0){
					_list.push(t);
				}
			})
      this.$emit('change',_list);
      this.$emit('input',this.values);

      this.isShow = false;
    },
    toChoose(e){
			let _ids = [];
			e.forEach(t=>{
				_ids.push(t.id);
			})
			this.values = _ids;
    },
    getList() {
      let _this = this;

      //,highlight:1,format_type:'html'
			console.log({state:1,delete_time:0,...this.datas,...this.searchForm});
      this.$http.httpPost({
        url: '/Company/getChooseList',
        datas: {state:1,delete_time:0,...this.datas,...this.searchForm},
        success(res) {
          _this.list = res;
					_this.$nextTick(()=>{
						for(let i=0;i < _this.list.length;i++){
							if(_this.values && _this.values.length > 0) {
								for (let j = 0; j < _this.values.length; j++) {
									if(_this.values[j].id === _this.list[i].id){
										console.log(_this.list[i]);
										_this.$refs.list.toggleRowSelection(_this.list[i],true);
									}
								}
							}
						}
					})

          // _this.list = [];
					//
          // for(let i =0;i < res.length;i++){
          //   res[i].arank = '';
          //   _this.tmpList[res[i].title] = res[i];
          //   _this.list.push(res[i]);
          // }
        }
      });
    },
  }
}
</script>
