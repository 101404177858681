export default {
  '/setting/sms': {
    name: '短信设置',
    default: '/setting/sms',
    listRouter: '/setting/sms',
    list: {
      com: '/setting/sms',
    },
  },
  '/setting/friendlink':{
    showAdd:true,
    showDel:true,
    showEdit:true,
    showDetail:false,
    showSearch:true,
    showCheck:false,
    showListDel:false,
    showListTuijian:false,
    showListToutiao:false,
    showListSort:false,
    showListState:false,
    routerData:null,
    listData:{orderBy:'sort',sortBy:'asc'},
    name:'友情链接管理',
    apiUrl:'/FriendLink',
    listRouter:'/setting/friendlink',
    editRouter:'/setting/friendlink/edit',
    addRouter:'/setting/friendlink/edit',
    delRouter:'/setting/friendlink/del',
    checkRouter:null,
    detailRouter:'',
    optionsWidth:300,
    list: {
      com: '',
      params:[
        {name: '标题', value: 'title'},
        {name: 'URL链接', value: 'url',type:'link'},
        {name: '排序', value: 'sort'},
        {name: '状态', value: 'state',type:'state'},
        {name: '添加时间', value: 'create_time'},
        // {name: '修改时间', value: 'update_time'},
      ]
    },
    edit:{
      com: '',
      params:[
        {name: '标题', value: 'title',edit:true,rq:true},
        {name: 'URL链接', value: 'url',edit:true,rq:true,def:'http://',tip:'链接地址前面必须带有http:// 或 https://'},
        {name: '排序', value: 'sort',edit:true,rq:true},
        {name: '状态', value: 'state',edit:true,type:'state'},
      ]
    },
    add:{
      com: '',
    },
    detail:{
      com: '',
    },
    search:{
      params:[
        {name: '标题', value: 'title'},
        {name: '状态', value: 'state',type:'select',options:[{name:'禁用',id:'0'},{name:'可用',id:'1'}]},
        {name: '添加时间', value: 'create_time',type:'timeRange'},
      ]
    }
  },
}
