<template>
  <div class="bg-light padding-20">
    <div class="margin-top">
<!--      {{formData}}-->
      <el-form :model="formData" :rules="rules" ref="form" label-width="150px">
        <el-form-item label="ID" v-if="formData && formData.id">
          {{formData.id}}
        </el-form-item>
				<el-form-item label="奖项" prop="type_jiangxiang_id">
					<type-select v-model="formData.type_jiangxiang_id" :can-add="false" url="/Type/listAll" :data="{akey:'jiangxiang',orderBy:'sort',sortBy:'asc',state:1}"></type-select>
				</el-form-item>
				<el-form-item label="年份" prop="year">
					<year-select v-model="formData.year" style="width: 500px;"></year-select>
				</el-form-item>
<!--        <el-form-item label="奖项名称" prop="title">-->
<!--          <el-input v-model="formData.title" style="width: 500px;" maxlength="40" show-word-limit></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="年份" prop="year" :rules="[{required: true,message:'年份必填'}]">-->
<!--          <year-select v-model="year" @change="changeYear" style="width: 500px;"></year-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="数据分类" prop="mm_type">-->
<!--          <type-select v-model="formData.mm_type" @change="(e)=>$set(formData,'mm_type',e)" :options="[{name:'药品',id:'medicine'},{name:'医疗器材',id:'apparatus'}]" style="width: 500px;"></type-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="封面图" prop="head_img">-->
<!--          <head-img-upload v-model="formData.head_img" :img="formData.head_img ? $config.assetsUrl + formData.head_img : ''" @change="(e)=>$set(formData,'head_img',e ? e : '')"></head-img-upload>-->
<!--        </el-form-item>-->
        <el-form-item label="奖项简介" prop="prize_info">
          <el-input type="textarea" :rows="4" placeholder="请输入奖项简介" v-model="formData.prize_info" style="width: 500px;" maxlength="100" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="奖项要求" prop="prize_need">
          <el-input type="textarea" :rows="4" placeholder="请输入奖项简介" v-model="formData.prize_need" style="width: 500px;" maxlength="100" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="奖项设置" prop="prize_setting">
          <el-input type="textarea" :rows="4" placeholder="请输入奖项简介" v-model="formData.prize_setting" style="width: 500px;" maxlength="100" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="获奖说明" prop="html_content">
          <editor v-model="formData.html_content" ref="editor" style="width:500px;"/>
        </el-form-item>
				<el-form-item label="年度填报字段" prop="params_json">
					<el-checkbox-group v-model="params_json" @change="onChangeParams">
						<el-checkbox v-for="(t,i) in paramsList" :label="t.key" :key="t.key">{{t.name}}</el-checkbox>
<!--						<el-checkbox label="fdsfs">99</el-checkbox>-->
					</el-checkbox-group>
<!--					<el-checkbox-group v-model="formData.params_json">-->
<!--						<el-checkbox label="复选框 A"></el-checkbox>-->
<!--						<el-checkbox label="复选框 B"></el-checkbox>-->
<!--						<el-checkbox label="复选框 C"></el-checkbox>-->
<!--						<el-checkbox label="禁用" disabled></el-checkbox>-->
<!--						<el-checkbox label="选中且禁用" disabled></el-checkbox>-->
<!--					</el-checkbox-group>-->
        </el-form-item>
<!--        <el-form-item label="获奖企业" prop="company_ids">-->
<!--          <company-select :datas="{year:formData.year}" :canChoose="!!formData.year" v-model="formData.company_ids" style="width: 500px;"></company-select>-->
<!--          <el-button type="danger" class="margin-left" v-if="formData.year" @click="toCleanYear">清空{{formData.year}}年数据</el-button>-->
<!--        </el-form-item>-->
<!--        <el-form-item>-->
<!--          <el-form-item label-width="300px" style="margin-bottom: 22px;" :label="item.title+':'" v-for="(item,index) in formData.company_ids" :prop="'company_ids.'+index+'.arank'" :rules="[{required:true,message:'排名不能为空',trigger:'blur'},{pattern:$regExp.positiveInteger,message: '只能输入正整数'}]">-->
<!--            排名：<el-input v-model="item.arank" style="width: 100px;"></el-input>-->
<!--            <el-button size="mini" style="margin-left:10px;" @click="delCompany(item,index)">删除</el-button>-->
<!--          </el-form-item>-->
<!--        </el-form-item>-->
<!--				<el-form-item label="是否开启收藏">-->
<!--					<el-switch-->
<!--						v-model="formData.open_favorite"-->
<!--						active-color="#13ce66"-->
<!--						:active-value="1"-->
<!--						:inactive-value="0"-->
<!--						active-text="是"-->
<!--						inactive-text="否">-->
<!--					</el-switch>-->
<!--				</el-form-item>-->
<!--				<el-form-item label="是否开启点赞">-->
<!--					<el-switch-->
<!--						v-model="formData.open_like"-->
<!--						active-color="#13ce66"-->
<!--						:active-value="1"-->
<!--						:inactive-value="0"-->
<!--						active-text="是"-->
<!--						inactive-text="否">-->
<!--					</el-switch>-->
<!--				</el-form-item>-->
<!--				<el-form-item label="是否开启评论">-->
<!--					<el-switch-->
<!--						v-model="formData.open_comment"-->
<!--						active-color="#13ce66"-->
<!--						:active-value="1"-->
<!--						:inactive-value="0"-->
<!--						active-text="是"-->
<!--						inactive-text="否">-->
<!--					</el-switch>-->
<!--				</el-form-item>-->
<!--	      <el-form-item label="查看权限" prop="user_vip_type">-->
<!--		      <block-vip v-model="formData.user_vip_type"></block-vip>-->
<!--	      </el-form-item>-->
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')">保存</el-button>
          <el-button @click="$router.back()">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "edit",
  data() {
    return {
      url:'',
			params_json:[],
      formData: {
        id: '',
        year:'',
        mm_type:'',
				type_jiangxiang_id:'',
        company_ids:[],
				params_json:[],
				user_vip_type:'user'
      },
      rules: {
				type_jiangxiang_id:[{required: true,message:'奖项必选'}],
        year:[{required: true,message:'年份必选'}],
        // head_img:[{required: true,message:'封面图片必填'}],
        // title:[{required: true,message:'奖项名称必填'}],
        // mm_type:[{required: true,message:'数据分类必填'}],
        prize_info:[{required: true,message:'奖项简介必填'}],
        prize_need:[{required: true,message:'奖项要求必填'}],
        prize_setting:[{required: true,message:'奖项设置必填'}],
        html_content:[{required: true,message:'奖项说明必填'}],
				params_json:[{required: true,message:'奖项填报字段必填'}],
	      // user_vip_type:[{required: true,message:'权限必填'}]
        // company_ids:[{required: true,message:'获奖企业必填'}],
      },
      province:'',
      year:'',
			paramsList:[
				{name:'冷链物流营收（万元）',key:'llwlys',type:'float',value:''},
				{name:'冷链物流利润（万元）',key:'llwllr',type:'float',value:''},
				{name:'冷链物流成本（万元）',key:'llwlcb',type:'float',value:''},
				{name:'人员规模（人）',key:'person',type:'number',value:''},
				{name:'冷藏车数量（辆）',key:'car',type:'number',value:''},
				{name:'冷库容积（立方米）',key:'volume',type:'float',value:''},
			]
    };
  },
  created() {
    console.log(this.$route.params);
    // this.formData.mm_type = this.$route.params.mm_type ? this.$route.params.mm_type : 'nongcp';
    this.formData.id = this.$route.query.id ? this.$route.query.id : '';
  },
  mounted() {
    if(this.formData.id) {
      this.getDetail();
      this.url = '/Prize/edit';
    }else{
      this.url = '/Prize/add';
    }
  },
  methods:{
		onChangeParams(e){
			let _params_json = [];
			if(e.length > 0){
				for(let i=0;i < this.paramsList.length;i++){
					if(this.params_json.indexOf(this.paramsList[i].key) >= 0){
						_params_json.push(this.paramsList[i]);
					}
				}
			}
			this.formData.params_json = _params_json;
		},
    delCompany(t,i){
      this.formData.company_ids.splice(i,1);
    },
    changeProvince(e){
      this.province = e;
      this.formData.province_code = e.code;
      this.formData.province_name = e.name;
    },
    changeYear(e){
      this.year = e;
      this.formData.year = e;
      this.formData.id = '';

      this.getDetail();
    },
    getDetail() {
      let _this = this;
      this.$http.httpPost({
        url: '/Prize/detail',
        datas: {id: this.formData.id},
        success(res) {
          // console.log(res);
					res.user_vip_type = 'user';
          _this.formData = res;
          _this.formData.type_jiangxiang_id = res.type_jiangxiang_id;
          _this.formData.prize_info = res.prize_info;
          _this.formData.prize_need = res.prize_need;
          _this.formData.prize_setting = res.prize_setting;
          _this.formData.html_content = res.html_content;
          // _this.formData.head_img = res.head_img;
	        // _this.formData.open_favorite = res.open_favorite;
	        // _this.formData.open_like = res.open_like;
	        // _this.formData.open_comment = res.open_comment;

					let _params_json = [];
					if(res.params_json && res.params_json.length > 0){
						res.params_json.forEach(t=>{
							_params_json.push(t.key);
						})
					}
					_this.params_json = _params_json;
					_this.formData.params_json = res.params_json;

          console.log(_this.formData);
        }
      });
    },
    create() {
      let _this = this;

      this.$http.httpPost({
        url: this.url,
        datas: {...this.formData},
        success(res) {
          _this.$utils.success('保存成功！');
          _this.$router.back();
        }
      });
    },
    submitForm(formName) {
      //存在回调的方法，因为回调外和回调内的作用域不一致，所以务必为 _this 复制 this，否则在回调中无法访问this对象
      let _this = this;
      //通过传递form表单ref名称，来调用表单的validate方法
      this.$refs[formName].validate(valid => {
        //为true时验证通过
        if (valid) {
          _this.create();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onChangeYear(e){
      let _this = this;
      this.formData.company_ids = [];

      this.getPrizeCompany();
    },
    getPrizeCompany(){
      let _this = this;
      this.$http.httpPost({
        url: '/PrizeCompany/listAll',
        datas: {tid:this.formData.id,year:this.formData.year,orderBy:'arank',sortBy:'asc'},
        success(res) {
          console.log(res);
          for(let i=0;i<res.length;i++){
            let _dasta = {
              title:res[i].name,
              id:res[i].company_id,
              arank:res[i].arank,
              year:res[i].year,
              tid:res[i].tid
            }
            _this.formData.company_ids.push(_dasta);
          }
          // _this.$utils.success('保存成功！');
          // _this.$router.back();
        }
      });
    },
    toCleanYear(){
      let _this = this;

      this.$confirm('此操作将永久删除数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.httpPost({
          url: '/PrizeCompany/del',
          datas: {tid:this.formData.id,year:this.formData.year},
          success(res) {
            _this.$message.success('删除成功');

            _this.onChangeYear();
          }
        });
      }).catch(() => {
      });
    }
  }
}
</script>
