<template>
	<div class="bg-light padding-20">
		<div class="margin-bottom">
			<el-button @click="$router.back()" icon="el-icon-back">返回</el-button>
		</div>
		<div class="margin-top" v-if="program">
			<div class="cl">
				<div class="z text-right dark-2" style="width: 150px;">奖项标题：</div>
				<div class="z" style="white-space: break-spaces;">{{program.title}}</div>
			</div>
			<div class="cl margin-top-20">
				<div class="z text-right dark-2" style="width: 150px;">奖项说明：</div>
				<div class="z" style="white-space: break-spaces;">{{program.clean_des}}</div>
			</div>
			<div class="cl margin-top-20">
				<div class="z text-right dark-2" style="width: 150px;">评选范围：</div>
				<div class="z" style="white-space: break-spaces;">{{program.fanwei}}</div>
			</div>
			<div class="cl margin-top-20">
				<div class="z text-right dark-2" style="width: 150px;">评选条件：</div>
				<div class="z" style="white-space: break-spaces;">{{program.tiaojian}}</div>
			</div>
			<div class="padding margin-bottom-20 margin-top-20 border-w-1" v-for="(item,index) in info.content">
				<div class="dark-0 f16">
					{{ index + 1 }}、{{ item.title }}
					<span class="f12" v-if="item.type === 'radio'">[单选]</span>
					<span class="f12" v-if="item.type === 'multi'">[多选]</span>
					<span class="f12" v-if="item.type === 'input'">[单项填空]</span>
					<span class="f12" v-if="item.type === 'multi-input'">[多项填空]</span>
					<span class="f12" v-if="item.type === 'table-input'">[横向填空]</span>
					<span class="f12" v-if="item.type === 'files'">[附件上传]</span>
				</div>
				<template v-if="item.type === 'radio' || item.type === 'multi'">
					<div class="margin-top f14 dark-2 padding" style="border:1px solid #008f29;" v-for="(st,si) in item.value_arr">
						<i class="el-icon-success success margin-right-5"></i>{{ st.title }}
					</div>
				</template>
				<template v-else-if="item.type === 'multi-input'">
					<div class="margin-top f14 padding" style="border:1px solid #008f29;" v-for="(st,si) in item.input">
						<div class="dark-2">标题：{{ st.title }}</div>
						<div style="margin-top:5px;">内容：{{st.value}}</div>
					</div>
				</template>
				<template v-else-if="item.type === 'table-input'">
					<div class="el-table el-table--border margin-top">
						<div class="el-table__body-wrapper">
							<table class="el-table__body" cellpadding="0" cellspacing="0" border="0" style="width: 100%;">
								<tbody>
								<tr class="el-table__row">
									<td class="el-table__cell" v-for="(st,si) in item.input" style="text-align: center;font-weight: bold;">
										{{st.title}}
									</td>
								</tr>
								<tr class="el-table__row">
									<td class="el-table__cell" v-for="(st,si) in item.input" style="text-align: center;">{{st.value}}</td>
								</tr>
								</tbody>
							</table>
						</div>
					</div>
				</template>
				<template v-else-if="item.type === 'files'">
					<div class="margin-top f14 dark-2 padding" style="border:1px solid #008f29;" v-for="(st,si) in item.value_arr">
						<a :href="$utils.formatImagePath(st.url)" target="_blank">{{st.name}}</a>
					</div>
				</template>
				<template v-else>
					<div class="margin-top f14 dark-2 padding" style="border:1px solid #008f29;">
						{{item.value[0]}}
					</div>
				</template>
				<file-pre-view :list="item.files" class="margin-top margin-bottom" v-if="item.files && item.files.length > 0"></file-pre-view>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "detail",
	data() {
		return {
			id: '',
			info: null,
			program:null
		}
	},
	created() {
		this.id = this.$route.query.id ? this.$route.query.id : '';
	},
	mounted() {
		this.getDetail();
	},
	methods: {
		getDetail() {
			let _this = this;

			if(!this.id){
				return;
			}

			this.$http.httpPost({
				url: '/SurveyLog/detail',
				datas: {id: this.id},
				success: (res) => {
					console.log(res);
					if (res) {
						_this.info = res;
						// _this.info.content = JSON.parse(res.content);

						console.log(_this.info);

						_this.getInfoDetail(res.tid)
					}
				}
			})
		},
		getInfoDetail(id) {
			let _this = this;
			if(!id){
				return;
			}

			this.$http.httpPost({
				url: '/SurveyTitle/detail',
				datas: {id: id},
				success: (res) => {
					if (res) {
						_this.program = res;
					}
				}
			})
		}
	}
}
</script>
