<template>
    <div class="bg-light padding-20">
        <el-button size="mini" type="primary" slot="right-btn" @click="$router.push({path:'/banner/res',query:{id:formData.type_banner_id}})">返回图片列表
        </el-button>
        <div class="page-content-main">
            <el-form :model="formData" status-icon :rules="rules" ref="form" label-width="auto" class="margin-top"
                     style="width:600px;">
                <el-form-item label="所属轮播位" prop="type_banner_id">
                    <type-select v-model="formData.type_banner_id" :data="{akey:'banner'}" :can-add="false"></type-select>
                </el-form-item>
                <el-form-item label="标题" prop="title">
                    <el-input v-model="formData.title" maxlength="20" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="图片" prop="res_url">
                    <head-img-upload v-model="formData.res_url"></head-img-upload>
                </el-form-item>
<!--	            <el-form-item label="链接类型" prop="res_type">-->
<!--		            <type-select v-model="formData.res_type" :can-add="false" :options="[{id:'web',name:'网页'},{id:'minipath',name:'小程序页面'},{id:'publicize',name:'宣传页(小程序)'}]" @change="onChangeResType"></type-select>-->
<!--		            <el-button class="margin-left" type="text" @click="cleanUrl">清空链接</el-button>-->
<!--	            </el-form-item>-->
<!--	            <el-form-item label="宣传页" prop="extid" v-if="formData.res_type === 'publicize'" key="publicize_type" :rules="[{required:true,message:'宣传页必选'}]">-->
<!--		            <type-select v-model="formData.extid" :can-add="false" url="/Publicize/listAll" :data="{state:1}" list-label="title" key="publicize"></type-select>-->
<!--	            </el-form-item>-->
<!--                <el-form-item label="URL地址" prop="out_url" v-if="formData.res_type === 'web'" :rules="[{required:true,message:'URL地址必填'}]">-->
<!--                    <el-input v-model="formData.out_url" maxlength="200" show-word-limit></el-input>-->
<!--                </el-form-item>-->
<!--	            <el-form-item label="小程序页面" v-if="formData.res_type === 'minipath'" :rules="[{required:true,message:'小程序页面必选'}]">-->
<!--		            <type-select v-model="formData.mini_path_pre" :can-add="false" :options="[{id:'/pages/hz/index/index',name:'货主主页'},{id:'/pages/gys/detail/detail',name:'供应商主页'}]"></type-select>-->
<!--	            </el-form-item>-->
<!--	            <el-form-item label="路径预览" v-if="formData.res_type === 'minipath' || formData.res_type === 'publicize'">-->
<!--		            {{minipath}}-->
<!--	            </el-form-item>-->
<!--	            <el-form-item label="用户uid" prop="uid" v-if="formData.res_type === 'minipath'" :rules="[{required:true,message:'用户uid必填'}]">-->
<!--		            <el-input v-model="formData.uid" maxlength="20" show-word-limit style="width:300px;"></el-input>-->
<!--	            </el-form-item>-->
                <el-form-item label="排序" prop="sort">
                    <el-input v-model="formData.sort" maxlength="20" show-word-limit style="width:300px;"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-switch
                        v-model="formData.state"
                        active-color="#13ce66"
                        :active-value="1"
                        :inactive-value="0"
                        active-text="启用"
                        inactive-text="禁用">
                    </el-switch>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('form')">确定</el-button>
                    <el-button type="" @click="$router.back()">返回</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    name: "GoodsSpecEdit",
    data() {
        return {
            formData: {
                id: '',
                title: '',
                out_url: '',
                res_type: 'web',
                type_banner_id: '',
                state: 1,
                sort: 0,
                res_url: '',
	            extid: '',
	            mini_path_pre:'',
	            uid:''
            },
            typeList: [],
            rules: {
                res_url: [
                    {required: true,message:'图片必传'},
                ],
                // res_type: [
                //     {required: true},
                // ],
                type_banner_id: [
                    {required: true},
                ],
                state: [
                    {required: true},
                ],
                sort: [
                    {required: true},
                ],
            },
        };
    },
    created() {
        this.formData.id = this.$route.query.id ? this.$route.query.id : '';
    },
    mounted() {
        if (this.formData.id) {
            this.getDetail();
        }
    },
    methods: {
	    onChangeResType(e){
		    this.formData.out_url = '';
				this.formData.mini_path_pre = '';
		    this.formData.uid = '';
		    this.formData.extid = '';

				if(e === 'publicize'){
					this.formData.mini_path_pre = '/publicize-module/detail/detail';
				}
	    },
	    cleanUrl(){
		    this.formData.out_url = '';
		    this.formData.mini_path_pre = '';
		    this.formData.uid = '';
		    this.formData.extid = '';
		    this.formData.res_type = '';
	    },
        submitForm(formName) {
            let _this = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    _this.$http.httpPost({
                        url: '/Banner/edit',
                        datas: _this.formData,
                        success: (res) => {
                            _this.$router.replace({path: '/banner/res', query: {id: _this.formData.type_banner_id}})
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        getDetail() {
            let _this = this;

            _this.$http.httpPost({
                url: '/Banner/detail',
                datas: {id: _this.formData.id},
                success: (res) => {
	                _this.formData.id = res.id;
	                _this.formData.title = res.title;
		                _this.formData.out_url = res.out_url;
		                _this.formData.res_type = res.res_type;
		                _this.formData.type_banner_id = res.type_banner_id;
		                _this.formData.state = res.state;
		                _this.formData.sort = res.sort;
		                _this.formData.res_url = res.res_url;
		                _this.formData.extid = res.extid;
		                // _this.formData.mini_path_pre = res.mini_path_pre;
		                // _this.formData.uid = res.uid;
                    // _this.formData = JSON.parse(JSON.stringify(res));

										if(res.res_type === 'minipath' || res.res_type === 'publicize'){
											let _arr = res.out_url.split('?');

											_this.formData.mini_path_pre = _arr[0];

											let _obj = _this.$utils.strToObj(_arr[1]);
											_this.formData.uid = _obj.id;
										}
                }
            })
        },
    },
	computed:{
			minipath(){
				let _url = '';
				if(this.formData.res_type === 'minipath') {
					_url = this.formData.mini_path_pre ? this.formData.mini_path_pre + '?id=' + (this.formData.uid ? this.formData.uid : '') : '';
					this.formData.out_url = _url;
				}else if(this.formData.res_type === 'publicize'){
					_url = this.formData.mini_path_pre ? this.formData.mini_path_pre + '?id=' + (this.formData.extid ? this.formData.extid : '') : '';
					this.formData.out_url = _url;
				}
				return _url
			}
	}
}
</script>
