export default {
  '/user/company': {
    showAdd: false,
    showDel: false,
    showEdit: false,
    showDetail: true,
    showSearch: true,
    routerData: null,
    listData: null,
    name: 'VIP价格管理',
    apiUrl: '/UserCompany',
    listRouter: '/user/company',
    editRouter: '/user/company/edit',
    addRouter: '/user/company/edit',
    delRouter: '/user/company/del',
    detailRouter: '/user/company/detail',
    list: {
      com: '',
      params: [
        {name: '企业名称', value: 'company_name'},
        {name: '创建人', value: 'create_user.username',root:true},
        {name: '统一社会信用编号', value: 'company_code'},
        {name: '营业执照', value: 'files_ids',type:'image'},
        {name: '状态', value: 'is_check', type: 'custom-select',options:[{label:'待审核',val:0,color:'warning'},{label:'审核通过',val:1,color:'success'},{label:'审核不通过',val:2,color:'danger'}]},
        {name: '创建时间', value: 'create_time'},
        {name: '修改时间', value: 'update_time', type: 'time'},
      ],
      extButtons:[
        {label:'审核',type:'shenpi',rules:{key:'is_check',val:0},button_type:'primary',url:'/UserCompany/toShenpi',column:{stateKey:'is_check',reasonKey:'reason'}}
      ]
    },
    edit: {
      com: '',
      params: [
      ]
    },
    add: {
      com: '',
    },
    detail: {
      com: '',
      params: [
        {name: '状态', value: 'is_check', type: 'custom-select',options:[{label:'待审核',value:0,color:'warning'},{label:'审核通过',value:1,color:'success'},{label:'审核不通过',value:2,color:'danger'}]},
        {name: '企业名称', value: 'company_name'},
        {name: '创建人', value: 'create_user.username',root:true},
        {name: '统一社会信用编号', value: 'company_code'},
        {name: '营业执照', value: 'files_ids',type:'image',root:true},
        {name: '创建时间', value: 'create_time'},
        {name: '修改时间', value: 'update_time', type: 'timeDate'},
      ]
    },
    search: {
      params: [
        {name: '企业名称', value: 'company_name'},
        {name: '社会信用编号', value: 'company_code'},
        {name: '审核状态', value: 'is_check',type:'select',def:'0',options:[{name:'待审核',id:'0'},{name:'审核通过',id:'1'},{name:'审核不通过',id:'2'}]},
      ]
    }
  },
  
  '/user': {
    showAdd: false,
    showDel: true,
    showEdit: true,
    showDetail: true,
    showSearch: true,
    showOutput: false,
    showListDel: false,
    // showPutFile:true,
    // putUrl:'/User',
    // putDemo:'/user_demo.xlsx',
    // putLabel:'导入用户数据',
    routerData: null,
    listData: null,
    name: '用户管理',
    apiUrl: '/User',
    listRouter: '/user',
    editRouter: '/user/edit',
    detailRouter: '/user/detail',
    list: {
      com: '',
      params: [
        {name: 'ID', value: 'id',width: 150},
        {name: '用户名', value: 'username',width: 100},
        {name: '用户类型', value: 'user_type_name',width: 100},
        {
          name: '所属企业',
          width:'300px',
          tem: [
            {name: '企业名称', val: 'company.company_name',root:true},
            {name: '用户类型', val: 'company.user_type_name', root: true},
          ]
        },
        {name: 'VIP到期时间', value: 'vip_end_time',width: 120},
        // {name: '手机号', value: 'mobile'},
        // {name: '注册时间', value: 'create_time'},
        {
          name: '手机/邮箱',
          width: 200,
          tem: [
            {name: '手机', val: 'mobile'},
            {name: '邮箱', val: 'user.email', root: true},
          ]
        },
        {
          name: '公司/职位',
          width: 200,
          tem: [
            {name: '公司', val: 'user.company', root: true},
            {name: '职位', val: 'user.job', root: true},
          ]
        },
        // {name: '邮箱', value: 'user.email',root:true},
        // {name: '公司', value: 'user.company',root:true},
        // {name: '职位', value: 'user.job',root:true},
        // {name: '是否VIP', value: 'vip_state',type:'bool'},
        {name: '状态', value: 'state', type: 'state'},
        {name: '注册时间', value: 'create_time',width: 180},
        // {name: '医药物流车辆', value: 'sub_total_income'},
        // {name: '医药物流车辆增长率(%)', value: 'sub_income_rate',type:'percent'},
      ]
    },
    edit: {
      com: '/user/edit',
      params: [
        {name: 'ID', value: 'id', rq: false, sc: true},
        {name: '用户名', value: 'username', rq: false, sc: true},
        {name: '手机号', value: 'mobile', rq: false, sc: true},
      ]
    },
    detail: {
      com: '/user/detail',
      params: [
        {name: 'ID', value: 'id'},
        {name: '用户名', value: 'username'},
        {name: '手机号', value: 'mobile'},
        {name: '邮箱', value: 'user.email', root: true},
        {name: '姓名', value: 'user.name', root: true},
        {name: '公司', value: 'user.company', root: true},
        {name: '职位', value: 'user.job', root: true},
        {name: '是否VIP', value: 'vip_state', type: 'bool'},
        {name: 'VIP截止时间', value: 'vip_end_time'},
        {name: '状态', value: 'state', type: 'state'},
      ]
    },
    search: {
      params: [
        {name: '用户名', value: 'username'},
        {name: '手机', value: 'mobile'},
        {name: '注册时间', value: 'create_time', type: 'timeRange'},
        {name: '状态', value: 'state', type: 'select', options: [{name: '正常', id: '1'}, {name: '禁用', id: '0'}]},
        {name: '是否VIP', value: 'vip_state', type: 'select', options: [{name: '是', id: '1'}, {name: '否', id: '0'}]},
      ]
    }
  },
}
