<template>
	<div class="bg-light padding-20">
		<!--        <div slot="right-btn" v-if="params && (params.showOutput || (params.rightBottons && params.rightBottons.length > 0))">-->
		<!--            <add-button label="导出所有用户" @click.native="output" v-if="params && params.showOutput"></add-button>-->
		<!--            <template v-if="params && params.rightBottons && params.rightBottons.length > 0" v-for="(item,index) in params.rightBottons">-->
		<!--                <base-button :type="item.type" :icon="item.icon" @click.native="toCall(item.path,item.data)">{{item.label}}</base-button>-->
		<!--            </template>-->
		<!--        </div>-->
		<el-row :gutter="10" v-if="params.showSearch">
			<el-form :model="searchForm" label-width="100px">
				<el-col :span="5" v-for="(item,index) in params['search']['params']">
					<el-form-item :label="item.name">
						<type-select v-if="item.type === 'select'" v-model="searchForm[item.value]" @change="(e)=>$set(searchForm,item.value,e)" :options="item.options ? item.options : null" :data="item.data ? item.data : null" :canAdd="false" style="width: 100%;"></type-select>
						<year-select v-model="searchForm.year" v-else-if="item.value === 'year'" style="width: 100%;"></year-select>
						<province-select v-model="searchForm.province_code" v-else-if="item.type === 'province'" style="width: 100%;" :is-obj="false"></province-select>
						<sort-select v-model="searchForm.sort" v-else-if="item.type === 'sort'" style="width: 100%;"></sort-select>
						<order-type-select v-model="searchForm.order_type" v-else-if="item.type === 'orderType'"></order-type-select>
						<order-state-select v-model="searchForm.state" v-else-if="item.type === 'orderState'"></order-state-select>
						<range-time v-model="searchForm[item.value]" v-else-if="item.type ==='timeRange'" style="width: 100%;"></range-time>
						<el-input v-model="searchForm[item.value]" class="block" v-else></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="4">
					<el-button type="primary" @click="toSearch">搜索</el-button>
					<el-button type="info" @click="toReset">重置</el-button>
				</el-col>
			</el-form>
		</el-row>
		<div class="margin-bottom cl">
			<base-button type="" size="medium" @click.native="$router.back()" v-if="params.showBack" class="z">
				{{ params.backText ? params.backText : '返回' }}
			</base-button>
			<add-button @click.native="toAdd" :rule="path+'/edit'" v-if="params.showAdd" class="z"></add-button>
			<auto-make-year-data v-if="params.showAutoMakeYear" class="y margin-left" :getYearUrl="params.autoMakeGetYearUrl" :getYearData="params.autoMakeGetYearData" :submitUrl="params.autoMakeSubmitUrl" :submitData="params.autoMakeSubmitData"></auto-make-year-data>
			<put-in v-if="params.showPutFile" class="y" v-model="showPutIn" @success="onPutSuccess" :data="params.putData" :url-path="params.putUrl" :demo-path="params.putDemo" :tip="params.pushTip">
				{{ params.putLabel ? params.putLabel : '导入数据' }}
			</put-in>
			<template v-if="params && (params.showOutput || (params.rightBottons && params.rightBottons.length > 0))">
				<base-button class="y margin-left" @click.native="toDownLoad(params.outPutPath,params.outPutName,params.outPutData ? params.outPutData : null)" v-if="params && params.showOutput && params.outPutPath">
					{{ params.outPutTitle }}
				</base-button>
				<template v-if="params && params.rightBottons && params.rightBottons.length > 0" v-for="(item,index) in params.rightBottons">
					<base-button class="y margin-left" :type="item.type" :icon="item.icon" @click.native="toCall(item.path,item.data)">
						{{ item.label }}
					</base-button>
				</template>
			</template>
		</div>
		<div class="bg-light">
			<div class="padding bg-gray-3 dark-2 f16">当前合计数量：<b class="brand">{{ totalCount }}</b></div>
			<el-table
				row-key="id"
				default-expand-all
				:tree-props="{children: 'children'}"
				:data="list"
				style="width: 100%" ref="listtable" :fit="true" @selection-change="selectionChange">
				<el-table-column type="selection" width="55" v-if="params.showListDel">
				</el-table-column>
				<template v-for="(item,index) in params['list']['params']">
					<el-table-column v-if="item.tem" :label="item.name" :width="item.width ? item.width : ''">
						<template slot-scope="scope">
							<template v-for="(item2,index2) in item.tem">
								<div v-if="item2.type === 'copy'">
									{{ item2.name ? item2.name + '：' : '' }}{{ item2.pre ? item2.pre : '' }}{{ $utils.evalGet(scope.row, 'scope.row', item2.val) }}
									<el-button size="mini" @click="copy((item2.pre ? item2.pre : '') + scope.row[item2.val])" class="margin-left">
										复制
									</el-button>
								</div>
								<div v-else-if="item2.val === 'title' && (scope.row.is_tuijian === 1 || scope.row.is_toutiao === 1 || scope.row.sort > 0)">
									<div class="margin-bottom">
										<el-tag size="mini" effect="dark" type="danger" class="margin-right" v-if="scope.row.is_tuijian">
											推荐
										</el-tag>
										<el-tag size="mini" effect="dark" type="warning" v-if="scope.row.is_toutiao" class="margin-right">
											头条
										</el-tag>
										<el-tag size="mini" effect="dark" type="success" v-if="scope.row.sort === 3">一级置顶</el-tag>
										<el-tag size="mini" effect="dark" type="success" v-if="scope.row.sort === 2">二级置顶</el-tag>
										<el-tag size="mini" effect="dark" type="success" v-if="scope.row.sort === 1">三级置顶</el-tag>
									</div>
									<div>
										{{ item2.name ? item2.name + '：' : '' }}{{ item2.pre ? item2.pre : '' }}{{ $utils.evalGet(scope.row, 'scope.row', item2.val) }}
									</div>
								</div>
								<div v-else-if="item2.type === 'tag'">
									<el-tag v-for="(item,index) in scope.row[item2.val]" class="margin-right hand" @click="copy(item)">
										{{ item }}
									</el-tag>
								</div>
								<div v-else>
									{{ item2.name ? item2.name + '：' : '' }}{{ item2.pre ? item2.pre : '' }}{{ $utils.evalGet(scope.row, 'scope.row', item2.val) }}
								</div>
							</template>
						</template>
					</el-table-column>
					<template v-else>
						<el-table-column :min-width="item.width ? item.width : null" :label="item.name" :prop="item.value" v-if="item.type === 'state'">
							<template slot-scope="scope">
								<el-tag :type="scope.row.state === 1 ? 'success' : 'danger'" disable-transitions>
									{{ scope.row.state === 0 ? '禁用' : '可用' }}
								</el-tag>
							</template>
						</el-table-column>
						<el-table-column :min-width="item.width ? item.width : null" :label="item.name" :prop="item.value" v-else-if="item.type === 'custom-select'">
							<template slot-scope="scope">
								<el-tag :class="{'hand':item.ext && item.ext.type === 'link' && item.ext.url}" v-for="(st,si) in item.options" :type="st.color" disable-transitions v-if="scope.row[item.value] === st.val" @click="item.ext && item.ext.type === 'link' && item.ext.url && $utils.evalGet(scope.row,(item.ext.linkKey ? item.ext.linkKey : 'id')) ? $router.push({path:item.ext.url,query:{id:item.ext.linkKey ? $utils.evalGet(scope.row,item.ext.linkKey) : ''}}) : null">
									{{ st.label }}
								</el-tag>
							</template>
						</el-table-column>
						<el-table-column :label="item.name" :prop="item.value" v-else-if="item.value === 'mm_type'">
							<template slot-scope="scope">
								<span v-if="scope.row[item.value] === 'medicine'">医药</span>
								<span v-if="scope.row[item.value] === 'apparatus'">医疗器械</span>
								<span v-if="scope.row[item.value] === 'chain'">冷链</span>
								<span v-if="scope.row[item.value] === 'agriculture'">食材供应链</span>
								<span v-if="scope.row[item.value] === 'all'">冷链/食材供应链</span>
								<span v-if="scope.row[item.value] === 'kfq'">经济技术开发区</span>
								<span v-if="scope.row[item.value] === 'sw'">生物医药产业园区</span>
							</template>
						</el-table-column>
						<el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'check'">
							<template slot-scope="scope">
								<el-tag type="success" disable-transitions v-if="scope.row.state === 1">审核通过</el-tag>
								<el-tag type="warning" disable-transitions v-if="scope.row.state === 0">待审核</el-tag>
								<el-tag type="danger" disable-transitions v-if="scope.row.state === 2">审核不通过</el-tag>
							</template>
						</el-table-column>
						<el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'bool'">
							<template slot-scope="scope">
								<el-tag :type="scope.row[item.value] === 1 ? 'success' : 'danger'" disable-transitions>
									{{ scope.row[item.value] === 0 ? '否' : '是' }}
								</el-tag>
							</template>
						</el-table-column>
						<el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'percent'">
							<template slot-scope="scope">
								{{ (scope.row[item.value] * 100).toFixed(2) }}%
							</template>
						</el-table-column>
						<el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'image'">
							<template slot-scope="scope">
								<el-image
									style="height: 60px"
									:src="scope.row[item.value].indexOf('http')>= 0 ? scope.row[item.value] : $config.assetsUrl + scope.row[item.value]"
									:preview-src-list="scope.row[item.value].indexOf('http')>= 0 ? [scope.row[item.value]] : [$config.assetsUrl + scope.row[item.value]]" v-if="scope.row[item.value]">
								</el-image>
							</template>
						</el-table-column>
						<el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'totime'">
							<template slot-scope="scope">
								<div v-if="scope.row.time_type === 'day'">{{ scope.row.to_time }}天</div>
								<div v-if="scope.row.time_type === 'week'">{{ scope.row.to_time }}周</div>
								<div v-if="scope.row.time_type === 'month'">{{ scope.row.to_time }}月</div>
								<div v-if="scope.row.time_type === 'year'">{{ scope.row.to_time }}年</div>
							</template>
						</el-table-column>
						<el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'orderState'">
							<template slot-scope="scope">
								<div v-if="scope.row.state === 1">待支付</div>
								<div v-if="scope.row.state === 2">已支付</div>
							</template>
						</el-table-column>
						<el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'pushState'">
							<template slot-scope="scope">
								<div v-if="scope.row[item.value] === 1">有效</div>
								<div v-if="scope.row[item.value] === 2">修订</div>
								<div v-if="scope.row[item.value] === 0">废止</div>
							</template>
						</el-table-column>
						<el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'todo'">
							<template slot-scope="scope">
								<div v-if="scope.row[item.value] === t1.value" v-for="(t1,i1) in item.doList">{{ t1.label }}</div>
							</template>
						</el-table-column>
						<el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'payType'">
							<template slot-scope="scope">
								<div v-if="scope.row.pay_type === 'weixin'">微信</div>
								<div v-if="scope.row.pay_type === 'alipay'">支付宝</div>
							</template>
						</el-table-column>
						<el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'orderType'">
							<template slot-scope="scope">
								<div v-if="scope.row.order_type === 'vip'">购买VIP</div>
								<div v-if="scope.row.order_type === 'bids'">购买标讯</div>
							</template>
						</el-table-column>
						<el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'time'">
							<template slot-scope="scope">
								{{ scope.row[item.value] > 0 ? $moment.unix(scope.row[item.value]).format('YYYY-MM-DD HH:mm:ss') : '-' }}
							</template>
						</el-table-column>
						<el-table-column :min-width="item.width ? item.width : null" :label="item.name" :prop="item.value" v-else-if="item.type === 'link'">
							<template slot-scope="scope">
								<a :href="$utils.formatPagePath(item.linkUrl ? item.linkUrl+scope.row[item.value] : scope.row[item.value])" :target="item.target ? item.target : '_blank'">{{ $utils.formatPagePath(item.linkUrl ? item.linkUrl+scope.row[item.value] : scope.row[item.value]) }}</a>
								<el-tag size="mini" type="info" effect="dark" class="hand margin-left" @click="copy($utils.formatPagePath(item.linkUrl ? item.linkUrl+scope.row[item.value] : scope.row[item.value]))">复制</el-tag>
							</template>
						</el-table-column>
						<el-table-column :min-width="item.width ? item.width : null" :label="item.name" :prop="item.value" v-else-if="item.type === 'html'">
							<template slot-scope="scope">
								<div class="cl rel">
									<div class="z" :class="item.more ? 'text-line-2' : ''" :style="item.more ? 'margin-left:25px;' : ''" v-html="item.root ? $utils.evalGet(scope.row,'scope.row',item.value) : scope.row[item.value]"></div>
									<el-button type="info" icon="el-icon-view" circle @click="showMore(scope.row[item.value])" v-if="item.more && scope.row[item.value]" size="mini" class="abs" style="top:0;left:0;padding:2px !important;"></el-button>
								</div>
							</template>
						</el-table-column>
						<el-table-column :min-width="item.width ? item.width : null" :label="item.name" :prop="item.value" v-else-if="item.type === 'vip'">
							<template slot-scope="scope">
								<div v-if="scope.row[item.value] === 'free'">完全公开</div>
								<div v-if="scope.row[item.value] === 'user'">注册会员</div>
								<div v-if="scope.row[item.value] === 'vip'">VIP</div>
							</template>
						</el-table-column>
						<el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'tongjiTime'" :min-width="item.width ? item.width : null">
							<template slot-scope="scope">
								<template v-if="scope.row.time_type === 'year'">
									{{ scope.row.year }}年
								</template>
								<template v-if="scope.row.time_type === 'month'">
									{{ scope.row.year }}年{{ yuedu[scope.row.times] }}
								</template>
								<template v-if="scope.row.time_type === 'quarter'">
									{{ scope.row.year }}年{{ jidu[scope.row.times] }}
								</template>
								<template v-if="scope.row.time_type === 'half'">
									{{ scope.row.year }}年{{ banniandu[scope.row.times] }}
								</template>
							</template>
						</el-table-column>
						<el-table-column :label="item.name" :prop="item.value" :min-width="item.width ? item.width : null" v-else>
<!--							<template slot-scope="scope">-->
<!--								<div class="cl rel">-->
<!--									<div class="z" :class="item.more ? 'text-line-2' : ''" :style="item.more ? 'margin-left:25px;' : ''">-->
<!--										{{ item.root ? $utils.evalGet(scope.row, 'scope', item.value) : scope.row[item.value] }}-->
<!--									</div>-->
<!--									<el-button type="info" icon="el-icon-view" circle @click="showMore(scope.row[item.value])" v-if="item.more && scope.row[item.value]" size="mini" class="abs" style="top:0;left:0;padding:2px !important;"></el-button>-->
<!--								</div>-->
<!--							</template>-->
							<template slot-scope="scope">
								{{ item.root ? $utils.evalGet(scope.row, 'scope', item.value) : scope.row[item.value] }}
							</template>
						</el-table-column>
					</template>
				</template>
				<el-table-column fixed="right" align="right" label="操作" :width="params.optionsWidth ? params.optionsWidth : (params.showDetail ? 300 : 200)" v-if="params.showAdd || params.showEdit || params.showDel || params.showDetail">
					<template slot-scope="scope">
						<template v-if="params['list']['extButtons']">
							<template v-for="(item,index) in params['list']['extButtons']">
								<template v-if="item.type === 'shenpi'">
									<base-button v-if="item.rules ? (scope.row[item.rules.key] === item.rules.val) : true" :type="item.button_type" @click.native="toShenpi(scope.row,item)">
										{{ item.label }}
									</base-button>
								</template>
								<template v-else-if="item.type === 'changeState'">
									<base-button v-if="item.rules ? (scope.row[item.rules.key] === item.rules.val) : true" :type="item.button_type" @click.native="buttonChangeState(scope.row.id,item)">
										{{ item.label }}
									</base-button>
								</template>
								<template v-else>
									<base-button @click.native="$router.push({path:item.path,query:{[item.key ? item.key : 'id']:scope.row.id}})" v-if="item.rules ? (scope.row[item.rules.key] === item.rules.val) : true" :type="item.button_type" @click="$router.push({path:item.path,query:{[item.key ? item.key : 'id']:scope.row.id}})">
										{{ item.label }}
									</base-button>
								</template>
							</template>
							<!--                            <base-button @click.native="$router.push({path:item.path,query:{[item.key ? item.key : 'id']:scope.row.id}})" v-for="(item,index) in params['list']['extButtons']" v-if="item.rules ? (scope.row[item.rules.key] === item.rules.val) : true" :type="item.button_type">{{item.label}}</base-button>-->
						</template>
						<base-button plain type="success" @click.native="changeState(scope.row.id,(params.okStateVal === 0 || params.okStateVal ? params.okStateVal : 1))" v-if="params.showCheck && scope.row[params.checkStateKey ? params.checkStateKey : 'state'] === (params.errorStateVal === 0 || params.errorStateVal ? params.errorStateVal : 0)">
							{{ params.stateOkText ? params.stateOkText : '审核' }}
						</base-button>
						<base-button plain type="danger" @click.native="changeState(scope.row.id,(params.errorStateVal === 0 || params.errorStateVal ? params.errorStateVal : 0))" v-if="params.showCheck && scope.row[params.checkStateKey ? params.checkStateKey : 'state'] === (params.okStateVal === 0 || params.okStateVal ? params.okStateVal : 1)">
							{{ params.stateErrorText ? params.stateErrorText : '反审核' }}
						</base-button>
						<base-button plain type="success" @click.native="changeState2(scope.row.id,(params.okStateVal2 === 0 || params.okStateVal2 ? params.okStateVal2 : 1))" v-if="params.showCheck2 && scope.row[params.checkStateKey2 ? params.checkStateKey2 : 'state'] === (params.errorStateVal2 === 0 || params.errorStateVal2 ? params.errorStateVal2 : 0)">
							{{ params.stateOkText2 ? params.stateOkText2 : '审核' }}
						</base-button>
						<base-button plain type="danger" @click.native="changeState2(scope.row.id,(params.errorStateVal2 === 0 || params.errorStateVal2 ? params.errorStateVal2 : 0))" v-if="params.showCheck2 && scope.row[params.checkStateKey2 ? params.checkStateKey2 : 'state'] === (params.okStateVal2 === 0 || params.okStateVal2 ? params.okStateVal2 : 1)">
							{{ params.stateErrorText2 ? params.stateErrorText2 : '反审核' }}
						</base-button>
						<base-button plain type="danger" @click.native="$router.push({path:path+'/edit',query:{pid:scope.row.id}})" v-if="params.listTree && (!scope.row.pid || scope.row.pid === 0 || scope.row.pid === '0')">添加子级</base-button>
						<edit-button @click.native="$router.push({ path:params.editRouter,query:{id:scope.row.id }})" v-if="params.showEdit && $utils.checkButtonRules(true,scope.row,params.editRules ? params.editRules : null)" :rule="params.editRouter"></edit-button>
						<del-button @click.native="del(scope.row.id)" :rule="params.delRouter" v-if="params.showDel && $utils.checkButtonRules(true,scope.row,params.delRules ? params.delRules : null)"></del-button>
						<base-button @click.native="$router.push({path:params.detailRouter,query:{id:scope.row.id }})" :rule="params.detailRouter" v-if="params.showDetail">
							{{ params.detailText ? params.detailText : '详情' }}
						</base-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="margin-top" v-if="params.showListDel || params.showListTuijian || params.showListToutiao">
				<base-button @click.native="selectAll" :rule="params.delRouter">全选</base-button>
				<del-button @click.native="listDel" :rule="params.delRouter" label="批量删除" v-if="params.showListDel"></del-button>
				<base-button icon="el-icon-close-notification" type="danger" @click.native="listChangeState('is_tuijian','推荐')" :rule="params.editRouter" v-if="params.showListTuijian">
					批量取消推荐
				</base-button>
				<base-button icon="el-icon-close-notification" type="primary" @click.native="listChangeState('is_toutiao','头条')" :rule="params.editRouter" v-if="params.showListToutiao">
					批量取消头条
				</base-button>
				<base-button icon="el-icon-close-notification" type="success" @click.native="listChangeState('sort','置顶')" :rule="params.editRouter" v-if="params.showListSort">
					批量取消置顶
				</base-button>
				<base-button icon="el-icon-close-notification" type="primary" plain @click.native="listChangeState('state','审核')" :rule="params.checkRouter" v-if="params.showListState">
					批量重置审核
				</base-button>
			</div>
			<div class="padding-top-bottom-20 text-center" v-if="!params.listTree">
				<el-pagination
					background
					layout="total,prev, pager, next"
					:page-size="searchForm.pageSize"
					:current-page.sync="searchForm.page"
					:total="totalCount"
					@current-change="pageChange">
				</el-pagination>
			</div>
		</div>
		<el-dialog title="导出" center width="500px" :visible.sync="showOutputReset">
			<div v-if="outputData && outputData.last_time">
				<el-button class="block" icon="el-icon-download" size="medium" type="primary" @click="download($config.assetsUrl+outputData.url)" v-if="outputData.last_time">
					直接下载({{ outputData.last_time }})
				</el-button>
			</div>
			<div class="margin-top">
				<el-form :model="outForm" label-width="100px">
					<el-form-item label="注册时间">
						<range-time v-model="outForm.create_time" style="width: 100%;" @change="changeOutTime"></range-time>
					</el-form-item>
					<el-form-item label="预计导出数量">
						{{ outCount }}
					</el-form-item>
					<el-form-item label="导出字段">
						<el-checkbox-group v-model="outForm.items">
							<el-checkbox label="create_time">注册时间</el-checkbox>
							<el-checkbox label="name">姓名</el-checkbox>
							<el-checkbox label="mobile">手机号</el-checkbox>
							<el-checkbox label="email">邮箱</el-checkbox>
							<el-checkbox label="company">公司</el-checkbox>
							<el-checkbox label="job">职位</el-checkbox>
							<el-checkbox label="vip">VIP</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
				</el-form>
			</div>
			<div class="margin-top">
				<el-button class="block" icon="el-icon-refresh-right" size="medium" type="warning" @click="reMark">
					重新生成(等待5分钟后再下载)
				</el-button>
			</div>
		</el-dialog>
		<el-dialog title="审核" width="400px" :visible.sync="shenpiDialogVisible">
			<el-form :model="shenpiForm" ref="shenpiform">
				<el-form-item label="审核结果" prop="state" :rules="[{required:true,message:'审核结果必选'}]">
					<el-select v-model="shenpiForm.state">
						<el-option :value="1" label="通过"></el-option>
						<el-option :value="2" label="不通过"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="原因" prop="reason" :rules="[{required:true,message:'原因不能为空'}]" v-if="shenpiForm.state === 2">
					<el-input v-model="shenpiForm.reason" maxlength="50" show-word-limit></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="toCloseShenpi">取 消</el-button>
				<el-button type="primary" @click="toSubmitShenpi">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "PolicyList",
	data() {
		return {
			jidu: {'1': '第一季度', '2': '第二季度', '3': '第三季度', '4': '第四季度','5': '累计'},
			yuedu: {'1': '1月', '2': '2月', '3': '3月', '4': '4月','5': '5月','6': '6月','7': '7月','8': '8月','9': '9月','10': '10月','11': '11月','12': '12月','13': '累计'},
			banniandu: {'1': '上半年', '2': '下半年'},
			showPutIn: false,
			outputData: null,
			showOutputReset: false,
			path: '',
			params: null,
			totalCount: 0,
			list: [],
			searchForm: {
				pageSize: 15,
				page: 1,
			},
			listData: null,
			addTotal: {},
			addTotalType: false,
			rules: {},
			time: null,
			loading: null,
			ids: [],
			outForm: {
				create_time: '',
				items: ["create_time", "name", "mobile", "email", "company", "job", "vip"]
			},
			outCount: 0,
			shenpiForm:{
				id:'',
				state:undefined,
				reason:''
			},
			shenpiSetting:null,
			shenpiDialogVisible:false,
		};
	},
	created() {
		let _config = this.$baseConfig;
		this.path = this.$route.path;
		this.params = _config[this.path];

		this.listData = this.params.listData ? this.params.listData : {};
		if (this.params.showSearch) {
			let _edit = this.params['search']['params'];

			for (let i = 0; i < _edit.length; i++) {
				if (_edit[i].type === 'timeRange') {
					this.$set(this.searchForm, _edit[i].value, '');
				} else {
					this.$set(this.searchForm, _edit[i].value, '');
				}
			}
		}

		this.searchForm = this.$route.query ? {...this.searchForm, ...this.$route.query} : {...this.searchForm};
		this.searchForm.page = parseInt(this.searchForm.page);
		this.searchForm.pageSize = parseInt(this.searchForm.pageSize);
		this.searchForm.sort = this.searchForm.sort ? parseInt(this.searchForm.sort) : '';
	},
	mounted() {
		this.getList();
	},
	methods: {
		toAdd(){
			this.$router.push({path:this.path+'/edit',query:{...this.$route.query}})
		},
		toShenpi(row,prop) {
			console.log(row,prop);
			this.shenpiForm.id = row.id;
			this.shenpiSetting = prop;
			this.shenpiDialogVisible = true;
		},
		toCloseShenpi(){
			this.shenpiForm.id = '';
			this.shenpiForm.state = undefined;
			this.shenpiForm.reason = '';
			this.shenpiSetting = null;
			this.shenpiDialogVisible = false;
		},
		toSubmitShenpi(){
			let _this = this;
			this.$refs.shenpiform.validate(valid => {
				//为true时验证通过
				if (valid) {
					_this.$http.httpPost({
						url: _this.shenpiSetting.url,
						datas: {id:_this.shenpiForm.id,[_this.shenpiSetting.column['stateKey']]:_this.shenpiForm.state,[_this.shenpiSetting.column['reasonKey']]:_this.shenpiForm.reason},
						success: (res) => {
							console.log(res);
							_this.$message.success('操作成功');
							_this.getList();
							_this.toCloseShenpi();
						}
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		copy(txt) {
			console.log(txt);
			let _this = this;
			this.$copyText(txt).then(function (e) {
				_this.$utils.success('复制成功');
			}, function (e) {

			})
		},
		toSearch() {
			for (let i in this.searchForm) {
				if (i.endsWith('_time')) {
					console.log(i);
				}
			}
			this.searchForm.page = 1;
			this.$router.replace({query: {...this.searchForm}});
		},
		toReset() {
			let _edit = this.params['search']['params'];

			for (let i = 0; i < _edit.length; i++) {
				this.$set(this.searchForm, _edit[i].value, '');
			}

			this.searchForm.page = 1;

			this.$router.replace({query: {...this.searchForm}});
		},
		getList() {
			let _this = this;
			this.$http.httpPost({
				url: this.params.listUrl ? this.params.listUrl : this.params.apiUrl + '/listPage',
				datas: {..._this.searchForm, ...this.listData},
				success(res) {
					if(_this.params.listTree){
						_this.list = res ? res : [];
					}else {
						_this.list = res.list ? res.list : [];
						_this.totalCount = res.totalCount;
					}
				}
			});
		},
		pageChange(page) {
			this.searchForm.page = page;
			this.$router.replace({query: {...this.searchForm}});
		},
		del(id) {
			let _this = this;
			this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					this.$http.httpPost({
						url: _this.params.apiUrl + '/del',
						datas: {id: id, del: 1},
						success(res) {
							_this.$utils.success("删除成功！");
							_this.getList();
						}
					});
				})
				.catch(() => {
					// _this.$utils.warning('取消了');
				});
		},
		output() {
			let _this = this;

			this.$http.httpPost({
				url: _this.params.apiUrl + '/getOutPut',
				datas: null,
				success(res) {
					_this.outputData = res;
					_this.showOutputReset = true;

					_this.getOutCount();
				}
			});
		},
		reMark() {
			let _this = this;
			this.$http.httpPost({
				url: _this.params.apiUrl + '/remark',
				datas: this.outForm,
				success(res) {
					_this.$utils.success('执行成功，请等待5分钟后再次点击导出按钮即可下载');
					_this.showOutputReset = false;
				}
			});
		},
		changeOutTime() {
			this.getOutCount();
		},
		getOutCount() {
			let _this = this;
			this.$http.httpPost({
				url: _this.params.apiUrl + '/getOutPutCount',
				datas: this.outForm,
				success(res) {
					_this.outCount = res.count;
				}
			})
		},
		download(url) {
			window.open(url, '_blank');
			this.showOutputReset = false;
		},
		selectAll() {
			this.$refs.listtable.toggleAllSelection();
		},
		selectionChange(val) {
			let _check = [];
			if (val.length > 0) {
				for (let i = 0; i < val.length; i++) {
					_check.push(val[i].id);
				}
			}

			this.ids = _check;
		},
		listChangeState(type, text) {
			let _this = this;
			if (this.ids.length > 0) {
				this.$confirm('是否批量改变' + this.ids.length + '条数据的' + text + '状态?', "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				})
					.then(() => {
						let _datas = {};

						_datas[type] = 0;

						this.$http.httpPost({
							url: _this.params.apiUrl + '/allEdit',
							datas: {id: [this.ids, 'in'], ..._datas},
							success(res) {
								_this.$utils.success("操作成功！");
								_this.getList();
							}
						});
					})
					.catch(() => {
						// _this.$utils.warning('取消了');
					});
			} else {
				this.$utils.warning('请选择要操作的数据');
			}
		},
		buttonChangeState(id, item) {
			let _this = this;
			if (id) {
				this.$confirm(item.text, "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				})
					.then(() => {
						this.$http.httpPost({
							url: item.apiUrl + '/changeState',
							datas: {id: id, [item.key ? item.key : 'state']: item.val},
							success(res) {
								_this.$utils.success("操作成功！");
								_this.getList();
							}
						});
					})
					.catch(() => {
						// _this.$utils.warning('取消了');
					});
			} else {
				this.$utils.warning('操作异常');
			}
		},
		changeState2(id, state) {
			let _this = this;
			let _text = '';
			if (id && state >= 0) {
				if (state === (this.params.errorStateVal2 === 0 || this.params.errorStateVal2 ? this.params.errorStateVal2 : 0)) {
					_text = this.params.stateErrorTip2 ? this.params.stateErrorTip2 : '是否恢复该条数据的审核状态为【待审核】？'
				} else if (state === (this.params.okStateVal2 === 0 || this.params.okStateVal2 ? this.params.okStateVal2 : 1)) {
					_text = this.params.stateOkTip2 ? this.params.stateOkTip2 : '是否【审核通过】该条数据？'
				}
				this.$confirm(_text, "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				})
					.then(() => {
						this.$http.httpPost({
							url: _this.params.apiUrl + '/changeState',
							datas: {id: id, [_this.params.checkStateKey2 ? _this.params.checkStateKey2 : 'state']: state},
							success(res) {
								_this.$utils.success("操作成功！");
								_this.getList();
							}
						});
					})
					.catch(() => {
						// _this.$utils.warning('取消了');
					});
			} else {
				this.$utils.warning('操作异常');
			}
		},
		changeState(id, state) {
			let _this = this;
			let _text = '';
			if (id && state >= 0) {
				if (state === (this.params.errorStateVal === 0 || this.params.errorStateVal ? this.params.errorStateVal : 0)) {
					_text = this.params.stateErrorTip ? this.params.stateErrorTip : '是否恢复该条数据的审核状态为【待审核】？'
				} else if (state === (this.params.okStateVal === 0 || this.params.okStateVal ? this.params.okStateVal : 1)) {
					_text = this.params.stateOkTip ? this.params.stateOkTip : '是否【审核通过】该条数据？'
				}
				this.$confirm(_text, "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				})
					.then(() => {
						this.$http.httpPost({
							url: _this.params.apiUrl + '/changeState',
							datas: {id: id, [_this.params.checkStateKey ? _this.params.checkStateKey : 'state']: state},
							success(res) {
								_this.$utils.success("操作成功！");
								_this.getList();
							}
						});
					})
					.catch(() => {
						// _this.$utils.warning('取消了');
					});
			} else {
				this.$utils.warning('操作异常');
			}
		},
		listDel() {
			let _this = this;
			if (this.ids.length > 0) {
				this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				})
					.then(() => {
						this.$http.httpPost({
							url: _this.params.apiUrl + '/del',
							datas: {id: [this.ids, 'in']},
							success(res) {
								_this.$utils.success("删除成功！");
								_this.getList();
							}
						});
					})
					.catch(() => {
						// _this.$utils.warning('取消了');
					});
			} else {
				this.$utils.warning('请选择要删除的数据');
			}
		},
		toDownLoad(path, name, data = {}) {
			let _this = this;
			if (path) {
				this.$http.httpPost({
					download: true,
					url: path,
					datas: {...data, ...this.searchForm},
					success(res) {
						const blob = new Blob([res]) // 构造一个blob对象来处理数据
						let num = _this.$moment().format('YYYYMMDD');

						const fileName = name + '-' + num + '.xlsx';
						const link = document.createElement('a') // 创建a标签
						link.download = fileName // a标签添加属性
						link.style.display = 'none'
						link.href = URL.createObjectURL(blob)
						document.body.appendChild(link)
						link.click();
						URL.revokeObjectURL(link.href);
						document.body.removeChild(link);
						_this.$utils.success("操作成功！");
					}
				});
			} else {
				this.$utils.warning('无法操作');
			}
		},
		toCall(path, data) {
			let _this = this;
			if (path && data) {
				this.$http.httpPost({
					url: path,
					datas: data,
					success(res) {
						_this.$utils.success("操作成功！");
					}
				});
			} else {
				this.$utils.warning('无法操作');
			}
		},
		showMore(e) {
			this.$alert(e, '详细内容', {
				dangerouslyUseHTMLString: true
			});
		},
		onPutSuccess() {
			this.$message.success('导入成功');

			this.getList();

			this.showPutIn = false;
		},
	},
	destroyed() {
		if (this.time) {
			clearInterval(this.time);
			this.time = null;
		}
	}
};
</script>
