export default {
  '/report/domain': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showDetail: false,
    showSearch: true,
    showOutput: false,
    showListDel: false,
    routerData: null,
    listData: {type: 'domain', mm_type: 'chain'},
    name: '细分领域报告',
    apiUrl: '/Report',
    listRouter: '/report/domain',
    editRouter: '/report/domain/edit',
    addRouter: '',
    delRouter: '',
    detailRouter: '/report/detail',
    
    list: {
      com: '',
      params: [
        {name: 'ID', value: 'id'},
        {name: '标题', value: 'title', type: 'html'},
        // {name: '数据分类', value: 'mm_type',type:'mm_type'},
        {name: '分类', value: 'domainReport.name', root: true},
        {name: '部门', value: 'department.name', root: true},
        // {name: '是否开启支付', value: 'is_pay',type:'bool'},
        {name: '价格', value: 'price'},
        {name: '权限', value: 'vip_txt'},
        {name: '发布时间', value: 'push_time'},
        {name: '创建时间', value: 'create_time'},
      ]
    },
    edit: {
      com: '',
      params: [
        {name: '标题', value: 'title', rq: true, sc: true, edit: true, max: 40},
        // {name:'数据分类',value:'mm_type',type:'select',options:[{name:'冷链',id:'chain'},{name:'食材供应链',id:'agriculture'},{name:'冷链/食材供应链',id:'all'}],rq:true,edit:true},
        {name: '摘要', value: 'describe', type: 'textarea', rq: true, sc: false, edit: true, max: 200},
        {name: '分类', value: 'type_domainReport_id', rq: true, sc: false, edit: true, type:'treeSelect',url:'/Type/listTree',queryData:{akey:'domainReport',state:1,orderBy:'sort',sortBy:'asc'}},
        {name: '部门', value: 'type_department_id', type: 'select', data: {akey: 'department', state: 1}, rq: false, sc: true, edit: true},
        {name: '文号', value: 'doc_number', rq: false, sc: false, edit: true, max: 20},
        {name:'封面图',value:'head_img',rq:false,sc:false,edit: true},
        {name: '发布时间', value: 'push_time', rq: false, sc: false, edit: true},
        {name: '附件', value: 'files_ids', type: 'file', rq: false, sc: false, edit: true},
        {name: '内容', value: 'html_content', type: 'editor', rq: true, sc: false, edit: true},
        // {name:'是否开启支付',value:'is_pay',type:'state',rq:true,edit:true},
        {name: '开启收藏', value: 'open_favorite', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '开启点赞', value: 'open_like', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '开启评论', value: 'open_comment', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '访问权限', value: 'user_vip_type', type: 'vipPrice', rq: true, edit: true},
      ],
      editData: {type: 'domain', mm_type: 'chain'}
    },
    search: {
      params: [
        {name: '标题', value: 'title'},
        // {name: '数据分类', value: 'mm_type',type:'select',options:[{name:'冷链',id:'chain'},{name:'食材供应链',id:'agriculture'},{name:'冷链/食材供应链',id:'all'}]},
        // {name: '文号', value: 'doc_number'},
        {name: '部门', value: 'type_department_id', type: 'select', data: {akey: 'department', state: 1}},
        // {name: '是否开启支付', value: 'is_pay',type:'select',options:[{name:'是',id:'1'},{name:'否',id:'0'}]},
        {name: '发布时间', value: 'push_time', type: 'timeRange'},
        {name: '创建时间', value: 'create_time', type: 'timeRange'}
      ]
    }
  },
  '/report/industry': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showDetail: false,
    showSearch: true,
    name: '行业发展报告',
    apiUrl: '/Report',
    listRouter: '/report/industry',
    editRouter: '/report/industry/edit',
    listData: {type: 'industry', mm_type: 'chain'},
    list: {
      com: '',
      params: [
        {name: 'ID', value: 'id'},
        {name: '书名', value: 'title', type: 'html'},
        // {name: '封面图', value: 'head_img', type: 'images'},
        // {name: '数据分类', value: 'mm_type',type:'mm_type'},
        {name: '作者', value: 'author'},
        {name: '出版社', value: 'publisher'},
        {name: '价格', value: 'price'},
        {name: '出版时间', value: 'push_time'},
        {name: '创建时间', value: 'create_time'},
      ]
    },
    edit: {
      com: '',
      params: [
        {name: '书名', value: 'title', rq: true, sc: true, edit: true, max: 40},
        // {name:'数据分类',value:'mm_type',type:'select',options:[{name:'冷链',id:'chain'},{name:'食材供应链',id:'agriculture'},{name:'冷链/食材供应链',id:'all'}],rq:true,edit:true},
        {name: '封面图', value: 'head_img', rq: true, sc: false, edit: true},
        {name: '作者', value: 'author', rq: true, sc: false, edit: true, max: 20},
        {name: '出版社', value: 'publisher', rq: true, sc: false, edit: true, max: 20},
        {name: '出版时间', value: 'push_time', rq: true, sc: false, edit: true},
        {name: '摘要', value: 'html_content', type: 'textarea', rq: true, sc: false, edit: true, max: 200},
        {name: '图书简介', value: 'book_info', type: 'editor', rq: true, sc: false, edit: true, max: 200},
        {name: '作者简介', value: 'author_info', type: 'editor', rq: true, sc: false, edit: true, max: 200},
        {name: '图书目录', value: 'book_list', type: 'editor', rq: true, sc: false, edit: true},
        {name: '价格', value: 'price', type: 'price', rq: true, sc: false, edit: true, max: 10},
        {name: '购买链接', value: 'pay_url', rq: true, sc: false, edit: true},
        {name: '开启收藏', value: 'open_favorite', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '开启点赞', value: 'open_like', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '开启评论', value: 'open_comment', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        // {name:'权限',value: 'permission',rq:true,sc:false},
      ],
      editData: {type: 'industry', mm_type: 'chain'}
    },
    search: {
      params: [
        {name: '书名', value: 'title'},
        // {name: '数据分类', value: 'mm_type',type:'select',options:[{name:'冷链',id:'chain'},{name:'食材供应链',id:'agriculture'},{name:'冷链/食材供应链',id:'all'}]},
        {name: '作者', value: 'author'},
        {name: '出版社', value: 'publisher'},
        {name: '出版时间', value: 'push_time', type: 'timeRange'},
        {name: '创建时间', value: 'create_time', type: 'timeRange'}
      ]
    }
  },
}
