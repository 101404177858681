<template>
	<div class="bg-light padding-20">
		<div>
			<el-button @click="$router.back()">返回</el-button>
		</div>
<!--		{{info}}-->
		<el-card class="margin-top-20" v-if="prize">
			<div slot="header" class="clearfix">
				<span>奖项信息</span>
			</div>
			<el-row :gutter="10">
				<el-form v-if="prize" label-width="200px">
					<el-col :span="12">
						<el-form-item label="奖项名称:">{{prize.jiangxiang.name}}</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="奖项年份:">{{prize.year}}年</el-form-item>
					</el-col>
				</el-form>
			</el-row>
		</el-card>
		<el-card class="margin-top-20" v-if="info">
			<div slot="header" class="clearfix">
				<span>企业基本信息</span>
			</div>
			<el-row :gutter="10">
				<el-form v-if="info" label-width="200px">
					<el-col :span="12">
						<el-form-item label="企业名称:">{{info.company.title}}</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="企业性质:">{{info.company.nature.name}}</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="企业属性:">{{info.company.property.name}}</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="企业所在地:">{{info.company.province_name}} - {{info.company.city_name}}</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="主要网点:">
							<el-tag class="margin-right" v-for="(t,i) in info.company.main_location">{{t}}</el-tag>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="客户:">
							<el-tag class="margin-right" v-for="(t,i) in info.company.customer">{{t}}</el-tag>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="荣誉:">
							<el-tag class="margin-right" v-for="(t,i) in info.company.honor">{{t}}</el-tag>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="企业宣传PPT:">
							<div>
								<div v-for="(t,i) in info.company.files">
									<a :href="$utils.formatImagePath(t.url)" target="_blank" class="under">{{t.name}}</a>
								</div>
							</div>
<!--							<a class="under" :href="$utils.formatImagePath(tt.url)" target="_blank" v-for="(tt,ii) in info.company.files">{{tt.name}}</a>-->
						</el-form-item>
					</el-col>
				</el-form>
			</el-row>
		</el-card>
		<el-card class="margin-top-20" v-if="info">
			<div slot="header" class="clearfix">
				<span>申报信息</span>
			</div>
			<el-form v-if="info" label-width="200px">
				<el-form-item :label="tt.name" v-for="(tt,ii) in info.params_json">{{tt.value}}</el-form-item>
			</el-form>
		</el-card>
	</div>
</template>

<script>
export default {
	name: "baomingDetail",
	data() {
		return {
			id:'',
			info:null,
			prize:null
		}
	},
	created() {
		this.id = this.$route.query.id ? this.$route.query.id : ''
	},
	mounted() {
		if(this.id){
			this.getDetail();
		}
	},
	methods: {
		getDetail(){
			let _this = this;
			this.$http.httpPost({
				url: '/PrizeCompanyItem/detail',
				datas: {id:this.id},
				success(res) {
					console.log(res);
					_this.info = res;
					_this.getPrizeDetail()
				}
			});
		},
		getPrizeDetail(){
			let _this = this;
			this.$http.httpPost({
				url: '/Prize/detail',
				datas: {id:this.info.tid},
				success(res) {
					_this.prize = res;
				}
			});
		}
	}
}
</script>
