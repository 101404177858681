<template>
  <div class="bg-light padding-20">
    <el-form
        :model="formData"
        status-icon
        :rules="rules"
        ref="form"
        label-width="auto"
        class="margin-top"
        style="width:800px;"
    >
        <el-form-item label="最低提现金额" prop="less_cash">
            <el-input v-model="formData.less_cash" style="width:500px;">
            </el-input>
        </el-form-item>
        <el-form-item label="开启测试支付" prop="test_pay">
            <el-switch
                v-model="formData.test_pay"
                active-color="#13ce66"
                active-value="1"
                inactive-value="0"
                active-text="开启"
                inactive-text="关闭"
            >
            </el-switch>
        </el-form-item>
      <el-form-item label="冷链联系人二维码" prop="pay_contact_qrcode">
        <head-img-upload v-model="formData.pay_contact_qrcode"></head-img-upload>
      </el-form-item>
      <el-form-item label="冷链联系人电话" prop="pay_contact_phone">
        <el-input v-model="formData.pay_contact_phone" style="width:500px;">
        </el-input>
      </el-form-item>
      <el-form-item label="农产品联系人二维码" prop="pay_contact_qrcode_ncp">
        <head-img-upload v-model="formData.pay_contact_qrcode_ncp"></head-img-upload>
      </el-form-item>
      <el-form-item label="农产品联系人电话" prop="pay_contact_phone_ncp">
        <el-input v-model="formData.pay_contact_phone_ncp" style="width:500px;">
        </el-input>
      </el-form-item>
        <el-form-item label="微信支付商户号" prop="weixin_mch_id">
            <el-input v-model="formData.weixin_mch_id" style="width:500px;">
            </el-input>
        </el-form-item>
        <el-form-item label="微信支付商户秘钥" prop="weixin_mch_secret">
            <el-input v-model="formData.weixin_mch_secret" style="width:500px;">
            </el-input>
        </el-form-item>
        <el-form-item label="支付路径" prop="pay_url">
            <el-input v-model="formData.pay_url" style="width:500px;">
            </el-input>(微信支付商户平台中设置)
        </el-form-item>
      <el-form-item label="支付回调地址" prop="notify_url">
        <el-input v-model="formData.notify_url" style="width:500px;"></el-input>
      </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitForm('form')">确定</el-button>
            <el-button type="" @click="$router.back()">返回</el-button>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
    name: "pay",
    data() {
        return {
            formData: {
                test_pay:'0',
                weixin_mch_id: "",
                weixin_mch_secret: "",
                pay_url:'',
                less_cash:0,
              pay_contact_qrcode:'',
              pay_contact_phone:'',
              pay_contact_qrcode_ncp:'',
              pay_contact_phone_ncp:'',
              notify_url:''
            },
            rules: {
                weixin_mch_id: [{required: true,message:'商户号必填'}],
                weixin_mch_secret: [{required: true,message:'商户秘钥必填'}],
                pay_url: [{required: true,message:'支付路径必填'}],
                less_cash: [{required: true,message:'最低提现金额必填'},{validator: this.$utils.rulePriceCanZero,message:'请填写正确的金额'}],
              notify_url:[{required: true,message:'回调地址必填'}]
                // weixin_service_qrcode: [{required: true,message:'服务号二维码必填'}],
            }
        };
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        submitForm(formName) {
            let _this = this;
            this.$refs[formName].validate(valid => {
                if (valid) {
                    _this.$http.httpPost({
                        url: "/SystemSetting/edit",
                        datas: {..._this.formData},
                        success: res => {
                            if (res === "ok") {
                                _this.$message.success("修改成功");
                            } else {
                                _this.$message.error("修改失败");
                            }
                            // _this.$message.info(res.msg);
                            // _this.$router.replace({path: '/cate/list'})
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        getDetail() {
            let _this = this;

            let _skeys = [];

            for (let k in this.formData) {
                _skeys.push(k);
            }

            _this.$http.httpPost({
                url: "/SystemSetting/getSystem",
                datas: {skey: _skeys},
                success(res){
                    console.log(res);
                    _this.formData = res;
                    console.log(_this.formData);
                }
            });
        }
    }
};
</script>
