<template>
  <el-breadcrumb class="app-breadcrumb" separator-class="el-icon-arrow-right">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item
        v-for="(item, index) in levelList"
        v-if="item.meta.title"
        :key="item.path"
      >
        <span
          v-if="item.redirect === 'noredirect' || index == levelList.length - 1"
          class="no-redirect"
          >{{ item.meta.title }}</span
        >
        <a class="linkIndex" v-else @click.prevent="handleLink(item)">{{
          item.meta.title
        }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>
<script>
export default {
  name: "MainBreadcrumb",
  data() {
    return {
      levelList: null
    };
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    }
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      let matched = this.$route.matched.filter(item => {
        if (item.name) {
          return true;
        }
      });

      const first = matched[0];
      if (first && first.name !== "首页") {
        if (this.$route.meta.parents) {
          matched = [
            { path: "/dashboard", meta: { title: "首页" } },
            {
              path: this.$route.meta.parents.path,
              meta: { title: this.$route.meta.parents.title }
            }
          ].concat(matched);
        } else {
          matched = [{ path: "/dashboard", meta: { title: "首页" } }].concat(
            matched
          );
        }
      }
      this.levelList = matched;
    },
    handleLink(data) {
      console.log(data);
      this.$router.push(data.path);
    }
  }
};
</script>
<style scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 60px;
}
.linkIndex {
    color: #aaa;
    font-weight: normal;
}
.no-redirect {
    color: #aaa;
    cursor: text;
}
</style>
