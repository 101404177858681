<template>
  <div>
    <div class="bg-light padding-20">
      <div class="padding-bottom">
        <el-button @click="$router.push({path:'/survey'})">返回问卷管理</el-button>
      </div>
      <div v-if="info" class="padding-top-bottom-20">
        <el-row class="dark-2">
          <el-col :span="12">奖项标题：<b class="success">{{ info.title }}</b></el-col>
          <el-col :span="12">创建时间：<b class="success">{{ info.create_time }}</b></el-col>
        </el-row>
        <el-row class="dark-2 margin-top-20">
          <el-col :span="12">参与数量：<b class="success">{{ info.join_count }}</b></el-col>
          <el-col :span="12">状态：
            <b class="success" v-if="info.state === 1">已发布</b>
            <b class="danger" v-if="info.state === 0">未发布</b>
          </el-col>
        </el-row>
				<div class="margin-top-20 dark-2">奖项说明：</div>
				<div v-html="info.des_html">{{info.des_html}}</div>
      </div>
    </div>
    <div class="bg-light padding-20 margin-top">
      <div class="padding-top-bottom cl">
        <div class="z">问题列表：</div>
        <base-button @click.native="addCol()" class="y" type="danger">添加表单</base-button>
      </div>
      <div>
        <div class="border-bottom padding-bottom margin-bottom-20" v-for="(item,index) in list">
          <div class="cl">
            <div class="z">
              <span class="margin-right">{{index + 1}}、{{item.title}}</span>
              <el-tag type="success" v-if="item.type === 'radio'">单选</el-tag>
              <el-tag type="danger" v-if="item.type === 'multi'">多选</el-tag>
              <el-tag type="warning" v-if="item.type === 'input'">单项填空</el-tag>
              <el-tag type="warning" v-if="item.type === 'multi-input'">多项填空</el-tag>
              <el-tag type="warning" v-if="item.type === 'table-input'">横向填空</el-tag>
              <el-tag type="warning" v-if="item.type === 'files'">附件</el-tag>
              <span class="margin-left" v-if="item.q_required === 1">
                <el-tag type="info">必答</el-tag>
              </span>
              <span class="margin-left" v-if="item.is_jump === 1">
                <el-tag type="info">被跳题</el-tag>
              </span>
              <span class="margin-left" v-if="item.jump_type !== 0">
                <el-tag type="primary" v-if="item.jump_type === 1">按选项跳题</el-tag>
                <el-tag type="warning" v-if="item.jump_type === 2">无条件跳题</el-tag>
              </span>
	            <span class="margin-left" v-if="item.is_files === 1">
                <el-tag type="primary">可传附件</el-tag>
              </span>
            </div>
            <div class="y">
              <!--            <el-button icon="el-icon-top f-b" size="mini" type="info"></el-button>-->
              <!--            <el-button icon="el-icon-bottom f-b" size="mini" type="info"></el-button>-->
              <el-button type="text" @click.native="addJump(item.id,item)" v-if="list.length > 1 && (index + 1) < list.length && item.type !== 'input' && item.is_jump === 0">逻辑跳题</el-button>
              <el-button type="text" @click.native="addCol('',item.id,item)" v-if="item.type !== 'input' && item.type !== 'files'">添加选项</el-button>
              <el-button type="text" @click.native="addCol(item.id,item.pid,item)">编辑</el-button>
              <el-button type="text" @click.native="del(item.id)">删除</el-button>
            </div>
          </div>
					<template v-if="item.child && item.child.length > 0">
						<template v-if="item.type === 'table-input'">
<!--							<el-table :data="item.child" border>-->
<!--								<el-table-column label="12"></el-table-column>-->
<!--								<el-table-column label="12"></el-table-column>-->
<!--							</el-table>-->
							<div class="el-table el-table--border margin-top">
								<div class="el-table__body-wrapper">
									<table class="el-table__body" cellpadding="0" cellspacing="0" border="0" style="width: 100%;">
										<tbody>
											<tr class="el-table__row">
												<td class="el-table__cell" v-for="(st,si) in item.child" style="text-align: center;font-weight: bold;">
													{{st.title}}
													<el-button type="text" class="success" @click.native="addCol(st.id,st.pid,st)">编辑</el-button>
													<el-button type="text" class="danger" @click.native="del(st.id)">删除</el-button>
												</td>
											</tr>
											<tr class="el-table__row">
												<td class="el-table__cell" v-for="(st,si) in item.child" style="text-align: center;font-weight: bold;"></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</template>
						<template v-else>
							<div class="cl padding border-w-1 margin-bottom"  v-for="(st,si) in item.child">
								<div class="cl">
									<div class="z" style="line-height: 40px;">{{si+1}}、{{st.title}}</div>
									<div class="y">
										<!--            <el-button type="text" class="dark-3" @click.native="del(item.id)">上移</el-button>-->
										<!--            <el-button type="text" class="dark-3" @click.native="del(item.id)">下移</el-button>-->
										<el-button type="text" class="success" @click.native="addCol(st.id,st.pid,st)">编辑</el-button>
										<el-button type="text" class="danger" @click.native="del(st.id)">删除</el-button>
									</div>
								</div>
								<div class="f14 success" v-if="st.is_input === 1">
									<template v-if="st.is_input === 1"><i class="el-icon-success margin-right-5"></i>允许填空</template>
									<template v-if="st.is_required === 1"><span class="padding-left-right">|</span><i class="el-icon-success margin-right-5"></i>填空必填</template>
								</div>
							</div>
						</template>
					</template>
        </div>
      </div>
    </div>
    <el-dialog title="逻辑跳题" :visible.sync="jumpDialog" @closed="onCloseJump" append-to-body>
      <div class="cl" style="height: 30px;line-height: 30px;">
        <div class="z f16">按选项跳题</div>
        <el-switch
          class="y"
          @change="onChangeJumpType"
          v-model="formData.jump_type"
          :active-value="1"
          :inactive-value="0">
        </el-switch>
      </div>
      <div v-for="(item,index) in jumpItem" v-if="formData.jump_type === 1">
        <div class="f14 dark-2" style="padding:5px 0;">{{item.title}}</div>
        <el-select v-model="jumpItem[index].jumpid" clearable placeholder="请选择" style="width: 100%;">
          <el-option
            v-for="item in jumpList"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="cl" style="padding-top:20px;border-top: 1px solid #eee;margin-top:20px;">
        <div class="z f16">无条件跳题</div>
        <el-switch
          class="y"
          @change="onChangeJumpType"
          v-model="formData.jump_type"
          :active-value="2"
          :inactive-value="0">
        </el-switch>
      </div>
      <div v-if="formData.jump_type === 2">
        <el-select v-model="formData.jumpid" placeholder="请选择" style="width: 100%;margin-top:10px;">
          <el-option
            v-for="item in jumpList"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="jumpDialog = false">取 消</el-button>
        <el-button type="primary" @click="toAddJump">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="添加/编辑选项" :visible.sync="dialogVisible" @closed="onCloseDialog" append-to-body>
      <el-form :model="formData" label-width="150px" ref="form" :rules="rules">
<!--				{{formData}}-->
        <el-form-item prop="type" label="选项类型" v-if="formData.pid === 0 || formData.pid === '' || formData.pid === '0'">
          <el-radio-group v-model="formData.type" @change="onChangeType">
            <el-radio label="radio">单选</el-radio>
            <el-radio label="multi">多选</el-radio>
            <el-radio label="input">单项填空</el-radio>
            <el-radio label="multi-input">多项填空</el-radio>
            <el-radio label="table-input">横向填空</el-radio>
            <el-radio label="files">附件</el-radio>
          </el-radio-group>
        </el-form-item>
	      <el-form-item label="是否允许上传附件" v-if="formData.type !== 'files' && (formData.pid === 0 || formData.pid === '' || formData.pid === '0')">
		      <el-switch
			      v-model="formData.is_files"
			      active-color="#13ce66"
			      :active-value="1"
			      :inactive-value="0"
			      active-text="是"
			      inactive-text="否">
		      </el-switch>
	      </el-form-item>
        <el-form-item label="是否必答" v-if="formData.type !== 'files' && (formData.pid === 0 || formData.pid === '' || formData.pid === '0')">
          <el-switch
            v-model="formData.q_required"
            active-color="#13ce66"
            :active-value="1"
            :inactive-value="0"
            active-text="是"
            inactive-text="否">
          </el-switch>
        </el-form-item>
        <template v-if="formData.pid && formData.pid !== '0'">
          <el-form-item prop="title" label="选项内容">
            <el-input v-model="formData.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="is_input" label="是否允许填空" v-if="formData.type === 'radio' || formData.type === 'multi'">
            <el-switch
              v-model="formData.is_input"
              active-color="#13ce66"
              :active-value="1"
              :inactive-value="0"
              active-text="允许"
              inactive-text="禁止"
              @change="onChangeInput">
            </el-switch>
          </el-form-item>
          <el-form-item prop="is_required" label="填空必答" v-if="formData.is_input === 1 && (formData.type === 'radio' || formData.type === 'multi')">
            <el-switch
              v-model="formData.is_required"
              active-color="#13ce66"
              :active-value="1"
              :inactive-value="0"
              active-text="是"
              inactive-text="否">
            </el-switch>
          </el-form-item>
        </template>
        <el-form-item prop="title" label="选项标题" v-else>
          <el-input v-model="formData.title" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toAddCol">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "add",
  data() {
    return {
      list: [],
      tid: '',
      info: null,
      dialogVisible: false,
      jumpDialog:false,
      colType: '1',
      formData: {
        id:'',
        pid: '',
        title: '',
        type: '',
        is_input:0,
        is_required:0,
	      is_files:0,
        q_required:1,
        jump_type:0,
        jumpid:''
        // colType: '1'
      },
      jumpItem:[],
      jumpList:[],
      rules: {
        title:[
          {required:true,message:'内容必填'}
        ],
        type:[
          {required:true,message:'类型必选'}
        ]
      }
    }
  },
  created() {
    this.tid = this.$route.query.id ? this.$route.query.id : '';
  },
  mounted() {
    this.getList();
    this.getDetail();
  },
  methods: {
    onChangeType(e){
      if(e === 'input'){
        this.formData.is_input = 0;
        this.formData.is_required = 0;
      }

			if(e === 'files'){
				this.formData.is_files = 0;
				this.formData.is_required = 1;
			}
    },
    onChangeInput(e){
      if(!e){
        this.formData.is_required = 0;
      }
    },
    addJump(id,item){
      console.log(item.jump_type);
      console.log(item.jump_type);

      let _this = this;

      this.jumpDialog = true;

      this.$nextTick(()=>{
        this.jumpItem = item.child;
        this.formData.id = id;
        this.formData.tid = item ? item.tid : 0;
        this.formData.jump_type = item ? item.jump_type : 0;
        this.formData.q_required = item ? item.q_required : 1;
        this.formData.create_time = item ? item.create_time : 0;
        this.formData.jumpid = item ? item.jumpid : '';

        this.getJumpList();
      })

    },
    getList() {
      let _this = this;

      if (this.tid) {
        _this.$http.httpPost({
          url: '/SurveyCol/listDigui',
          datas: {id: this.tid},
          success: (res) => {
            console.log(res);
            _this.list = res;
          }
        })
      }
    },
    getJumpList(){
      let _this = this;
      let _createTime = this.$moment(this.formData.create_time).unix();

      _this.$http.httpPost({
        url: '/SurveyCol/listAll',
        datas: {tid:this.formData.tid,pid:'0',create_time:[_createTime,'>'],orderBy:'create_time',sortBy:'asc'},
        success: (res) => {
          console.log(res);
          _this.jumpList = res;
        }
      })
    },
    onChangeJumpType(e){
      console.log(e);
      let _this = this;

      if(e){
        this.getJumpList();
      }else{
        this.jumpList = [];
      }
    },
    getDetail() {
      let _this = this;

      if (this.tid) {
        _this.$http.httpPost({
          url: '/SurveyTitle/detail',
          datas: {id: this.tid},
          success: (res) => {
            _this.info = res;
          }
        })
      }
    },
    toAddJump(){
      let _this = this;

      _this.$http.httpPost({
        url: '/SurveyCol/addJump',
        datas: {...this.formData,list:this.jumpItem},
        success: (res) => {
          console.log(res);

          _this.$message.success('保存成功');
          _this.getList();
          _this.jumpDialog = false;
        }
      })
    },
    toAddCol() {
      let _this = this;

      let _url = '';

      if(this.formData.id){
        _url = '/SurveyCol/edit';
      }else{
        _url = '/SurveyCol/add';
      }

      this.$refs['form'].validate((valid) => {
        console.log(valid);
        if (valid) {
          _this.$http.httpPost({
            url: _url,
            datas: {..._this.formData,tid:this.tid},
            success: (res) => {
              _this.$message.success('添加成功');
              _this.getList();
              _this.onCloseDialog();
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    onCloseJump(){
      console.log('onCloseJump');
    },
    onCloseDialog() {

      console.log('onCloseDialog');

      this.formData.id = '';
      this.formData.tid = '';
      this.formData.pid = '';
      this.formData.title = '';
      this.formData.colType = '1';
      this.formData.type = '';
      this.formData.is_required = 0;
      this.formData.is_input = 0;
      this.formData.is_files = 0;

      this.dialogVisible = false;

      this.$refs['form'].clearValidate();
    },
    addCol(id = '',pid = '',item) {

      console.log(item);

      let _this = this;

      this.formData.id = id;
      this.formData.pid = pid;
      this.formData.title = id ? item.title : '';
      this.formData.type = item ? item.type : '';
      this.formData.is_input = item ? item.is_input : 0;
      this.formData.is_required = item ? item.is_required : 0;
      this.formData.jump_type = item ? item.jump_type : 0;
      this.formData.jumpid = item ? item.jumpid : '';
      this.formData.q_required = item ? item.q_required : 1;
      this.formData.is_files = item ? item.is_files : 0;

			if(item) {
				if (item.type === 'table-input' || item.type === 'multi-input') {
					this.formData.is_input = 1;
					this.formData.is_required = 1;
				}
			}

      this.dialogVisible = true;
    },
    // editCol(id,title){
    //     let _this = this;
    //     let _title = '';
    //
    //     if(id){
    //         _title = '选项';
    //     }else{
    //         _title = '问题';
    //     }
    //
    //     this.$prompt('请输入'+_title, '提示', {
    //         confirmButtonText: '确定',
    //         cancelButtonText: '取消',
    //         inputValue:title,
    //         inputValidator(value){
    //             if(value && value.trim()){
    //                 return true;
    //             }else {
    //                 return false;
    //             }
    //         },
    //         inputErrorMessage: _title+'内容必填'
    //     }).then(({ value }) => {
    //         _this.$http.httpPost({
    //             url: '/SurveyCol/edit',
    //             datas: {title:value,tid:_this.id,id:id},
    //             success: (res) => {
    //                 _this.$message.success('修改成功');
    //                 _this.getList();
    //             }
    //         })
    //     }).catch(() => {
    //     });
    // },
    del(id) {
      var _this = this;
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.$http.httpPost({
          url: '/SurveyCol/del',
          datas: {id: id, del: 1},
          success: (res) => {
            _this.getList();
          }
        });
      }).catch(() => {
      });
    },
  }
}
</script>
