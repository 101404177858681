<template>
	<div class="bg-light padding-20">
		<el-row :gutter="10">
			<el-form :model="searchForm" label-width="100px">
<!--				{{searchForm}}-{{times}}-->
				<el-col :span="5">
					<el-form-item label="月度" v-if="searchForm.time_type === 'month'">
						<month-picker v-model="times" now @change="onChangeTime"></month-picker>
					</el-form-item>
					<el-form-item label="季度" v-if="searchForm.time_type === 'quarter'">
						<quarter-picker v-model="times" now @change="onChangeTime"></quarter-picker>
					</el-form-item>
					<el-form-item label="半年度" v-if="searchForm.time_type === 'half'">
						<half-picker v-model="times" now @change="onChangeTime"></half-picker>
					</el-form-item>
					<el-form-item label="年度" v-if="searchForm.time_type === 'year'">
						<year-picker v-model="times" now @change="onChangeTime"></year-picker>
					</el-form-item>
					<!--                    <el-form-item label="年份">-->
					<!--											{{searchForm.times}}-->
					<!--											<quarter-picker v-model="searchForm.times" now></quarter-picker>-->
					<!--											<half-picker now></half-picker>-->
					<!--											<month-picker now></month-picker>-->
					<!--                        <year-select v-model="searchForm.year" @change="change"></year-select>-->
					<!--                    </el-form-item>-->
				</el-col>
				<el-col :span="5" v-if="list.length > 0">
					<del-button size="normal" @click.native="del(searchForm.year)" :label="'删除数据'"></del-button>
				</el-col>
				<el-col :span="14" class="text-right">
					<put-in v-model="showPutIn" @success="onPutSuccess" :data="{key:'putin',year:searchForm.year,akey:searchForm.akey,time_type:searchForm.time_type,times:searchForm.times}" url-path="/HydataInout" :demo-path="'/'+searchForm.akey+'_demo.xlsx'">导入{{buttonText}}</put-in>
				</el-col>
			</el-form>
		</el-row>
		<div class="border-top" v-if="list.length > 0">
			<el-table
				:data="list"
				style="width: 100%">
				<el-table-column label="时间" prop="year">
					<template slot-scope="scope">
						<template v-if="scope.row.time_type === 'year'">
							{{scope.row.year}}年
						</template>
						<template v-if="scope.row.time_type === 'month'">
							{{scope.row.year}}年{{scope.row.times}}月
						</template>
						<template v-if="scope.row.time_type === 'quarter'">
							{{scope.row.year}}年{{jidu[scope.row.times]}}
						</template>
						<template v-if="scope.row.time_type === 'half'">
							{{scope.row.year}}年{{banniandu[scope.row.times]}}
						</template>
					</template>
				</el-table-column>
				<el-table-column label="类别" prop="title"></el-table-column>
				<el-table-column label="重量(万吨)" prop="weight"></el-table-column>
				<el-table-column label="金额(亿元)" prop="amount"></el-table-column>
				<el-table-column label="同比(%)" prop="tongbi"></el-table-column>
				<el-table-column label="环比(%)" prop="huanbi"></el-table-column>
				<el-table-column label="操作" width="100" align="right">
					<template slot-scope="scope">
						<edit-button @click.native="$router.push({ path: '/hydata/inout/'+searchForm.akey+'/'+searchForm.time_type+'/edit',query:{id:scope.row.id }})"></edit-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="text-center padding-top-bottom-20 rel">
				<el-pagination
					background
					layout="total,prev, pager, next"
					:page-size="searchForm.pageSize"
					:current-page.sync="searchForm.page"
					:total="totalCount"
					@current-change="pageChange">
				</el-pagination>
			</div>
		</div>
		<div class="padding-top-bottom-40 border-top text-center dark-2" v-else>
			暂无数据，
			<add-button @click.native="initData" :label="'初始化数据'"></add-button>
		</div>
	</div>
</template>

<script>
export default {
	name: "list",
	data() {
		return {
			value1: '',
			showPutIn: false,
			searchForm: {
				pageSize: 15,
				page: 1,
				year: '',
				akey: '',
				time_type: '',
				times: ''
			},
			times: '',
			list: [],
			totalCount: 0,
			akey: '',
			jidu:{'1':'第一季度','2':'第二季度','3':'第三季度','4':'第四季度'},
			banniandu:{'1':'上半年','1':'下半年'}
		}
	},
	created() {
		let _now = new Date();
		this.searchForm.year = _now.getFullYear();

		this.searchForm = this.$route.query ? {...this.searchForm, ...this.$route.query} : {...this.searchForm};
		this.searchForm.page = parseInt(this.searchForm.page);
		this.searchForm.pageSize = parseInt(this.searchForm.pageSize);

		this.searchForm.akey = this.$route.params.akey ? this.$route.params.akey : '';
		this.searchForm.time_type = this.$route.query.time_type ? this.$route.query.time_type : '';
		this.searchForm.times = this.$route.query.times ? this.$route.query.times : '';
		this.searchForm.year = this.$route.query.year ? this.$route.query.year : '';

		this.times = this.searchForm.year;

		if(this.$route.query.times){
			this.times += '-'+this.$route.query.times;
		}
		// if(this.searchForm.year){
		// 	console.log(this.searchForm.year);
		// 	this.times = this.searchForm.year+'-'+this.searchForm.times;
		// }

	},
	mounted() {
		let _this = this;

		this.$nextTick(()=>{
			let _time = _this.times ? _this.times.split('-') : '';
			_this.searchForm.year= _time[0];
			if(_time && _time[1]){
				_this.searchForm.times= _time[1];
			}
			_this.getList();
		})
	},
	computed:{
		buttonText(){
			if(this.searchForm.time_type === 'quarter'){
				return this.searchForm.year+'年'+this.jidu[this.searchForm.times]+'数据'
			}else if(this.searchForm.time_type === 'half'){
				return this.searchForm.year+'年'+this.banniandu[this.searchForm.times]+'数据'
			}else if(this.searchForm.time_type === 'month'){
				return this.searchForm.year+'年'+this.searchForm.times+'月数据'
			}else{
				return this.searchForm.year+'年数据'
			}
		}
	},
	methods: {
		onChangeTime(e) {
			this.searchForm.year = e.year;
			this.searchForm.times = e.times;

			// this.getList();
			this.$router.replace({query: {...this.searchForm}});
		},
		change(e) {
			this.searchForm.year = e;

			this.$router.replace({query: {...this.searchForm}});
		},
		toSearch() {
			this.$router.replace({query: {...this.searchForm}});
		},
		toReset() {
			let _edit = this.params['edit']['params'];

			for (let i = 0; i < _edit.length; i++) {
				if (_edit[i].sc && typeof _edit[i].value === 'string') {
					this.$set(this.searchForm, _edit[i].value, '');
				}
			}

			this.$router.replace({query: {...this.searchForm}});
		},
		initData() {
			let _this = this;
			this.$http.httpPost({
				url: '/HydataInout/initData',
				datas: {...this.searchForm},
				success: (res) => {
					console.log(res);
					_this.getList();
				}
			});
		},
		pageChange(page) {
			this.searchForm.page = page;
			this.$router.replace({query: {...this.searchForm}});
		},
		getList() {
			let _this = this;
			this.$http.httpPost({
				url: '/HydataInout/listPage',
				datas: {...this.searchForm,orderBy:'year',sortBy:'desc'},
				success: (res) => {
					_this.list = res.list ? res.list : [];
					_this.totalCount = res.totalCount;
				}
			});
		},
		del(year) {
			let _this = this;
			this.$confirm("是否删除" + this.buttonText, "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					this.$http.httpPost({
						url: '/HydataInout/del',
						datas: {year: year, akey: _this.searchForm.akey, time_type: _this.searchForm.time_type},
						success(res) {
							_this.$utils.success("删除成功！");
							_this.getList();
						}
					});
				})
				.catch(() => {
					// _this.$utils.warning('取消了');
				});
		},
		onPutSuccess() {
			// this.$message.success('导入成功');

			this.getList();

			this.showPutIn = false;
		},
	}
}
</script>
