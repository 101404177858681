<template>
	<div class="bg-light padding-20">
		<div class="">
			<el-form :model="searchForm" ref="searchForm" label-width="100px">
				<el-row :gutter="10">
					<el-col :span="5">
						<el-form-item label="订单号：">
							<el-input v-model="searchForm.order_no"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="交易单号：">
							<el-input v-model="searchForm.out_trade_no"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="订单类别">
							<el-select v-model="searchForm.order_type">
								<el-option label="-全部-" value=""></el-option>
								<el-option label="细分领域报告" value="domain"></el-option>
								<el-option label="问答(购买答案)" value="answer"></el-option>
								<el-option label="VIP" value="vip"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="订单状态">
							<el-select v-model="searchForm.state">
								<el-option label="-全部-" value=""></el-option>
								<el-option label="已关闭" value="0"></el-option>
								<el-option label="待付款" value="1"></el-option>
								<el-option label="已付款" value="2"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="4">
						<el-button type="primary" @click="toSearch">查询</el-button>
						<el-button @click="clearSearch">清空</el-button>
					</el-col>
				</el-row>
			</el-form>
			<el-table :data="dataList" default-expand-all style="width: 100%" row-key="id"
								:tree-props="{children: 'child'}" :indent="30" v-if="dataList.length > 0">
				<el-table-column
					prop="order_no"
					label="订单号/支付单号" width="200">
					<template slot-scope="scope">
						{{ scope.row.order_no }}<br/>
						{{ scope.row.out_trade_no }}
					</template>
				</el-table-column>
				<el-table-column
					prop="consignee"
					label="买家信息" width="200">
					<template slot-scope="scope">
						<div>姓名：{{ scope.row.user.name }}</div>
						<div>手机号码：{{ scope.row.mobile }}</div>
						<div>企业信息：{{ scope.row.user.company }}</div>
						<div>职位：{{ scope.row.user.job }}</div>
					</template>
				</el-table-column>
				<el-table-column
					prop="order_type"
					label="订单类别">
					<template slot-scope="scope">
						<b v-if="scope.row.order_type === 'domain'">细分领域报告</b>
						<b v-if="scope.row.order_type === 'question'">问答</b>
						<b v-if="scope.row.order_type === 'answer'">问答(购买答案)</b>
						<b v-if="scope.row.order_type === 'vip'">购买VIP</b>
						<b v-if="scope.row.order_type === 'news'">购买资讯</b>
						<b v-if="scope.row.order_type === 'policy'">购买政策详细内容</b>
						<b v-if="scope.row.order_type === 'standard'">购买标准详细内容</b>
					</template>
				</el-table-column>
				<el-table-column
					prop="order_amount"
					label="订单金额">
					<template slot-scope="scope">
						<div>订单金额：{{ scope.row.order_amount }}</div>
						<div>实际支付金额：{{ scope.row.pay_amount }}</div>
					</template>
				</el-table-column>
				<el-table-column
					label="购买对象" width="300">
					<template slot-scope="scope">
						<div>ID：{{ scope.row.ext_id }}</div>
						<div>描述：{{ scope.row.order_body }}</div>
					</template>
				</el-table-column>
				<el-table-column
					prop="state"
					label="状态" style="font-size:12px;" width="150">
					<template slot-scope="scope">
						<el-tag type="info" disable-transitions v-if="scope.row.state === 0">已关闭</el-tag>
						<el-tag type="info" disable-transitions v-if="scope.row.state === 1">未付款</el-tag>
						<el-tag type="success" disable-transitions v-if="scope.row.state === 2">已付款</el-tag>
					</template>
				</el-table-column>
				<el-table-column
					prop="create_time"
					label="下单时间"></el-table-column>
				<el-table-column align="right">
					<template slot-scope="scope">
						<base-button @click.native="$router.push({path:'/order/detail/'+scope.row.id})"
												 rule="/order/detail">详情
						</base-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="text-center null-data f14 info" v-else>
				还没有数据
			</div>
			<div class="text-center padding-top-bottom-20" v-if="dataList.length > 0">
				<el-pagination
					background
					layout="total,prev, pager, next"
					:page-size="pageSize"
					:current-page.sync="searchForm.page"
					:total="totalCount"
					@current-change="pageChange">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "MemberList",
	data() {
		return {
			searchForm: {
				order_no: '',
				out_trade_no: '',
				phone: '',
				state: '',
				order_type: '',
				consignee: '',
				page: 1
			},
			dialogVisible: false,
			dataList: [],
			totalPage: 0,
			totalCount: 0,
			pageSize: 20,
			page: 1,
			formData: {
				id: '',
				state: 0,
				shipping_name: '',
				shipping_no: ''
			},
			rules: {
				id: [
					{required: true},
				],
				state: [
					{required: true},
				],
				shipping_name: [
					{required: true},
				],
				shipping_no: [
					{required: true},
				],
			}
		}
	},
	created() {
		let _query = this.$route.query;
		this.searchForm = {...this.searchForm, ..._query};
		this.searchForm.page = _query.page ? parseInt(_query.page) : 1;
	},
	methods: {
		toSearch() {
			this.searchForm.page = 1;
			this.$router.replace({query: this.searchForm});
			this.getList();
		},
		clearSearch() {
			this.searchForm = {
				order_no: '',
				out_trade_no: '',
				phone: '',
				state: '',
				order_type: '',
				consignee: '',
				page: 1
			};
			// this.page = 1;
			this.$router.replace({query: this.searchForm});
			this.getList();
		},
		getList() {
			let _this = this;

			// if(!this.searchForm.order_type){
			//     this.searchForm.order_type = ['industry','!=']
			// }

			this.dataList = [];
			this.$http.httpPost({
				url: '/Order/listPage',
				datas: {...this.searchForm, pageSize: this.pageSize, order_type: this.searchForm.order_type ? this.searchForm.order_type : ['industry', '!=']},
				success: (res) => {
					console.log(res);
					_this.dataList = res.list;
					_this.totalPage = res.totalPage;
					_this.totalCount = res.totalCount;
				}
			});
		},
		changeState(id, state) {
			var _this = this;
			this.formData.id = id;
			this.formData.state = state;
			this.dialogVisible = true;
			// this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
			//     confirmButtonText: '确定',
			//     cancelButtonText: '取消',
			//     type: 'warning'
			// }).then(() => {
			//     _this.$http.httpPost({
			//         url:'Order/state',
			//         datas:{id:id,state:state},
			//         success:(res)=>{
			// 	        _this.getList();
			//         }
			//     });
			// }).catch(() => {
			// });
		},
		submitForm(formName) {
			let _this = this;
			console.log(_this.formData);
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.$http.httpPost({
						url: '/Order/state',
						datas: _this.formData,
						success: (res) => {
							console.log(res);
							_this.dialogVisible = false;
							_this.getList();
							// _this.$router.replace({path: '/crowd/list'})
						}
					});
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		pageChange(page) {
			this.searchForm.page = page;
			this.$router.replace({query: this.searchForm})
			this.getList();
		},
	},
	mounted() {
		this.getList();
	}
}
</script>
<style scoped>
.el-select, .el-range-editor {
	width: 100%;
}
</style>
