export default {
  '/calculator/type': {
    isData: true,
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: false,
    name: '地区默认参数设置',
    apiUrl: '/Calculator',
    listRouter: '/calculator/type',
    editRouter: '/calculator/type/edit',
    listData: {orderBy: 'year desc,times', sortBy: 'desc', time_type: 'sxspxfl'},
    list: {
      com: '/calculator/type',
    },
    edit: {
      com: '',
    }
  },
}
