<template>
    <div>
			<span class="margin-right danger" v-if="tip">{{tip}}</span>
        <a :href="$config.assetsUrl + demoPath" target="_blank" v-if="demoPath" class="margin-right">下载示例</a>
        <el-button type="primary" @click="values = true"><slot></slot></el-button>
        <el-dialog
            class="text-center"
            title="提示"
            :visible.sync="values"
            width="30%">
            <el-upload
                accept=".xlsx,.xls"
                class="upload-demo"
                ref="upload"
                :limit="1"
                :data="data"
                :action="$config.apiUrl+urlPath+'/putin'"
                :headers="{Authorization:$user.getToken()}"
                :on-success="onPutSuccess"
                :on-change="handleChange"
                :show-file-list="true"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">导入</el-button>
            </el-upload>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="toClose">关闭</el-button>
              </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "PutInt",
    data(){
        return{
            values:false
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props: {
        value: {
            type:Boolean,
            default: false
        },
        data:{
            type:[Array,Object],
            default(){
                return {}
            }
        },
        urlPath: {
            type:String,
            default: ''
        },
        demoPath:{
            type:String,
            default: ''
        },
			tip:{
            type:String,
            default: ''
        }
    },
    created() {
        this.values = !!this.value;
    },
    watch:{
        value(n,o){
            this.values = !!n;
        },
    },
    methods:{
        handleChange(file, fileList) {
            this.putfile = file;
        },
        onPutSuccess(response, file, fileList){
          console.log(response);
          if(response.code === 0) {
            this.$message.success('导入成功');

            this.values = false;

            this.$refs.upload.clearFiles();

            this.$emit('success');
          }else{
            this.$message.error(response.msg);
            // this.$refs.upload.abort();
            this.$refs.upload.clearFiles();
          }
        },
        submitUpload() {
            this.$refs.upload.submit();
        },
        toClose(){
            this.values = false;
            this.$refs.upload.clearFiles();

            this.$emit('change',this.values);
        }
    }
}
</script>
