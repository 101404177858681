export default {
  '/hydata/chanliang/shuiguo/quarter': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: false,
    putUrl: '/HydataChanliang',
    putDemo: '/chanliang_demo.xlsx',
    name: '产量-水果',
    apiUrl: '/HydataChanliang',
    listRouter: '/hydata/chanliang/shuiguo/quarter',
    editRouter: '/hydata/chanliang/shuiguo/quarter/edit',
    listData: { type: 'shuiguo',orderBy: 'year', sortBy: 'desc',time_type:'quarter'},
    putData: {type: 'shuiguo',time_type: 'quarter'},
    list: {
      com: '',
      params: [
        {name: '季度', value: 'year', type: 'tongjiTime'},
        {name: '总量(万吨)', value: 'total'},
        {name: '同比(%)', value: 'rate'},
      ]
    },
    edit: {
      editData: {type: 'shuiguo',time_type: 'quarter'},
      cols: 3,
      chooseYearQuarter: true,
      labelWidth: '100px',
      inputWidth: '100%',
      com: '',
      // chooseYear: true,
      params: [
        {name: '季度', value: 'year', type: 'quarterPicker', rq: true, sc: true, edit:true,cols:1},
        {name: '描述', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '同比', value: 'rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%',cols:1},
        {name: '北京', value: 'beijing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '天津', value: 'tianjing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '河北', value: 'hebei', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '山东', value: 'shandong', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '江苏', value: 'jiangsu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '浙江', value: 'zhejiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '上海', value: 'shanghai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '广东', value: 'guangdong', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '福建', value: 'fujian', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '海南', value: 'hainan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '河南', value: 'henan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '安徽', value: 'anhui', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '湖北', value: 'hubei', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '湖南', value: 'hunan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '江西', value: 'jiangxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '黑龙江', value: 'heilongjiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '吉林', value: 'jilin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '辽宁', value: 'liaoning', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '新疆', value: 'xinjiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '甘肃', value: 'gansu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '宁夏', value: 'ningxia', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '陕西', value: 'shanxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '青海', value: 'qinghai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '西藏', value: 'xizang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '山西', value: 'shanxi2', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '四川', value: 'sichuan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '重庆', value: 'chongqing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '贵州', value: 'guizhou', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '云南', value: 'yunnan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '广西', value: 'guangxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '内蒙古', value: 'menggu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
      ]
    }
  },
  
  '/hydata/chanliang/shuiguo/year': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    putUrl: '/HydataChanliang',
    putDemo: '/import/产量导入模版[年度].xlsx',
    name: '产量-水果',
    apiUrl: '/HydataChanliang',
    listRouter: '/hydata/chanliang/shuiguo/year',
    editRouter: '/hydata/chanliang/shuiguo/year/edit',
    listData: { type: 'shuiguo',orderBy: 'year', sortBy: 'desc',time_type:'year'},
    putData: {type: 'shuiguo',time_type: 'year'},
    list: {
      com: '',
      params: [
        {name: '年份', value: 'year', type: 'tongjiTime'},
        {name: '总量(万吨)', value: 'total'},
        {name: '同比(%)', value: 'rate'},
      ]
    },
    edit: {
      editData: {type: 'shuiguo',time_type: 'year'},
      cols: 3,
      chooseYearQuarter: true,
      labelWidth: '100px',
      inputWidth: '100%',
      com: '',
      // chooseYear: true,
      params: [
        {name: '年度', value: 'year', type: 'yearPicker', rq: true, sc: true, edit:true,cols:1},
        {name: '描述', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '同比', value: 'rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%',cols:1},
        {name: '北京', value: 'beijing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '天津', value: 'tianjing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '河北', value: 'hebei', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '山东', value: 'shandong', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '江苏', value: 'jiangsu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '浙江', value: 'zhejiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '上海', value: 'shanghai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '广东', value: 'guangdong', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '福建', value: 'fujian', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '海南', value: 'hainan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '河南', value: 'henan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '安徽', value: 'anhui', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '湖北', value: 'hubei', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '湖南', value: 'hunan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '江西', value: 'jiangxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '黑龙江', value: 'heilongjiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '吉林', value: 'jilin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '辽宁', value: 'liaoning', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '新疆', value: 'xinjiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '甘肃', value: 'gansu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '宁夏', value: 'ningxia', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '陕西', value: 'shanxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '青海', value: 'qinghai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '西藏', value: 'xizang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '山西', value: 'shanxi2', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '四川', value: 'sichuan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '重庆', value: 'chongqing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '贵州', value: 'guizhou', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '云南', value: 'yunnan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '广西', value: 'guangxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '内蒙古', value: 'menggu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
      ]
    }
  },
  
  '/hydata/chanliang/shucai/quarter': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: false,
    putUrl: '/HydataChanliang',
    putDemo: '/chanliang_demo.xlsx',
    name: '产量-蔬菜',
    apiUrl: '/HydataChanliang',
    listRouter: '/hydata/chanliang/shucai/quarter',
    editRouter: '/hydata/chanliang/shucai/quarter/edit',
    listData: { type: 'shucai',orderBy: 'year', sortBy: 'desc',time_type:'quarter'},
    putData: {type: 'shucai',time_type: 'quarter'},
    list: {
      com: '',
      params: [
        {name: '季度', value: 'year', type: 'tongjiTime'},
        {name: '总量(万吨)', value: 'total'},
        {name: '同比(%)', value: 'rate'},
      ]
    },
    edit: {
      editData: {type: 'shucai',time_type: 'quarter'},
      cols: 3,
      chooseYearQuarter: true,
      labelWidth: '100px',
      inputWidth: '100%',
      com: '',
      // chooseYear: true,
      params: [
        {name: '季度', value: 'year', type: 'quarterPicker', rq: true, sc: true, edit:true,cols:1},
        {name: '描述', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '同比', value: 'rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%',cols:1},
        {name: '北京', value: 'beijing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '天津', value: 'tianjing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '河北', value: 'hebei', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '山东', value: 'shandong', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '江苏', value: 'jiangsu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '浙江', value: 'zhejiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '上海', value: 'shanghai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '广东', value: 'guangdong', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '福建', value: 'fujian', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '海南', value: 'hainan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '河南', value: 'henan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '安徽', value: 'anhui', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '湖北', value: 'hubei', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '湖南', value: 'hunan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '江西', value: 'jiangxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '黑龙江', value: 'heilongjiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '吉林', value: 'jilin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '辽宁', value: 'liaoning', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '新疆', value: 'xinjiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '甘肃', value: 'gansu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '宁夏', value: 'ningxia', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '陕西', value: 'shanxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '青海', value: 'qinghai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '西藏', value: 'xizang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '山西', value: 'shanxi2', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '四川', value: 'sichuan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '重庆', value: 'chongqing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '贵州', value: 'guizhou', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '云南', value: 'yunnan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '广西', value: 'guangxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '内蒙古', value: 'menggu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
      ]
    }
  },
  
  '/hydata/chanliang/shucai/year': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    putUrl: '/HydataChanliang',
    putDemo: '/import/产量导入模版[年度].xlsx',
    name: '产量-蔬菜',
    apiUrl: '/HydataChanliang',
    listRouter: '/hydata/chanliang/shucai/year',
    editRouter: '/hydata/chanliang/shucai/year/edit',
    listData: { type: 'shucai',orderBy: 'year', sortBy: 'desc',time_type:'year'},
    putData: {type: 'shucai',time_type: 'year'},
    list: {
      com: '',
      params: [
        {name: '年份', value: 'year', type: 'tongjiTime'},
        {name: '总量(万吨)', value: 'total'},
        {name: '同比(%)', value: 'rate'},
      ]
    },
    edit: {
      editData: {type: 'shucai',time_type: 'year'},
      cols: 3,
      chooseYearQuarter: true,
      labelWidth: '100px',
      inputWidth: '100%',
      com: '',
      // chooseYear: true,
      params: [
        {name: '年度', value: 'year', type: 'yearPicker', rq: true, sc: true, edit:true,cols:1},
        {name: '描述', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '同比', value: 'rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%',cols:1},
        {name: '北京', value: 'beijing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '天津', value: 'tianjing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '河北', value: 'hebei', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '山东', value: 'shandong', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '江苏', value: 'jiangsu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '浙江', value: 'zhejiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '上海', value: 'shanghai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '广东', value: 'guangdong', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '福建', value: 'fujian', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '海南', value: 'hainan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '河南', value: 'henan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '安徽', value: 'anhui', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '湖北', value: 'hubei', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '湖南', value: 'hunan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '江西', value: 'jiangxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '黑龙江', value: 'heilongjiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '吉林', value: 'jilin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '辽宁', value: 'liaoning', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '新疆', value: 'xinjiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '甘肃', value: 'gansu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '宁夏', value: 'ningxia', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '陕西', value: 'shanxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '青海', value: 'qinghai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '西藏', value: 'xizang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '山西', value: 'shanxi2', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '四川', value: 'sichuan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '重庆', value: 'chongqing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '贵州', value: 'guizhou', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '云南', value: 'yunnan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '广西', value: 'guangxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '内蒙古', value: 'menggu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
      ]
    }
  },
  
  '/hydata/chanliang/rouzhipin/quarter': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: false,
    putUrl: '/HydataChanliang',
    putDemo: '/chanliang_demo.xlsx',
    name: '产量-蔬菜',
    apiUrl: '/HydataChanliang',
    listRouter: '/hydata/chanliang/rouzhipin/quarter',
    editRouter: '/hydata/chanliang/rouzhipin/quarter/edit',
    listData: { type: 'rouzhipin',orderBy: 'year', sortBy: 'desc',time_type:'quarter'},
    putData: {type: 'rouzhipin',time_type: 'quarter'},
    list: {
      com: '',
      params: [
        {name: '季度', value: 'year', type: 'tongjiTime'},
        {name: '总量(万吨)', value: 'total'},
        {name: '同比(%)', value: 'rate'},
      ]
    },
    edit: {
      editData: {type: 'rouzhipin',time_type: 'quarter'},
      cols: 3,
      chooseYearQuarter: true,
      labelWidth: '100px',
      inputWidth: '100%',
      com: '',
      // chooseYear: true,
      params: [
        {name: '季度', value: 'year', type: 'quarterPicker', rq: true, sc: true, edit:true,cols:1},
        {name: '描述', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '同比', value: 'rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%',cols:1},
        {name: '北京', value: 'beijing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '天津', value: 'tianjing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '河北', value: 'hebei', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '山东', value: 'shandong', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '江苏', value: 'jiangsu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '浙江', value: 'zhejiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '上海', value: 'shanghai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '广东', value: 'guangdong', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '福建', value: 'fujian', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '海南', value: 'hainan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '河南', value: 'henan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '安徽', value: 'anhui', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '湖北', value: 'hubei', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '湖南', value: 'hunan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '江西', value: 'jiangxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '黑龙江', value: 'heilongjiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '吉林', value: 'jilin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '辽宁', value: 'liaoning', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '新疆', value: 'xinjiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '甘肃', value: 'gansu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '宁夏', value: 'ningxia', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '陕西', value: 'shanxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '青海', value: 'qinghai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '西藏', value: 'xizang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '山西', value: 'shanxi2', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '四川', value: 'sichuan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '重庆', value: 'chongqing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '贵州', value: 'guizhou', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '云南', value: 'yunnan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '广西', value: 'guangxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '内蒙古', value: 'menggu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
      ]
    }
  },
  
  '/hydata/chanliang/rouzhipin/year': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    putUrl: '/HydataChanliang',
    putDemo: '/import/产量导入模版[年度].xlsx',
    name: '产量-肉类',
    apiUrl: '/HydataChanliang',
    listRouter: '/hydata/chanliang/rouzhipin/year',
    editRouter: '/hydata/chanliang/rouzhipin/year/edit',
    listData: { type: 'rouzhipin',orderBy: 'year', sortBy: 'desc',time_type:'year'},
    putData: {type: 'rouzhipin',time_type: 'year'},
    list: {
      com: '',
      params: [
        {name: '年份', value: 'year', type: 'tongjiTime'},
        {name: '总量(万吨)', value: 'total'},
        {name: '同比(%)', value: 'rate'},
      ]
    },
    edit: {
      editData: {type: 'rouzhipin',time_type: 'year'},
      cols: 3,
      chooseYearQuarter: true,
      labelWidth: '100px',
      inputWidth: '100%',
      com: '',
      // chooseYear: true,
      params: [
        {name: '年度', value: 'year', type: 'yearPicker', rq: true, sc: true, edit:true,cols:1},
        {name: '描述', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '同比', value: 'rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%',cols:1},
        {name: '北京', value: 'beijing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '天津', value: 'tianjing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '河北', value: 'hebei', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '山东', value: 'shandong', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '江苏', value: 'jiangsu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '浙江', value: 'zhejiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '上海', value: 'shanghai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '广东', value: 'guangdong', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '福建', value: 'fujian', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '海南', value: 'hainan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '河南', value: 'henan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '安徽', value: 'anhui', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '湖北', value: 'hubei', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '湖南', value: 'hunan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '江西', value: 'jiangxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '黑龙江', value: 'heilongjiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '吉林', value: 'jilin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '辽宁', value: 'liaoning', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '新疆', value: 'xinjiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '甘肃', value: 'gansu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '宁夏', value: 'ningxia', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '陕西', value: 'shanxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '青海', value: 'qinghai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '西藏', value: 'xizang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '山西', value: 'shanxi2', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '四川', value: 'sichuan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '重庆', value: 'chongqing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '贵州', value: 'guizhou', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '云南', value: 'yunnan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '广西', value: 'guangxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '内蒙古', value: 'menggu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
      ]
    }
  },
  
  '/hydata/chanliang/shuichanpin/quarter': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: false,
    putUrl: '/HydataChanliang',
    putDemo: '/chanliang_demo.xlsx',
    name: '产量-水产品',
    apiUrl: '/HydataChanliang',
    listRouter: '/hydata/chanliang/shuichanpin/quarter',
    editRouter: '/hydata/chanliang/shuichanpin/quarter/edit',
    listData: { type: 'shuichanpin',orderBy: 'year', sortBy: 'desc',time_type:'quarter'},
    putData: {type: 'shuichanpin',time_type: 'quarter'},
    list: {
      com: '',
      params: [
        {name: '季度', value: 'year', type: 'tongjiTime'},
        {name: '总量(万吨)', value: 'total'},
        {name: '同比(%)', value: 'rate'},
      ]
    },
    edit: {
      editData: {type: 'shuichanpin',time_type: 'quarter'},
      cols: 3,
      chooseYearQuarter: true,
      labelWidth: '100px',
      inputWidth: '100%',
      com: '',
      // chooseYear: true,
      params: [
        {name: '季度', value: 'year', type: 'quarterPicker', rq: true, sc: true, edit:true,cols:1},
        {name: '描述', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '同比', value: 'rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%',cols:1},
        {name: '北京', value: 'beijing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '天津', value: 'tianjing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '河北', value: 'hebei', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '山东', value: 'shandong', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '江苏', value: 'jiangsu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '浙江', value: 'zhejiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '上海', value: 'shanghai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '广东', value: 'guangdong', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '福建', value: 'fujian', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '海南', value: 'hainan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '河南', value: 'henan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '安徽', value: 'anhui', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '湖北', value: 'hubei', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '湖南', value: 'hunan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '江西', value: 'jiangxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '黑龙江', value: 'heilongjiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '吉林', value: 'jilin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '辽宁', value: 'liaoning', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '新疆', value: 'xinjiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '甘肃', value: 'gansu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '宁夏', value: 'ningxia', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '陕西', value: 'shanxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '青海', value: 'qinghai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '西藏', value: 'xizang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '山西', value: 'shanxi2', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '四川', value: 'sichuan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '重庆', value: 'chongqing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '贵州', value: 'guizhou', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '云南', value: 'yunnan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '广西', value: 'guangxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '内蒙古', value: 'menggu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
      ]
    }
  },
  
  '/hydata/chanliang/shuichanpin/year': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    putUrl: '/HydataChanliang',
    putDemo: '/import/产量导入模版[年度].xlsx',
    name: '产量-水产品',
    apiUrl: '/HydataChanliang',
    listRouter: '/hydata/chanliang/shuichanpin/year',
    editRouter: '/hydata/chanliang/shuichanpin/year/edit',
    listData: { type: 'shuichanpin',orderBy: 'year', sortBy: 'desc',time_type:'year'},
    putData: {type: 'shuichanpin',time_type: 'year'},
    list: {
      com: '',
      params: [
        {name: '年份', value: 'year', type: 'tongjiTime'},
        {name: '总量(万吨)', value: 'total'},
        {name: '同比(%)', value: 'rate'},
      ]
    },
    edit: {
      editData: {type: 'shuichanpin',time_type: 'year'},
      cols: 3,
      chooseYearQuarter: true,
      labelWidth: '100px',
      inputWidth: '100%',
      com: '',
      // chooseYear: true,
      params: [
        {name: '年度', value: 'year', type: 'yearPicker', rq: true, sc: true, edit:true,cols:1},
        {name: '描述', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '同比', value: 'rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%',cols:1},
        {name: '北京', value: 'beijing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '天津', value: 'tianjing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '河北', value: 'hebei', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '山东', value: 'shandong', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '江苏', value: 'jiangsu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '浙江', value: 'zhejiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '上海', value: 'shanghai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '广东', value: 'guangdong', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '福建', value: 'fujian', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '海南', value: 'hainan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '河南', value: 'henan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '安徽', value: 'anhui', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '湖北', value: 'hubei', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '湖南', value: 'hunan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '江西', value: 'jiangxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '黑龙江', value: 'heilongjiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '吉林', value: 'jilin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '辽宁', value: 'liaoning', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '新疆', value: 'xinjiang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '甘肃', value: 'gansu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '宁夏', value: 'ningxia', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '陕西', value: 'shanxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '青海', value: 'qinghai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '西藏', value: 'xizang', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '山西', value: 'shanxi2', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '四川', value: 'sichuan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '重庆', value: 'chongqing', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '贵州', value: 'guizhou', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '云南', value: 'yunnan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '广西', value: 'guangxi', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '内蒙古', value: 'menggu', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
      ]
    }
  },
  
  '/hydata/chanliang/ruzhipin/quarter': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: false,
    putUrl: '/HydataChanliang',
    putDemo: '/chanliang_demo.xlsx',
    name: '产量-乳制品',
    apiUrl: '/HydataChanliang',
    listRouter: '/hydata/chanliang/ruzhipin/quarter',
    editRouter: '/hydata/chanliang/ruzhipin/quarter/edit',
    listData: { type: 'ruzhipin',orderBy: 'year', sortBy: 'desc',time_type:'quarter'},
    putData: {type: 'ruzhipin',time_type: 'quarter'},
    list: {
      com: '',
      params: [
        {name: '季度', value: 'year', type: 'tongjiTime'},
        {name: '总量(万吨)', value: 'total'},
        {name: '同比(%)', value: 'rate'},
      ]
    },
    edit: {
      editData: {type: 'ruzhipin',time_type: 'quarter'},
      cols: 2,
      chooseYearQuarter: true,
      labelWidth: '100px',
      inputWidth: '100%',
      com: '',
      // chooseYear: true,
      params: [
        {name: '季度', value: 'year', type: 'quarterPicker', rq: true, sc: true, edit:true,cols:1},
        {name: '描述', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '总量', value: 'total', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
      ]
    }
  },
  
  '/hydata/chanliang/ruzhipin/year': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    putUrl: '/HydataChanliang',
    putDemo: '/import/产量乳制品导入模版[年度].xlsx',
    name: '产量-乳制品',
    apiUrl: '/HydataChanliang',
    listRouter: '/hydata/chanliang/ruzhipin/year',
    editRouter: '/hydata/chanliang/ruzhipin/year/edit',
    listData: { type: 'ruzhipin',orderBy: 'year', sortBy: 'desc',time_type:'year'},
    putData: {type: 'ruzhipin',time_type: 'year'},
    list: {
      com: '',
      params: [
        {name: '年份', value: 'year', type: 'tongjiTime'},
        {name: '总量(万吨)', value: 'total'},
        {name: '同比(%)', value: 'rate'},
      ]
    },
    edit: {
      editData: {type: 'ruzhipin',time_type: 'year'},
      cols: 2,
      chooseYearQuarter: true,
      labelWidth: '100px',
      inputWidth: '100%',
      com: '',
      // chooseYear: true,
      params: [
        {name: '年度', value: 'year', type: 'yearPicker', rq: true, sc: true, edit:true,cols:1},
        {name: '描述', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '总量', value: 'total', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
      ]
    }
  },
  
  '/hydata/chanliang/sudong/quarter': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: false,
    putUrl: '/HydataChanliang',
    putDemo: '/chanliang_demo.xlsx',
    name: '产量-速冻品',
    apiUrl: '/HydataChanliang',
    listRouter: '/hydata/chanliang/sudong/quarter',
    editRouter: '/hydata/chanliang/sudong/quarter/edit',
    listData: { type: 'sudongshipin',orderBy: 'year', sortBy: 'desc',time_type:'quarter'},
    putData: {type: 'sudongshipin',time_type: 'quarter'},
    list: {
      com: '',
      params: [
        {name: '季度', value: 'year', type: 'tongjiTime'},
        {name: '总量(万吨)', value: 'total'},
        {name: '同比(%)', value: 'rate'},
      ]
    },
    edit: {
      editData: {type: 'sudongshipin',time_type: 'quarter'},
      cols: 2,
      chooseYearQuarter: true,
      labelWidth: '100px',
      inputWidth: '100%',
      com: '',
      // chooseYear: true,
      params: [
        {name: '季度', value: 'year', type: 'quarterPicker', rq: true, sc: true, edit:true,cols:1},
        {name: '描述', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '总量', value: 'total', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
      ]
    }
  },
  
  '/hydata/chanliang/sudong/year': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    putUrl: '/HydataChanliang',
    putDemo: '/import/产量速冻食品导入模版[年度].xlsx',
    name: '产量-速冻品',
    apiUrl: '/HydataChanliang',
    listRouter: '/hydata/chanliang/sudong/year',
    editRouter: '/hydata/chanliang/sudong/year/edit',
    listData: { type: 'sudongshipin',orderBy: 'year', sortBy: 'desc',time_type:'year'},
    putData: {type: 'sudongshipin',time_type: 'year'},
    list: {
      com: '',
      params: [
        {name: '年份', value: 'year', type: 'tongjiTime'},
        {name: '总量(万吨)', value: 'total'},
        {name: '同比(%)', value: 'rate'},
      ]
    },
    edit: {
      editData: {type: 'sudongshipin',time_type: 'year'},
      cols: 2,
      chooseYearQuarter: true,
      labelWidth: '100px',
      inputWidth: '100%',
      com: '',
      // chooseYear: true,
      params: [
        {name: '年度', value: 'year', type: 'yearPicker', rq: true, sc: true, edit:true,cols:1},
        {name: '描述', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '总量', value: 'total', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
      ]
    }
  },
  
  '/hydata/chanliang/cost/year': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    putUrl: '/HydataChanliangCost',
    putDemo: '/import/人均农食消费导入模版[年度].xlsx',
    name: '产量-人均消费',
    apiUrl: '/HydataChanliangCost',
    listRouter: '/hydata/chanliang/cost/year',
    editRouter: '/hydata/chanliang/cost/year/edit',
    listData: { orderBy: 'year', sortBy: 'desc',time_type:'year'},
    putData: {time_type: 'year'},
    list: {
      com: '',
      params: [
        {name: '年份', value: 'year', type: 'tongjiTime'},
        // {name: '总量(万吨)', value: 'total'},
        {name: '人均蔬菜及食用菌消费量', value: 'shucai',width:'200px'},
        {name: '发达国家蔬菜人均消费量', value: 'fada_shucai',width:'200px'},
        {name: '人均肉类消费量', value: 'rou',width:'150px'},
        {name: '发达国家肉类人均消费量', value: 'fada_rou',width:'200px'},
        {name: '人均禽类消费量', value: 'qin',width:'150px'},
        {name: '发达国家禽类人均消费量', value: 'fada_qin',width:'200px'},
        {name: '人均水产品消费量', value: 'shuichanpin',width:'150px'},
        {name: '发达国家水产品人均消费量', value: 'fada_shuichanpin',width:'200px'},
        {name: '人均蛋类消费量', value: 'dan',width:'150px'},
        {name: '发达国家蛋类人均消费量', value: 'fada_dan',width:'200px'},
        {name: '人均奶类消费量', value: 'nai',width:'150px'},
        {name: '发达国家奶类人均消费量', value: 'fada_nai',width:'200px'},
        {name: '人均干鲜瓜果类消费量', value: 'shuiguo',width:'200px'},
        {name: '发达国家瓜果类人均消费量', value: 'fada_shuiguo',width:'200px'},
        {name: '同比(%)', value: 'rate'},
      ]
    },
    edit: {
      editData: {time_type: 'year'},
      cols: 2,
      chooseYearQuarter: true,
      labelWidth: '200px',
      inputWidth: '100%',
      com: '',
      // chooseYear: true,
      params: [
        {name: '年度', value: 'year', type: 'yearPicker', rq: true, sc: true, edit:true,cols:1},
        {name: '描述', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '同比', value: 'rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%',cols:1},
        {name: '人均蔬菜及食用菌消费量', value: 'shucai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '千克'},
        {name: '发达国家蔬菜人均消费量', value: 'fada_shucai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '千克'},
        {name: '人均肉类消费量', value: 'rou', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '千克'},
        {name: '发达国家肉类人均消费量', value: 'fada_rou', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '千克'},
        {name: '人均禽类消费量', value: 'qin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '千克'},
        {name: '发达国家禽类人均消费量', value: 'fada_qin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '千克'},
        {name: '人均水产品消费量', value: 'shuichanpin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '千克'},
        {name: '发达国家水产品人均消费量', value: 'fada_shuichanpin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '千克'},
        {name: '人均蛋类消费量', value: 'dan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '千克'},
        {name: '发达国家蛋类人均消费量', value: 'fada_dan', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '千克'},
        {name: '人均奶类消费量', value: 'nai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '千克'},
        {name: '发达国家奶类人均消费量', value: 'fada_nai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '千克'},
        {name: '人均干鲜瓜果类消费量', value: 'shuiguo', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '千克'},
        {name: '发达国家瓜果类人均消费量', value: 'fada_shuiguo', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '千克'},
      ]
    }
  },
}
