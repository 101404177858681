<template>
  <div class="bg-light padding-20">
    <el-form :model="info" ref="form" label-width="150px">
      <el-form-item label="ID" v-if="info.id">
        {{info.id}}
      </el-form-item>
      <el-form-item label="用户名">{{info.username}}</el-form-item>
      <el-form-item label="手机号">{{info.mobile}}</el-form-item>
      <el-form-item label="邮箱">{{info.user.email ? info.user.email : '-'}}</el-form-item>
      <el-form-item label="姓名">{{info.user.name ? info.user.name : '-'}}</el-form-item>
      <el-form-item label="公司">{{info.user.company ? info.user.company : '-'}}</el-form-item>
      <el-form-item label="职位">{{info.user.job ? info.user.job : '-'}}</el-form-item>
      <el-form-item :label="item.title" prop="vip_end_time" v-for="(item,index) in info.vip_list">
        {{info.vip_list[index].vip_end_time ? info.vip_list[index].vip_end_time : '-'}}
      </el-form-item>
      <el-form-item label="是否禁用">
        <el-tag type="success" v-if="info.state === 1">正常</el-tag>
        <el-tag type="danger" v-if="info.state === 0">禁用</el-tag>
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.back()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "detail",
  data() {
    return {
      info:null,
      id:'',
    }
  },
  created() {
    this.id = this.$route.query.id ? this.$route.query.id : '';
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      let _this = this;
      this.$http.httpPost({
        url: '/User/detail',
        datas: { id: this.id },
        success(res) {
          _this.info = res ? res : null;

          if(_this.info) {
            _this.info.password = '';
          }
        }
      });
    },
  }
}
</script>
