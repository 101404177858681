<template>
	<div class="bg-light padding-20">
		<base-button slot="right-btn" @click.native="$router.push({path:'/banner'})" rule="/banner">
			返回轮播位
		</base-button>
		<add-button slot="right-btn" label="添加轮播图片" v-if="dataList.length > 0" @click.native="$router.push({path:'/banner/res/add',query:{id:id}})" rule="/banner/res/add"></add-button>
		<div class="margin-top">
			<div class="padding bg-gray-3">轮播位：<b>{{ cateDetail.name }}</b>，标识：<b>{{ cateDetail.avalue }}</b></div>
			<el-table :data="dataList" default-expand-all style="width: 100%"
								:row-key="(row)=>{return row.is_spec && row.is_spec === 1 ? 'cell_'+row.id : 'sub_'+row.id}"
								:tree-props="{children: 'child'}" :indent="30" v-if="dataList.length > 0">
				<el-table-column
					prop="title"
					label="标题">
				</el-table-column>
				<el-table-column
					prop="res_url"
					label="图片">
					<template slot-scope="scope">
						<el-image
							style="height: 60px"
							:src="scope.row.res_url.indexOf('http')>= 0 ? scope.row.res_url : $config.assetsUrl + scope.row.res_url"
							:preview-src-list="scope.row.res_url.indexOf('http')>= 0 ? [scope.row.res_url] : [$config.assetsUrl + scope.row.res_url]">
						</el-image>
					</template>
				</el-table-column>
<!--				<el-table-column-->
<!--					prop="out_url"-->
<!--					label="链接">-->
<!--					<template slot-scope="scope">-->
<!--						<a :href="scope.row.out_url" target="_blank" v-if="scope.row.out_url">{{ scope.row.out_url }}</a>-->
<!--						<span v-else>-</span>-->
<!--					</template>-->
<!--				</el-table-column>-->
				<el-table-column
					prop="sort"
					label="排序">
				</el-table-column>
				<el-table-column
					prop="state"
					label="状态" style="font-size:12px;">
					<template slot-scope="scope">
						<el-tag :type="scope.row.state === 1 ? 'success' : 'danger'" disable-transitions>
							{{ scope.row.state === 0 ? '禁用' : '可用' }}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column
					prop="create_time"
					label="创建时间"></el-table-column>
				<el-table-column align="right">
					<template slot-scope="scope">
						<edit-button @click.native="$router.push({path:'/banner/res/edit',query:{id:scope.row.id}})" rule="/banner/res/edit"></edit-button>
						<del-button @click.native="del(scope.row.id)" rule="/banner/res/del"></del-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="text-center null-data f14 info" v-else>
				还没有数据，
				<add-button label="立即添加图片" @click.native="$router.push({path:'/banner/res/add',query:{id:id}})" rule="/banner/res/add"></add-button>
			</div>
			<div class="text-center padding-top-bottom-20" v-if="dataList.length > 0">
				<el-pagination
					background
					layout="total,prev, pager, next"
					:page-size="pageSize"
					:current-page.sync="page"
					:total="totalCount"
					@current-change="pageChange">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "GoodsSpecList",
	data() {
		return {
			dataList: [],
			totalPage: 0,
			totalCount: 0,
			pageSize: 20,
			page: 1,
			id: '',
			cateDetail: {}
		}
	},
	created() {
		this.id = this.$route.query.id ? this.$route.query.id : 0;
	},
	methods: {
		getList() {
			let _this = this;
			this.dataList = [];
			this.$http.httpPost({
				url: '/banner/listPage',
				datas: {page: this.page, pageSize: this.pageSize, type_banner_id: this.id, orderBy: 'sort', sortBy: 'asc'},
				success: (res) => {
					_this.dataList = res.list;
					_this.totalPage = res.totalPage;
					_this.totalCount = res.totalCount;
				}
			});
		},
		del(id) {
			let _this = this;
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.httpPost({
					url: '/Banner/del',
					datas: {id: id},
					success: (res) => {
						_this.getList();
					}
				});
			}).catch(() => {
			});
		},
		pageChange(page) {
			this.page = page;
			this.getList();
		},
		getCateDetal() {
			let _this = this;
			_this.$http.httpPost({
				url: '/Type/detail',
				datas: {id: this.id},
				success: (res) => {
					_this.cateDetail = res;
				}
			})
		}
	},
	mounted() {
		if (this.id) {
			this.getList();
			this.getCateDetal();
		}
	}
}
</script>
