<template>
	<div>
		<el-form :model="form" ref="form" label-width="auto">
			<el-form-item label="描述" prop="content1">
				<div class="rel">
					<clean-editor v-model="form.content1"></clean-editor>
				</div>
			</el-form-item>
			<el-card header="全国开放口岸">
				<el-form-item label-width="0px" prop="table_content">
					<editor v-model="form.table_content"></editor>
				</el-form-item>
			</el-card>
			<el-card header="各品类指定监管场地" class="margin-top">
				<el-form-item label="肉类" prop="rou_place">
					<one-file-upload v-model="form.rou_place" accept=".doc,.docx,.ppt,.pptx,.pdf,.xls,.xlsx"></one-file-upload>
<!--					<file-upload v-model="form.rou_place" :files="[form.files]"></file-upload>-->
<!--					<el-input v-model="form.rou_place" placeholder="请输入链接地址"></el-input>-->
				</el-form-item>
				<el-form-item label="水果" prop="shuiguo_place">
					<one-file-upload v-model="form.shuiguo_place" accept=".doc,.docx,.ppt,.pptx,.pdf,.xls,.xlsx"></one-file-upload>
<!--					<el-input v-model="form.shuiguo_place" placeholder="请输入链接地址"></el-input>-->
				</el-form-item>
				<el-form-item label="冰鲜水产品" prop="bxs_place">
					<one-file-upload v-model="form.bxs_place" accept=".doc,.docx,.ppt,.pptx,.pdf,.xls,.xlsx"></one-file-upload>
<!--					<el-input v-model="form.bxs_place" placeholder="请输入链接地址"></el-input>-->
				</el-form-item>
			</el-card>
			<el-card header="符合评估审查要求的国家或地区输华肉类产品名单" class="margin-top">
				<el-input v-model="form.in_list" placeholder="请输入链接地址"></el-input>
			</el-card>
			<div class="margin-top">
				<el-button @click="$router.back()">返回</el-button>
				<el-button type="primary" @click="toSubmit">保存</el-button>
			</div>
		</el-form>
	</div>
</template>

<script>
export default {
	name: "edit",
	data() {
		return {
			form:{
				id:'',
				content1:'',
				table_content:'',
				rou_place:'',
				shuiguo_place:'',
				bxs_place:'',
				in_list:''
			}
		}
	},
	created() {
	},
	mounted() {
		this.getDetail();
	},
	methods: {
		getDetail(){
			let _this = this;
			_this.$http.httpPost({
				url:'/HydataInoutKouan/listAll',
				datas: {limit:1,orderBy:'create_time',sortBy:'desc'},
				success: (res) => {
					if(res && res[0]){
						let _data = res[0];
						this.form.id = _data.id;
						this.form.content1 = _data.content1;
						this.form.table_content = _data.table_content;
						this.form.in_list = _data.in_list;
						this.form.bxs_place = _data.bxs_place;
						this.form.shuiguo_place = _data.shuiguo_place;
						this.form.rou_place = _data.rou_place;
					}
				}
			})
		},
		toSubmit(){
			let _this = this;
			this.$refs.form.validate((valid) => {
				if (valid) {
					let _data = JSON.parse(JSON.stringify(_this.form));
					_this.$http.httpPost({
						url: _data.id ? '/HydataInoutKouan/edit' : '/HydataInoutKouan/add',
						datas: _data,
						success: (res) => {
							_this.$message.success('保存成功');
							_this.getDetail()
						}
					})
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		}
	}
}
</script>
