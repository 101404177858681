<template>
  <div class="bg-light padding-20">
        <div class="">
            <el-form :model="searchForm" ref="searchForm" inline label-width="100px">
	            <el-form-item label="订单号：">
		            <el-input v-model="searchForm.order_no"></el-input>
	            </el-form-item>
	            <el-form-item label="交易单号：">
		            <el-input v-model="searchForm.out_trade_no"></el-input>
	            </el-form-item>
	            <el-form-item label="收件人">
		            <el-input v-model="searchForm.consignee"></el-input>
	            </el-form-item>
	            <el-form-item label="收件人电话">
		            <el-input v-model="searchForm.phone"></el-input>
	            </el-form-item>
	            <el-form-item label="订单状态">
		            <el-select v-model="searchForm.state">
			            <el-option label="-全部-" value=""></el-option>
			            <el-option label="已关闭" value="0"></el-option>
			            <el-option label="待付款" value="1"></el-option>
			            <el-option label="已付款待发货" value="2"></el-option>
			            <el-option label="已发货" value="3"></el-option>
		            </el-select>
	            </el-form-item>
                <el-button type="primary" @click="toSearch">查询</el-button>
                <el-button @click="clearSearch">清空</el-button>
            </el-form>
            <el-table :data="dataList" default-expand-all style="width: 100%" row-key="id"
                      :tree-props="{children: 'child'}" :indent="30" v-if="dataList.length > 0">
                <el-table-column
                    prop="order_no"
                    label="订单号" width="200">
                    <template slot-scope="scope">
                        {{ scope.row.order_no }}
<!--                        {{ scope.row.out_trade_no }}-->
                    </template>
                </el-table-column>
                <el-table-column
                    prop="order_type"
                    label="订单类别" width="150">
                    <template slot-scope="scope">
                        <b v-if="scope.row.order_type === 'industry'">行业发展报告</b>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="consignee"
                    label="收货信息" width="300">
                    <template slot-scope="scope">
                        <div>收件人：{{scope.row.consignee}}</div>
                        <div>收件人电话：{{scope.row.phone}}</div>
                        <div>收件地址：{{scope.row.province_name}}{{scope.row.city_name}}{{scope.row.area_name}}{{scope.row.address}}</div>
                    </template>
                </el-table-column>
	            <el-table-column
		            prop="consignee"
		            label="买家信息" width="200">
		            <template slot-scope="scope">
			            <div>姓名：{{scope.row.user.name}}</div>
			            <div>手机号码：{{scope.row.user_detail.mobile}}</div>
			            <div>企业信息：{{scope.row.user.company}}</div>
			            <div>职位：{{scope.row.user.job}}</div>
		            </template>
	            </el-table-column>
                <el-table-column
                    label="物流信息" width="200">
                    <template slot-scope="scope">
                        <div>物流公司：{{scope.row.shipping_name}}</div>
                        <div>物流单号：{{scope.row.shipping_no}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="order_amount"
                    label="订单金额" width="100">
                </el-table-column>
                <el-table-column
                    prop="pay_amount"
                    label="实际支付金额" width="120">
                </el-table-column>
                <el-table-column
                    prop="state"
                    label="状态" style="font-size:12px;" width="100">
                    <template slot-scope="scope">
                        <el-tag type="info" disable-transitions v-if="scope.row.state === 0">已关闭</el-tag>
                        <el-tag type="info" disable-transitions v-if="scope.row.state === 1">未付款</el-tag>
                        <el-tag type="warning" disable-transitions v-if="scope.row.state === 2">已付款-待发货</el-tag>
                        <el-tag type="success" disable-transitions v-if="scope.row.state === 3">已发货</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="create_time"
                    label="下单时间" width="180"></el-table-column>
                <el-table-column align="right" width="200" fixed="right">
                    <template slot-scope="scope">
                        <base-button @click.native="changeState(scope.row.id,3)" v-if="scope.row.state === 2" type="warning">发货</base-button>
                        <base-button @click.native="changeState(scope.row.id,2,scope.row)" v-if="scope.row.state === 3" type="danger">修改发货状态</base-button>
                        <base-button @click.native="$router.push({path:'/order/detail/'+scope.row.id})">详情</base-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="text-center null-data f14 info" v-else>
                还没有数据
            </div>
            <div class="text-center padding-top-bottom-20" v-if="dataList.length > 0">
                <el-pagination
                    background
                    layout="total,prev, pager, next"
                    :page-size="pageSize"
                    :current-page.sync="searchForm.page"
                    :total="totalCount"
                    @current-change="pageChange">
                </el-pagination>
            </div>
        </div>
        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" @closed="closed">
            <el-form :model="formData" status-icon :rules="rules" ref="form" label-width="auto" class="margin-top">
                <el-form-item label="发货状态" prop="state">
                    <type-select :options="[{name:'待发货',id:2},{name:'已发货',id:3}]" v-model="formData.state"></type-select>
                </el-form-item>
                <el-form-item label="物流公司" prop="shipping_name" :rules="[{required: formData.state === 3,message:'缺少物流公司名称'}]">
                    <el-input v-model.trim="formData.shipping_name" maxlength="100" show-word-limit
                              style="width:100%;"></el-input>
                </el-form-item>
                <el-form-item label="物流单号" prop="shipping_no" :rules="[{required: formData.state === 3,message:'缺少物流单号'}]">
                    <el-input v-model.trim="formData.shipping_no" maxlength="100" show-word-limit
                              style="width:100%;"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitForm('form')">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "MemberList",
    data() {
        return {
            searchForm: {
                order_no:'',
                out_trade_no:'',
                phone:'',
                state:'',
                order_type:'',
                consignee:'',
                page:1
            },
            dialogVisible: false,
            dataList: [],
            totalPage: 0,
            totalCount: 0,
            pageSize: 20,
            page: 1,
            formData: {
                id: '',
                state: 0,
                shipping_name: '',
                shipping_no: ''
            },
            rules: {
                id: [
                    {required: true,message:'缺少订单id'},
                ],
                state: [
                    {required: true,message:'缺少订单状态'},
                ],
                shipping_name: [
                    {required: true,message:'缺少物流公司名称'},
                ],
                shipping_no: [
                    {required: true,message:'缺少物流单号'},
                ],
            }
        }
    },
    created() {
        let _query = this.$route.query;
        this.searchForm = {...this.searchForm,..._query};
        this.searchForm.page = _query.page ? parseInt(_query.page) : 1;
    },
    methods: {
        toSearch() {
            this.searchForm.page = 1;
            this.$router.replace({query:this.searchForm});
            this.getList();
        },
        clearSearch() {
            this.searchForm = {
                order_no:'',
                out_trade_no:'',
                phone:'',
                state:'',
                order_type:'',
                consignee:'',
                page:1
            };
            // this.page = 1;
            this.$router.replace({query:this.searchForm});
            this.getList();
        },
        getList() {
            let _this = this;
            this.dataList = [];
            this.$http.httpPost({
                url: '/Order/listPage',
                datas: {...this.searchForm,pageSize: this.pageSize,order_type:'industry'},
                success: (res) => {
                    console.log(res);
                    _this.dataList = res.list;
                    _this.totalPage = res.totalPage;
                    _this.totalCount = res.totalCount;
                }
            });
        },
        changeState(id, state,item = null) {

            console.log(state);
            var _this = this;
            this.formData.id = id;
            this.formData.state = state;
            this.dialogVisible = true;

            if(item){
                this.formData.shipping_name = item.shipping_name;
                this.formData.shipping_no = item.shipping_no;
            }
        },
        submitForm(formName) {
            let _this = this;
            console.log(_this.formData);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$http.httpPost({
                        url: '/Order/state',
                        datas: _this.formData,
                        success: (res) => {
                            console.log(res);
                            _this.dialogVisible = false;
                            _this.getList();
                            // _this.$router.replace({path: '/crowd/list'})
                        }
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        pageChange(page) {
            this.searchForm.page = page;
            this.$router.replace({query:this.searchForm})
            this.getList();
        },
        closed(){
            this.formData.id = '';
            this.formData.shipping_name = '';
            this.formData.shipping_no = '';
        }
    },
    mounted() {
        this.getList();
    }
}
</script>
<style scoped>
.el-select,.el-range-editor{width:100%;}
</style>
