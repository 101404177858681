<template>
	<!--  <vue-neditor-wrap :key="skey" v-model="values" :config="config" @input="input"></vue-neditor-wrap>-->
	<vue-ueditor-wrap :key="skey" v-model="values" :config="config" @input="input"></vue-ueditor-wrap>
</template>

<script>
export default {
	name: "UEditor",
	data() {
		return {
			values: '',
			isInit: false,
			config: {
				zIndex: 3000,
				// theme:'notadd',
				// 编辑器不自动被内容撑高
				autoHeightEnabled: false,
				autoFloatEnabled: false,
				// 初始容器高度
				initialFrameHeight: 150,
				// 初始容器宽度
				initialFrameWidth: '100%',
				// 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
				serverUrl: this.$config.editorUrl + '/UEditor/php/controller.php',
				// UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
				UEDITOR_HOME_URL: '/ueditor/',
				toolbars: [[
					'|', 'undo', 'redo', '|',
					'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'blockquote', 'pasteplain', '|', 'forecolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
					'lineheight', '|',
					'fontsize', '|',
					'indent', '|',
					'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase', 'tolowercase', '|',
					'link', 'unlink', '|'
				]],
			}
		}
	},
	model: {
		prop: 'value',
		event: 'input'
	},
	props: {
		skey: String,
		value: String,
		height: {
			type: Number,
			default: 150
		}
	},
	methods: {
		input(e) {
			this.$emit('input', e);
		}
	},
	created() {
		this.config.initialFrameHeight = this.height;
	},
	watch: {
		value(n, o) {
			console.log(n);
			console.log(this.isInit);
			let _this = this;
			// if(!this.isInit) {
			this.values = n;
			// this.isInit = true;
			// }
		}
	},
}
</script>
<style>
.no-line-height .el-form-item__content {
	line-height: unset !important;
}
</style>
