export default {
  '/policy/policy': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showDetail: false,
    showSearch: true,
    showOutput: true,
    outPutTitle: '导出数据(可根据搜索条件导出)',
    outPutName: '政策数据',
    outPutPath: '/Policy/output',
    outPutData: {type: 'policy'},
    showListDel: false,
    routerData: null,
    listData: {draft:'0',type: 'policy'},
    name: '政策',
    apiUrl: '/Policy',
    listRouter: '/policy/policy',
    editRouter: '/policy/policy/edit',
    addRouter: '',
    delRouter: '',
    detailRouter: '/policy/detail',
    
    list: {
      com: '',
      params: [
        {name: '标题', value: 'title', type: 'html',width:300},
        {name: '封面图', value: 'head_img', type:'image'},
        {name: '类别', value: 'policy.name', root: true},
        // {name: '数据分类', value: 'mm_type',type:'mm_type'},
        {name: '部门', value: 'department'},
        // {name: '是否开启支付', value: 'is_pay',type:'bool'},
        {name: '价格', value: 'price'},
        {name: '权限', value: 'vip_txt'},
        {name: '发布时间', value: 'push_time'},
        // {name: '创建时间', value: 'create_time'},
      ]
    },
    edit: {
      com: '',
      params: [
        {name: '标题', value: 'title', rq: true, edit: true, max: 250},
        {name: '类别', value: 'type_policy_id', type: 'select', data: {akey: 'policy', state: 1}, rq: true, edit: true},
        // {name:'数据分类',value:'mm_type',type:'select',options:[{name:'冷链',id:'chain'},{name:'食材供应链',id:'agriculture'},{name:'冷链/食材供应链',id:'all'}],rq:true,edit:true},
        // {name:'部门',value: 'type_department_id',type:'select',data:{akey:'department',state:1},rq:false,edit:true},
        {name: '部门', value: 'department', rq: false, edit: true},
        {name: '文号', value: 'doc_number', rq: false, edit: true, max: 20},
        {name: '原文地址', value: 'yuanwen_url', rq: false, edit: true, max: 250},
        {name: '解读地址', value: 'jiedu_url', rq: false, edit: true, max: 250},
        {name: '封面图', value: 'head_img', rq: false, edit: true},
        {name: '发布时间', value: 'push_time', rq: true, sc: false, edit: true},
        {name: '附件', value: 'files_ids', type: 'file', rq: false, edit: true},
        {name: '内容', value: 'html_content', type: 'editor', rq: true, edit: true},
        // {name:'权限',value: 'permission',rq:false,edit:true,type:'vip'},
        // {name:'是否开启支付',value:'is_pay',type:'state',rq:true,edit:true},
        {name: '开启收藏', value: 'open_favorite', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '开启点赞', value: 'open_like', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '开启评论', value: 'open_comment', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '访问权限', value: 'user_vip_type', type: 'vipPrice', rq: true, edit: true},
      ],
      editData: {type: 'policy'}
    },
    search: {
      params: [
        {name: '标题', value: 'title'},
        {name: '类别', value: 'type_policy_id', type: 'select', data: {akey: 'policy', state: 1}},
        // {name: '数据分类', value: 'mm_type',type:'select',options:[{name:'冷链',id:'chain'},{name:'食材供应链',id:'agriculture'},{name:'冷链/食材供应链',id:'all'}]},
        // {name: '部门', value: 'type_department_id', type: 'select', data: {akey: 'department', state: 1}},
        {name: '部门', value: 'department'},
        // {name: '是否开启支付', value: 'is_pay',type:'select',options:[{name:'是',id:'1'},{name:'否',id:'0'}]},
        {name: '发布时间', value: 'push_time', type: 'timeRange'},
        {name: '创建时间', value: 'create_time', type: 'timeRange'}
      ]
    }
  },
  
  '/policy/policy/draft': {
    showAdd: false,
    showDel: true,
    showEdit: true,
    showDetail: false,
    showSearch: true,
    showOutput: false,
    showCheck: true,
    checkStateKey:'draft',
    okStateVal:0,
    errorStateVal:1,
    stateOkText:'发布',
    stateOkTip:'是否发布该资讯？',
    outPutTitle: '导出数据(可根据搜索条件导出)',
    outPutName: '政策数据',
    outPutPath: '/Policy/output',
    outPutData: {type: 'policy'},
    showListDel: false,
    routerData: null,
    listData: {draft:'1',type: 'policy'},
    name: '政策',
    apiUrl: '/Policy',
    listRouter: '/policy/policy/draft',
    editRouter: '/policy/policy/draft/edit',
    addRouter: '',
    delRouter: '',
    detailRouter: '/policy/draft/detail',
    
    list: {
      com: '',
      params: [
        {name: '标题', value: 'title', type: 'html',width:300},
        {name: '封面图', value: 'head_img', type:'image',width: 100},
        {name: '类别', value: 'policy.name', root: true,width: 100},
        // {name: '数据分类', value: 'mm_type',type:'mm_type'},
        {name: '部门', value: 'department',width: 200},
        // {name: '是否开启支付', value: 'is_pay',type:'bool'},
        {name: '抓取网站', value: 'site',width: 100},
        // {name: '抓取网址', value: 'collect',type:'link',width: 400},
        {name: '创建时间', value: 'create_time',width: 160},
        {name: '抓取时间', value: 'collect_time',width: 160},
      ]
    },
    edit: {
      com: '',
      params: [
        {name: '标题', value: 'title', rq: true, edit: true, max: 250},
        {name: '类别', value: 'type_policy_id', type: 'select', data: {akey: 'policy', state: 1}, rq: true, edit: true},
        // {name:'数据分类',value:'mm_type',type:'select',options:[{name:'冷链',id:'chain'},{name:'食材供应链',id:'agriculture'},{name:'冷链/食材供应链',id:'all'}],rq:true,edit:true},
        // {name:'部门',value: 'type_department_id',type:'select',data:{akey:'department',state:1},rq:false,edit:true},
        {name: '部门', value: 'department', rq: false, edit: true},
        {name: '文号', value: 'doc_number', rq: false, edit: true, max: 20},
        {name: '原文地址', value: 'yuanwen_url', rq: false, edit: true, max: 250},
        {name: '解读地址', value: 'jiedu_url', rq: false, edit: true, max: 250},
        {name: '封面图', value: 'head_img', rq: false, edit: true},
        {name: '发布时间', value: 'push_time', rq: true, sc: false, edit: true},
        {name: '附件', value: 'files_ids', type: 'file', rq: false, edit: true},
        {name: '内容', value: 'html_content', type: 'editor', rq: true, edit: true},
        // {name:'权限',value: 'permission',rq:false,edit:true,type:'vip'},
        // {name:'是否开启支付',value:'is_pay',type:'state',rq:true,edit:true},
        {name: '开启收藏', value: 'open_favorite', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '开启点赞', value: 'open_like', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '开启评论', value: 'open_comment', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '访问权限', value: 'user_vip_type', type: 'vipPrice', rq: true, edit: true},
      ],
      editData: {type: 'policy'}
    },
    search: {
      params: [
        {name: '标题', value: 'title'},
        {name: '类别', value: 'type_policy_id', type: 'select', data: {akey: 'policy', state: 1}},
        // {name: '数据分类', value: 'mm_type',type:'select',options:[{name:'冷链',id:'chain'},{name:'食材供应链',id:'agriculture'},{name:'冷链/食材供应链',id:'all'}]},
        // {name: '部门', value: 'type_department_id', type: 'select', data: {akey: 'department', state: 1}},
        {name: '部门', value: 'department'},
        // {name: '是否开启支付', value: 'is_pay',type:'select',options:[{name:'是',id:'1'},{name:'否',id:'0'}]},
        {name: '发布时间', value: 'push_time', type: 'timeRange'},
        {name: '创建时间', value: 'create_time', type: 'timeRange'}
      ]
    }
  },
  
  '/policy/standard': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showDetail: false,
    showSearch: true,
    showOutput: true,
    outPutTitle: '导出数据(可根据搜索条件导出)',
    outPutName: '标准数据',
    outPutPath: '/Policy/output',
    outPutData: {type: 'standard'},
    showListDel: false,
    routerData: null,
    listData: {draft:'0',type: 'standard'},
    name: '标准',
    apiUrl: '/Policy',
    listRouter: '/policy/standard',
    editRouter: '/policy/standard/edit',
    addRouter: '',
    delRouter: '',
    detailRouter: '/policy/detail',
    list: {
      com: '',
      params: [
        {name: '标题', value: 'title', type: 'html',width:300},
        {name: '封面图', value: 'head_img', type:'image'},
        {name: '类别', value: 'policy.name', root: true},
        // {name: '数据分类', value: 'mm_type',type:'mm_type'},
        {name: '部门', value: 'department'},
        // {name: '是否开启支付', value: 'is_pay',type:'bool'},
        {name: '价格', value: 'price'},
        {name: '权限', value: 'vip_txt'},
        {name: '状态', value: 'push_state', type: 'pushState'},
        {name: '发布时间', value: 'push_time'},
        {name: '实施时间', value: 'todo_time'},
        // {name: '创建时间', value: 'create_time'},
      ]
    },
    edit: {
      com: '',
      params: [
        {name: '标题', value: 'title', rq: true, sc: true, edit: true, max: 250},
        {name: '类别', value: 'type_policy_id', type: 'select', data: {akey: 'standard', state: 1}, rq: true, sc: true, edit: true},
        // {name:'数据分类',value:'mm_type',type:'select',options:[{name:'冷链',id:'chain'},{name:'食材供应链',id:'agriculture'},{name:'冷链/食材供应链',id:'all'}],rq:true,edit:true},
        // {name:'部门',value: 'type_department_id',type:'select',data:{akey:'department',state:1},rq:false,sc:true,edit:true},
        {name: '部门', value: 'department', rq: false, edit: true},
        {name: '文号', value: 'doc_number', rq: false, sc: false, edit: true, max: 20},
        {name: '封面图', value: 'head_img', rq: false, sc: false, edit: true},
        {name: '状态', value: 'push_state', rq: true, edit: true, type: 'select', options: [{name: '有效', id: 1}, {name: '修订', id: 2}, {name: '废止', id: 0}]},
        {name: '发布时间', value: 'push_time', rq: true, sc: false, edit: true},
        {name: '实施时间', value: 'todo_time', rq: true, sc: false, edit: true},
        {name: '附件', value: 'files_ids', type: 'file', rq: false, sc: false, edit: true},
        {name: '内容', value: 'html_content', type: 'editor', rq: true, sc: false, edit: true},
        // {name:'是否开启支付',value:'is_pay',type:'state',rq:true,edit:true},
        {name: '开启收藏', value: 'open_favorite', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '开启点赞', value: 'open_like', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '开启评论', value: 'open_comment', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '访问权限', value: 'user_vip_type', type: 'vipPrice', rq: true, edit: true},
      ],
      editData: {type: 'standard'}
    },
    search: {
      params: [
        {name: '标题', value: 'title'},
        {name: '类别', value: 'type_policy_id', type: 'select', data: {akey: 'standard', state: 1}},
        // {name: '数据分类', value: 'mm_type',type:'select',options:[{name:'冷链',id:'chain'},{name:'食材供应链',id:'agriculture'},{name:'冷链/食材供应链',id:'all'}]},
        // {name: '部门', value: 'type_department_id', type: 'select', data: {akey: 'department', state: 1}},
        {name: '部门', value: 'department'},
        // {name: '是否开启支付', value: 'is_pay',type:'select',options:[{name:'是',id:'1'},{name:'否',id:'0'}]},
        {name: '发布时间', value: 'push_time', type: 'timeRange'},
        {name: '实施时间', value: 'todo_time', type: 'timeRange'},
        {name: '创建时间', value: 'create_time', type: 'timeRange'}
      ]
    }
  },
  
  '/policy/standard/draft': {
    showAdd: false,
    showDel: true,
    showEdit: true,
    showDetail: false,
    showSearch: true,
    showOutput: false,
    showCheck: true,
    checkStateKey:'draft',
    okStateVal:0,
    errorStateVal:1,
    stateOkText:'发布',
    stateOkTip:'是否发布该标准？',
    outPutTitle: '导出数据(可根据搜索条件导出)',
    outPutName: '标准数据',
    outPutPath: '/Policy/output',
    outPutData: {type: 'standard'},
    showListDel: false,
    routerData: null,
    listData: {draft:'1',type: 'standard'},
    name: '标准',
    apiUrl: '/Policy',
    listRouter: '/policy/standard/draft',
    editRouter: '/policy/standard/draft/edit',
    addRouter: '',
    delRouter: '',
    detailRouter: '/policy/draft/detail',
    optionsWidth:280,
    list: {
      com: '',
      params: [
        {name: '标题', value: 'title', type: 'html',width:300},
        {name: '封面图', value: 'head_img', type:'image',width: 100},
        {name: '类别', value: 'policy.name', root: true,width: 100},
        // {name: '数据分类', value: 'mm_type',type:'mm_type'},
        {name: '部门', value: 'department',width: 200},
        // {name: '是否开启支付', value: 'is_pay',type:'bool'},
        {name: '抓取网站', value: 'site',width: 100},
        // {name: '抓取网址', value: 'collect',type:'link',width: 400},
        {name: '创建时间', value: 'create_time',width: 160},
        {name: '抓取时间', value: 'collect_time',width: 160},
        // {name: '创建时间', value: 'create_time'},
      ]
    },
    edit: {
      com: '',
      params: [
        {name: '标题', value: 'title', rq: true, sc: true, edit: true, max: 250},
        {name: '类别', value: 'type_policy_id', type: 'select', data: {akey: 'standard', state: 1}, rq: true, sc: true, edit: true},
        // {name:'数据分类',value:'mm_type',type:'select',options:[{name:'冷链',id:'chain'},{name:'食材供应链',id:'agriculture'},{name:'冷链/食材供应链',id:'all'}],rq:true,edit:true},
        // {name:'部门',value: 'type_department_id',type:'select',data:{akey:'department',state:1},rq:false,sc:true,edit:true},
        {name: '部门', value: 'department', rq: false, edit: true},
        {name: '文号', value: 'doc_number', rq: false, sc: false, edit: true, max: 20},
        {name: '封面图', value: 'head_img', rq: false, sc: false, edit: true},
        {name: '状态', value: 'push_state', rq: true, edit: true, type: 'select', options: [{name: '有效', id: 1}, {name: '修订', id: 2}, {name: '废止', id: 0}]},
        {name: '发布时间', value: 'push_time', rq: true, sc: false, edit: true},
        {name: '实施时间', value: 'todo_time', rq: true, sc: false, edit: true},
        {name: '附件', value: 'files_ids', type: 'file', rq: false, sc: false, edit: true},
        {name: '内容', value: 'html_content', type: 'editor', rq: true, sc: false, edit: true},
        // {name:'是否开启支付',value:'is_pay',type:'state',rq:true,edit:true},
        {name: '开启收藏', value: 'open_favorite', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '开启点赞', value: 'open_like', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '开启评论', value: 'open_comment', type: 'bool', def: 1, rq: true, sc: false, edit: true, max: 20},
        {name: '访问权限', value: 'user_vip_type', type: 'vipPrice', rq: true, edit: true},
      ],
      editData: {type: 'standard'}
    },
    search: {
      params: [
        {name: '标题', value: 'title'},
        {name: '类别', value: 'type_policy_id', type: 'select', data: {akey: 'standard', state: 1}},
        // {name: '数据分类', value: 'mm_type',type:'select',options:[{name:'冷链',id:'chain'},{name:'食材供应链',id:'agriculture'},{name:'冷链/食材供应链',id:'all'}]},
        // {name: '部门', value: 'type_department_id', type: 'select', data: {akey: 'department', state: 1}},
        {name: '部门', value: 'department'},
        // {name: '是否开启支付', value: 'is_pay',type:'select',options:[{name:'是',id:'1'},{name:'否',id:'0'}]},
        {name: '发布时间', value: 'push_time', type: 'timeRange'},
        {name: '实施时间', value: 'todo_time', type: 'timeRange'},
        {name: '创建时间', value: 'create_time', type: 'timeRange'}
      ]
    }
  },
}
