<template>
	<el-date-picker
		style="width: auto !important;"
		:editable="false"
		:clearable="false"
		append-to-body
		v-model="values"
		type="year"
		value-format="yyyy"
		format="yyyy 年"
		@change="change"
		placeholder="选择年份" v-bind="props">
	</el-date-picker>
</template>

<script>
export default {
	name: "YearPicker",
	data() {
		return {
			list: [],
			values: '',
		}
	},
	model: {
		prop: 'value',
		event: 'input'
	},
	props: {
		value: {
			type: [String, Number],
			default: ''
		},
		now: {
			type: Boolean,
			default: false
		},
		props:{
			type:Object,
			default(){
				return {}
			}
		}
	},
	created() {
		if (this.value) {
			this.values = this.value.toString();
		} else if (this.now) {
			this.values = this.$moment().format('YYYY')
			this.$emit('input', this.values);
			// this.$emit('change',{year:this.values,times:''});
		}
		console.log(this.$props)
	},
	watch: {
		value(n, o) {
			this.values = n ? n.toString() : '';
		}
	},
	mounted() {

	},
	methods: {
		change(e) {
			this.$emit("input", e);
			this.$emit('change', {year: e, times: '0'});
		}
	}
}
</script>
