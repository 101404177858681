<template>
	<el-cascader
		size="large"
		:options="options"
		v-model="values"
		:props="prop"
		@change="change">
	</el-cascader>
</template>

<script>
import {provinceAndCityData, regionData, codeToText, pcTextArr} from 'element-china-area-data'

import pc_code from '@/components/miihe-china-area-data/pc.json'
// import provinceAndCityData from '@/components/miihe-china-area-data/areaCode.json'
// import CodeToText from '@/components/miihe-china-area-data/areaName.json'
export default {
	name: "ProvincePicker",
	data() {
		return {
			list:[],
			options: null,
			values: [],
			name: '',
			prop: {
				value: 'code',
				label: 'name',
				children: 'children',
				expandTrigger: 'hover',
				// lazy: true,
				// lazyLoad(node, resolve) {
				// 	console.log(node);
				// 	const { level } = node;
				// 	// setTimeout(() => {
				// 		const nodes = Array.from({ length: level + 1 })
				// 			.map(item => ({
				// 				value: ++id,
				// 				label: `选项${id}`,
				// 				leaf: level >= 2
				// 			}));
				// 		console.log(nodes);
				// 		// 通过调用resolve将子节点数据返回，通知组件数据加载完成
				// 		resolve(nodes);
				// 	// }, 1000);
				// }
			},
			cols: []
		}
	},
	model: {
		prop: 'value',
		event: 'input'
	},
	props: {
		value: {
			type: [Array, String],
			default() {
				return []
			}
		},
		type: {
			type: String,
			default: 'pc'
		},
		outData: {
			type: String,
			default: 'province,city'
		},
		outType: {
			type: String,
			default: 'array'
		}
	},
	created() {
		this.options = this.type === 'pca' ? regionData : pc_code;
		// this.options = pcTextArr;

		// console.log(JSON.stringify(regionData));
		console.log(codeToText);

		// this.options = provinceAndCityData;
		this.values = this.value ? (typeof this.value === 'string' ? this.value.split(',') : this.value) : [];

		this.cols = this.outData ? this.outData.split(',') : (this.type === 'pca' ? 'province,city,area'.split(',') : 'province,city'.split(','));
	},
	watch: {
		value(n, o) {
			this.values = n ? (typeof n === 'string' ? n.split(',') : n) : [];
		}
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList() {
			let _this = this;
			this.$http.httpPost({
				url: "/Divisions/listAll",
				datas: {pid:'3318'},
				success(res) {
					let _list = [];
					for(let i=0;i < res.length;i++) {
						let _a = {code: res[i].code, name: res[i].name}
						_list.push(_a);
					}
					_this.list = _list;
				}
			});
		},
		getChild(pid,cb) {
			let _this = this;
			this.$http.httpPost({
				url: "/Divisions/listAll",
				datas: {pid:pid},
				success(res) {
					let _list = [];
					for(let i=0;i < res.length;i++) {
						let _a = {code: res[i].code, name: res[i].name}
						_list.push(_a);
					}
					cb && cb(_list);
				}
			});
		},
		change(e) {
			console.log(e);
			let _out = {};
			let _labels = [];
			let _province_code = e[0];
			let _province_name = codeToText[_province_code];
			let _city_code = e[1];
			let _city_name = codeToText[_city_code];

			_out.provinceCode = _province_code;
			_out.provinceName = _province_name;
			_out.cityCode = _city_code;
			_out.cityName = _city_name;

			if (this.type === 'pca') {
				let _area_code = e[2];
				let _area_name = CodeToText[_area_code];

				_out.areaCode = _area_code;
				_out.areaName = _area_name;
			}

			for (let i = 0; i < e.length; i++) {
				_labels.push(codeToText[e[i]]);
			}

			let _e = [];

			for (let i = 0; i < this.cols.length; i++) {
				if (!this.$utils.isUndefined(_out[this.cols[i] + 'Code']) && _out[this.cols[i] + 'Code']) {
					_e.push(_out[this.cols[i] + 'Code']);
				}
			}


			if (_e.length === 1) {
				_e = _e[0];
			} else if (_e.length === 2) {
				_e = _e[0] + ',' + _e[1];
			} else {
				_e = _e[0] + ',' + _e[1] + ',' + _e[2];
			}
			console.log(_e)
			console.log(_out)
			console.log(_labels)

			this.$emit("input", this.outType === 'array' ? _e : (typeof _e === 'string' ? _e : _e.join(',')));

			this.$emit('change', _out);

			this.$emit('label', this.outType === 'array' ? _labels : (typeof _labels === 'string' ? _labels : _labels.join(' / ')));
		}
	}
}
</script>
