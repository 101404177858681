<template>
    <m-main-layout>
        <el-button size="small" type="primary" slot="right-btn" @click="$router.push({path:'/rule/path/list'})">规则列表
        </el-button>
        <div class="page-content-main">
            <el-form :model="formData" status-icon :rules="rules" ref="form" label-width="auto" class="margin-top"
                     style="width:600px;">
                <el-input v-model="formData.id" type="hidden"></el-input>
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="formData.username" maxlength="20" show-word-limit
                              style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="formData.password" maxlength="20" show-word-limit
                              style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="成员角色" prop="group_id">
                    <el-select v-model="formData.group_id" placeholder="请选择">
                        <el-option v-for="item in typeList" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否可用" prop="status">
                    <el-switch
                        v-model="formData.state"
                        active-color="#13ce66"
                        active-value="1"
                        inactive-value="0"
                        active-text="启用"
                        inactive-text="禁用">
                    </el-switch>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('form')">确定</el-button>
                    <el-button type="" @click="$router.back()">返回</el-button>
                </el-form-item>
            </el-form>
        </div>
    </m-main-layout>
</template>

<script>
export default {
    name: "edit",
    data() {
        return {
            formData: {
                id: '',
                username: '',
                password: '',
                nickname: '',
                state: '1',
                mobile: '',
                group_id: ''
            },
            typeList: [],
            rules: {
                username: [
                    {required: true, message: '规则路径'},
                    {min: 1, max: 50, message: '请输入1-50位字符'}
                ],
                password: [
                    {min: 4, max: 20, message: '请输入8-20位字符'}
                ],
                state: [
                    {required: true, message: '状态必选'},
                ],
                group_id: [
                    {required: true, message: '角色必选'},
                ]
            },
        };
    },
    created() {
        this.formData.id = this.$route.query.id ? this.$route.query.id : 0;
    },
    mounted() {
        if (this.formData.id !== 0) {
            this.getDetail();
            this.getOption();
        } else {
            this.$message.error({message: '缺少参数', duration: 2000});

        }
    },
    methods: {
        submitForm(formName) {
            let _this = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log(_this.formData);
                    _this.$api.memberEdit(_this.formData,
                        function (res) {
                            console.log(res);
                            _this.$router.replace({path: '/member/list'})
                        })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        getDetail() {
            let _this = this;
            console.log(_this.formData.id);
            this.$api.memberDetail({id: _this.formData.id},
                function (res) {
                    _this.formData.id = res.id;
                    _this.formData.username = res.username;
                    _this.formData.group_id = res.group_id;
                    _this.formData.state = res.state.toString();
                })
        },
        getOption() {
            let _this = this;
            this.$api.ruleGroupList(null,
                function (res) {
                    if (res.length > 0) {
                        for (let i = 0; i < res.length; i++) {
                            let _obj = {label: res[i].name, value: res[i].id};
                            _this.typeList.push(_obj);
                        }
                    }
                })
        }
    }
}
</script>
