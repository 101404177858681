<template>
  <div class="bg-light padding-20">
    <el-button @click="$router.back()">返回列表</el-button>
    <el-descriptions title="开票信息" direction="vertical" :column="4" border v-if="info" class="margin-top-20">
      <el-descriptions-item label="状态">
        <el-tag type="info" v-if="info.state === 0">待审核</el-tag>
        <el-tag type="success" v-if="info.state === 1">已开票</el-tag>
        <el-tag type="danger" v-if="info.state === 2">已拒绝</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="开票类型">
        <el-tag type="primary" v-if="info.open_type === 'person'">个人</el-tag>
        <el-tag type="warning" v-if="info.open_type === 'company'">企业</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="发票类型">
        <el-tag type="primary" v-if="info.type === 'email'">电子票</el-tag>
        <el-tag type="warning" v-if="info.type === 'paper'">纸质票</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="用户昵称">{{info.user.nickname}}</el-descriptions-item>
      <el-descriptions-item label="用户姓名">{{info.user.name}}</el-descriptions-item>
      <el-descriptions-item label="开票名称">{{info.title}}</el-descriptions-item>
	    <el-descriptions-item label="开票金额">{{info.pay_amount}}</el-descriptions-item>
      <el-descriptions-item label="开票税号">{{info.number}}</el-descriptions-item>
      <el-descriptions-item label="开票事项">{{info.program}}</el-descriptions-item>
      <el-descriptions-item label="申请时间">{{info.create_time}}</el-descriptions-item>
      <el-descriptions-item label="单位地址">{{info.company_adr ? info.company_adr : '-'}}</el-descriptions-item>
      <el-descriptions-item label="单位电话">{{info.company_phone ? info.company_phone : '-'}}</el-descriptions-item>
      <el-descriptions-item label="开户银行">{{info.company_bank ? info.company_bank : '-'}}</el-descriptions-item>
      <el-descriptions-item label="银行账号">{{info.company_account ? info.company_account : '-'}}</el-descriptions-item>
      <el-descriptions-item label="电子邮箱" v-if="info.type === 'email'">{{info.email}}</el-descriptions-item>
      <el-descriptions-item label="电子票据" v-if="info.type === 'email'">
        <el-button type="primary" icon="el-icon-folder-opened" size="small" @click="toFile(info.file)" v-if="info.file">附件</el-button>
        <div v-else>未上传</div>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions title="邮寄地址" direction="vertical" :column="2" border v-if="info && info.type === 'paper'" class="margin-top-20">
      <el-descriptions-item label="收件人">{{info.consignee}}</el-descriptions-item>
      <el-descriptions-item label="收件人电话">{{info.phone}}</el-descriptions-item>
      <el-descriptions-item label="物流公司">{{info.shipping_name ? info.shipping_name : '-'}}</el-descriptions-item>
      <el-descriptions-item label="物流单号">{{info.shipping_number ? info.shipping_number : '-'}}</el-descriptions-item>
      <el-descriptions-item label="收件地址">{{info.address ? info.address : '-'}}</el-descriptions-item>
    </el-descriptions>
<!--    <el-descriptions title="物流信息" direction="vertical" :column="4" border v-if="info" class="margin-top-20">-->
<!--      <el-descriptions-item label="用户昵称">{{info.user.nickname}}</el-descriptions-item>-->
<!--      <el-descriptions-item label="用户姓名">{{info.user.name}}</el-descriptions-item>-->
<!--      <el-descriptions-item label="居住地">苏州市</el-descriptions-item>-->
<!--      <el-descriptions-item label="居住地">苏州市</el-descriptions-item>-->
<!--      <el-descriptions-item label="备注">-->
<!--        <el-tag size="small">学校</el-tag>-->
<!--      </el-descriptions-item>-->
<!--      <el-descriptions-item label="联系地址">江苏省苏州市吴中区吴中大道 1188 号</el-descriptions-item>-->
<!--      <el-descriptions-item label="联系地址">江苏省苏州市吴中区吴中大道 1188 号</el-descriptions-item>-->
<!--    </el-descriptions>-->
  </div>
</template>

<script>
export default {
  name: "detail",
  data() {
    return {
      id:'',
      info:null
    }
  },
  created() {
    this.id = this.$route.query.id ? this.$route.query.id : '';
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    toFile(file){
      window.open(file.indexOf('http') >= 0 ? file : this.$config.assetsUrl + file, '_blank');
    },
    getDetail(){
      let _this = this;

      if(this.id) {
        this.$http.httpPost({
          url: '/UserInvoiceLog/detail',
          datas: {id: this.id},
          success(res) {
            if(res){
              _this.info = res;
            }else{
              _this.$alert('内容不存在，请返回', '提示', {
                confirmButtonText: '立即返回',
                showClose:false,
                callback: action => {
                  _this.$router.back();
                }
              });
            }
          }
        });
      }else{
        this.$alert('内容不存在，请返回', '提示', {
          confirmButtonText: '立即返回',
          callback: action => {
            this.$router.back();
          }
        });
      }
    }
  }
}
</script>
