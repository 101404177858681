import OldConfig from './oldConfig'
import UserConfig from './UserConfig'
import DictConfig from './DictConfig'
import PrizeConfig from './PrizeConfig'
import HydataCarConfig from './HydataCarConfig'
import HydataLenglianConfig from './HydataLenglianConfig'
import HydataLengkuConfig from './HydataLengkuConfig'
import NewsConfig from './NewsConfig'
import HydataChanliangConfig from './HydataChanliangConfig'
import HydataInoutConfig from './HydataInoutConfig'
import CalculatorConfig from './CalculatorConfig'
import DivisionsConfig from './DivisionsConfig'
import PolicyConfig from './PolicyConfig'
import ReportConfig from './ReportConfig'
import SystemConfig from './SystemConfig'

export default {
  ...OldConfig,
  ...UserConfig,
  ...DictConfig,
  ...PrizeConfig,
  ...HydataCarConfig,
  ...HydataLenglianConfig,
  ...HydataLengkuConfig,
  ...NewsConfig,
  ...HydataChanliangConfig,
  ...HydataInoutConfig,
  ...CalculatorConfig,
  ...DivisionsConfig,
  ...PolicyConfig,
  ...ReportConfig,
  ...SystemConfig
}
