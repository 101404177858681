<template>
	<div class="bg-light padding-20">
		<el-form :model="formData" status-icon :rules="rules" ref="form" label-width="auto" class="margin-top" style="width:800px;">
			<el-form-item label="物流费用占比(%)" prop="wlfyzb" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">
				<el-input v-model="formData.wlfyzb" style="width:500px;" maxlength="20" show-word-limit clearable></el-input>
				<div class="dark-3 f14">冷链物流费用约占餐饮收入的百分比</div>
			</el-form-item>
			<el-form-item label="仓储收入占比(%)" prop="ccfyzb" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">
				<el-input v-model="formData.ccfyzb" style="width:500px;" maxlength="20" show-word-limit clearable></el-input>
				<div class="dark-3 f14">其中冷链仓储收入约占冷链物流费用的百分比</div>
			</el-form-item>
			<el-form-item label="托转立方(立方)" prop="tzlf" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">
				<el-input v-model="formData.tzlf" style="width:500px;" maxlength="20" show-word-limit clearable></el-input>
				<div class="dark-3 f14">每托约等于多少立方</div>
			</el-form-item>
			<el-form-item label="冷库平均周转率(%)" prop="lkpjzzl" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">
				<el-input v-model="formData.lkpjzzl" style="width:500px;" maxlength="20" show-word-limit clearable></el-input>
				<div class="dark-3 f14">全年冷库平均周转率</div>
			</el-form-item>
			<el-form-item label="餐饮带来的冷链业务量占比(%)" prop="llywzb" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">
				<el-input v-model="formData.llywzb" style="width:500px;" maxlength="20" show-word-limit clearable></el-input>
				<div class="dark-3 f14">餐饮带来的冷链业务量约占整体的百分比</div>
			</el-form-item>
			<el-form-item label="冷冻货物全年周转次数(次)" prop="ldhwzzcs" :rules="[{pattern:$regExp.integer,message:'请填写正确的数值'}]">
				<el-input v-model="formData.ldhwzzcs" style="width:500px;" maxlength="20" show-word-limit clearable></el-input>
				<div class="dark-3 f14">冷冻货物平均按照45天在冷库周转一次，全年可周转8次</div>
			</el-form-item>
			<el-form-item label="港口货物全年可周转次数(次)" prop="gkhwzzcs" :rules="[{pattern:$regExp.integer,message:'请填写正确的数值'}]">
				<el-input v-model="formData.gkhwzzcs" style="width:500px;" maxlength="20" show-word-limit clearable></el-input>
				<div class="dark-3 f14">货物平均按照三个月在冷库周转一次，全年可周转4次</div>
			</el-form-item>
			<el-form-item label="立方转吨(吨)" prop="lfzd" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">
				<el-input v-model="formData.lfzd" style="width:500px;" maxlength="20" show-word-limit clearable></el-input>
				<div class="dark-3 f14">1立方米冷库约等于多少吨</div>
			</el-form-item>
			<el-form-item label="全国人均冷库容量(立方米)" prop="rjlkrl" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">
				<el-input v-model="formData.rjlkrl" style="width:500px;" maxlength="20" show-word-limit clearable></el-input>
				<div class="dark-3 f14">全国人均冷库容量</div>
			</el-form-item>
			<el-form-item label="恩格尔对应的系数24%以下" prop="xs24" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">
				<el-input v-model="formData.xs24" style="width:500px;" maxlength="20" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="恩格尔对应的系数25%——28%" prop="xs2528" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">
				<el-input v-model="formData.xs2528" style="width:500px;" maxlength="20" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="恩格尔对应的系数29%——30%" prop="xs2930" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">
				<el-input v-model="formData.xs2930" style="width:500px;" maxlength="20" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="恩格尔对应的系数31%——35%" prop="xs3135" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">
				<el-input v-model="formData.xs3135" style="width:500px;" maxlength="20" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="恩格尔对应的系数36%以上" prop="xs36" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">
				<el-input v-model="formData.xs36" style="width:500px;" maxlength="20" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('form')">确定</el-button>
				<el-button type="" @click="$router.back()">返回</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
export default {
	name: "add",
	data() {
		return {
			formData: {
				wlfyzb: '',
				ccfyzb: '',
				tzlf: '',
				lkpjzzl: '',
				llywzb: '',
				ldhwzzcs: '',
				lfzd: '',
				rjlkrl: '',
				xs24: '',
				xs2528: '',
				xs2930: '',
				xs3135: '',
				xs36: '',
				gkhwzzcs: '',
			},
			rules: {
				// less_kaipiao_price: [{required: true, message: '最低开票金额必填'}, {pattern: this.$regExp.money, message: '请输入正确的金额'}],
			}
		};
	},
	mounted() {
		this.getDetail();
	},
	methods: {
		submitForm(formName) {
			let _this = this;
			this.$refs[formName].validate(valid => {
				if (valid) {
					_this.$http.httpPost({
						url: "/SystemSetting/edit",
						datas: {..._this.formData},
						success: res => {
							if (res === "ok") {
								_this.$message.success("修改成功");
							} else {
								_this.$message.error("修改失败");
							}
							// _this.$message.info(res.msg);
							// _this.$router.replace({path: '/cate/list'})
						}
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		getDetail() {
			let _this = this;

			let _skeys = [];

			for (let k in this.formData) {
				_skeys.push(k);
			}

			_this.$http.httpPost({
				url: "/SystemSetting/getSystem",
				datas: {skey: _skeys},
				success: res => {
					if(Object.prototype.toString.call(res) === '[object Object]'){
						for(let i in res){
							_this.formData[i] = res[i];
						}
					}
				}
			});
		}
	}
};
</script>
