<template>
  <div class="bg-light padding-20">
    <el-form :model="formData" status-icon :rules="rules" ref="form" label-width="auto" class="margin-top">
        <el-form-item label="协会简介" prop="about_ncp">
            <editor v-model="formData.about_ncp"/>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitForm('form')">确定</el-button>
            <el-button type="" @click="$router.back()">返回</el-button>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
    name: "add",
    data() {
        return {
            formData: {
                about_ncp:''
            },
            rules: {
                // service:[{required:true}],
              about_ncp: [{required: true}],
                // secret:[{required:true}],
            },
        };
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        submitForm(formName) {
            let _this = this;
            this.$refs[formName].validate(valid => {
                if (valid) {
                    _this.$http.httpPost({
                        url: "/SystemSetting/edit",
                        datas: {..._this.formData},
                        success: res => {
                            if (res === "ok") {
                                _this.$message.success("修改成功");
                            } else {
                                _this.$message.error("修改失败");
                            }
                            // _this.$message.info(res.msg);
                            // _this.$router.replace({path: '/cate/list'})
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        getDetail() {
            let _this = this;

            let _skeys = [];

            for (let k in this.formData) {
                _skeys.push(k);
            }

            _this.$http.httpPost({
                url: "/SystemSetting/getSystem",
                datas: {skey: _skeys},
                success: res => {
                    _this.formData = res;
                }
            });
        }
    }
}
</script>
