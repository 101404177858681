let dev = {
    apiUrl:'http://127.0.0.1:9502/Admin',
    assetsUrl:'http://127.0.0.1:9502',
    uploadUrl:'http://127.0.0.1:9502/Admin/Upload/upload',
    editorUrl:'http://127.0.0.1:9502',
    webUrl:'http://localhost:8081',
}

let pro = {
    apiUrl:'http://api.chaincloud.org.cn/Admin',
    assetsUrl:'http://api.chaincloud.org.cn/static',
    uploadUrl:'http://api.chaincloud.org.cn/Admin/Upload/upload',
    editorUrl:'http://api.chaincloud.org.cn',
    webUrl:'http://www.chaincloud.org.cn',
}

export default {

    //线上测试
    // apiUrl:'//api.test.chaincloud.org.cn/Admin',
    // uploadUrl:'//api.test.chaincloud.org.cn/Admin/Upload/upload',
    // assetsUrl:'//api.test.chaincloud.org.cn/static',
    // editorUrl:'//api.test.chaincloud.org.cn',
    // webUrl:'http://test.chaincloud.org.cn',

    //线上生产
    // apiUrl:'//api.chaincloud.org.cn/Admin',
    // uploadUrl:'//api.chaincloud.org.cn/Admin/Upload/upload',
    // assetsUrl:'//api.chaincloud.org.cn',
    // editorUrl:'//api.chaincloud.org.cn',
    // webUrl:'http://test.chaincloud.org.cn',

    //本地测试
    // apiUrl:'http://127.0.0.1:9505/Admin',
    // uploadUrl:'http://127.0.0.1:9505/Admin/Upload/upload',
    // assetsUrl:'http://127.0.0.1:9505',
    // editorUrl:'http://127.0.0.1:9505',
    // webUrl:'http://localhost:8081',
    apiUrl:process.env.NODE_ENV === 'development' ? dev.apiUrl : pro.apiUrl,
    assetsUrl:process.env.NODE_ENV === 'development' ? dev.assetsUrl : pro.assetsUrl,
    uploadUrl:process.env.NODE_ENV === 'development' ? dev.uploadUrl : pro.uploadUrl,
    editorUrl:process.env.NODE_ENV === 'development' ? dev.editorUrl : pro.editorUrl,
    webUrl:process.env.NODE_ENV === 'development' ? dev.webUrl : pro.webUrl,


    secret:'miihe!@#',
    sideMenu:[
        {title:'概况',path:'/dashboard',query:null,icon:'fa fa-tachometer'},
        {title:'系统设置',path:'/system/show',query:null,icon:'fa fa-tachometer'},
        {title:'权限管理',path:'/rule',query:null,icon:'el-icon-present',
            child:[
                {title:'路由管理',path:'/rule/path/list',query:null},
                {title:'角色管理',path:'/rule/group/list',query:null},
                {title:'成员管理',path:'/member/list',query:null},
            ]
        },
        // {name:'产品',path:'/product',query:null,icon:'el-icon-present',
        //     sub:[
        //         {name:'产品管理',path:'/product/list',query:null},
        //         {name:'产品价格',path:'/product/price',query:null},
        //     ]
        // },
        // {name:'问题管理',path:'/question',query:null,icon:'el-icon-present',
        //     sub:[
        //         {name:'问题分类',path:'/questionType/list',query:null},
        //         {name:'问题列表',path:'/question/list',query:null},
        //     ]
        // },
        // {name:'微信管理',path:'/wx',query:null,icon:'el-icon-present',
        //     sub:[
        //         {name:'消息记录',path:'/wx/msg/list',query:null},
        //         {name:'自动回复',path:'/wx/reply/list',query:null},
        //         {name:'菜单管理',path:'/wx/menu/list',query:null},
        //     ]
        // },
        // {name:'禁用词',path:'/voidword',query:null,icon:'fa fa-tachometer'},
        // {name:'用户管理',path:'/user/list',query:null,icon:'el-icon-user'},
        // {name:'订单',path:'/order/list',query:null,icon:'el-icon-shopping-cart-full'},
        // {name:'资金流水',path:'/dashboard',query:null,icon:'el-icon-coin'},
        // {name:'云营销',path:'/invert',query:null,icon:'el-icon-cloudy',
        //     sub:[
        //         {name:'推广人管理',path:'/invert/list',query:null},
        //         {name:'结算管理',path:'/product/price',query:null},
        //     ]
        // },
        // {name:'成员管理',path:'/member',query:null,icon:'el-icon-burger'},
    ],
}
