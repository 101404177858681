<template>
	<div class="bg-light">
		<div class="padding bg-gray-3 dark-2 f16">当前合计数量：<b class="brand">{{ totalCount }}</b></div>
		<el-table :data="list">
			<el-table-column label="名称" prop="name"></el-table-column>
			<el-table-column label="行政区号" prop="code"></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button size="small" type="primary" @click="toFind(scope.row)">查看下级</el-button>
					<el-button size="small" type="success" @click="toEdit(scope.row)">编辑</el-button>
					<el-button size="small" type="danger">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="padding-top-bottom-20 text-center">
			<el-pagination
				background
				layout="total,prev, pager, next"
				:page-size="query.pageSize"
				:current-page.sync="query.page"
				:total="totalCount"
				@current-change="pageChange">
			</el-pagination>
		</div>
		<el-dialog title="编辑" width="400px" :visible.sync="show" @closed="toClose">
<!--			{{form}}-->
			<el-form :model="form" ref="form" label-width="100px">
				<el-form-item label="名称" prop="name" :rules="[{required:true,message:'名称不能为空'}]">
					<el-input v-model="form.name" maxlength="15" show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="行政区号" prop="code" :rules="[{required:true,message:'行政区号不能为空'}]">
					<el-input v-model="form.code" maxlength="15" show-word-limit></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="toClose">取 消</el-button>
				<el-button type="primary" @click="toSubmit">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "index",
	data() {
		return {
			list:[],
			query:{
				page:1,
				pageSize:20,
				pid:'0'
			},
			totalCount:0,
			totalPage:0,
			show:false,
			form:{
				id:'',
				name:'',
				code:'',
				pid:''
			}
		}
	},
	created() {
		this.query.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
		this.query.pageSize = this.$route.query.pageSize ? parseInt(this.$route.query.pageSize) : 20;
		this.query.pid = this.$route.query.pid ? this.$route.query.pid : '0';
	},
	mounted() {
		this.getList();
	},
	methods: {
		toFind(e){
			this.query.pid = e.id;
			this.query.page = 1;
			this.$router.push({query: {...this.query}});
		},
		toEdit(e){
			let _this = this;
			_this.$http.httpPost({
				url: "/Divisions/detail",
				datas: {id:e.id},
				success: res => {
					_this.form.id = res.id;
					_this.form.name = res.name;
					_this.form.code = res.code;
					_this.form.pid = res.pid;

					_this.show = true;
				}
			});
		},
		getList(){
			let _this = this;
			_this.$http.httpPost({
				url: "/Divisions/listPage",
				datas: this.query,
				success: res => {
					_this.list = res.list;
					_this.totalCount = res.totalCount;
				}
			});
		},
		pageChange(page){
			this.query.page = page;
			this.$router.push({query: {...this.query}});
		},
		toClose(){
			this.form.id = '';
			this.form.name = '';
			this.form.code = '';
			this.form.pid = '';
			this.show=false;
		},
		toSubmit(){
			let _this = this;
			this.$refs.form.validate(valid => {
				//为true时验证通过
				if (valid) {
					_this.$http.httpPost({
						url: '/Divisions/edit',
						datas: _this.form,
						success: (res) => {
							console.log(res);
							_this.$message.success('操作成功');
							_this.getList();
							_this.toClose();
						}
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		}
	}
}
</script>
