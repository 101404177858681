<template>
	<div class="bg-light padding-20">
		<el-row :gutter="10">
<!--			{{form}}-->
			<el-form :model="form" :rules="rules" label-width="auto" ref="form">
				<el-col :span="6">
					<el-form-item label="参数类型" prop="type">
						<el-select style="width: 100%;" v-model="form.type" @change="onChangeType">
							<el-option :label="t.label" :value="t.value" v-for="(t,i) in typeList"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="18">
					<el-form-item>
						<el-input v-model="defaultValue" maxlength="20" show-word-limit clearable style="width: 200px;margin-right: 10px;"></el-input>
						<el-button type="primary" @click="editAll">批量设置</el-button>
						<el-button type="danger" @click="cleanAll">批量清空</el-button>
						<el-button type="info" @click="resetAll">重置</el-button>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="省份" prop="province_code" :rules="[{required:true,message:'省份不能为空'}]">
						<province-select style="width: 100%;" :is-obj="false" v-model="form.province_code" @input="onChangeProvince"></province-select>
					</el-form-item>
				</el-col>
				<el-col :span="18"><div style="height: 62px;"></div></el-col>
				<el-col :span="6" v-for="(t,i) in form.content_json">
					<el-form-item :label="t.name" :prop="t.code" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">
						<el-input v-model="t.value" maxlength="20" show-word-limit clearable></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item>
						<el-button icon="el-icon-arrow-left" @click="goBack">返回</el-button>
						<el-button type="primary" @click="toSubmit">保存</el-button>
					</el-form-item>
				</el-col>
			</el-form>
		</el-row>
	</div>
</template>

<script>
export default {
	name: "type",
	data() {
		return {
			rules:{
				type:[{required:true,message:'参数类型必选'}]
			},
			aaa:'',
			bbb:'',
			form:{
				id:'',
				type:'',
				province_code:'',
				province_name:'',
				content_json:[],
			},

			typeList:[
				{label:'餐饮收入(亿元)',value:'cysr'},
				{label:'平均冷库租金(元/托/天)',value:'lkzj'},
				{label:'生鲜食品消费量(万吨)',value:'sxspxfl'},
				{label:'人均生鲜食品消费量(KG)',value:'rjsxspxfl'},
				{label:'常住人口(人)',value:'czrk'},
				{label:'恩格尔系数(%)',value:'egexs'},
				{label:'冷库实际库容量(万立方米)',value:'lktotal'},
				{label:'港口冷库需求总量(万吨)',value:'gktotal'},
			],

			defaultValue:''
		}
	},
	created() {
	},
	mounted() {
	},
	methods: {
		editAll(){
			if(!this.defaultValue.trim()){
				return;
			}
			if(this.form && this.form.content_json){
				for(let i=0;i < this.form.content_json.length;i++){
					this.form.content_json[i].value = this.defaultValue;
				}
			}
		},
		cleanAll(){
			if(this.form && this.form.content_json){
				for(let i=0;i < this.form.content_json.length;i++){
					this.form.content_json[i].value = '';
				}
			}
		},
		resetAll(){
			this.defaultValue = '';
		},
		goBack(){
			this.$router.back();
		},
		onChangeType(e){
			this.getDetail()
		},
		getDetail(){
			let _this = this;
			if(!this.form.type || !this.form.province_code){
				return;
			}
			_this.$http.httpPost({
				url: "/Calculator/detail",
				datas: {type:this.form.type,province_code:this.form.province_code},
				success: res => {
					console.log(res);
					if(!res){
						// for(let i in _this.form){
						// 	if(i !== 'type'){
						// 		_this.$set(_this.form,i,'');
						// 	}
						// }
						_this.form.id = '';
						if(_this.form && _this.form.content_json){
							for(let i=0;i < _this.form.content_json.length;i++){
								_this.form.content_json[i].value = '';
							}
						}
					}else{
						_this.form.id = res.id;
						_this.form.content_json = JSON.parse(res.content_json);
						// for(let i in _this.form){
						// 	if(i !== 'type' && i !== 'id'){
						// 		_this.$set(_this.form,i,res[i] === '0.00' || res[i] === 0 || res[i] === '0' ? '' : parseFloat(res[i]));
						// 	}
						// }
					}
				}
			});
		},
		onChangeProvince(e){
			console.log(e);
			let _this = this;
			this.form.province_name = e.name;
			_this.$http.httpPost({
				url: '/Divisions/listAll',
				datas: {pid:e.id},
				success: res => {
					// console.log(res);
					let _list = [];
					for(let i=0;i < res.length;i++){
						let _data = {id:res[i].id,code:res[i].code,name:res[i].name,value:''};
						_list.push(_data);
					}
					_this.form.content_json = _list;
					_this.getDetail();
				}
			});
		},
		toSubmit() {
			let _this = this;
			this.$refs.form.validate(valid => {
				if (valid) {
					let _data = JSON.parse(JSON.stringify(_this.form));
					// for(let i in _data){
					// 	if(i !== 'id' && i !== 'type'){
					// 		if(!_data[i]){
					// 			_data[i] = '0.00'
					// 		}
					// 	}
					// }
					_data.content_json = JSON.stringify(_data.content_json);
					_this.$http.httpPost({
						url: _data.id ? "/Calculator/edit" : '/Calculator/add',
						datas: _data,
						success: res => {
							if (res) {
								_this.$message.success("修改成功");
								_this.getDetail();
							} else {
								_this.$message.error("修改失败");
							}
						}
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
	}
}
</script>
