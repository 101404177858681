<template>
    <div>
        <el-table :data="dataList" default-expand-all style="width: 100%" row-key="id"
                  :tree-props="{children: 'child'}" :indent="30" v-if="dataList.length > 0">
            <el-table-column
                prop="order_no"
                label="提现单号"></el-table-column>
            <el-table-column
                prop="consignee"
                label="提现用户" width="300">
                <template slot-scope="scope">
                    <div>UID：{{scope.row.uid}}</div>
                    <div>昵称：{{scope.row.user.nickname}}</div>
                </template>
            </el-table-column>
            <el-table-column
                prop="money"
                label="提现金额">
            </el-table-column>
            <el-table-column
                prop="real_money"
                label="实际提现金额">
            </el-table-column>
            <el-table-column
                prop="account_name"
                label="账户名">
            </el-table-column>
            <el-table-column
                prop="account_num"
                label="银行账户">
            </el-table-column>
            <el-table-column
                prop="account_bank"
                label="银行名称">
            </el-table-column>
            <el-table-column
                prop="state"
                label="状态" style="font-size:12px;" width="150">
                <template slot-scope="scope">
                    <el-tag type="warning" disable-transitions v-if="scope.row.state === 0">待审核</el-tag>
                    <el-tag type="success" disable-transitions v-if="scope.row.state === 1">已审核</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                prop="create_time"
                label="申请时间"></el-table-column>
            <el-table-column align="right" width="300">
                <template slot-scope="scope">
                    <base-button @click.native="changeState(scope.row.id,1)" v-if="scope.row.state === 0" type="warning">确认提现</base-button>
                    <base-button @click.native="changeState(scope.row.id,0)" v-if="scope.row.state === 1" type="danger">重置状态</base-button>
<!--                    <base-button @click.native="$router.push({path:'/order/detail/'+scope.row.id})">详情</base-button>-->
                </template>
            </el-table-column>
        </el-table>
        <div class="text-center null-data f14 info" v-else>
            还没有数据
        </div>
        <div class="text-center padding-top-bottom-20" v-if="dataList.length > 0">
            <el-pagination
                background
                layout="total,prev, pager, next"
                :page-size="pageSize"
                :current-page.sync="searchForm.page"
                :total="totalCount"
                @current-change="pageChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    name: "list",
    data() {
        return {
            searchForm: {
                order_no:'',
                out_trade_no:'',
                phone:'',
                state:'',
                order_type:'',
                consignee:'',
                page:1
            },
            dataList: [],
            totalPage: 0,
            totalCount: 0,
            pageSize: 20,
            page: 1,
        }
    },
    created() {
        let _query = this.$route.query;
        this.searchForm = {...this.searchForm,..._query};
        this.searchForm.page = _query.page ? parseInt(_query.page) : 1;
    },
    mounted() {
        this.getList();
    },
    methods: {
        toSearch() {
            this.searchForm.page = 1;
            this.$router.replace({query:this.searchForm});
            this.getList();
        },
        clearSearch() {
            this.searchForm = {
                order_no:'',
                out_trade_no:'',
                phone:'',
                state:'',
                order_type:'',
                consignee:'',
                page:1
            };
            // this.page = 1;
            this.$router.replace({query:this.searchForm});
            this.getList();
        },
        getList() {
            let _this = this;
            this.dataList = [];
            this.$http.httpPost({
                url: '/UserCash/listPage',
                datas: {...this.searchForm,pageSize: this.pageSize},
                success: (res) => {
                    console.log(res);
                    _this.dataList = res.list;
                    _this.totalPage = res.totalPage;
                    _this.totalCount = res.totalCount;
                }
            });
        },
        changeState(id, state,item = null) {

            let _this = this;

            let _msg = state === 1 ? '是否确认提现？' : '是否确认重置？';
            this.$confirm(_msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.$http.httpPost({
                    url: '/UserCash/state',
                    datas: {id:id,state:state},
                    success: (res) => {
                        _this.getList();
                        _this.$message.success('操作成功');
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        pageChange(page) {
            this.searchForm.page = page;
            this.$router.replace({query:this.searchForm})
            this.getList();
        }
    },
}
</script>
