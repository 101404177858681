<template>
	<el-menu
		:router="true"
		:collapse="isCollapse"
		:default-active="$router.currentRoute.path"
		background-color="#304156"
		text-color="#fff"
		style="height:100%;overflow-y: auto;"
	>
		<template v-for="(item, index) in sideMenu">
			<template v-if="item.child && item.child.length > 0">
				<el-submenu :index="item.path" :key="'t-' + index">
					<template slot="title">
						<i :class="item.icon" v-if="item.icon"></i>
						<span>{{ item.title }}</span>
					</template>
					<template v-for="(subitem, subindex) in item.child">
						<template v-if="subitem.child && subitem.child.length > 0">
							<el-submenu :index="subitem.path" :key="'t-s-' + subindex">
								<template slot="title">
									<span>{{ subitem.title }}</span>
								</template>
								<template v-for="(st,si) in subitem.child">
									<template v-if="st.child && st.child.length > 0">
										<el-submenu :index="st.path" :key="'t-s-s-' + si">
											<template slot="title">
												<span>{{ st.title }}</span>
											</template>
											<template v-for="(st2,si2) in st.child">
												<el-menu-item
													:index="st2.path"
													:key="'t-s-s-s-' + si2"
													:route="{path:st2.path,query:st2.query}"
													class="sub-menu-item">
													{{ st2.title }}
												</el-menu-item>
											</template>
										</el-submenu>
									</template>
									<template v-else>
										<el-menu-item
											:index="st.path"
											:key="'t-s-s-' + si"
											:route="{path:st.path,query:st.query}"
											class="sub-menu-item">
											{{ st.title }}
										</el-menu-item>
									</template>
								</template>
							</el-submenu>
						</template>
						<template v-else>
							<el-menu-item
								:index="subitem.path"
								:key="'s-' + subindex"
								:route="subitem.path"
								class="sub-menu-item">
								{{ subitem.title }}
							</el-menu-item>
						</template>
					</template>
				</el-submenu>
			</template>
			<template v-else>
				<el-menu-item :index="item.path" :key="'t-' + index" :route="item.path">
					<i :class="item.icon" v-if="item.icon"></i>
					<span slot="title">{{ item.title }}</span>
				</el-menu-item>
			</template>
		</template>
	</el-menu>
</template>

<script>
export default {
	name: "SideMenu",
	data() {
		return {
			sideMenu: [],
			defaultActive: null,
			isCollapse: false
		};
	},
	props: {
		sideCollapse: {
			type: Boolean,
			default: false
		}
	},
	created() {
		// console.log(this.$sideMenu);
		this.sideMenu = this.$sideMenu;
		// console.log(this.sideMenu);
		// let _menu = this.$utils.sessionGet('menu');
		// if (_menu) {
		//     this.sideMenu = JSON.parse(_menu);
		//     this.sideMenu = this.$config.sideMenu;
		// }
		// this.defaultActive = this.$route.path;
	},
	watch: {
		sideCollapse(n, o) {
			// console.log(o);
			this.isCollapse = n;
		}
	},
	mounted() {
		// console.log(this.defaultActive);
		// this.getList();
	},
	methods: {
		getList() {
			let _this = this;

			_this.sideMenu = [];

			// this.$http.httpPost({
			//     url: "/admin/menu",
			//     datas: null,
			//     success(res) {
			//         let _menu = [
			//             { name: "菜单管理", url: "/menu",icon:'el-icon-tickets'},
			//             // { name: "示例", url: "/test" }
			//         ];
			//
			//         _this.sideMenu = res ? _menu.concat(res) : [];
			//         // _this.sideMenu = _this.$config.sideMenu;
			//         // console.log(_this.sideMenu);
			//         // _this.total = res.total;
			//     }
			// });
		}
	}
};
</script>
