<template>
	<div class="bg-light padding-20">
<!--		{{dataForm}}-{{timesQuarter}}-->
		<el-form :model="dataForm" label-width="150px" ref="form">
			<el-form-item label="ID" v-if="dataForm.id">
				{{dataForm.id}}
			</el-form-item>
			<el-form-item label="季度" prop="year" :rules="[{required:true,message:'年度不能为空'}]">
				<quarter-picker v-model="timesQuarter" @change="onChangeTime"></quarter-picker>
			</el-form-item>
			<el-form-item label="描述">
				<el-input v-model="dataForm.content1" type="textarea" style="width: 500px;" :rows="4"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="warning" @click="toAdd">添加厂商</el-button>
			</el-form-item>
			<div class="cl" v-for="(tt,ii) in dataForm.supplierList" label-width="auto">
				<div class="z">
					<el-form-item label="厂商名称" :prop="'supplierList.'+ii+'.name'" :rules="[{required:true,message:'厂商名称不能为空'}]">
						<el-input v-model="tt.name" maxlength="30" show-word-limit style="width: 400px;"></el-input>
					</el-form-item>
				</div>
				<div class="z">
					<el-form-item label="车辆数量" :prop="'supplierList.'+ii+'.num'" :rules="[{required:true,message:'数量不能为空'}]">
						<el-input-number v-model="tt.num" :precision="0" :step="1" :min="1" :max="99999"></el-input-number>
						<el-button class="margin-left" type="danger" @click="toDel(ii)">删除</el-button>
					</el-form-item>
				</div>
			</div>
			<el-form-item>
				<el-button @click="$router.back()">返回</el-button>
				<el-button type="primary" @click="toSubmit">提交</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
export default {
	name: "edit",
	data() {
		return {
			dataForm:{
				id:'',
				content1:'',
				time_type:'quarter',
				times:'0',
				year:'',
				supplierList:[]
			},
			timesQuarter:''
		}
	},
	created() {
		this.dataForm.id = this.$route.query.id ? this.$route.query.id : ''
	},
	mounted() {
		if(this.dataForm.id){
			this.getDetail({id:this.dataForm.id});
		}
	},
	methods: {
		onChangeTime(e) {
			console.log(e);
			this.dataForm.year = e.year;
			this.dataForm.times = e.times;
			this.dataForm.supplierList = [];
			this.dataForm.content1 = '';
			this.dataForm.id = '';
			this.getDetail({year:this.dataForm.year,times:this.dataForm.times,time_type:this.dataForm.time_type});
		},
		getDetail(where){
			let _this = this;
			this.$http.httpPost({
				url: '/HydataCarSupplier/detail',
				datas: where,
				success: (res) => {
					console.log(res);
					if(res){
						_this.dataForm.id = res.id;
						_this.dataForm.content1 = res.content1;
						_this.dataForm.times = res.times;
						_this.dataForm.year = res.year;
						_this.dataForm.supplierList = res.supplierList;
						_this.timesQuarter = res.year+'-'+res.times;
					}
				}
			})
		},
		toSubmit(){
			let _this = this;
			if(this.dataForm.supplierList.length <= 0){
				_this.$message.error('请添加厂商');
				return;
			}
			this.$refs.form.validate((valid) => {
				if (valid) {
					let _data = JSON.parse(JSON.stringify(_this.dataForm));
					_data.supplierList = JSON.stringify(_data.supplierList);
					_this.$http.httpPost({
						url: _data.id ? '/HydataCarSupplier/edit' : '/HydataCarSupplier/add',
						datas: _data,
						success: (res) => {
							_this.$message.success('提交成功');
							_this.$router.back();
						}
					})
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		toAdd(){
			let _data = {name:'',num:''};
			this.dataForm.supplierList.push(_data);
		},
		toDel(i){
			this.dataForm.supplierList.splice(i,1);
		}
	}
}
</script>
