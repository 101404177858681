<template>
	<div>
		<div v-for="(item,index) in list" :key="item.url">
			<i class="el-icon-paperclip brand"></i><a :href="$utils.formatImagePath(item.url)" target="_blank">{{item.name}}</a>
		</div>
	</div>
<!--	<el-row :gutter="gutter">-->
<!--		<el-col :span="24 / lineCount" v-for="(item,index) in list" :key="item.url" class="border-radius-10 hide margin-bottom" style="margin-bottom:15px;">-->
<!--			<el-image-->
<!--				style="width:100%;height:160px;background-color: #f4f4f4;"-->
<!--				:style="imgStyle"-->
<!--				fit="contain"-->
<!--				:src="$utils.formatImagePath(item.url)"-->
<!--				:preview-src-list="allList">-->
<!--			</el-image>-->
<!--		</el-col>-->
<!--	</el-row>-->
</template>

<script>
export default {
	name: "FilePreView",
	data() {
		return {
			allList:[]
		}
	},
	props:{
		list:{
			type:Array,
			default:[]
		},
		lineCount:{
			type:Number,
			default:6
		},
		gutter:{
			type:Number,
			default:10
		},
		outClass:{
			type:String,
			default:''
		},
		imgClass:{
			type:String,
			default:''
		},
		imgStyle:{
			type:String,
			default:''
		},
		itemClass:{
			type:String,
			default:'margin-bottom'
		},
		valueKey:{
			type:String,
			default:''
		},
		height:{
			type:String,
			default:'200rpx'
		},
		mode:{
			type:String,
			default:'aspectFill'
		}
	},
	created() {
		for(let i=0;i < this.list.length;i++){
			this.allList.push(this.$utils.formatImagePath(this.list[i].url));
		}
	},
	mounted() {
	},
	methods: {}
}
</script>
