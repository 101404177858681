<template>
  <div>
    <div class="bg-light padding-20">
      <div class="padding-bottom">
        <el-button @click="$router.push({path:'/survey'})">返回问卷管理</el-button>
      </div>
      <div v-if="info" class="padding-top-bottom-20">
        <el-row class="dark-2">
          <el-col :span="12">问卷标题：<b class="success">{{ info.title }}</b></el-col>
          <el-col :span="12">创建时间：<b class="success">{{ info.create_time }}</b></el-col>
        </el-row>
        <el-row class="dark-2 margin-top-20">
          <el-col :span="12">参与数量：<b class="success">{{ info.join_count }}</b></el-col>
          <el-col :span="12">状态：
            <b class="success" v-if="info.state === 1">已发布</b>
            <b class="danger" v-if="info.state === 0">未发布</b>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="margin-top bg-light padding-20">
      <div class="padding-top-bottom">结果统计</div>
      <div class="padding-top-bottom border-bottom" v-for="(item,index) in list">
        <template v-if="item.type === 'multi' || item.type === 'radio'">
          <el-row :gutter="10">
            <el-col :span="12">
              <div class="cl padding-top-bottom">
                <div class="f-b f16 z">{{ index + 1 }}、{{ item.title }}</div>
                <el-button size="mini" type="primary" class="y" @click="toShowEditTotal(item)">修改统计</el-button>
                <span class="y f14 dark-3 margin-right-5" style="line-height: 28px;" v-if="item.use_mock === 0">真实数据</span>
                <span class="y f14 dark-3 margin-right-5" style="line-height: 28px;" v-if="item.use_mock === 1">真假累加</span>
                <span class="y f14 dark-3 margin-right-5" style="line-height: 28px;" v-if="item.use_mock === 2">固定假</span>
              </div>
              <el-table :data="item.total" border class="margin-top" max-height="300" :header-cell-style="{background:'#f6f6f6'}">
                <el-table-column label="选项" prop="title" fixed>
                  <template slot-scope="scope">
                    {{ scope.row.title }}
                    <el-button class="margin-left" type="text" v-if="scope.row.is_input === 1" @click="toShowInput(scope.row.sub_total)">查看详情
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column label="小计" prop="count" width="100"></el-table-column>
                <el-table-column label="比例" prop="percent" width="100">
                  <template slot-scope="scope">{{ scope.row.percent }}%</template>
                </el-table-column>
              </el-table>
            </el-col>
            <el-col :span="12">
              <template v-if="item.total.length > 0">
                <bar-echarts
                  :ref="'bar'+index"
                  :grid-bottom="30"
                  height="300px"
                  width="100%"
                  show-legend
                ></bar-echarts>
              </template>
              <div class="text-center f16 dark-3 padding-top-bottom-20" v-else>暂无数据</div>
            </el-col>
          </el-row>

          <!--          <el-button size="small" icon="el-icon-s-data" class="margin-top" :type="showBar[index] ? 'info' : 'primary'" @click="toShowBar(index,!showBar[index])">{{showBar[index] ? '隐藏图表' : '显示图表'}}</el-button>-->
          <!--          <div v-if="item.total.length > 0">-->
          <!--            -->
          <!--          </div>-->

        </template>
        <!--        <div v-else>-->
        <!--          <div v-if="item.total.length > 0"-->
        <!--        </div>-->
        <template v-else>
          <div class="f-b f16 padding-top-bottom">{{ index + 1 }}、{{ item.title }}</div>
          <el-table :data="item.total" border class="margin-top" height="250"
                    :header-cell-style="{background:'#f6f6f6'}">
            <el-table-column label="序号" type="index" width="100" fixed></el-table-column>
            <el-table-column label="内容" prop="content"></el-table-column>
          </el-table>
        </template>
        <!--        <el-button class="margin-top" type="primary" @click="toShowInput(item)" v-else>查看详细内容</el-button>-->
      </div>
    </div>
    <el-dialog title="内容详情" :visible.sync="showDialog" custom-class="dialog-h-400" append-to-body>
      <el-table :data="inputList">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column label="内容" prop="content"></el-table-column>
      </el-table>
      <div slot="footer" class="text-center">
        <el-button @click="showDialog = false">关 闭</el-button>
      </div>
    </el-dialog>
    <el-dialog title="修改统计结果" :visible.sync="showEditTotalDialog" custom-class="padding-top" append-to-body @closed="toCloseEditTotal">
      <el-tabs v-model="totalActive" @tab-click="tabClick" type="card">
        <el-tab-pane label="修改统计" name="edit">
          <el-form ref="form" :model="formData" :rules="rules">
            <el-form-item label="数据展示方式" label-width="130px">
              <el-radio-group v-model="formData.use_mock">
                <el-radio :label="0">真实数据</el-radio>
                <el-radio :label="1">真假累加</el-radio>
                <el-radio :label="2">固定显示假数据</el-radio>
              </el-radio-group>
            </el-form-item>
            <template v-if="formData.use_mock === 1 || formData.use_mock === 2">
              <div class="padding-top-bottom">合计数量(分母):{{ formData.use_mock === 1 ? $utils.keepNumberTwoDecimal(parseFloat(formData.old_total_count) + parseFloat(formData.total_count)) : $utils.keepNumberTwoDecimal(parseFloat(formData.total_count))}}</div>
              <el-row :gutter="10">
                <el-col :span="11" v-if="formData.use_mock === 1">
                  <el-form-item label="真实数量" label-width="90px">
                    <el-input v-model="formData.old_total_count" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="2" class="text-center f20" style="line-height: 40px;" v-if="formData.use_mock === 1">+</el-col>
                <el-col :span="11">
                  <el-form-item prop="total_count" label="自定义数量" label-width="100px" :rules="[{required:true,message:'内容必填'},{pattern:$regExp.positiveInteger,message: '必须是正整数'}]">
                    <el-input v-model="formData.total_count" maxlength="8" show-word-limit></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <template v-for="(item,index) in formData.total">
                <div class="f14 f-b" style="line-height: 40px;">{{index + 1}}、{{item.title}}：{{formData.use_mock === 1 ? $utils.keepNumberTwoDecimal(parseFloat(formData.total[index].old_count) + parseFloat(formData.total[index].count)) : $utils.keepNumberTwoDecimal(parseFloat(formData.total[index].count))}}</div>
                <el-row :gutter="10" style="border-bottom: 1px solid #eee;">
                  <el-col :span="11" v-if="formData.use_mock === 1">
                    <el-form-item label="真实数量" label-width="90px">
                      <el-input v-model="formData.total[index].old_count" disabled></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="2" class="text-center f20" style="line-height: 40px;" v-if="formData.use_mock === 1">+</el-col>
                  <el-col :span="11">
                    <el-form-item label="自定义数量" :prop="'total.'+index+'.count'" :rules="[{required:true,message:'内容必填'},{pattern:$regExp.positiveInteger,message: '必须是正整数'}]" label-width="100px">
                      <el-input v-model="formData.total[index].count" maxlength="8" show-word-limit>
                        <template slot="append">{{formData.use_mock === 1 ? $utils.keepNumberTwoDecimal((parseFloat(formData.total[index].old_count) + parseFloat(formData.total[index].count)) / (parseFloat(formData.old_total_count) + parseFloat(formData.total_count)) * 100) : $utils.keepNumberTwoDecimal(parseFloat(formData.total[index].count) / parseFloat(formData.total_count) * 100)}}%</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </template>
            </template>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="历史记录" name="history">
          <el-table :data="historyList">
            <el-table-column type="index"></el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.is_used === 1">使用中</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="数据展示方式">
              <template slot-scope="scope">
                <span v-if="scope.row.use_mock === 1">真假累加</span>
                <span v-if="scope.row.use_mock === 2">固定假数据</span>
              </template>
            </el-table-column>
            <el-table-column label="操作人">
              <template slot-scope="scope">{{scope.row.user.username}}</template>
            </el-table-column>
            <el-table-column prop="create_time" label="数据添加时间"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" :disabled="scope.row.is_used === 1" @click="toUseMock(scope.row)">应用</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="padding-top-bottom-20 text-center">
            <el-pagination
              background
              layout="total,prev, pager, next"
              :page-size="historyListData.pageSize"
              :current-page.sync="historyListData.page"
              :total="historyListTotal"
              @current-change="historyPageChange">
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="text-center" v-if="totalActive === 'edit'">
        <el-button @click="toCloseEditTotal">关 闭</el-button>
        <el-button type="primary" @click="toSubmitEditTotal">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "result",
  data() {
    return {
      list: [],
      id: '',
      info: null,
      bars: [],
      showDialog: false,
      showEditTotalDialog: false,

      inputList: [],

      showBar: [],

      formData: {
        total_count: '',
        old_total_count:'',
        title:'',
        tid:'',
        qid:'',
        use_mock:0,
        total:[]
      },

      historyListData:{
        page:1,
        pageSize:10,
      },

      historyListTotal:0,
      historyList:[],

      rules:{
        total_count: [
          {required:true,message:'内容必填'},
          {pattern:this.$regExp.positiveInteger,message: '必须是正整数'}
        ]
      },

      totalActive:'edit',
    }
  },
  created() {
    this.id = this.$route.query.id ? this.$route.query.id : '';
  },
  mounted() {
    this.getTotal();
    this.getDetail();
  },
  methods: {
    toUseMock(item){
      console.log(item);
      let _this = this;
      this.$confirm('是否应用此数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.$http.httpPost({
          url: '/SurveyTotalLogMock/toUse',
          datas: {tid:item.tid,qid:item.qid,id:item.id},
          success: (res) => {
            console.log(res)
            _this.getMockHistory();
            _this.getTotal();
          }
        });
      }).catch(() => {});
    },
    tabClick(t,e){
      console.log(t.name);
      if(t.name === 'history'){
        this.getMockHistory();
      }
    },
    historyPageChange(e){
      this.historyListData.page = e;

      this.getMockHistory();
    },
    toShowEditTotal(item){
      console.log(item);
      this.formData.total_count = 0;
      this.formData.old_total_count = this.info.join_count;
      this.formData.qid = item.id;
      this.formData.tid = item.tid;
      this.formData.title = item.title;

      if(item.total && item.total.length > 0){
        for(let i=0;i < item.total.length;i++){
          let _sub = item.total[i];
          let _data = {
            tid:_sub.tid,
            qid:item.id,
            aid:_sub.id,
            count:0,
            old_count:_sub.count,
            percent:_sub.percent,
            title:_sub.title,
          }

          this.formData.total.push(_data);
        }
      }

      this.showEditTotalDialog = true;
    },
    toCloseEditTotal(){
      this.showEditTotalDialog = false;

      this.formData.total_count = '';
      this.formData.qid = '';
      this.formData.tid = '';
      this.formData.title = '';
      this.formData.use_mock = 0;
      this.formData.total = [];
    },
    getMockHistory(){
      let _this = this;

      _this.$http.httpPost({
        url: '/SurveyTotalLogMock/listPage',
        datas: {...this.historyListData,tid:this.formData.tid,qid:this.formData.qid},
        success: (res) => {
          console.log(res)
          _this.historyList = res.list;
          _this.historyListTotal = res.totalCount;
        }
      });
    },
    toSubmitEditTotal(){
      let _this = this;

      console.log(this.formData);
	    let _total_count = parseInt(this.formData.total_count);
	    let _check_total_count = 0;
	
	    for (let i=0;i < this.formData.total.length;i++){
		    _check_total_count += parseInt(this.formData.total[i].count);
	    }
	
	    if(_check_total_count !== _total_count){
		    this.$message.error('数量相加必须为 '+_total_count);
		    return;
	    }

      this.$refs['form'].validate((validate)=>{
        if(validate){
          _this.$http.httpPost({
            url: '/SurveyTotalLogMock/add',
            datas: _this.formData,
            success: (res) => {
              console.log(res)

              _this.getTotal();
            }
          });
        }
      })
      this.toCloseEditTotal();
    },
    toShowBar(i, e) {
      this.$set(this.showBar, i, e);

      this.$nextTick(() => {
        this.$refs['bar' + i][0].resize();
      }, this)
    },
    toShowInput(e) {
      this.inputList = e;
      this.showDialog = true;
    },
    getTotal() {
      let _this = this;

      if (this.id) {
        _this.$http.httpPost({
          url: '/SurveyTotal/getTotal',
          datas: {tid: this.id},
          success: (res) => {
            _this.list = res;

            for (let i = 0; i < res.length; i++) {

              if (res.type !== 'input') {
                _this.$set(this.showBar, i, false);
              }

              let _total = res[i].total;

              if (_total.length > 0) {
                let _data = [];
                let _xaxis = [];

                for (let j = 0; j < _total.length; j++) {
                  _data.push(_total[j].percent);
                  _xaxis.push(_total[j].title);
                }

                _this.$nextTick(() => {
                  if (_this.$refs['bar' + i]) {
                    _this.$refs['bar' + i][0].initData(
                      [
                        {name: '占比', type: 'bar', color: 'green', data: _data, dw: '%'},
                      ],
                      _xaxis
                    );
                  }
                })
              }
            }
          }
        })
      }
    },
    getDetail() {
      let _this = this;

      if (this.id) {
        _this.$http.httpPost({
          url: '/SurveyTitle/detail',
          datas: {id: this.id},
          success: (res) => {
            _this.info = res;
          }
        })
      }
    }
  }
}
</script>
