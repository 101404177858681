<template>
	<div class="bg-light padding-20">
		<el-form :model="dataForm" label-width="150px" ref="form">
			<el-form-item label="ID" v-if="dataForm.id">
				{{dataForm.id}}
			</el-form-item>
			<el-form-item label="类别">
				{{dataForm.typeStr}}
			</el-form-item>
			<el-form-item label="年度" prop="year" :rules="[{required:true,message:'年度不能为空'}]">
				<year-picker v-model="dataForm.year" @change="onChangeTime"></year-picker>
			</el-form-item>
			<el-form-item label="描述">
				<el-input v-model="dataForm.content1" type="textarea" style="width: 500px;" :rows="4"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="warning" @click="toAdd">添加国别</el-button>
			</el-form-item>
			<div class="cl" v-for="(tt,ii) in dataForm.countryList">
				<div class="z">
					<el-form-item label="国别名称" :prop="'countryList.'+ii+'.name'" :rules="[{required:true,message:'厂商名称不能为空'}]">
						<el-input v-model="tt.name" maxlength="30" show-word-limit style="width: 400px;"></el-input>
					</el-form-item>
				</div>
				<div class="z">
					<el-form-item label="占比" :prop="'countryList.'+ii+'.num'" :rules="[{required:true,message:'数量不能为空'},{pattern:$regExp.money,message:'请填写正确的数值'}]">
						<el-input v-model="tt.num" maxlength="30" show-word-limit style="width: 200px;">
							<template slot="append">%</template>
						</el-input>
						<el-button class="margin-left" type="danger" @click="toDel(ii)">删除</el-button>
					</el-form-item>
				</div>
			</div>
			<div class="cl" v-if="dataForm.countryList.length > 0">
				<div class="z" style="width: 550px;height: 62px;"></div>
				<div class="z">
					<el-form-item label="累计占比">{{getTotal}}%</el-form-item>
				</div>
			</div>
			<el-form-item>
				<el-button @click="$router.back()">返回</el-button>
				<el-button type="primary" @click="toSubmit">提交</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
export default {
	name: "edit",
	data() {
		return {
			dataForm:{
				id:'',
				content1:'',
				time_type:'year',
				times:'0',
				year:'',
				type:'',
				typeStr:'',
				countryList:[]
			},
			inoutType:{
				'shucai':'蔬菜及制品进口',
				'shuiguo':'水果及制品进口',
				'rou':'肉类及制品进口',
				'shuichanpin':'水海产品及制品进口',
				'ruzhipin':'乳制品进口',
			}
		}
	},
	created() {
		this.dataForm.id = this.$route.query.id ? this.$route.query.id : ''
		this.dataForm.type = this.$route.query.type ? this.$route.query.type : ''
		if(this.dataForm.type){
			this.dataForm.typeStr = this.inoutType[this.dataForm.type];
		}
	},
	mounted() {
		if(this.dataForm.id){
			this.getDetail({id:this.dataForm.id});
		}
	},
	computed:{
		getTotal(){
			let total = 0;
			for(let i=0;i < this.dataForm.countryList.length;i++){
				if(this.dataForm.countryList[i].num){
					total += parseFloat(this.dataForm.countryList[i].num)
				}
			}
			return parseFloat(total.toFixed(2));
		}
	},
	methods: {
		onChangeTime(e) {
			this.dataForm.year = e.year;
			this.dataForm.countryList = [];
			this.dataForm.content1 = '';
			this.dataForm.id = '';
			this.getDetail({year:this.dataForm.year,time_type:this.dataForm.time_type,type:this.dataForm.type});
		},
		getDetail(where){
			let _this = this;
			this.$http.httpPost({
				url: '/HydataInoutCountry/detail',
				datas: where,
				success: (res) => {
					console.log(res);
					if(res){
						this.dataForm.id = res.id;
						this.dataForm.content1 = res.content1;
						this.dataForm.times = res.times;
						this.dataForm.year = res.year;
						this.dataForm.typeStr = res.typeStr;
						this.dataForm.type = res.type;
						this.dataForm.countryList = res.countryList;
					}
				}
			})
		},
		toSubmit(){
			let _this = this;
			if(this.dataForm.countryList.length <= 0){
				_this.$message.error('请添加国别');
				return;
			}
			this.$refs.form.validate((valid) => {
				if (valid) {
					if(this.getTotal !== 100){
						_this.$message.error('占比之和必须等于100%');
						return;
					}
					let _data = JSON.parse(JSON.stringify(_this.dataForm));
					_data.countryList = JSON.stringify(_data.countryList);
					_this.$http.httpPost({
						url: _data.id ? '/HydataInoutCountry/edit' : '/HydataInoutCountry/add',
						datas: _data,
						success: (res) => {
							_this.$message.success('提交成功');
							_this.$router.back();
						}
					})
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		toAdd(){
			let _data = {name:'',num:''};
			this.dataForm.countryList.push(_data);
		},
		toDel(i){
			this.dataForm.countryList.splice(i,1);
		}
	}
}
</script>
