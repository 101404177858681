<template>
  <div class="bg-light padding-20">
        <el-row :gutter="10">
            <el-form :model="searchForm" label-width="100px">
                <el-col :span="5">
                    <el-form-item label="年份">
                        <year-select v-model="searchForm.year" @change="change"></year-select>
                    </el-form-item>
                </el-col>
                <el-col :span="5" v-if="list.length > 0">
                    <del-button size="normal" @click.native="del(searchForm.year)" :label="'删除'+searchForm.year+'年数据'"></del-button>
                </el-col>
                <el-col :span="14" class="text-right">
                    <put-in v-model="showPutIn" @success="onPutSuccess" :data="{key:'putin',year:searchForm.year}" url-path="/HydataNongpi" demo-path="/nongpi_demo.xlsx">导入{{searchForm.year}}年数据</put-in>
                </el-col>
            </el-form>
        </el-row>
        <div class="border-top" v-if="list.length > 0">
            <el-table
                :data="list"
                style="width: 100%">
                <el-table-column label="类别" prop="title"></el-table-column>
                <el-table-column label="市场数量(个)" prop="market_count"></el-table-column>
                <el-table-column label="总摊拉数(个)" prop="stall_count"></el-table-column>
                <el-table-column label="年末出租摊拉数(个)" prop="end_stall_count"></el-table-column>
                <el-table-column label="营业面积(平方米)" prop="area"></el-table-column>
                <el-table-column label="成交额(万元)" prop="amount"></el-table-column>
                <el-table-column label="操作" width="100" align="right">
                    <template slot-scope="scope">
                        <edit-button @click.native="$router.push({ path: '/hydata/nongpi/edit',query:{id:scope.row.id }})"></edit-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="text-center padding-top-bottom-20 rel">
                <el-pagination
                    background
                    layout="total,prev, pager, next"
                    :page-size="searchForm.pageSize"
                    :current-page.sync="searchForm.page"
                    :total="totalCount"
                    @current-change="pageChange">
                </el-pagination>
            </div>
        </div>
        <div class="padding-top-bottom-40 border-top text-center dark-2" v-else>
            暂无数据，<add-button @click.native="initData" :label="'初始化'+searchForm.year+'年数据'"></add-button>
        </div>
<!--        <put-in v-model="showPutIn" @success="onPutSuccess" :data="{key:'putin',year:searchForm.year}" url-path="/HydataNongpi"></put-in>-->
    </div>
</template>

<script>
export default {
    name: "list",
    data(){
        return{
            showPutIn:false,
            searchForm: {
                pageSize:15,
                page:1,
                year:''
            },
            list:[],
            totalCount:0
        }
    },
    created() {
        let _now = new Date();
        this.searchForm.year = _now.getFullYear()-1;

        this.searchForm = this.$route.query ? {...this.searchForm,...this.$route.query} : {...this.searchForm};
        this.searchForm.page = parseInt(this.searchForm.page);
        this.searchForm.pageSize = parseInt(this.searchForm.pageSize);
    },
    mounted() {
        this.getList();
    },
    methods: {
        change(e){
            this.searchForm.year = e;

            this.$router.replace({query: {...this.searchForm}});
        },
        toSearch() {
            this.$router.replace({query: {...this.searchForm}});
        },
        toReset() {
            let _edit = this.params['edit']['params'];

            for (let i = 0; i < _edit.length; i++) {
                if (_edit[i].sc && typeof _edit[i].value === 'string') {
                    this.$set(this.searchForm, _edit[i].value, '');
                }
            }

            this.$router.replace({query: {...this.searchForm}});
        },
        initData(){
            let _this = this;
            this.$http.httpPost({
                url: '/HydataNongpi/initData',
                datas: {year:this.searchForm.year},
                success: (res) => {
                    console.log(res);
                    _this.getList();
                }
            });
        },
        pageChange(page){
            this.searchForm.page = page;
            this.$router.replace({query:{...this.searchForm}});
        },
        getList(){
            let _this = this;
            this.$http.httpPost({
                url: '/HydataNongpi/listPage',
                datas: {...this.searchForm},
                success: (res) => {
                    _this.list = res.list ? res.list : [];
                    _this.totalCount = res.totalCount;
                }
            });
        },
        del(year){
            let _this = this;
            this.$confirm("是否删除"+year+'年数据', "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.$http.httpPost({
                        url: '/HydataNongpi/del',
                        datas: {year:year},
                        success(res) {
                            _this.$utils.success("删除成功！");
                            _this.getList();
                        }
                    });
                })
                .catch(() => {
                    // _this.$utils.warning('取消了');
                });
        },
        onPutSuccess(){
            this.$message.success('导入成功');

            this.getList();

            this.showPutIn = false;
        },
    }
}
</script>
