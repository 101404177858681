<template>
	<div>
		<el-radio-group v-model="formData.user_vip_type" @change="changeVipType">
			<el-radio label="free">完全公开</el-radio>
			<el-radio label="user">注册会员</el-radio>
			<el-radio :label="item.id" v-for="(item,index) in vip_list">{{ item.name }}</el-radio>
		</el-radio-group>
		<el-form-item v-if="superId.indexOf(formData.user_vip_type) >= 0" prop="price" :rules="[{validator:rulePriceNotZero}]">
			<el-radio-group v-model="priceType" @change="changePriceType">
				<el-radio :label="1">￥9.9</el-radio>
				<el-radio :label="2">￥19.9</el-radio>
				<el-radio :label="3">￥3880</el-radio>
				<el-radio :label="4">其它</el-radio>
			</el-radio-group>
			<el-input v-model="formData.price" maxlength="100" style="margin-left:10px;width: 155px;" @input="inputPrice"></el-input>
			设置单条付费价格
		</el-form-item>
	</div>
</template>

<script>
export default {
	name: "PriceVip",
	data() {
		return {
			vip_list: [],
			formData: {
				price: 0,
				user_vip_type: 'free'
			},
			priceType: 1,
			superId: [],
		}
	},
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		value: {
			type: [Object, null],
			default() {
				return {
					price: 0,
					// is_pay:1,
					user_vip_type: 'free'
				};
			}
		}
	},
	created() {
		let _data = {
			price: 0,
			// is_pay: 1,
			user_vip_type: 'free'
		}

		this.formData = this.value && this.value.user_vip_type ? this.value : _data;

		// this.formData.price = parseFloat(this.formData.price).toString();

		let _arr = ['9.9', '19.9', '3880'];

		if (!this.formData.price || _arr.indexOf(this.formData.price.toString()) < 0) {
			this.priceType = 4;
		} else {
			this.priceType = _arr.indexOf(this.formData.price.toString()) + 1;
		}

		this.$emit("change", this.formData);
	},
	watch: {
		value(n, o) {
			let _data = {
				price: 0,
				// is_pay: 1,
				user_vip_type: 'free'
			}

			this.formData = n ? n : _data;

			console.log(this.formData);

			// this.formData.price = this.formData.price ? parseFloat(this.formData.price).toString() : '';

			let _arr = ['9.9', '19.9', '3880'];

			if (!this.formData.price || _arr.indexOf(this.formData.price.toString()) < 0) {
				console.log('===')
				this.priceType = 4;
			} else {
				this.priceType = _arr.indexOf(this.formData.price.toString()) + 1;
			}
		}
	},
	mounted() {
		this.getVipType();
	},
	methods: {
		rulePriceNotZero(rule, value, callback){
			value = this.formData.price;
			if (!value) {
				return callback(new Error('价格不能为空'));
			}

			// let _v = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
			let _v = /((^[1-9][0-9]{0,6})|^0)(\.\d{0,2}){0,1}$/;

			console.log(_v.test(value))
			if (!_v.test(value)) {
				// return callback(new Error('请输入数字值'));
				//数字格式不正确 或 整数超过6位数
				return callback(new Error('价格格式不正确 或 整数超过6位数'));
			} else {
				if (parseFloat(value) <= 0) {
					return callback(new Error('价格必须大于0'));
				} else {
					return callback();
				}
			}
		},
		changeIsPay(e) {
			this.formData.is_pay = e;
			this.$emit("change", this.formData);
		},
		changePriceType(e) {
			if (e === 1) {
				this.formData.price = '9.9';
			} else if (e === 2) {
				this.formData.price = '19.9';
			} else if (e === 3) {
				this.formData.price = '3880';
			} else if (e === 4) {
				this.formData.price = '';
			}

			this.$emit("change", this.formData);
		},
		changeVipType(e) {
			this.formData.user_vip_type = e;

			if (e === 'free' || e === 'user' || this.superId.indexOf(this.formData.user_vip_type) < 0) {
				this.formData.price = '0';
			} else {
				this.formData.price = '9.9';
				this.priceType = 1;
			}

			this.$emit("change", this.formData);
		},
		inputPrice(e) {
			let _arr = ['9.9', '19.9', '3880'];
			if (_arr.indexOf(e) < 0) {
				this.priceType = 4;
			} else {
				this.priceType = _arr.indexOf(e) + 1;
			}

			this.formData.price = e;
			this.$emit("change", this.formData);
		},
		getVipType() {
			let _this = this;
			this.$http.httpPost({
				url: '/VipPrice/listAll',
				datas: {orderBy: 'sort', sortBy: 'asc', state: 1, pid: [0, '<=']},
				success: (res) => {
					console.log(res);
					let _list = [];
					let _ids = [];

					for (let i = 0; i < res.length; i++) {
						let _data = {id: res[i].id, name: res[i].title};

						_list.push(_data);

						_ids.push(res[i].id);

						if (res[i].is_super === 1) {
							_this.superId.push(res[i].id);
						}
					}

					// _list.push({id:_ids.join(','),name:'所有VIP'});
					_this.superId.push(_ids.join(','));

					_this.vip_list = _list;
				}
			})
		}
	}
}
</script>
