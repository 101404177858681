<template>
	<div class="bg-light padding-20">
<!--		{{dataForm}}-{{timesQuarter}}-->
		<el-form :model="dataForm" label-width="100px" ref="form">
			<el-form-item label="ID" v-if="dataForm.id">
				{{dataForm.id}}
			</el-form-item>
			<el-form-item label="季度" prop="year" :rules="[{required:true,message:'年度不能为空'}]">
				<quarter-picker v-model="timesQuarter" @change="onChangeTime"></quarter-picker>
			</el-form-item>
			<el-form-item label="描述">
				<clean-editor class="rel" style="z-index: 1;" v-model="dataForm.content1"></clean-editor>
			</el-form-item>
			<el-form-item>
				<el-button type="warning" @click="toAdd">添加项目</el-button>
			</el-form-item>
			<div class="cl" v-for="(tt,ii) in dataForm.supplierList" label-width="auto">
				<div class="z">
					<el-form-item label="项目名称" :prop="'supplierList.'+ii+'.name'" :rules="[{required:true,message:'项目名称不能为空'}]">
						<el-input v-model="tt.name" maxlength="30" show-word-limit style="width: 200px;"></el-input>
					</el-form-item>
				</div>
				<div class="z">
					<el-form-item label="月份" :prop="'supplierList.'+ii+'.month'" :rules="[{required:true,message:'月份不能为空'}]">
						<el-select v-model="tt.month" style="width: 200px;">
							<el-option :value="m" :label="m+'月'" v-for="m in ['1','2','3','4','5','6','7','8','9','10','11','12']"></el-option>
						</el-select>
					</el-form-item>
				</div>
				<div class="z">
					<el-form-item label="所在省份" :prop="'supplierList.'+ii+'.province_code'" :rules="[{required:true,message:'所在省份不能为空'}]">
						<province-select v-model="tt.province" style="width: 200px;" @change="(e)=>onChangeProvince(e,ii)"></province-select>
					</el-form-item>
				</div>
				<div class="z">
					<el-form-item label="状态" :prop="'supplierList.'+ii+'.status'" :rules="[{required:true,message:'状态不能为空'}]">
						<el-radio-group v-model="tt.status" style="width: 200px;">
							<el-radio label="stop">暂停</el-radio>
							<el-radio label="building">在建</el-radio>
						</el-radio-group>
					</el-form-item>
				</div>
				<div class="z">
					<el-form-item label="金额(亿元)" :prop="'supplierList.'+ii+'.price'" :rules="[{required:true,message:'金额不能为空'},{pattern:$regExp.money,message:'请填写正确的金额'}]">
						<el-input v-model="tt.price" style="width: 200px;"></el-input>
					</el-form-item>
				</div>
				<div class="z">
					<el-form-item label="项目介绍" :prop="'supplierList.'+ii+'.content'" :rules="[{required:true,message:'项目介绍不能为空'}]">
						<el-input type="textarea" :rows="4" v-model="tt.content" style="width: 500px;"></el-input>
						<el-button class="margin-left" type="danger" @click="toDel(ii)">删除</el-button>
					</el-form-item>
				</div>
			</div>
			<el-form-item>
				<el-button @click="$router.back()">返回</el-button>
				<el-button type="primary" @click="toSubmit">提交</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
export default {
	name: "edit",
	data() {
		return {
			dataForm:{
				id:'',
				content1:'',
				time_type:'quarter',
				times:'0',
				year:'',
				supplierList:[]
			},
			timesQuarter:''
		}
	},
	created() {
		this.dataForm.id = this.$route.query.id ? this.$route.query.id : ''
	},
	mounted() {
		if(this.dataForm.id){
			this.getDetail({id:this.dataForm.id});
		}
	},
	methods: {
		onChangeProvince(e,i){
			console.log(e);
			this.dataForm.supplierList[i].province_code = e.code;
			this.dataForm.supplierList[i].province_name = e.name;
		},
		onChangeTime(e) {
			console.log(e);
			this.dataForm.year = e.year;
			this.dataForm.times = e.times;
			this.dataForm.supplierList = [];
			this.dataForm.content1 = '';
			this.dataForm.id = '';
			this.getDetail({year:this.dataForm.year,times:this.dataForm.times,time_type:this.dataForm.time_type});
		},
		getDetail(where){
			let _this = this;
			this.$http.httpPost({
				url: '/HydataLengkuProject/detail',
				datas: where,
				success: (res) => {
					console.log(res);
					if(res){
						_this.dataForm.id = res.id;
						_this.dataForm.content1 = res.content1;
						_this.dataForm.times = res.times;
						_this.dataForm.year = res.year;
						_this.dataForm.supplierList = res.supplierList;
						_this.timesQuarter = res.year+'-'+res.times;
					}
				}
			})
		},
		toSubmit(){
			let _this = this;
			if(this.dataForm.supplierList.length <= 0){
				_this.$message.error('请添加厂商');
				return;
			}
			this.$refs.form.validate((valid) => {
				if (valid) {
					let _data = JSON.parse(JSON.stringify(_this.dataForm));
					_data.supplierList = JSON.stringify(_data.supplierList);
					_this.$http.httpPost({
						url: _data.id ? '/HydataLengkuProject/edit' : '/HydataLengkuProject/add',
						datas: _data,
						success: (res) => {
							_this.$message.success('提交成功');
							_this.$router.back();
						}
					})
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		toAdd(){
			let _data = {name:'',month:'',id:'',content:'',province:'',province_code:'',province_name:'',status:'',price:''};
			this.dataForm.supplierList.push(_data);
		},
		toDel(i){
			this.dataForm.supplierList.splice(i,1);
		}
	}
}
</script>
