<template>
    <el-button :type="type" :size="size" :icon="icon" :disabled="disabled">
        <slot></slot>
    </el-button>
</template>

<script>
export default {
    name: "BaseButton",
    data() {
        return {
            bSize: 'mini'
        }
    },
    props: {
        icon: '',
        type: '',
        size: {
            type: String,
            default: 'mini'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        size(n, o) {
            this.bSize = n;
        }
    },
}
</script>
