import Vue from 'vue';
let vm = new Vue();

import Config from './config'

export default {
    //逻辑判断
    callOperator(arg1, arg2, operator) {
        // console.log(arg1,arg2,operator);
        // console.log(arg1 === arg2);
        
        operator = this.isUndefined(operator) ? '===' : operator;
        
        switch (operator) {
            case '<':
                return arg1 < arg2;
            case '<=':
                return arg1 <= arg2;
            case '>':
                return arg1 > arg2;
            case '>=':
                return arg1 >= arg2;
            case '!==':
                return arg1 !== arg2;
            case 'tf':
                return !!arg1 === !!arg2;
            case 'in':
                return arg2.indexOf(arg1) >= 0;
            case 'not in':
                return arg2.indexOf(arg1) < 0;
            default:
                return arg1 === arg2;
        }
    },
    checkButtonRules(optionShow, scopeRow, rules) {
        // console.log(optionShow,scopeRow,rules);
        if (!this.isUndefined(optionShow)) {
            if (optionShow) {
                if (!this.isUndefined(rules)) {
                    // console.log(Object.prototype.toString.call(rules));
                    if (Object.prototype.toString.call(rules) === "[object Array]") {
                        let _out = [];
                        
                        for (let i = 0; i < rules.length; i++) {
                            let _check = this.callOperator(scopeRow[rules[i].key], rules[i].val, rules[i].operator ? rules[i].operator : '===');
                            _out.push(_check);
                        }
                        
                        // console.log(rules);
                        
                        for(let j=0;j < _out.length;j++){
                            if(!_out[j]){
                                return false;
                            }
                        }
                        return true;
                    } else {
                        return this.callOperator(scopeRow[rules.key], rules.val, rules.operator ? rules.operator : '===');
                    }
                }
                return true;
            }
            return false;
        } else {
            if (!this.isUndefined(rules)) {
                if (typeof rules === "[object Array]") {
                    let _out = true;
                    
                    for (let i = 0; i < rules.length; i++) {
                        _out = this.callOperator(scopeRow[rules[i].key], rules[i].val, rules[i].operator ? rules[i].operator : '===');
                    }
                    
                    return _out;
                } else {
                    return this.callOperator(scopeRow[rules.key], rules.val, rules.operator ? rules.operator : '===');
                }
            }
            return true;
        }
    },
    formatImagePath(path){
        if(!path){
            return ''
        }
        return path.indexOf('http') >= 0 ? path : Config.assetsUrl + path;
    },
    formatPagePath(path){
        if(!path){
            return ''
        }
        return path.indexOf('http') >= 0 ? path : Config.webUrl + path;
    },
    keepNumberTwoDecimal(num){
        let _res = parseFloat(num);

        if(isNaN(num)){
            return 0;
        }
        _res = Math.round(num * 100) / 100;

        return _res;
    },
    evalGet(obj,pre,target){
        let scope = obj;
        // return eval(pre+'.'+target);

        let _arr = target.split('.');

        // console.log(_arr);

        for (let i = 0;i < _arr.length;i++){
            if(scope) {
                if(scope[_arr[i]] === 0 || scope[_arr[i]] === '0' || scope[_arr[i]]) {
                    scope = scope[_arr[i]];
                }else{
                    scope = '';
                }
            }
        }

        return scope;
    },
    evalInfoGet(obj,pre,target){
        let info = obj;
        // return eval(pre+'.'+target);

        let _arr = target.split('.');

        for (let i = 0;i < _arr.length;i++){
            if(info) {
                info = info[_arr[i]] ? info[_arr[i]] : '';
            }
        }

        return info;
    },
    randomWords(n = 6){
        //创建26个字母数组
        let arr = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
        let idvalue ='';
        for(let i=0;i<n;i++){
            idvalue+=arr[Math.floor(Math.random()*26)];
        }
        return idvalue;
    },
    success(title){
        vm.$message({
            message: title,
            type: 'success'
        });
    },
    warning(title){
        vm.$message({
            message: title,
            type: 'warning'
        });
    },
    error(title){
        vm.$message({
            message: title,
            type: 'error'
        });
    },
    isWxImg(img) {
        if (img) {
            if (!/\.(gif|jpg|jpeg|png|GIF|JPEG|JPG|PNG)$/.test(img)) {
                return true;
            }

            return false;
        } else {
            return false;
        }
    },
    mustLetter(rule, value, callback){
        if (value === '') {
            return callback(new Error('不能为空'));
        }

        let _v = /[a-z0-9]$/;
        if (!_v.test(value)) {
            return callback(new Error('必须是小写字母或数字'));
        } else {
            return callback();
        }
    },
    mustTrim(rule,value,callback){
        if (value === '') {
            return callback(new Error('不能为空'));
        }

        value = value.replace(/^\s+|\s+$/gm,'');

        if (value === '') {
            return callback(new Error('不能为空'));
        }else{
            return callback();
        }
    },
    mustNormalWord(rule,value,callback){
        if (value === '') {
            return callback(new Error('不能为空'));
        }

        let _v = /^[\u4e00-\u9fa5a-zA-Z0-9\u3002\uff1b\uff0c\uff1a\u201c\u201d\uff08\uff09\u3001\uff1f\u300a\u300b\n\r\.,\[\]\(\)\!]+$/;

        if (!_v.test(value.replace(/(\r\n|\n|\r)/gm, '').trim())) {
            return callback(new Error('只能输入汉字、字母、数字和标点符号'));
        } else {
            return callback();
        }
    },
    mustNumber(rule, value, callback){
        if (value === '') {
            return callback(new Error('不能为空'));
        }

        let _v = /(^[1-9]\d*$)/;
        if (!_v.test(value)) {
            return callback(new Error('必须是整数'));
        } else {
            return callback();
        }
    },
    must09(rule, value, callback){
        if (value === '') {
            return callback(new Error('不能为空'));
        }

        // if (!Number.isInteger(value)) {
        //     return callback(new Error('请输入数字值'));
        // }

        if(value.length>1){
            let flagRex =/^0/;
            if(flagRex.test(value)){
                return callback(new Error('请输入正确的数字'));
            }
        }

        let _v = /(^[0-9]{1,8}$)/;
        if (!_v.test(value)) {
            return callback(new Error('必须是整数'));
        } else {
            return callback();
        }
    },
    biggerZero(rule, value, callback){
        if (value === '') {
            return callback(new Error('不能为空'));
        }

        if (!Number.isInteger(value) && typeof value !== 'number') {
            return callback(new Error('请输入数字值'));
        } else {
            if (value < 0) {
                return callback(new Error('必须>=0'));
            } else {
                return callback();
            }
        }
    },
    biggerZero2(rule, value, callback){
        if (value === '') {
            return callback(new Error('不能为空'));
        }

        // console.log(typeof value);
        if (!Number.isInteger(value) && typeof value !== 'number') {
            return callback(new Error('请输入数字值'));
        } else {
            if (value <= 0) {
                return callback(new Error('必须大于0'));
            } else {
                return callback();
            }
        }
    },
    rulePercent(rule, value, callback){
        if (value === '') {
            return callback(new Error('不能为空'));
        }

        let _v = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;

        if (!_v.test(value)) {
            return callback(new Error('请输入数字值'));
        } else {
            if (parseFloat(value) < 0 || parseFloat(value) > 100) {
                return callback(new Error('必须>=0 并且 <= 100'));
            } else {
                return callback();
            }
        }
    },
    rulePriceNotZero(rule, value, callback){
        console.log(rule, value)
        if (!value) {
            return callback(new Error('价格不能为空'));
        }

        // let _v = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
        let _v = /((^[1-9][0-9]{0,6})|^0)(\.\d{0,2}){0,1}$/;

        console.log(_v.test(value))
        if (!_v.test(value)) {
            // return callback(new Error('请输入数字值'));
            //数字格式不正确 或 整数超过6位数
            return callback(new Error('价格格式不正确 或 整数超过6位数'));
        } else {
            if (parseFloat(value) <= 0) {
                return callback(new Error('价格必须大于0'));
            } else {
                return callback();
            }
        }
    },
    rulePriceCanZero(rule, value, callback){
        if (!value) {
            return callback();
        }

        if(value) {
            // let _v = /^[0-9]{1,6}([.][0-9]{1,2})?$/;
            // let _v = /^([1-9][0-9]{0,5}(\.[0-9])?)|(0\.[1-9])$/;
            let _v = /((^[1-9][0-9]{0,6})|^0)(\.\d{0,2}){0,1}$/;

            if (parseFloat(value) < 0) {
                return callback(new Error('必须大于0'));
            } else {
                if (!_v.test(value)) {
                    return callback(new Error('数字格式不正确 或 整数超过7位数'));
                } else {
                    return callback();
                }
            }
        }
    },
    calcDescartes (array) {

        let res = [];
        let _arr = [];
        let skus = [];

        for(let i=0;i < array.length;i++){
            console.log(array[i].spec_value.length);
            if(array[i].spec_value.length > 0) {
                _arr.push(array[i].spec_value);
            }
        }


        if(_arr.length > 0) {
            if (_arr.length < 2) {

                for (let i = 0; i < _arr[0].length; i++) {
                    res.push([_arr[0][i]]);
                }
            } else {
                res = [].reduce.call(_arr, function (col, set) {
                    let _res = [];

                    col.forEach(function (c) {
                        set.forEach(function (s) {
                            let t = [].concat(Array.isArray(c) ? c : [c]);
                            t.push(s);
                            _res.push(t);

                        })
                    });

                    return _res;
                });
            }



            for (let k = 0; k < res.length; k++) {
                let _sku = [];
                for (let j = 0; j < res[k].length; j++) {
                    _sku.push({
                        id: array[j].id,
                        name: array[j].name,
                        value_id: res[k][j].id,
                        value_name: res[k][j].name
                    });
                }
                skus.push({spec: _sku, price: 0.00, stock: 0, code: '', img: ''});
            }

            return skus;
        }

        return skus;
    },
    checkParams(rule,val){
        for(let item in rule){
            if(rule[item].check === 'require'){
                if(this.isUndefined(val[item].trim())){
                    console.error('param '+item+' must be require!');
                    return false;
                }
            }

            if(typeof val[item] !== rule[item].type && !this.isUndefined(val[item])){
                console.error('param '+item+' must be '+rule[item].type+'!');
                return false;
            }
        }
        return true;
    },
    formatPrice(price){
        return (Math.floor(Number(price) * 100) / 100).toFixed(2);
    },
    isUndefined(variable) {
        return (typeof variable == 'undefined' || variable === null || variable === '');
    },
    sessionSet(key,val){
        if((typeof val === 'string' || typeof val === 'number') && typeof key === 'string') {
            return sessionStorage.setItem(key, val);
        }else{
            console.error('value & key must be a string!');
        }
        return false;
    },
    sessionGet(key){
        if(typeof key === 'string'){
            let _val = sessionStorage.getItem(key);

            return _val ? _val : '';
        }else{
            console.error('key must be a string!');
        }
        return false;
    },
    sessionDel(key){
        if(typeof key === 'string'){
            return sessionStorage.removeItem(key);
        }else{
            console.error('key must be a string!');
        }
        return false;
    },
    sessionClear(){
        return sessionStorage.clear();
    },
    copy(data){
        let oInput = document.createElement('input');
        oInput.value = data;
        oInput.classList.add('copy-input');
        document.body.appendChild(oInput);
        vm.$nextTick(() => {
            oInput.select(); // 选择对象;
            console.log(oInput.value);
            document.execCommand("Copy"); // 执行浏览器复制命令
            vm.$message({
                message: '复制成功',
                type: 'success'
            });
            oInput.remove();
        });
    },
    // rsaEncode(content){
    //
    //     // let encryptor = new JSEncrypt()
    //     //
    //     // encryptor.setPublicKey(key)
    //     //
    //     // let cptData = encryptor.encryptLong(data)
    //     //
    //     // return cptData
    //
    //     let encrypeJs = new JSEncrypt();
    //     encrypeJs.setPublicKey(Config.publicKey);
    //     // return encrypeJs.encryptLong(content);
    //     return encrypeJs.encrypt(content);
    // },
    // rsaDecode(content){
    //     let encrypeJs = new JSEncrypt();
    //     encrypeJs.setPublicKey(Config.publicKey);
    //     return encrypeJs.decrypt(content);
    // }
}
