<template>
	<el-popover
		v-model="visible"
		placement="bottom"
		width="400"
		@show="onShow"
		trigger="click" popper-class="diy-popover">
		<div class="">
			<div class="el-picker-panel__body-wrapper">
				<div class="el-picker-panel__body">
					<div class="el-date-picker__header el-date-picker__header--bordered">
						<button type="button" aria-label="前一年" class="el-picker-panel__icon-btn el-date-picker__prev-btn el-icon-d-arrow-left" @click="lastYear"></button>
						<span role="button" class="el-date-picker__header-label">{{tmpYear}} 年</span>
						<button type="button" aria-label="后一年" class="el-picker-panel__icon-btn el-date-picker__next-btn el-icon-d-arrow-right" @click="nextYear"></button>
					</div>
					<div class="el-picker-panel__content">
						<table class="el-month-table" style="width: 100%;">
							<tbody>
							<tr>
								<td :class="times === '01' && year === tmpYear ? 'in-range start-date end-date' : ''">
									<div><a class="cell" style="margin:0 8px;width: unset !important;" @click="choose('01')">上半年</a></div>
								</td>
								<td :class="times === '02' && year === tmpYear ? 'in-range start-date end-date' : ''">
									<div><a class="cell" style="margin:0 8px;width: unset !important;" @click="choose('02')">下半年</a></div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<el-input prefix-icon="el-icon-date" slot="reference" placeholder="请选择日期" clearable readonly class="hand" style="max-width: 200px;" v-model="text"></el-input>
	</el-popover>
</template>

<script>
export default {
	name: "HalfPicker",
	data() {
		return {
			visible:false,
			year:'',
			tmpYear:'',
			times:'',
			tmpTimes:'',
			text:'',
			halfArr:{'01':'上半年','02':'下半年'}
		}
	},
	props:{
		value:{
			type:String,
			default:''
		},
		now:{
			type:Boolean,
			default:false
		}
	},
	model:{
		prop:'value',
		event:'input'
	},
	created() {
		this.tmpYear = this.$moment().year();

		if(this.value){
			let _tmp = this.value.split('-');
			this.year = _tmp[0];
			this.tmpYear = _tmp[0];
			this.times = _tmp[1];
			this.text = this.year + '年 ' + (this.halfArr[this.times]);
		}else if(this.now){
			this.year = this.$moment().year();
			this.tmpYear = this.$moment().year();
			this.times = this.$moment().month() <= 5 ? '01' : '02';

			this.text = this.year + '年 ' + (this.halfArr[this.times]);

			this.$emit('input',this.year+'-'+this.times);
			// this.$emit('change',{year:this.year,times:this.times});
		}
	},
	mounted() {
	},
	methods: {
		lastYear(){
			this.tmpYear = parseInt(this.tmpYear) - 1
		},
		nextYear(){
			this.tmpYear = parseInt(this.tmpYear) + 1
		},
		choose(e){
			this.times = e;
			this.year = this.tmpYear
			this.text = this.year + '年 ' + (this.halfArr[this.times]);
			this.$emit('input',this.year+'-'+this.times);
			this.$emit('change',{year:this.year,times:this.times});
			this.visible = false;
		},
		onShow(){
			if(this.year){
				this.tmpYear = this.year;
			}
		}
	},
	watch:{
		value(n,o){
			if(n){
				let _tmp = n.split('-');
				this.year = _tmp[0];
				this.tmpYear = _tmp[0];
				this.times = _tmp[1];
				this.text = this.year + '年 ' + (this.halfArr[this.times]);
				// this.$emit('change',{year:this.year,times:this.times});
			}
		}
	}
}
</script>
