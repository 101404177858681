<template>
    <el-date-picker
			style="width: auto;"
			:editable="false"
			:clearable="false"
			v-model="values"
			append-to-body
			type="month"
			value-format="yyyy-MM"
			format="yyyy 年 MM 月"
			@change="change"
			placeholder="选择月">
    </el-date-picker>
</template>

<script>
export default {
    name: "MonthPicker",
    data(){
        return{
            list:[],
            values:''
        }
    },
    model:{
        prop:'value',
        event:'input'
    },
    props:{
        value:{
            type:String,
            default:''
        },
			now:{
            type:Boolean,
            default:false
        }
    },
    created() {
        if(this.value){
					this.values = this.value;
				}else if(this.now){
					this.values = this.$moment().format('YYYY-MM')
					this.$emit('input',this.values);
					// this.$emit('change',{year:this.$moment(this.values).format('YYYY'),times:this.$moment(this.values).format('MM')});
				}
    },
    watch:{
        value(n,o){
            this.values = n ? n : '';
        }
    },
    mounted() {

    },
    methods:{
        change(e){
					this.$emit("input", e);
					this.$emit('change',{year:this.$moment(e).format('YYYY'),times:this.$moment(e).format('MM')});
        }
    }
}
</script>
