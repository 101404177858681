<template>
    <el-date-picker
        v-model="values"
        type="daterange"
        align="right"
        unlink-panels
        :clearable="false"
        :editable="false"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        @change="change">
    </el-date-picker>
</template>

<script>
export default {
    name: "RangeTime",
    data(){
        return{
            values:'',
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props: {
        value: {
            type:String,
            default: ''
        },
    },
    created() {
        if(this.value){
            let _times = this.value.split(',');
            let _timeArr = [];
            _timeArr.push(this.$moment(_times[0]).toDate());
            _timeArr.push(this.$moment(_times[1]).toDate());

            this.values = _timeArr;

        }else{
            this.values = ''
        }
        // this.values = this.value ? this.$moment(this.value).toDate() : '';
    },
    watch:{
        value(n,o){
            // this.values = n ? this.$moment(n).toDate() : '';
            if(n && JSON.stringify(n) !== JSON.stringify(o)){
                let _times = n.split(',');
                let _timeArr = [];

                if(_times.length > 0) {
                  _timeArr.push(_times[0] ? this.$moment(_times[0]).toDate() : '');
                  _timeArr.push(_times[1] ? this.$moment(_times[1]).toDate() : '');
                }

                this.values = _timeArr;

            }else{
                this.values = ''
            }
        },
    },
    methods:{
        change(e){
            let _year = e[0].getFullYear();
            let _month = e[0].getMonth() + 1;
            let _day = e[0].getDate();

            let _year2 = e[1].getFullYear();
            let _month2 = e[1].getMonth() + 1;
            let _day2 = e[1].getDate();

            this.$emit('change',_year+'-'+_month+'-'+_day+','+_year2+'-'+_month2+'-'+_day2);
        }
    }
}
</script>
