export default {
  '/hydata/lenglian': {
    isData: true,
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: false,
    putUrl: '/HydataLenglian',
    putDemo: '/lenglian_demo.xlsx',
    name: '冷链物流数据',
    apiUrl: '/HydataLenglian',
    listRouter: '/hydata/lenglian',
    editRouter: '/hydata/lenglian/edit',
    listData: {orderBy: 'year', sortBy: 'desc'},
    list: {
      com: '',
      params: [
        // {name: 'ID', value: 'id'},
        {name: '时间', value: 'timeStr', width: 60},
        // {name: '冷链物流市场规模(亿元)', value: 'wlsc_amount'},
        // {name: '冷链物流市场规模增长率(%)', value: 'wlsc_rate'},
        {name: '冷链物流总额(万亿元)', value: 'lenglian_amount'},
        {name: '增长率(%)', value: 'lenglian_rate'},
        {name: '冷链物流总收入(亿元)', value: 'shehui_amount'},
        {name: '增长率(%)', value: 'shehui_rate'},
        {name: '冷链物流总需求(万吨)', value: 'total'},
        {name: '增长率(%)', value: 'rate'},
        // {name: '蔬菜', value: 'shucai'},
        // {name: '水果', value: 'shuiguo'},
        // {name: '肉制品', value: 'rouzhipin'},
        // {name: '水产品', value: 'shuichanpin'},
        // {name: '乳制品', value: 'ruzhipin'},
        // {name: '速冻食品', value: 'sudongshipin'},
        {
          name: '数量(万吨)', value: 'order_no', width: '200',
          tem: [
            {name: '蔬菜', val: 'shucai'},
            {name: '水果', val: 'shuiguo'},
            {name: '肉制品', val: 'rouzhipin'},
            {name: '水产品', val: 'shuichanpin'},
            {name: '乳制品', val: 'ruzhipin'},
            {name: '速冻食品', val: 'sudongshipin'}
          ]
        },
      ]
    },
    edit: {
      com: '',
      // chooseYear: true,
      // editData: {time_type: 'quarter'},
      chooseYearQuarter: true,
      params: [
        // {name:'年份',value:'year',rq:true,sc:true,edit:true},
        {name: '时间类型', value: 'time_type', type: 'radioGroup',disabledById:true, rq: true, sc: true, edit: true,options:[{label:'年度',value:'year'},{label:'季度',value:'quarter'}],notReset:true,resetColumn:['year','times','timesQuarter']},
        {name: '季度', value: 'year', type: 'quarterPicker', rq: true, sc: true,edit:true, show:(row)=>{
            if(row.time_type === 'quarter'){
              return true;
            }else{
              return false;
            }
          }},
        {name: '年度', value: 'year', type: 'yearPicker', rq: true, sc: true, edit:true, show:(row)=>{
            if(row.time_type === 'year'){
              return true;
            }else{
              return false;
            }
          }},
        // {name: '冷链物流市场规模', value: 'wlsc_amount', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元',show:(row)=>{
        //     if(row.time_type){
        //       return true;
        //     }else{
        //       return false;
        //     }
        //   }},
        {name: '描述文字1', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,show:(row)=>{
            if(row.time_type){
              return true;
            }else{
              return false;
            }
          }},
        {name: '冷链物流总额', value: 'lenglian_amount', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元',show:(row)=>{
            if(row.time_type){
              return true;
            }else{
              return false;
            }
          }},
        {name: '冷链物流总收入', value: 'shehui_amount', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元',show:(row)=>{
            if(row.time_type){
              return true;
            }else{
              return false;
            }
          }},
        {name: '描述文字2', value: 'content2', type: 'cleanEditor', rq: false, sc: false, edit: true,show:(row)=>{
            if(row.time_type){
              return true;
            }else{
              return false;
            }
          }},
        {name: '蔬菜冷链需求量', value: 'shucai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨',show:(row)=>{
            if(row.time_type){
              return true;
            }else{
              return false;
            }
          }},
        {name: '水果冷链需求量', value: 'shuiguo', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨',show:(row)=>{
            if(row.time_type){
              return true;
            }else{
              return false;
            }
          }},
        {name: '肉类冷链需求量', value: 'rouzhipin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨',show:(row)=>{
            if(row.time_type){
              return true;
            }else{
              return false;
            }
          }},
        {name: '水产品冷链需求量', value: 'shuichanpin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨',show:(row)=>{
            if(row.time_type){
              return true;
            }else{
              return false;
            }
          }},
        {name: '乳制品冷链需求量', value: 'ruzhipin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨',show:(row)=>{
            if(row.time_type){
              return true;
            }else{
              return false;
            }
          }},
        {name: '速冻产品冷链需求量', value: 'sudongshipin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨',show:(row)=>{
            if(row.time_type){
              return true;
            }else{
              return false;
            }
          }},
      ]
    }
  },
  
  '/hydata/lenglian/year': {
    isData: true,
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    putUrl: '/HydataLenglian',
    putDemo: '/import/宏观冷链导入模版[年度].xlsx',
    pushTip: '季度、年度的导入模版有区别，请仔细确认模版是否正确',
    name: '宏观冷链',
    apiUrl: '/HydataLenglian',
    listRouter: '/hydata/lenglian/year',
    editRouter: '/hydata/lenglian/year/edit',
    listData: {orderBy: 'year', sortBy: 'desc',time_type:'year'},
    putData: {time_type: 'year'},
    list: {
      com: '',
      params: [
        {name: '时间', value: 'timeStr', width: 60},
        {name: '冷链物流总额(万亿元)', value: 'lenglian_amount'},
        {name: '增长率(%)', value: 'lenglian_rate'},
        {name: '冷链物流总收入(亿元)', value: 'shehui_amount'},
        {name: '增长率(%)', value: 'shehui_rate'},
        {name: '冷链物流总需求(万吨)', value: 'wlsc_amount'},
        {name: '增长率(%)', value: 'wlsc_rate'},
        {
          name: '数量(万吨)', value: 'order_no', width: '200',
          tem: [
            {name: '蔬菜', val: 'shucai'},
            {name: '水果', val: 'shuiguo'},
            {name: '肉制品', val: 'rouzhipin'},
            {name: '水产品', val: 'shuichanpin'},
            {name: '乳制品', val: 'ruzhipin'},
            {name: '速冻食品', val: 'sudongshipin'}
          ]
        },
      ]
    },
    edit: {
      com: '',
      editData: {time_type: 'year'},
      chooseYearQuarter: true,
      cols:2,
      params: [
        {name: '年度', value: 'year', type: 'yearPicker', rq: true, sc: true, edit:true,cols:1},
        {name: '冷链物流总额描述', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '冷链物流总额', value: 'lenglian_amount', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万亿元'},
        {name: '同比', value: 'lenglian_rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '冷链物流总收入描述', value: 'content2', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '冷链物流总收入', value: 'shehui_amount', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元'},
        {name: '同比', value: 'shehui_rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '冷链物流总需求描述', value: 'content3', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '冷链物流总需求', value: 'wlsc_amount', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'wlsc_rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '细分品类需求描述', value: 'content4', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '蔬菜冷链需求量', value: 'shucai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '水果冷链需求量', value: 'shuiguo', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '肉类冷链需求量', value: 'rouzhipin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '水产品冷链需求量', value: 'shuichanpin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '乳制品冷链需求量', value: 'ruzhipin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '速冻产品冷链需求量', value: 'sudongshipin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
      ]
    }
  },
  
  '/hydata/lenglian/quarter': {
    isData: true,
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    showAutoMakeYear: true,
    autoMakeGetYearUrl:'/HydataLenglian/getYearQuarter',
    autoMakeGetYearData:{time_type:'quarter'},
    autoMakeSubmitUrl:'/HydataLenglian/autoMakeYearData',
    autoMakeSubmitData:{time_type:'quarter',times:[5,'<']},
    putUrl: '/HydataLenglian',
    putDemo: '/import/宏观冷链导入模版[季度].xlsx',
    pushTip: '季度、年度的导入模版有区别，请仔细确认模版是否正确',
    name: '宏观冷链',
    apiUrl: '/HydataLenglian',
    listRouter: '/hydata/lenglian/quarter',
    editRouter: '/hydata/lenglian/quarter/edit',
    editRules:{key:'times',val:5,operator:'!=='},
    delRules:{key:'times',val:5,operator:'!=='},
    listData: {orderBy: 'year desc,times', sortBy: 'asc',time_type:'quarter'},
    putData: {time_type: 'quarter'},
    list: {
      com: '',
      params: [
        {name: '时间', value: 'timeStr', width: 60},
        {name: '冷链物流总额(万亿元)', value: 'lenglian_amount'},
        {name: '增长率(%)', value: 'lenglian_rate'},
        {name: '冷链物流总收入(亿元)', value: 'shehui_amount'},
        {name: '增长率(%)', value: 'shehui_rate'},
        {name: '冷链物流总需求(万吨)', value: 'wlsc_amount'},
        {name: '增长率(%)', value: 'wlsc_rate'},
        // {
        //   name: '数量(万吨)', value: 'order_no', width: '200',
        //   tem: [
        //     {name: '蔬菜', val: 'shucai'},
        //     {name: '水果', val: 'shuiguo'},
        //     {name: '肉制品', val: 'rouzhipin'},
        //     {name: '水产品', val: 'shuichanpin'},
        //     {name: '乳制品', val: 'ruzhipin'},
        //     {name: '速冻食品', val: 'sudongshipin'}
        //   ]
        // },
      ]
    },
    edit: {
      com: '',
      editData: {time_type: 'quarter'},
      chooseYearQuarter: true,
      cols:2,
      params: [
        {name: '季度', value: 'year', type: 'quarterPicker', rq: true, sc: true, edit:true,cols:1},
        {name: '冷链物流总额描述', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '冷链物流总额', value: 'lenglian_amount', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万亿元'},
        {name: '同比', value: 'lenglian_rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '冷链物流总收入描述', value: 'content2', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '冷链物流总收入', value: 'shehui_amount', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元'},
        {name: '同比', value: 'shehui_rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '冷链物流总需求描述', value: 'content3', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '冷链物流总需求', value: 'wlsc_amount', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'wlsc_rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        // {name: '描述文字2', value: 'content2', type: 'textarea', rq: false, sc: false, edit: true,cols:1},
        // {name: '蔬菜冷链需求量', value: 'shucai', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        // {name: '水果冷链需求量', value: 'shuiguo', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        // {name: '肉类冷链需求量', value: 'rouzhipin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        // {name: '水产品冷链需求量', value: 'shuichanpin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        // {name: '乳制品冷链需求量', value: 'ruzhipin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        // {name: '速冻产品冷链需求量', value: 'sudongshipin', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        // {name: '同比', value: 'wlsc_rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
      ]
    }
  },
  
  '/hydata/lenglian/shebei/quarter': {
    isData: true,
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: false,
    putUrl: '/HydataLenglianShebei',
    putDemo: '/lenglian_demo.xlsx',
    name: '冷链设施设备',
    apiUrl: '/HydataLenglianShebei',
    listRouter: '/hydata/lenglian/shebei/quarter',
    editRouter: '/hydata/lenglian/shebei/quarter/edit',
    editRules:{key:'times',val:5,operator:'!=='},
    delRules:{key:'times',val:5,operator:'!=='},
    listData: {orderBy: 'year', sortBy: 'desc',time_type:'quarter'},
    putData: {time_type: 'quarter'},
    list: {
      com: '',
      params: [
        {name: '季度', value: 'year', type: 'tongjiTime'},
        {name: '叉车市场规模（亿元）', value: 'amount'},
        {name: '同比(%)', value: 'amount_rate'},
        {name: '货架市场规模（亿元）', value: 'amount2'},
        {name: '同比(%)', value: 'amount2_rate'},
        {name: '压缩机市场规模（亿元）', value: 'amount3'},
        {name: '同比(%)', value: 'amount3_rate'},
        {name: '冷机市场规模（亿元）', value: 'amount4'},
        {name: '同比(%)', value: 'amount4_rate'},
        {name: '展示柜市场规模（亿元）', value: 'amount5'},
        {name: '同比(%)', value: 'amount5_rate'},
      ]
    },
    edit: {
      com: '',
      cols: 2,
      editData: {time_type: 'quarter'},
      chooseYearQuarter: true,
      labelWidth:'200px',
      params: [
        {name: '季度', value: 'year', type: 'quarterPicker', rq: true, sc: true, edit:true,cols:1},
        {name: '描述文字1', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '叉车市场规模（亿元）', value: 'amount', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元'},
        {name: '同比', value: 'amount_rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '货架市场规模（亿元）', value: 'amount2', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元'},
        {name: '同比', value: 'amount2_rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '压缩机市场规模（亿元）', value: 'amount3', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元'},
        {name: '同比', value: 'amount3_rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '冷机市场规模（亿元）', value: 'amount4', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元'},
        {name: '同比', value: 'amount4_rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '展示柜市场规模（亿元）', value: 'amount5', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元'},
        {name: '同比', value: 'amount5_rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
      ]
    }
  },
  
  '/hydata/lenglian/shebei/year': {
    isData: true,
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    putUrl: '/HydataLenglianShebei',
    putDemo: '/import/冷链设备导入模版[年度].xlsx',
    name: '冷链设施设备',
    apiUrl: '/HydataLenglianShebei',
    listRouter: '/hydata/lenglian/shebei/year',
    editRouter: '/hydata/lenglian/shebei/year/edit',
    listData: {orderBy: 'year', sortBy: 'desc',time_type:'year'},
    putData: {time_type: 'year'},
    list: {
      com: '',
      params: [
        {name: '季度', value: 'year', type: 'tongjiTime'},
        {name: '叉车市场规模（亿元）', value: 'amount'},
        {name: '同比(%)', value: 'amount_rate'},
        {name: '货架市场规模（亿元）', value: 'amount2'},
        {name: '同比(%)', value: 'amount2_rate'},
        {name: '压缩机市场规模（亿元）', value: 'amount3'},
        {name: '同比(%)', value: 'amount3_rate'},
        {name: '冷机市场规模（亿元）', value: 'amount4'},
        {name: '同比(%)', value: 'amount4_rate'},
        {name: '展示柜市场规模（亿元）', value: 'amount5'},
        {name: '同比(%)', value: 'amount5_rate'},
      ]
    },
    edit: {
      com: '',
      editData: {time_type: 'year'},
      chooseYearQuarter: true,
      labelWidth:'200px',
      cols:2,
      params: [
        {name: '年度', value: 'year', type: 'yearPicker', rq: true, sc: true, edit:true,cols:1},
        {name: '描述文字1', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '叉车市场规模（亿元）', value: 'amount', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元'},
        {name: '同比', value: 'amount_rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '货架市场规模（亿元）', value: 'amount2', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元'},
        {name: '同比', value: 'amount2_rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '压缩机市场规模（亿元）', value: 'amount3', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元'},
        {name: '同比', value: 'amount3_rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '冷机市场规模（亿元）', value: 'amount4', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元'},
        {name: '同比', value: 'amount4_rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '展示柜市场规模（亿元）', value: 'amount5', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元'},
        {name: '同比', value: 'amount5_rate', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
      ]
    }
  },
}
