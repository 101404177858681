<template>
    <el-container>
        <el-aside width="200px" style="background-color: #304156;padding-top:60px;overflow-y: hidden !important;" class="rel hide">
          <div style="height: 60px;top:0;width:100%;z-index: 2" class="cl padding abs">
                <img src="/images/logo.png" height="40" class="z"/>
                <div class="z light margin-left">
                    <div class="f-b f-lq" style="line-height: 40px;">链云数据平台</div>
<!--                    <div class="f-b f-lq">冷链委</div>-->
<!--                    <div>数据管理后台</div>-->
                </div>
            </div>
            <!--        <m-side-menu :side-collapse="showSideMenu"/>-->
          <m-side-menu :side-collapse="showSideMenu" class="el-menu-vertical-demo" style="min-height: unset !important;"/>
        </el-aside>
        <el-container>
            <el-header class="top-nav" style="line-height:60px;">
                <div class="cl">
<!--                    <div class="z">-->
<!--            <span class="nav-item" @click="toggleSide"-->
<!--            ><i class="el-icon-s-fold f20" style="vertical-align: middle;"-->
<!--            /></span>-->
<!--                    </div>-->
                    <div class="z margin-left">
                        <m-breadcrumb />
                    </div>
                    <div class="y">
                        <span class="nav-item">{{ $user.getUserName() }}</span>
                        <a @click="$router.push({path:'/changePass'})" class="nav-item">修改密码</a>
                        <span class="nav-item" @click="quit()">退出</span>
                    </div>
                </div>
            </el-header>
            <el-main class="bg-gray-3">
<!--                <div class="bg-light padding-20">-->
                    <router-view :key="$route.fullPath"/>
<!--                </div>-->
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
export default {
    name: "Home",
    data() {
        return {
            showSideMenu: true
        };
    },
    created() {
        document.querySelector('body').style.backgroundImage = '';
    },
    methods: {
        toggleSide() {
            this.showSideMenu = !this.showSideMenu;
        },
        quit() {
            this.$utils.sessionClear();
            this.$router.replace({ path: "/login" });
        }
    }
};
</script>
<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    min-width: 200px;
    min-height: 400px;
}
</style>
