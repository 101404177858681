export default {
  '/hydata/inout/month': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    showAutoMakeYear: true,
    autoMakeGetYearUrl:'/HydataInout/getYearQuarter',
    autoMakeGetYearData:{time_type:'month'},
    autoMakeSubmitUrl:'/HydataInout/autoMakeYearData',
    autoMakeSubmitData:{time_type:'month',times:[13,'<']},
    putUrl: '/HydataInout',
    putDemo: '/import/进出口总值导入模版[月度].xlsx',
    pushTip: '月度、年度的导入模版有区别，请仔细确认模版是否正确',
    name: '进出口总值',
    apiUrl: '/HydataInout',
    listRouter: '/hydata/inout/month',
    editRouter: '/hydata/inout/month/edit',
    editRules:{key:'times',val:13,operator:'!=='},
    delRules:{key:'times',val:13,operator:'!=='},
    listData: { orderBy: 'year desc,times', sortBy: 'asc',time_type:'month'},
    putData: {time_type: 'month'},
    list: {
      com: '',
      params: [
        {name: '月度', value: 'year', type: 'tongjiTime'},
        {name: '进出口总值(亿元)', value: 'amount_inout'},
        {name: '同比(%)', value: 'tongbi_inout'},
        {name: '进口总值(亿元)', value: 'amount_in'},
        {name: '同比(%)', value: 'tongbi_in'},
        {name: '出口总值(亿元)', value: 'amount_out'},
        {name: '同比(%)', value: 'tongbi_out'},
      ]
    },
    edit: {
      editData: {time_type: 'month'},
      cols: 2,
      chooseYearQuarter: true,
      labelWidth: '100px',
      inputWidth: 'auto',
      com: '',
      // chooseYear: true,
      params: [
        {name: '月度', value: 'year', type: 'monthPicker', rq: true, sc: true, edit:true,cols:1},
        {name: '描述', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '进出口总值', value: 'amount_inout', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元'},
        {name: '同比', value: 'tongbi_inout', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '进口总值', value: 'amount_in', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元'},
        {name: '同比', value: 'tongbi_in', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '出口总值', value: 'amount_out', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元'},
        {name: '同比', value: 'tongbi_out', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
      ]
    }
  },
  '/hydata/inout/year': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    putUrl: '/HydataInout',
    putDemo: '/import/进出口总值导入模版[年度].xlsx',
    pushTip: '月度、年度的导入模版有区别，请仔细确认模版是否正确',
    name: '进出口总值',
    apiUrl: '/HydataInout',
    listRouter: '/hydata/inout/year',
    editRouter: '/hydata/inout/year/edit',
    listData: { orderBy: 'year', sortBy: 'desc',time_type:'year'},
    putData: {time_type: 'year'},
    list: {
      com: '',
      params: [
        {name: '年份', value: 'year', type: 'tongjiTime'},
        {name: '进出口总值(亿元)', value: 'amount_inout'},
        {name: '同比(%)', value: 'tongbi_inout'},
        {name: '进口总值(亿元)', value: 'amount_in'},
        {name: '同比(%)', value: 'tongbi_in'},
        {name: '出口总值(亿元)', value: 'amount_out'},
        {name: '同比(%)', value: 'tongbi_out'},
      ]
    },
    edit: {
      editData: {time_type: 'year'},
      cols: 2,
      chooseYearQuarter: true,
      labelWidth: '100px',
      inputWidth: 'auto',
      com: '',
      // chooseYear: true,
      params: [
        {name: '年度', value: 'year', type: 'yearPicker', rq: true, sc: true, edit:true,cols:1,props:{
            pickerOptions:{
              disabledDate:(v)=>{
                let year = new Date(v).getFullYear();
                return year >= 2024
              }
            }
          }},
        {name: '描述', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '进出口总值', value: 'amount_inout', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元'},
        {name: '同比', value: 'tongbi_inout', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '进口总值', value: 'amount_in', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元'},
        {name: '同比', value: 'tongbi_in', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '出口总值', value: 'amount_out', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '亿元'},
        {name: '同比', value: 'tongbi_out', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
      ]
    }
  },
  
  '/hydata/inout/type/month': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    showAutoMakeYear: true,
    autoMakeGetYearUrl:'/HydataInoutType/getYearQuarter',
    autoMakeGetYearData:{time_type:'month'},
    autoMakeSubmitUrl:'/HydataInoutType/autoMakeYearData',
    autoMakeSubmitData:{time_type:'month',times:[13,'<']},
    putUrl: '/HydataInoutType',
    putDemo: '/import/进出口品类导入模版[月度].xlsx',
    pushTip: '月度、年度的导入模版有区别，请仔细确认模版是否正确',
    name: '各品类进出口',
    apiUrl: '/HydataInoutType',
    listRouter: '/hydata/inout/type/month',
    editRouter: '/hydata/inout/type/month/edit',
    editRules:{key:'times',val:13,operator:'!=='},
    delRules:{key:'times',val:13,operator:'!=='},
    listData: { orderBy: 'year desc,times', sortBy: 'asc',time_type:'month'},
    putData: {time_type: 'month'},
    list: {
      com: '',
      params: [
        {name: '月度', value: 'year', type: 'tongjiTime',width: 150},
        {name: '蔬菜及制品进口(万吨)', value: 'shucai_in',width:160},
        {name: '同比(%)', value: 'tongbi_shucai_in'},
        {name: '蔬菜及制品出口(万吨)', value: 'shucai_out',width:160},
        {name: '同比(%)', value: 'tongbi_shucai_out'},
        {name: '水果及制品进口(万吨)', value: 'shuiguo_in',width:160},
        {name: '同比(%)', value: 'tongbi_shuiguo_in'},
        {name: '水果及制品出口(万吨)', value: 'shuiguo_out',width:160},
        {name: '同比(%)', value: 'tongbi_shuiguo_out'},
        {name: '肉类及制品进口(万吨)', value: 'rou_in',width:160},
        {name: '同比(%)', value: 'tongbi_rou_in'},
        {name: '肉类及制品出口(万吨)', value: 'rou_out',width:160},
        {name: '同比(%)', value: 'tongbi_rou_out'},
        {name: '水海产品及制品进口(万吨)', value: 'shuichanpin_in',width:200},
        {name: '同比(%)', value: 'tongbi_shuichanpin_in'},
        {name: '水海产品及制品出口(万吨)', value: 'shuichanpin_out',width:200},
        {name: '同比(%)', value: 'tongbi_shuichanpin_out'},
        {name: '乳制品进口(万吨)', value: 'ruzhipin_in',width:160},
        {name: '同比(%)', value: 'tongbi_ruzhipin_in'},
        {name: '乳制品出口(万吨)', value: 'ruzhipin_out',width:160},
        {name: '同比(%)', value: 'tongbi_ruzhipin_out'},
      ]
    },
    edit: {
      editData: {time_type: 'month'},
      cols: 4,
      chooseYearQuarter: true,
      labelWidth: '150px',
      inputWidth: 'auto',
      com: '',
      // chooseYear: true,
      params: [
        {name: '月度', value: 'year', type: 'monthPicker', rq: true, sc: true, edit:true,cols:1,
          props:{
            pickerOptions:{
              disabledDate:(v)=>{
                let year = new Date(v).getFullYear();
                return year >= 2024
              }
            }
          }
        },
        {name: '描述', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '蔬菜及制品进口', value: 'shucai_in', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_shucai_in', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '蔬菜及制品出口', value: 'shucai_out', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_shucai_out', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '水果及制品进口', value: 'shuiguo_in', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_shuiguo_in', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '水果及制品出口', value: 'shuiguo_out', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_shuiguo_out', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '肉类及制品进口', value: 'rou_in', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_rou_in', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '肉类及制品出口', value: 'rou_out', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_rou_out', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '水海产品及制品进口', value: 'shuichanpin_in', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_shuichanpin_in', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '水海产品及制品出口', value: 'shuichanpin_out', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_shuichanpin_out', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '乳制品进口', value: 'ruzhipin_in', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_ruzhipin_in', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '乳制品出口', value: 'ruzhipin_out', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_ruzhipin_out', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
      ]
    }
  },
  '/hydata/inout/type/year': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    putUrl: '/HydataInoutType',
    putDemo: '/import/进出口品类导入模版[年度].xlsx',
    pushTip: '月度、年度的导入模版有区别，请仔细确认模版是否正确',
    name: '各品类进出口',
    apiUrl: '/HydataInoutType',
    listRouter: '/hydata/inout/type/year',
    editRouter: '/hydata/inout/type/year/edit',
    listData: { orderBy: 'year', sortBy: 'desc',time_type:'year'},
    putData: {time_type: 'year'},
    list: {
      com: '',
      params: [
        {name: '季度', value: 'year', type: 'tongjiTime',width: 150},
        {name: '蔬菜及制品进口(万吨)', value: 'shucai_in',width:160},
        {name: '同比(%)', value: 'tongbi_shucai_in'},
        {name: '蔬菜及制品出口(万吨)', value: 'shucai_out',width:160},
        {name: '同比(%)', value: 'tongbi_shucai_out'},
        {name: '水果及制品进口(万吨)', value: 'shuiguo_in',width:160},
        {name: '同比(%)', value: 'tongbi_shuiguo_in'},
        {name: '水果及制品出口(万吨)', value: 'shuiguo_out',width:160},
        {name: '同比(%)', value: 'tongbi_shuiguo_out'},
        {name: '肉类及制品进口(万吨)', value: 'rou_in',width:160},
        {name: '同比(%)', value: 'tongbi_rou_in'},
        {name: '肉类及制品出口(万吨)', value: 'rou_out',width:160},
        {name: '同比(%)', value: 'tongbi_rou_out'},
        {name: '水海产品及制品进口(万吨)', value: 'shuichanpin_in',width:200},
        {name: '同比(%)', value: 'tongbi_shuichanpin_in'},
        {name: '水海产品及制品出口(万吨)', value: 'shuichanpin_out',width:200},
        {name: '同比(%)', value: 'tongbi_shuichanpin_out'},
        {name: '乳制品进口(万吨)', value: 'ruzhipin_in',width:160},
        {name: '同比(%)', value: 'tongbi_ruzhipin_in'},
        {name: '乳制品出口(万吨)', value: 'ruzhipin_out',width:160},
        {name: '同比(%)', value: 'tongbi_ruzhipin_out'},
      ]
    },
    edit: {
      editData: {time_type: 'year'},
      cols: 4,
      chooseYearQuarter: true,
      labelWidth: '150px',
      inputWidth: 'auto',
      com: '',
      // chooseYear: true,
      params: [
        {name: '年度', value: 'year', type: 'yearPicker', rq: true, sc: true, edit:true,cols:1,
          props:{
            pickerOptions:{
              disabledDate:(v)=>{
                let year = new Date(v).getFullYear();
                return year >= 2024
              }
            }
          }
        },
        {name: '描述', value: 'content1', type: 'cleanEditor', rq: false, sc: false, edit: true,cols:1},
        {name: '蔬菜及制品进口', value: 'shucai_in', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_shucai_in', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '蔬菜及制品出口', value: 'shucai_out', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_shucai_out', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '水果及制品进口', value: 'shuiguo_in', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_shuiguo_in', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '水果及制品出口', value: 'shuiguo_out', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_shuiguo_out', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '肉类及制品进口', value: 'rou_in', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_rou_in', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '肉类及制品出口', value: 'rou_out', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_rou_out', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '水海产品及制品进口', value: 'shuichanpin_in', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_shuichanpin_in', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '水海产品及制品出口', value: 'shuichanpin_out', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_shuichanpin_out', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '乳制品进口', value: 'ruzhipin_in', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_ruzhipin_in', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
        {name: '乳制品出口', value: 'ruzhipin_out', type: 'price', rq: true, sc: false, edit: true, max: 10, append: '万吨'},
        {name: '同比', value: 'tongbi_ruzhipin_out', type: 'percent', rq: true, sc: false, edit: true, max: 10, append: '%'},
      ]
    }
  },
  
  '/hydata/inout/country/shucai/year': {
    isData: true,
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    putUrl: '/HydataInoutCountry',
    putDemo: '/import/进出口国别导入模版[年度].xlsx',
    // pushTip: '季度、年度的导入模版有区别，请仔细确认模版是否正确',
    name: '蔬菜及制品进口国别数据',
    apiUrl: '/HydataInoutCountry',
    listRouter: '/hydata/inout/country/shucai/year',
    editRouter: '/hydata/inout/country/shucai/year/edit',
    listData: {orderBy: 'year desc,times', sortBy: 'desc', time_type: 'year',type:'shucai'},
    putData: {time_type: 'year',type:'shucai'},
    list: {
      com: '',
      params: [
        {name: '年度', value: 'year', type: 'tongjiTime'},
        {name: '国别数量', value: 'amount'},
      ]
    },
    edit: {
      com: '/hydata/InoutCountry/yearEdit',
      editData: {time_type: 'year',type:'shucai'}
    }
  },
  
  '/hydata/inout/country/shuiguo/year': {
    isData: true,
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    putUrl: '/HydataInoutCountry',
    putDemo: '/import/进出口国别导入模版[年度].xlsx',
    // pushTip: '季度、年度的导入模版有区别，请仔细确认模版是否正确',
    name: '水果及制品进口国别数据',
    apiUrl: '/HydataInoutCountry',
    listRouter: '/hydata/inout/country/shuiguo/year',
    editRouter: '/hydata/inout/country/shuiguo/year/edit',
    listData: {orderBy: 'year desc,times', sortBy: 'desc', time_type: 'year',type:'shuiguo'},
    putData: {time_type: 'year',type:'shuiguo'},
    list: {
      com: '',
      params: [
        {name: '年度', value: 'year', type: 'tongjiTime'},
        {name: '国别数量', value: 'amount'},
      ]
    },
    edit: {
      com: '/hydata/InoutCountry/yearEdit',
      editData: {time_type: 'year',type:'shuiguo'}
    }
  },
  
  '/hydata/inout/country/rou/year': {
    isData: true,
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    putUrl: '/HydataInoutCountry',
    putDemo: '/import/进出口国别导入模版[年度].xlsx',
    // pushTip: '季度、年度的导入模版有区别，请仔细确认模版是否正确',
    name: '肉类及制品进口国别数据',
    apiUrl: '/HydataInoutCountry',
    listRouter: '/hydata/inout/country/rou/year',
    editRouter: '/hydata/inout/country/rou/year/edit',
    listData: {orderBy: 'year desc,times', sortBy: 'desc', time_type: 'year',type:'rou'},
    putData: {time_type: 'year',type:'rou'},
    list: {
      com: '',
      params: [
        {name: '年度', value: 'year', type: 'tongjiTime'},
        {name: '国别数量', value: 'amount'},
      ]
    },
    edit: {
      com: '/hydata/InoutCountry/yearEdit',
      editData: {time_type: 'year',type:'rou'}
    }
  },
  
  '/hydata/inout/country/shuichanpin/year': {
    isData: true,
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    putUrl: '/HydataInoutCountry',
    putDemo: '/import/进出口国别导入模版[年度].xlsx',
    // pushTip: '季度、年度的导入模版有区别，请仔细确认模版是否正确',
    name: '水海产品及制品进口国别数据',
    apiUrl: '/HydataInoutCountry',
    listRouter: '/hydata/inout/country/shuichanpin/year',
    editRouter: '/hydata/inout/country/shuichanpin/year/edit',
    listData: {orderBy: 'year desc,times', sortBy: 'desc', time_type: 'year',type:'shuichanpin'},
    putData: {time_type: 'year',type:'shuichanpin'},
    list: {
      com: '',
      params: [
        {name: '年度', value: 'year', type: 'tongjiTime'},
        {name: '国别数量', value: 'amount'},
      ]
    },
    edit: {
      com: '/hydata/InoutCountry/yearEdit',
      editData: {time_type: 'year',type:'shuichanpin'}
    }
  },
  
  '/hydata/inout/country/ruzhipin/year': {
    isData: true,
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    putUrl: '/HydataInoutCountry',
    putDemo: '/import/进出口国别导入模版[年度].xlsx',
    // pushTip: '季度、年度的导入模版有区别，请仔细确认模版是否正确',
    name: '乳制品进口国别数据',
    apiUrl: '/HydataInoutCountry',
    listRouter: '/hydata/inout/country/ruzhipin/year',
    editRouter: '/hydata/inout/country/ruzhipin/year/edit',
    listData: {orderBy: 'year desc,times', sortBy: 'desc', time_type: 'year',type:'ruzhipin'},
    putData: {time_type: 'year',type:'ruzhipin'},
    list: {
      com: '',
      params: [
        {name: '年度', value: 'year', type: 'tongjiTime'},
        {name: '国别数量', value: 'amount'},
      ]
    },
    edit: {
      com: '/hydata/InoutCountry/yearEdit',
      editData: {time_type: 'year',type:'ruzhipin'}
    }
  },
  
  '/hydata/inout/kouan': {
    isData: true,
    showAdd: true,
    showDel: true,
    showEdit: true,
    showPutFile: true,
    putUrl: '/HydataInoutKouan',
    putDemo: '/import/进出口国别导入模版[年度].xlsx',
    // pushTip: '季度、年度的导入模版有区别，请仔细确认模版是否正确',
    name: '口岸',
    apiUrl: '/HydataInoutKouan',
    listRouter: '/hydata/inout/kouan',
    editRouter: '/hydata/inout/kouan/edit',
    listData: {orderBy: 'create_time', sortBy: 'desc',},
    putData: null,
    list: {
      com: '/hydata/InoutKouan/edit',
    },
    edit: {
      com: '/hydata/InoutKouan/edit',
    }
  },
}
