<template>
  <div class="bg-light padding-20">
    <el-form :model="formData" ref="form" label-width="150px">
      <el-form-item label="名称">
        <el-input v-model="formData.title" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="时长">
        <el-input v-model="formData.to_time" style="width:100%;max-width: 400px"></el-input>
        <price-time-select class="margin-left" v-model="formData.time_type" style="width: 90px;"></price-time-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "vipedit",
  data() {
    return {
      formData:{
        id:'',
        title:'',
        to_time:'',
        old_price:'',
        price:'',
        sort:'0',
        des:'',
        state:1,
        time_type:''
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {}
}
</script>
