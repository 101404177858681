<template>
	<div class="bg-light padding-20">
		<el-row :gutter="10">
			{{form}}
			<el-form :model="form" :rules="rules" label-width="auto" ref="form">
				<el-col :span="6">
					<el-form-item label="参数类型" prop="type">
						<el-select style="width: 100%;" v-model="form.type" @change="onChangeType">
							<el-option :label="t.label" :value="t.value" v-for="(t,i) in typeList"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="18">
					<el-form-item>
						<el-input v-model="defaultValue" maxlength="20" show-word-limit clearable style="width: 200px;margin-right: 10px;"></el-input>
						<el-button type="primary" @click="editAll">批量设置</el-button>
						<el-button type="danger" @click="cleanAll">批量清空</el-button>
						<el-button type="info" @click="resetAll">重置</el-button>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="省份" prop="province_code" :rules="[{required:true,message:'省份不能为空'}]">
						<province-select style="width: 100%;" :is-obj="false" v-model="form.province_code" @input="onChangeProvince"></province-select>
					</el-form-item>
				</el-col>
				<el-col :span="18"><div style="height: 62px;"></div></el-col>
				<el-col :span="6" v-for="(t,i) in form.content_json">
					<el-form-item :label="t.name" :prop="t.code" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">
						<el-input v-model="t.value" maxlength="20" show-word-limit clearable></el-input>
					</el-form-item>
				</el-col>
<!--				<el-col :span="6">-->
<!--					<el-form-item label="天津" prop="tianjing" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.tianjing" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="河北" prop="hebei" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.hebei" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="山东" prop="shandong" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.shandong" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="江苏" prop="jiangsu" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.jiangsu" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="浙江" prop="zhejiang" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.zhejiang" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="上海" prop="shanghai" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.shanghai" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="广东" prop="guangdong" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.guangdong" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="福建" prop="fujian" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.fujian" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="海南" prop="hainan" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.hainan" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="安徽" prop="anhui" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.anhui" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="湖北" prop="hubei" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.hubei" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="湖南" prop="hunan" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.hunan" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="江西" prop="jiangxi" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.jiangxi" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="黑龙江" prop="heilongjiang" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.heilongjiang" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="吉林" prop="jilin" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.jilin" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="辽宁" prop="liaoning" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.liaoning" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="新疆" prop="xinjiang" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.xinjiang" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="甘肃" prop="gansu" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.gansu" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="宁夏" prop="ningxia" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.ningxia" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="陕西" prop="shanxi" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.shanxi" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="青海" prop="qinghai" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.qinghai" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="西藏" prop="xizang" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.xizang" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="山西" prop="shanxi2" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.shanxi2" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="四川" prop="sichuan" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.sichuan" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="重庆" prop="chongqing" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.chongqing" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="贵州" prop="guizhou" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.guizhou" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="云南" prop="yunnan" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.yunnan" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="广西" prop="guangxi" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.guangxi" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="内蒙古" prop="menggu" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.menggu" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="6">-->
<!--					<el-form-item label="河南" prop="henan" :rules="[{pattern:$regExp.money,message:'请填写正确的数值'}]">-->
<!--						<el-input v-model="form.henan" maxlength="20" show-word-limit clearable></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
				<el-col :span="24">
					<el-form-item>
						<el-button icon="el-icon-arrow-left" @click="goBack">返回</el-button>
						<el-button type="primary" @click="toSubmit">保存</el-button>
					</el-form-item>
				</el-col>
			</el-form>
		</el-row>
	</div>
</template>

<script>
export default {
	name: "type",
	data() {
		return {
			rules:{
				type:[{required:true,message:'参数类型必选'}]
			},
			aaa:'',
			bbb:'',
			form:{
				id:'',
				type:'',
				province_code:'110000',
				province_name:'',
				content_json:[],
				// beijing:'',
				// tianjing:'',
				// hebei:'',
				// shandong:'',
				// jiangsu:'',
				// zhejiang:'',
				// shanghai:'',
				// guangdong:'',
				// fujian:'',
				// hainan:'',
				// anhui:'',
				// hubei:'',
				// hunan:'',
				// jiangxi:'',
				// heilongjiang:'',
				// jilin:'',
				// liaoning:'',
				// xinjiang:'',
				// gansu:'',
				// ningxia:'',
				// shanxi:'',
				// qinghai:'',
				// xizang:'',
				// shanxi2:'',
				// sichuan:'',
				// chongqing:'',
				// guizhou:'',
				// yunnan:'',
				// guangxi:'',
				// menggu:'',
				// henan:'',
			},

			typeList:[
				{label:'餐饮收入(亿元)',value:'cysr'},
				{label:'平均冷库租金(元/托/天)',value:'lkzj'},
				{label:'生鲜食品消费量(万吨)',value:'sxspxfl'},
				{label:'人均生鲜食品消费量(KG)',value:'rjsxspxfl'},
				{label:'常住人口(人)',value:'czrk'},
				{label:'恩格尔系数(%)',value:'egexs'},
				{label:'冷库实际库容量(万立方米)',value:'lktotal'},
			],

			defaultValue:''
		}
	},
	created() {
	},
	mounted() {
	},
	methods: {
		editAll(){
			if(!this.defaultValue.trim()){
				return;
			}
			for(let i in this.form){
				if(i !== 'type' && i !== 'id'){
					this.$set(this.form,i,this.defaultValue);
				}
			}
		},
		cleanAll(){
			for(let i in this.form){
				if(i !== 'type' && i !== 'id'){
					this.$set(this.form,i,'');
				}
			}
		},
		resetAll(){
			this.defaultValue = '';
		},
		goBack(){
			this.$router.back();
		},
		onChangeType(e){
			this.getDetail()
		},
		getDetail(){
			let _this = this;
			if(!this.form.type || !this.form.province_code){
				return;
			}
			_this.$http.httpPost({
				url: "/Calculator/detail",
				datas: {type:this.form.type,province_code:this.form.province_code},
				success: res => {
					console.log(res);
					// if(!res){
					// 	for(let i in _this.form){
					// 		if(i !== 'type'){
					// 			_this.$set(_this.form,i,'');
					// 		}
					// 	}
					// }else{
					// 	_this.form.id = res.id;
					// 	for(let i in _this.form){
					// 		if(i !== 'type' && i !== 'id'){
					// 			_this.$set(_this.form,i,res[i] === '0.00' || res[i] === 0 || res[i] === '0' ? '' : parseFloat(res[i]));
					// 		}
					// 	}
					// }
				}
			});
		},
		onChangeProvince(e){
			console.log(e);
			let _this = this;
			this.form.province_name = e.name;
			_this.$http.httpPost({
				url: '/Divisions/listAll',
				datas: {pid:e.id},
				success: res => {
					console.log(res);
					let _list = [];
					for(let i=0;i < res.length;i++){
						let _data = {id:res[i].id,code:res[i].code,name:res[i].name,value:''};
						_list.push(_data);
					}
					_this.form.content_json = _list;
				}
			});
		},
		toSubmit() {
			let _this = this;
			this.$refs.form.validate(valid => {
				if (valid) {
					let _data = JSON.parse(JSON.stringify(_this.form));
					for(let i in _data){
						if(i !== 'id' && i !== 'type'){
							if(!_data[i]){
								_data[i] = '0.00'
							}
						}
					}
					_this.$http.httpPost({
						url: _data.id ? "/Calculator/edit" : '/Calculator/add',
						datas: _data,
						success: res => {
							if (res) {
								_this.$message.success("修改成功");
								_this.getDetail();
							} else {
								_this.$message.error("修改失败");
							}
						}
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
	}
}
</script>
