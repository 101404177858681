<template>
    <el-select v-model="values" placeholder="请选择" :filterable="filterable" @change="change">
        <div style="flex-direction: row;display: flex;padding:0 5px 5px 5px;" v-if="canAdd && data && data.akey">
            <el-input placeholder="请输入内容" v-model="name" maxlength="30" show-word-limit></el-input>
            <add-button @click.native="ajaxAdd" label="添加" style="margin-left:5px;"></add-button>
        </div>
        <el-option
            v-for="item in list"
            :key="item.id"
            :label="item.name"
            :value="item.id"></el-option>
    </el-select>
</template>

<script>
export default {
    name: "TypeSelect",
    data(){
        return{
            list:[],
            values:'',
            name:''
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        value:{
            type:[String,Number],
            default:''
        },
        data:{
            type:[Array,Object],
            default(){
                return null
            }
        },
        url:{
            type:String,
            default:''
        },
        options:{
            type:[Array,Object],
            default(){
                return null
            }
        },
        canAdd:{
            type:Boolean,
            default:true
        },
      filterable:{
        type:Boolean,
        default:false
      }
    },
    created() {
        this.values = this.value;
    },
    watch:{
        value(n,o){
            this.values = n;
        }
    },
    mounted() {
        if(this.options) {
            this.list = this.options;
        }else{
            this.getList();
        }
    },
    methods:{
        getList() {
            let _this = this;

            this.$http.httpPost({
                url: '/Type/listAll',
                datas: {...this.data,orderBy:'sort',sortBy:'asc'},
                success(res) {
                    _this.list = res;
                }
            });
        },
        change(e){
            this.$emit("change", e);
        },
        ajaxAdd(){
            let _this = this;

            this.$http.httpPost({
                url: '/Type/add',
                datas: {name:this.name,akey:this.data.akey},
                success(res) {
                    console.log(res);
                    _this.getList();
                    _this.name = '';
                }
            });
        }
    }
}
</script>
