export default {
  '/dict/domainReport': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showDetail: false,
    showSearch: true,
    listTree: true,
    routerData: null,
    listData: {akey:'domainReport',orderBy:'sort',sortBy:'asc'},
    name: '细分领域报告类别',
    apiUrl: '/Type',
    listUrl: '/Type/listTree',
    listRouter: '/dict/domainReport',
    editRouter: '/dict/domainReport/edit',
    addRouter: '/dict/domainReport/edit',
    delRouter: '/dict/domainReport/del',
    detailRouter: '/dict/domainReport/detail',
    optionsWidth:350,
    list: {
      com: '',
      params: [
        {name: '名称', value: 'name',width:'150px'},
        // {name: '唯一标识', value: 'code'},
        {name: '类别', value: 'avalue', type: 'custom-select',options:[{label:'内容列表',val:'list',color:'danger'},{label:'分类',val:'type',color:'success'}]},
        {name: '背景图', value: 'head_img',type:'image'},
        {name: '排序', value: 'sort'},
        {name: '状态', value: 'state', type: 'custom-select',options:[{label:'禁用',val:0,color:'danger'},{label:'可用',val:1,color:'success'}]},
        {name: '创建时间', value: 'create_time',width:'120px'},
      ]
    },
    edit: {
      com: '',
      editData:{akey:'domainReport'},
      params: [
        {name: '名称', value: 'name',rq: true, sc: true, edit: true},
        {name: '类别', value: 'avalue',rq: true, sc: true, edit: true,type:'select',options:[{name:'分类',id:'type'},{name:'内容列表',id:'list'}]},
        // {name: '唯一标识', value: 'code',rq: true, sc: true, edit: true},
        {name: '背景图', value: 'head_img',rq: false, sc: true, edit: true},
        {name: '排序', value: 'sort',def:'0',rq: true, sc: true, edit: true},
        {name: '状态', value: 'state',def:1, type:'state',rq: true, sc: true, edit: true},
      ]
    },
    add: {
      com: '',
    },
    detail: {
      com: '',
      params: [
        {name: '创建时间', value: 'create_time'},
        {name: '修改时间', value: 'update_time', type: 'timeDate'},
      ]
    },
    search: {
      params: [
        {name: '名称', value: 'name'},
        // {name: '唯一标识', value: 'code'},
        {name: '状态', value: 'state',type:'select',options:[{name:'禁用',id:'0'},{name:'可用',id:'1'}]},
      ]
    }
  },
  '/dict/news': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showDetail: false,
    showSearch: true,
    listTree: false,
    routerData: null,
    listData: {akey:'news',orderBy:'sort',sortBy:'asc'},
    name: '资讯类别',
    apiUrl: '/Type',
    // listUrl: '/Type/listPage',
    listRouter: '/dict/news',
    editRouter: '/dict/news/edit',
    addRouter: '/dict/news/edit',
    delRouter: '/dict/news/del',
    detailRouter: '/dict/news/detail',
    optionsWidth:350,
    list: {
      com: '',
      params: [
        {name: '名称', value: 'name',width:'150px'},
        {name: '唯一标识', value: 'code'},
        // {name: '类别', value: 'avalue', type: 'custom-select',options:[{label:'内容列表',val:'list',color:'danger'},{label:'分类',val:'type',color:'success'}]},
        {name: '背景图', value: 'icon',type:'image'},
        {name: '默认封面图', value: 'head_img',type:'image'},
        {name: '排序', value: 'sort'},
        {name: '状态', value: 'state', type: 'custom-select',options:[{label:'禁用',val:0,color:'danger'},{label:'可用',val:1,color:'success'}]},
        {name: '创建时间', value: 'create_time',width:'120px'},
      ]
    },
    edit: {
      com: '',
      editData:{akey:'news'},
      params: [
        {name: '名称', value: 'name',rq: true, sc: true, edit: true},
        // {name: '类别', value: 'avalue',rq: true, sc: true, edit: true,type:'select',options:[{name:'分类',id:'type'},{name:'内容列表',id:'list'}]},
        {name: '唯一标识', value: 'code',rq: true, sc: true, edit: true},
        {name: '背景图', value: 'icon',type:'image',rq: false, sc: true, edit: true,tips:'资讯首页显示'},
        {name: '默认封面图', value: 'head_img',rq: false, sc: true, edit: true},
        {name: '排序', value: 'sort',def:'0',rq: true, sc: true, edit: true},
        {name: '状态', value: 'state',def:1, type:'state',rq: true, sc: true, edit: true},
      ]
    },
    add: {
      com: '',
    },
    detail: {
      com: '',
      params: [
        {name: '创建时间', value: 'create_time'},
        {name: '修改时间', value: 'update_time', type: 'timeDate'},
      ]
    },
    search: {
      params: [
        {name: '名称', value: 'name'},
        // {name: '唯一标识', value: 'code'},
        {name: '状态', value: 'state',type:'select',options:[{name:'禁用',id:'0'},{name:'可用',id:'1'}]},
      ]
    }
  },
  '/dict/jiangxiang': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showDetail: false,
    showSearch: true,
    listTree: false,
    routerData: null,
    listData: {akey:'jiangxiang',orderBy:'sort',sortBy:'asc'},
    name: '奖项类别',
    apiUrl: '/Type',
    // listUrl: '/Type/listPage',
    listRouter: '/dict/jiangxiang',
    editRouter: '/dict/jiangxiang/edit',
    addRouter: '/dict/jiangxiang/edit',
    delRouter: '/dict/jiangxiang/del',
    detailRouter: '/dict/jiangxiang/detail',
    optionsWidth:350,
    list: {
      com: '',
      params: [
        {name: '名称', value: 'name',width:'150px'},
        // {name: '企业数量', value: 'avalue'},
        // {name: '类别', value: 'avalue', type: 'custom-select',options:[{label:'内容列表',val:'list',color:'danger'},{label:'分类',val:'type',color:'success'}]},
        {name: '封面图', value: 'head_img',type:'image'},
        {name: '排序', value: 'sort'},
        {name: '状态', value: 'state', type: 'custom-select',options:[{label:'禁用',val:0,color:'danger'},{label:'可用',val:1,color:'success'}]},
        {name: '创建时间', value: 'create_time',width:'120px'},
      ]
    },
    edit: {
      com: '',
      editData:{akey:'jiangxiang'},
      params: [
        {name: '名称', value: 'name',rq: true, sc: true, edit: true},
        // {name: '企业数量', value: 'avalue',rq: true, sc: true, edit: true,type:'number',tips:'为0时，不限数量'},
        // {name: '类别', value: 'avalue',rq: true, sc: true, edit: true,type:'select',options:[{name:'分类',id:'type'},{name:'内容列表',id:'list'}]},
        {name: '封面图', value: 'head_img',rq: true, sc: true, edit: true},
        {name: '排序', value: 'sort',def:'0',rq: true, sc: true, edit: true},
        {name: '状态', value: 'state',def:1, type:'state',rq: true, sc: true, edit: true},
      ]
    },
    add: {
      com: '',
    },
    detail: {
      com: '',
      params: [
        {name: '状态', value: 'is_check', type: 'custom-select',options:[{label:'待审核',value:0,color:'warning'},{label:'审核通过',value:1,color:'success'},{label:'审核不通过',value:2,color:'danger'}]},
        {name: '企业名称', value: 'company_name'},
        {name: '创建人', value: 'create_user.username',root:true},
        {name: '统一社会信用编号', value: 'company_code'},
        {name: '营业执照', value: 'files_ids',type:'image',root:true},
        {name: '创建时间', value: 'create_time'},
        {name: '修改时间', value: 'update_time', type: 'timeDate'},
      ]
    },
    search: {
      params: [
        {name: '名称', value: 'name'},
        // {name: '企业数量', value: 'avalue'},
        {name: '状态', value: 'state',type:'select',options:[{name:'禁用',id:'0'},{name:'可用',id:'1'}]},
      ]
    }
  },
  
  '/dict/nature': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showDetail: false,
    showSearch: true,
    listTree: false,
    routerData: null,
    listData: {akey:'nature',orderBy:'sort',sortBy:'asc'},
    name: '企业性质',
    apiUrl: '/Type',
    // listUrl: '/Type/listPage',
    listRouter: '/dict/nature',
    editRouter: '/dict/nature/edit',
    addRouter: '/dict/nature/edit',
    delRouter: '/dict/nature/del',
    detailRouter: '/dict/nature/detail',
    optionsWidth:350,
    list: {
      com: '',
      params: [
        {name: '名称', value: 'name',width:'150px'},
        // {name: '数据值', value: 'avalue'},
        // {name: '类别', value: 'avalue', type: 'custom-select',options:[{label:'内容列表',val:'list',color:'danger'},{label:'分类',val:'type',color:'success'}]},
        // {name: '封面图', value: 'head_img',type:'image'},
        {name: '排序', value: 'sort'},
        {name: '状态', value: 'state', type: 'custom-select',options:[{label:'禁用',val:0,color:'danger'},{label:'可用',val:1,color:'success'}]},
        {name: '创建时间', value: 'create_time',width:'120px'},
      ]
    },
    edit: {
      com: '',
      editData:{akey:'nature'},
      params: [
        {name: '名称', value: 'name',rq: true, sc: true, edit: true},
        // {name: '数据值', value: 'avalue',rq: true, sc: true, edit: true},
        // {name: '企业数量', value: 'avalue',rq: true, sc: true, edit: true,type:'number',tips:'为0时，不限数量'},
        // {name: '类别', value: 'avalue',rq: true, sc: true, edit: true,type:'select',options:[{name:'分类',id:'type'},{name:'内容列表',id:'list'}]},
        // {name: '封面图', value: 'head_img',rq: true, sc: true, edit: true},
        {name: '排序', value: 'sort',def:'0',rq: true, sc: true, edit: true},
        {name: '状态', value: 'state',def:1, type:'state',rq: true, sc: true, edit: true},
      ]
    },
    add: {
      com: '',
    },
    detail: {
      com: '',
      params: [
        {name: '创建时间', value: 'create_time'},
        {name: '修改时间', value: 'update_time', type: 'timeDate'},
      ]
    },
    search: {
      params: [
        {name: '名称', value: 'name'},
        // {name: '数据值', value: 'avalue'},
        {name: '状态', value: 'state',type:'select',options:[{name:'禁用',id:'0'},{name:'可用',id:'1'}]},
      ]
    }
  },
  
  '/dict/property': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showDetail: false,
    showSearch: true,
    listTree: false,
    routerData: null,
    listData: {akey:'property',orderBy:'sort',sortBy:'asc'},
    name: '企业属性',
    apiUrl: '/Type',
    // listUrl: '/Type/listPage',
    listRouter: '/dict/property',
    editRouter: '/dict/property/edit',
    addRouter: '/dict/property/edit',
    delRouter: '/dict/property/del',
    detailRouter: '/dict/property/detail',
    optionsWidth:350,
    list: {
      com: '',
      params: [
        {name: '名称', value: 'name',width:'150px'},
        {name: '排序', value: 'sort'},
        {name: '状态', value: 'state', type: 'custom-select',options:[{label:'禁用',val:0,color:'danger'},{label:'可用',val:1,color:'success'}]},
        {name: '创建时间', value: 'create_time',width:'120px'},
      ]
    },
    edit: {
      com: '',
      editData:{akey:'property'},
      params: [
        {name: '名称', value: 'name',rq: true, sc: true, edit: true},
        {name: '排序', value: 'sort',def:'0',rq: true, sc: true, edit: true},
        {name: '状态', value: 'state',def:1, type:'state',rq: true, sc: true, edit: true},
      ]
    },
    add: {
      com: '',
    },
    detail: {
      com: '',
      params: [
      ]
    },
    search: {
      params: [
        {name: '名称', value: 'name'},
        {name: '状态', value: 'state',type:'select',options:[{name:'禁用',id:'0'},{name:'可用',id:'1'}]},
      ]
    }
  },
  
  '/dict/params': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showDetail: false,
    showSearch: true,
    listTree: false,
    routerData: null,
    listData: {akey:'params',orderBy:'sort',sortBy:'asc'},
    name: '企业奖项字段',
    apiUrl: '/Type',
    // listUrl: '/Type/listPage',
    listRouter: '/dict/params',
    editRouter: '/dict/params/edit',
    addRouter: '/dict/params/edit',
    delRouter: '/dict/params/del',
    detailRouter: '/dict/params/detail',
    optionsWidth:350,
    list: {
      com: '',
      params: [
        {name: '名称', value: 'name',width:'150px'},
        {name: '排序', value: 'sort'},
        {name: '状态', value: 'state', type: 'custom-select',options:[{label:'禁用',val:0,color:'danger'},{label:'可用',val:1,color:'success'}]},
        {name: '创建时间', value: 'create_time',width:'120px'},
      ]
    },
    edit: {
      com: '',
      editData:{akey:'params'},
      params: [
        {name: '名称', value: 'name',rq: true, sc: true, edit: true},
        {name: '排序', value: 'sort',def:'0',rq: true, sc: true, edit: true},
        {name: '状态', value: 'state',def:1, type:'state',rq: true, sc: true, edit: true},
      ]
    },
    add: {
      com: '',
    },
    detail: {
      com: '',
      params: [
      ]
    },
    search: {
      params: [
        {name: '名称', value: 'name'},
        {name: '状态', value: 'state',type:'select',options:[{name:'禁用',id:'0'},{name:'可用',id:'1'}]},
      ]
    }
  },
  
  '/dict/policy': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showDetail: false,
    showSearch: true,
    listTree: false,
    routerData: null,
    listData: {akey:'policy',orderBy:'sort',sortBy:'asc'},
    name: '政策分类',
    apiUrl: '/Type',
    // listUrl: '/Type/listPage',
    listRouter: '/dict/policy',
    editRouter: '/dict/policy/edit',
    addRouter: '/dict/policy/edit',
    delRouter: '/dict/policy/del',
    detailRouter: '/dict/policy/detail',
    optionsWidth:350,
    list: {
      com: '',
      params: [
        {name: '名称', value: 'name',width:'150px'},
        // {name: '类别', value: 'avalue', type: 'custom-select',options:[{label:'内容列表',val:'list',color:'danger'},{label:'分类',val:'type',color:'success'}]},
        {name: '背景图', value: 'head_img',type:'image'},
        {name: '排序', value: 'sort'},
        {name: '状态', value: 'state', type: 'custom-select',options:[{label:'禁用',val:0,color:'danger'},{label:'可用',val:1,color:'success'}]},
        {name: '创建时间', value: 'create_time',width:'120px'},
      ]
    },
    edit: {
      com: '',
      editData:{akey:'policy'},
      params: [
        {name: '名称', value: 'name',rq: true, sc: true, edit: true},
        {name: '背景图', value: 'head_img',rq: false, sc: true, edit: true},
        {name: '排序', value: 'sort',def:'0',rq: true, sc: true, edit: true},
        {name: '状态', value: 'state',def:1, type:'state',rq: true, sc: true, edit: true},
      ]
    },
    add: {
      com: '',
    },
    detail: {
      com: '',
      params: [
        {name: '创建时间', value: 'create_time'},
        {name: '修改时间', value: 'update_time', type: 'timeDate'},
      ]
    },
    search: {
      params: [
        {name: '名称', value: 'name'},
        // {name: '唯一标识', value: 'code'},
        {name: '状态', value: 'state',type:'select',options:[{name:'禁用',id:'0'},{name:'可用',id:'1'}]},
      ]
    }
  },
  
  '/dict/standard': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showDetail: false,
    showSearch: true,
    listTree: false,
    routerData: null,
    listData: {akey:'standard',orderBy:'sort',sortBy:'asc'},
    name: '标准分类',
    apiUrl: '/Type',
    // listUrl: '/Type/listPage',
    listRouter: '/dict/standard',
    editRouter: '/dict/standard/edit',
    addRouter: '/dict/standard/edit',
    delRouter: '/dict/standard/del',
    detailRouter: '/dict/standard/detail',
    optionsWidth:350,
    list: {
      com: '',
      params: [
        {name: '名称', value: 'name',width:'150px'},
        // {name: '类别', value: 'avalue', type: 'custom-select',options:[{label:'内容列表',val:'list',color:'danger'},{label:'分类',val:'type',color:'success'}]},
        {name: '背景图', value: 'head_img',type:'image'},
        {name: '排序', value: 'sort'},
        {name: '状态', value: 'state', type: 'custom-select',options:[{label:'禁用',val:0,color:'danger'},{label:'可用',val:1,color:'success'}]},
        {name: '创建时间', value: 'create_time',width:'120px'},
      ]
    },
    edit: {
      com: '',
      editData:{akey:'standard'},
      params: [
        {name: '名称', value: 'name',rq: true, sc: true, edit: true},
        {name: '背景图', value: 'head_img',rq: false, sc: true, edit: true},
        {name: '排序', value: 'sort',def:'0',rq: true, sc: true, edit: true},
        {name: '状态', value: 'state',def:1, type:'state',rq: true, sc: true, edit: true},
      ]
    },
    add: {
      com: '',
    },
    detail: {
      com: '',
      params: [
        {name: '创建时间', value: 'create_time'},
        {name: '修改时间', value: 'update_time', type: 'timeDate'},
      ]
    },
    search: {
      params: [
        {name: '名称', value: 'name'},
        // {name: '唯一标识', value: 'code'},
        {name: '状态', value: 'state',type:'select',options:[{name:'禁用',id:'0'},{name:'可用',id:'1'}]},
      ]
    }
  },
  
  '/setting/menu': {
    showAdd: true,
    showDel: true,
    showEdit: true,
    showDetail: false,
    showSearch: true,
    listTree: false,
    routerData: null,
    listData: {akey:'menu',orderBy:'sort',sortBy:'asc'},
    name: '菜单设置',
    apiUrl: '/Type',
    // listUrl: '/Type/listPage',
    listRouter: '/setting/menu',
    editRouter: '/setting/menu/edit',
    addRouter: '/setting/menu/edit',
    delRouter: '/setting/menu/del',
    detailRouter: '/setting/menu/detail',
    optionsWidth:350,
    list: {
      com: '',
      params: [
        {name: '名称', value: 'name',width:'80px'},
        {name: 'PC端页面地址', value: 'avalue'},
        {name: 'PC端头图', value: 'head_img',type:'image'},
        {name: '小程序端头图', value: 'icon',type:'image'},
        {name: '编码', value: 'code'},
        {name: '排序', value: 'sort'},
        {name: '状态', value: 'state', type: 'custom-select',options:[{label:'禁用',val:0,color:'danger'},{label:'可用',val:1,color:'success'}]},
        {name: '创建时间', value: 'create_time',width:'120px'},
      ]
    },
    edit: {
      com: '',
      editData:{akey:'menu'},
      params: [
        {name: '名称', value: 'name',rq: true, sc: true, edit: true},
        {name: '编码', value: 'code',rq: true, sc: true, edit: true},
        {name: '主标题', value: 'title',rq: true, sc: true, edit: true},
        {name: '副标题', value: 'sub_title',rq: false, sc: true, edit: true},
        {name: '描述', value: 'des',rq: false, sc: true, edit: true},
        {name: '标题排列', value: 'align',rq: true, sc: true, edit: true,type:'radioGroup',options:[{label:'居左',value:'left'},{label:'居中',value:'center'}]},
        {name: 'PC端页面地址', value: 'avalue',rq: true, sc: true, edit: true},
        {name: 'PC端头图', value: 'head_img',rq: true, sc: true, edit: true},
        {name: '小程序端头图', value: 'icon',rq: true, sc: true, edit: true,type:'image'},
        {name: '排序', value: 'sort',def:'0',rq: true, sc: true, edit: true},
        {name: '状态', value: 'state',def:1, type:'state',rq: true, sc: true, edit: true},
      ]
    },
    add: {
      com: '',
    },
    detail: {
      com: '',
      params: [
      ]
    },
    search: {
      params: [
        {name: '名称', value: 'name'},
        {name: '状态', value: 'state',type:'select',options:[{name:'禁用',id:'0'},{name:'可用',id:'1'}]},
      ]
    }
  },
}
