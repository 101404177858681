<template>
    <el-button type="warning" :size="size" icon="el-icon-delete" :disabled="disabled">{{label}}</el-button>
</template>

<script>
export default {
    name: "DelButton",
    props: {
        rule: '',
        disabled: {
            type: Boolean,
            default: false
        },
        size:{
            type:String,
            default:'mini'
        },
        label:{
            type:String,
            default:'删除'
        }
    },
}
</script>
