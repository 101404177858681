<template>
    <div>
        <el-row :gutter="10">
            <el-form :model="searchForm" label-width="100px">
                <el-col :span="5" v-for="(item,index) in params['edit']['params']" v-if="item.sc && typeof item.value === 'string'">
                    <el-form-item :label="item.name">
                        <type-select v-if="item.type === 'select'" v-model="searchForm[item.value]" @change="(e)=>$set(searchForm,item.value,e)" :options="item.options ? item.options : null" :data="item.data ? item.data : null" :canAdd="false"></type-select>
                        <year-select v-model="searchForm.year" v-else-if="item.value === 'year'"></year-select>
                        <province-select v-model="searchForm[item.value]" v-else-if="item.type === 'province'" @change="changeProvince" :is-obj="false"></province-select>
                        <el-input v-model="searchForm[item.value]" class="block" v-else></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="5" v-if="params && params.edit.chooseYear">
                    <el-form-item label="年份">
                        <year-select v-model="searchForm.year"></year-select>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="toSearch">搜索</el-button>
                    <el-button type="info" @click="toReset">重置</el-button>
                </el-col>
            </el-form>
        </el-row>
        <div class="margin-bottom margin-top">
            <add-button @click.native="$router.push({path:path+'/edit'})" label="添加数据" v-if="params.showAdd"></add-button>
        </div>
        <div class="border-top" v-if="list.length > 0">
            <el-table
                :data="list"
                style="width: 100%">
                <template v-for="(item,index) in params['list']['params']">
                    <el-table-column :label="item.name" :prop="item.value" v-if="item.value === 'mm_type'">
                        <template slot-scope="scope">
                            <span v-if="scope.row.mm_type === 'medicine'">药品</span>
                            <span v-if="scope.row.mm_type === 'apparatus'">医疗器材</span>
                            <span v-if="scope.row.mm_type === 'kfq'">经济技术开发区</span>
                            <span v-if="scope.row.mm_type === 'sw'">生物医药产业园区</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'percent'">
                        <template slot-scope="scope">
                            {{ (scope.row[item.value] * 100).toFixed(2)}}%
                        </template>
                    </el-table-column>
                    <el-table-column :label="item.name" :prop="item.value" v-else></el-table-column>
                </template>
                <el-table-column align="right" width="250" label="操作">
                    <template slot-scope="scope">
                        <edit-button @click.native="$router.push({ path: path+'/edit',query:{id:scope.row.id }})" v-if="params.showEdit"></edit-button>
                        <del-button @click.native="del(scope.row.id)" v-if="params.showDel"></del-button>
                        <base-button @click.native="$router.push({path:path+'/detail',query:{id:scope.row.id }})" v-if="params.showDetail">详情</base-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="text-center padding-top-bottom-20 rel">
                <el-pagination
                    background
                    layout="total,prev, pager, next"
                    :page-size="searchForm.pageSize"
                    :current-page.sync="searchForm.page"
                    :total="totalCount"
                    @current-change="pageChange">
                </el-pagination>
            </div>
        </div>
        <div class="padding-top-bottom-40 border-top text-center dark-2" v-else>
            暂无数据，<add-button @click.native="$router.push({path:path+'/edit'})" v-if="params.showAdd"></add-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "PolicyList",
    data() {
        return {
            path:'',
            params:null,
            totalCount: 0,
            list: [],
            searchForm: {
                pageSize:15,
                page:1,
            },
            listSort:null,
            addTotal:{},
            addTotalType:false,
            rules: {},
            province:''
        };
    },
    created() {
        let _config = this.$baseConfig;
        this.path = this.$route.path;
        this.params = _config[this.path];

        // if(this.params.apiUrl){
        //     this.path = this.params.apiUrl;
        // }
        //
        // console.log(this.$route.params.type);

        this.listSort = this.params.listSort ? this.params.listSort : {};
        if(this.$route.params.type){
            this.$set(this.searchForm,'type',this.$route.params.type);
        }

        let _edit = this.params['edit']['params'];

        for(let i = 0;i < _edit.length;i++){
            if(_edit[i].sc && typeof _edit[i].value === 'string') {
                if (_edit[i].value === 'permission' || _edit[i].value === 'attachment') {
                    this.$set(this.searchForm, _edit[i].value, []);
                } else {
                    this.$set(this.searchForm, _edit[i].value, '');
                }
            }
        }

        if(this.params['edit'].chooseYear){
            this.$set(this.searchForm, 'year', '');
        }

        this.searchForm = this.$route.query ? {...this.searchForm,...this.$route.query} : {...this.searchForm};
        this.searchForm.page = parseInt(this.searchForm.page);
        this.searchForm.pageSize = parseInt(this.searchForm.pageSize);
    },
    mounted() {
        this.getList();
    },
    methods: {
        changeProvince(e){
            this.searchForm.province_code = e;
            // this.searchForm.province_name = e.name;
        },
        toSearch() {
            this.searchForm.page = 1;
            this.$router.replace({query:{...this.searchForm}});
        },
        toReset(){
            let _edit = this.params['edit']['params'];

            for(let i = 0;i < _edit.length;i++){
                if(_edit[i].sc && typeof _edit[i].value === 'string') {
                    if(_edit[i].value === 'province_code'){

                    }else {
                        this.$set(this.searchForm, _edit[i].value, '');
                    }
                }
            }

            if(this.params.edit.chooseYear){
                this.$set(this.searchForm,'year', '');
            }

            this.searchForm.page = 1;

            this.$router.replace({query:{...this.searchForm}});
        },
        getList() {
            let _this = this;
            this.$http.httpPost({
                url: this.params.apiUrl+'/listPage',
                datas: {..._this.searchForm,...this.listSort},
                success(res) {
                    _this.list = res.list ? res.list : [];
                    _this.totalCount = res.totalCount;
                }
            });
        },
        pageChange(page) {
            this.searchForm.page = page;
            this.$router.replace({query:{...this.searchForm}});
        },
        del(id) {
            let _this = this;
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.$http.httpPost({
                        url: _this.params.apiUrl + '/del',
                        datas: {id: id,del:1},
                        success(res) {
                            _this.$utils.success("删除成功！");
                            _this.getList();
                        }
                    });
                })
                .catch(() => {
                    // _this.$utils.warning('取消了');
                });
        }
    }
};
</script>
