<template>
	<div>
		<template v-if="typeof item.edit === 'function' ? item.edit(formData) : item.edit">
			<type-select v-if="item.type === 'select'" v-model="formData[item.value]" @change="(e)=>$set(formData,item.value,e)" :options="item.options ? item.options : null" :data="item.data ? item.data : null" :style="{width:item.width ? item.width : (params.edit.inputWidth ? params.edit.inputWidth : '100%'),maxWidth:'500px'}" :can-add="item.canAdd ? item.canAdd : false"></type-select>
			<company-select v-else-if="item.type === 'company'" v-model="formData[item.value]" @change="(e)=>$set(formData,item.value,e)" style="width: 500px;"></company-select>
			<editor v-model="formData[item.value]" ref="editor" v-else-if="item.type === 'editor'" style="width:100%;position: relative;z-index: 1;"></editor>
			<clean-editor v-model="formData[item.value]" ref="cleanEditor" v-else-if="item.type === 'cleanEditor'" style="width:100%;position: relative;z-index: 1;"></clean-editor>
			<el-input type="textarea" :rows="8" :placeholder="'请输入'+item.name" v-model="formData[item.value]" v-else-if="item.type === 'textarea'" style="width: 500px;" :maxlength="item.max ? item.max : ''" show-word-limit>
			</el-input>
			<head-img-upload v-model="formData.head_img" :img="formData.head_img ? $config.assetsUrl + formData.head_img : ''" v-else-if="item.value === 'head_img'" @change="(e)=>$set(formData,'head_img',e ? e : '')"></head-img-upload>
			<head-img-upload v-model="formData[item.value]" :img="$utils.formatImagePath(formData[item.value])" v-else-if="item.type === 'image'" @change="(e)=>$set(formData,item.value,e ? e : '')"></head-img-upload>
			<push-time v-model="formData.todo_time" style="width: 500px;" v-else-if="item.value === 'todo_time'"></push-time>
			<push-time v-model="formData.push_time" style="width: 500px;" v-else-if="item.value === 'push_time'"></push-time>
			<year-picker v-model="formData[item.value]" v-else-if="item.type === 'yearPicker'" @change="onChangeTime" :props="item.props"></year-picker>
			<month-picker v-model="timesQuarter2" v-else-if="item.type === 'monthPicker'" @change="onChangeTime"></month-picker>
			<quarter-picker v-model="timesQuarter2" v-else-if="item.type === 'quarterPicker'" @change="onChangeTime" :props="item.props"></quarter-picker>
			<half-picker v-model="timesQuarter2" v-else-if="item.type === 'halfPicker'" @change="onChangeTime"></half-picker>
			<year-select v-model="formData.year" :style="{width:item.width ? item.width : (params.edit.inputWidth ? params.edit.inputWidth : '500px')}" v-else-if="item.value === 'year'"></year-select>
			<file-upload v-model="formData[item.value]" v-else-if="item.type === 'file'" :files="formData[item.fileKey ? item.fileKey : 'files']"></file-upload>
			<template v-else-if="item.type === 'totime'">
				<el-input v-model="formData[item.value]" :style="{width:item.width ? item.width : (params.edit.inputWidth ? params.edit.inputWidth : '100%'),maxWidth: '400px'}"></el-input>
				<price-time-select class="margin-left" v-model="formData.time_type" style="width: 90px;"></price-time-select>
			</template>
			<el-switch
				v-else-if="item.type === 'switch'"
				v-model="formData[item.value]"
				:active-value="1"
				:inactive-value="0"
				active-color="#13ce66">
			</el-switch>
			<el-switch
				v-model="formData[item.value]"
				active-color="#13ce66"
				:active-value="1"
				:inactive-value="0"
				active-text="启用"
				inactive-text="禁用" v-else-if="item.type === 'state'">
			</el-switch>
			<el-input-number v-model="formData[item.value]" :min="0" :max="999999" :step="1" v-else-if="item.type === 'number'" style="width: 100%;"></el-input-number>
			<el-switch
				v-model="formData[item.value]"
				active-color="#13ce66"
				:active-value="1"
				:inactive-value="0"
				active-text="是"
				inactive-text="否" v-else-if="item.type === 'bool'">
			</el-switch>
			<province-select v-model="province2" v-else-if="item.type === 'province'" @change="changeProvince" :style="{width:item.width ? item.width : (params.edit.inputWidth ? params.edit.inputWidth : '100%'),maxWidth:'500px'}"></province-select>
			<el-radio-group v-model="formData[item.value]" v-else-if="item.type === 'radioGroup'" @change="onChangeRadio(item)" :disabled="item.disabledById ? !!formData.id : false">
				<el-radio :label="tt.value" v-for="(tt,ii) in item.options">{{tt.label}}</el-radio>
			</el-radio-group>
			<el-form-item v-else-if="item.type === 'vipPrice'">
				<price-vip @change="changeVipPrice" :value="{price:formData.price,user_vip_type:formData.user_vip_type}"></price-vip>
			</el-form-item>
			<el-form-item v-else-if="item.type === 'vip'">
				<block-vip v-model="formData.user_vip_type"></block-vip>
			</el-form-item>
			<tree-select v-else-if="item.type === 'treeSelect'" :props="item.props" :url="item.url" :query-data="item.queryData" v-model="formData[item.value]" style="width: 500px"></tree-select>
			<el-input v-model="formData[item.value]" :style="{width:item.width ? item.width : (params.edit.inputWidth ? params.edit.inputWidth : '100%'),maxWidth:'500px'}" :maxlength="item.max ? item.max : ''" show-word-limit :disabled="!!item.disabled" v-else>
				<template slot="prepend" v-if="item.prepend">{{ item.prepend }}</template>
				<template slot="append" v-if="item.append">{{ item.append }}</template>
			</el-input>
			<span class="margin-left" v-if="item.tips">{{ item.tips ? item.tips : '' }}</span>
		</template>
		<template v-else>
			{{ formData[item.value] }}
		</template>
	</div>
</template>

<script>
export default {
	name: "editForm",
	data() {
		return {
			province2:'',
			timesQuarter2:''
		}
	},
	props:{
		item:{
			type:Object,
			default(){
				return {}
			}
		},
		formData:{
			type:Object,
			default(){
				return {}
			}
		},
		params:{
			type:Object,
			default(){
				return {}
			}
		},
		province:{
			type:[Object,String],
			default(){
				return ''
			}
		},
		timesQuarter:{
			type:[Object,String],
			default(){
				return ''
			}
		}
	},
	created() {
		this.province2 = this.province;
		this.timesQuarter2 = this.timesQuarter;
	},
	watch:{
		province(n,o){
			this.province2 = n;
		},
		timesQuarter(n,o){
			this.timesQuarter2 = n;
		}
	},
	mounted() {
	},
	methods: {
		onChangeTime(e){
			this.$emit('onChangeTime',e);
		},
		onChangeRadio(e){
			this.$emit('onChangeRadio',e);
		},
		changeVipPrice(e){
			this.$emit('changeVipPrice',e);
		},
		changeProvince(e){
			this.$emit('changeProvince',e);
		}
	}
}
</script>
