<template>
    <div>
        <div class="el-upload el-upload--picture-card margin-right rel hide margin-bottom" v-if="values">
            <img class="el-upload-list__item-thumbnail" :src="values.indexOf('http') >= 0 ? values : $config.assetsUrl + values" alt="" style="width: 100%;">
            <span class="miihe-upload-item">
                <span @click="showBigImg">
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span @click="delImg">
                  <i class="el-icon-delete"></i>
                </span>
              </span>
        </div>
        <el-upload
            :show-file-list="false"
            :action="$config.uploadUrl"
            :data="{key:imgKey}"
            :headers="{Authorization:$user.getToken()}"
            accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.GIF,.BMP"
            ref="upload"
            :on-success="onSuccess"
            :on-exceed="onExceed"
            :file-list="fileList"
            :limit="limit"
            :auto-upload="true">
            <el-button size="small" type="primary">点击上传</el-button>
					<div slot="tip">{{tip}}</div>
        </el-upload>
        <el-dialog :visible.sync="show" append-to-body>
            <img width="100%" :src="bigImg" alt="">
        </el-dialog>
    </div>
</template>

<script>
import emitter from "element-ui/src/mixins/emitter";

export default {
    name: "HeadImgUpload",
  mixins: [emitter],
    data() {
        return {
            bigImg: '',
            show: false,
            imgKey:'headImg',
            fileList:[],
            values:''
        };
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
      value:{
        type:String,
        default:''
      },
      limit:{
        type:Number,
        default:1
      },
      tip:{
        type:String,
        default:'图片格式：jpeg、jpg、png、gif、bmp；图片大小：不能超过300Mb；'
      }
    },
    created() {
        this.values = this.value;
    },
    watch:{
        value(n,o){
            this.values = n;

            if(!n){
                this.fileList = [];
            }
        }
    },
    methods: {
        delImg(){
            this.values = '';
            this.$refs.upload.clearFiles();
            this.$emit('change','');

          this.dispatch('ElFormItem', 'el.form.change', [''])
          this.dispatch('ElFormItem', 'el.form.blur', [''])
        },
        showBigImg() {
            this.bigImg = this.values.indexOf('http') >= 0 ? this.values : this.$config.assetsUrl + this.values;
            this.show = true;
        },
        onSuccess(response, file, fileList) {
            if(response.code !== 0){
                this.$utils.error(response.msg);
                this.fileList = [];
            }else{
                this.fileList = fileList;

                this.values = response.result.url;

                this.$emit('change',response.result.url);

              this.dispatch('ElFormItem', 'el.form.change', [response.result.url])
              this.dispatch('ElFormItem', 'el.form.blur', [response.result.url])
            }
        },
        onExceed(f,fl){
            console.log('323');
        }
    },
    destroyed() {
        console.log('dis');
    }
}
</script>
