<template>
  <el-main class="bg-info-light">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="bread-crumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item v-for="item in breadText">{{item}}</el-breadcrumb-item>
    </el-breadcrumb>
    <span style="margin-top: -37px;float: right;"><slot name="right-btn"></slot></span>
    <slot></slot>
  </el-main>
</template>

<script>
    export default {
        name: "MainLayout",
        data() {
            return {
                breadText: []
            };
        },
        methods: {
            bread() {
                this.breadText = [];

                for (let i = 0; i < this.$route.meta.length; i++) {
                    this.breadText.push(this.$route.meta[i]);
                }
            }
        },
        mounted() {
            this.breadText = [];
            for (let i = 0; i < this.$route.meta.length; i++) {
                this.breadText.push(this.$route.meta[i]);
            }
        }
    }
</script>
