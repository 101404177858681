<template>
    <div class="page-content-main">
        <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="auto" class="bg-light" style="width: 300px;margin: 0 auto;">
            <el-form-item label="" prop="old_pass" class="margin-top">
                <el-input v-model="formData.old_pass" placeholder="旧密码"></el-input>
            </el-form-item>
            <el-form-item label="" prop="new_pass" class="margin-top">
                <el-input v-model="formData.new_pass" placeholder="新密码"></el-input>
            </el-form-item>
            <el-form-item label="" prop="re_pass" class="margin-top">
                <el-input v-model="formData.re_pass" placeholder="重复新密码"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">修改</el-button>
                <el-button type="" @click="$router.back()">返回</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
name: "changePass",
    data() {
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.formData.new_pass) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            formData: {
                old_pass: '',
                new_pass: '',
                re_pass:''
            },
            rules: {
                old_pass: [
                    { required: true, message: '请输入旧密码', trigger: 'blur' }
                ],
                new_pass: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { min: 4, max: 20, message: '请输入4-20位密码', trigger: 'blur' }
                ],
                re_pass: [
                    { required: true, message: '请输入重复新密码'},
                    { min: 4, max: 20, message: '请输入4-20位密码'},
                    {validator: validatePass2}
                ]
            },
        };
    },
    methods:{
        submitForm(form){
            let _this = this;
            this.$refs[form].validate((valid) => {
                if (valid) {
                    _this.$http.httpPost({
                        url: '/Member/changePwd',
                        datas: _this.formData,
                        success: (res) => {
                            if(res === 'ok'){
                                _this.$message.success({message: '密码修改成功，请重新登录', duration: 2000});
                                _this.$utils.sessionClear();
                                _this.$router.replace({path:'/login'});
                            }
                        }
                    })
                }
            })
        }
    }
}
</script>
