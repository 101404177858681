<template>
  <div class="bg-light padding-20">
    <el-table :data="list">
      <el-table-column label="用户昵称" width="100">
        <template slot-scope="scope">
          {{scope.row.user.nickname}}
        </template>
      </el-table-column>
      <el-table-column label="用户姓名" width="100">
        <template slot-scope="scope">
          {{scope.row.user.name}}
        </template>
      </el-table-column>
      <el-table-column label="开票类型" width="100">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.open_type === 'person'">个人</el-tag>
          <el-tag type="danger" v-if="scope.row.open_type === 'company'">企业</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.state === 0">待审核</el-tag>
          <el-tag type="success" v-if="scope.row.state === 1">已开票</el-tag>
          <el-tag type="danger" v-if="scope.row.state === 2">已拒绝</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="发票类型" width="100">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.type === 'email'">电子票</el-tag>
          <el-tag type="warning" v-if="scope.row.type === 'paper'">纸质票</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="开票名称" prop="title"></el-table-column>
	    <el-table-column label="开票金额" prop="pay_amount"></el-table-column>
      <el-table-column label="发票税号" prop="number"></el-table-column>
<!--      <el-table-column label="开票事项" prop="program"></el-table-column>-->
      <el-table-column label="申请时间" prop="create_time"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <base-button plain type="success" @click.native="changeState(scope.row,1)" v-if="scope.row.state === 0">审核</base-button>
          <base-button plain type="danger" @click.native="changeState(scope.row,0)" v-if="scope.row.state === 1 || scope.row.state === 2">反审核</base-button>
          <base-button @click.native="toDetail(scope.row)">详情</base-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center padding-top-bottom-20 rel">
      <el-pagination
        background
        layout="total,prev, pager, next,jumper"
        :page-size="searchForm.pageSize"
        :current-page.sync="searchForm.page"
        :total="totalCount"
        @current-change="pageChange">
      </el-pagination>
    </div>
    <el-dialog title="提示" :visible.sync="showDialog" @closed="onDialogClose" width="400px">
      <div class="text-center f18 padding-bottom-30">{{dialogText}}</div>
      <el-form :model="form" ref="form" label-width="100px" v-if="form.state === 1 || form.state === 2">
        <el-form-item label="状态" :rules="[{required:true,message:'请选择审核状态'}]">
          <el-radio-group v-model="form.state">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="form.state === 1">
          <template v-if="form.type === 'paper'">
            <el-form-item label="快递公司" prop="shipping_name" :rules="[{required:true,message:'请输入快递公司'}]">
              <el-input v-model="form.shipping_name" autocomplete="off" placeholder="请输入快递公司" maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="快递单号" prop="shipping_number" :rules="[{required:true,message:'请输入快递单号'}]">
              <el-input v-model="form.shipping_number" autocomplete="off" placeholder="请输入快递单号" maxlength="50" show-word-limit></el-input>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item label="邮箱地址">
              {{form.email}}
            </el-form-item>
            <el-form-item label="发票文件" prop="file" :rules="[{required:true,message:'请上传发票文件'}]">
              <head-img-upload tip="" v-model="form.file"></head-img-upload>
            </el-form-item>
          </template>
        </template>
        <template v-else-if="form.state === 2">
          <el-form-item label="拒绝原因" prop="reason" :rules="[{required:true,message:'请输入拒绝原因'}]">
            <el-input v-model="form.reason" autocomplete="off" placeholder="请输入拒绝原因" maxlength="50" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "list",
  data() {
    return {
      list:[],
      searchForm:{
        page:1,
        pageSize:15
      },
      form:{
        shipping_name:'',
        shipping_number:'',
        id:'',
        state:'',
        type:'',
        email:'',
        file:'',
        reason:''
      },
      info:null,
      dialogText:'',
      totalCount:0,
      showDialog:false
    }
  },
  created() {
  },
  mounted() {
    this.getList();
  },
  methods: {
    onDialogClose(){
      this.form.state = '';
      this.form.id = '';
      this.form.shipping_number = '';
      this.form.shipping_name = '';
      this.form.type = '';
      this.form.email = '';
      this.form.file = '';
      this.dialogText = '';
    },
    getList(){
      let _this = this;
      this.$http.httpPost({
        url: '/UserInvoiceLog/listPage',
        datas: this.searchForm,
        success(res) {
          console.log(res);
          _this.list = res.list;
          _this.totalCount = res.totalCount;
        }
      });
    },
    pageChange(e){
      this.searchForm.page = e;

      this.getList();
    },
    toDetail(e){
      this.$router.push(({path:'/user/invoice/log/detail',query:{id:e.id}}))
    },
    submit(){
      let _this = this;
      if(this.$refs['form']) {
        this.$refs['form'].validate(valid => {
          //为true时验证通过
          if (valid) {
            let _text = '';

            if(_this.form.state === 1) {
              if (_this.form.type === 'email') {
                _text = '是否确定发送电子发票到邮箱 ' + _this.form.email;
              } else {
                _text = '是否已将纸质发票邮寄到 ' + _this.info.address;
              }
            }else if(_this.form.state === 2){
              _text = '是否确定拒绝此申请？';
            }

            _this.$confirm(_text, '确认信息', {
              distinguishCancelAndClose: true,
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              showClose:false,
            }).then(() => {
                _this.toSave();
              }).catch(action => {
                // _this.onDialogClose();
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }else{
        this.toSave();
      }
    },
    toSave(){
      let _this = this;
      let loading = null;
      if(_this.form.type === 'email' && _this.form.state === 1){
        loading = this.$loading({
          lock: true,
          text: '发送邮件中...',
          spinner: 'el-icon-loading',
        });
      }

      _this.$http.httpPost({
        url: '/UserInvoiceLog/changeState',
        datas: _this.form,
        success(res) {
          _this.$utils.success("操作成功！");
          _this.getList();
          if(_this.form.type === 'email' && _this.form.state === 1) {
            loading.close()
          }

          _this.showDialog = false;
        },
        fail(err){
          if(_this.form.type === 'email' && _this.form.state === 1) {
            loading.close()
          }

          _this.$message.error(err.msg);
        }
      });
    },
    changeState(row,state){
      let _this = this;

      if(state === 1){
        this.dialogText = '是否确定通过审核？'
      }else if(state === 0){
        this.dialogText = '是否确定恢复到待审核状态？'
      }else if(state === 2){
        this.dialogText = '是否拒绝改申请？'
      }

      this.form.state = state;
      this.form.id = row.id;
      this.form.type = row.type;
      this.form.email = row.email;
      this.info = row;

      this.showDialog = true;
    }
  }
}
</script>
