import Vue from 'vue'
let self = new Vue();

export default {
    setToken(v){
        self.$utils.sessionSet('token',v);
    },

    getToken(){
        return self.$utils.sessionGet('token');
    },

    setUserName(v){
        self.$utils.sessionSet('username',v);
    },

    getUserName(){
        return self.$utils.sessionGet('username');
    },

    setUid(v){
        self.$utils.sessionSet('uid',v);
    },

    getUid(){
        return self.$utils.sessionGet('uid');
    }
}
